import React, { type FC } from 'react';
import { Typography } from '@mui/material';

import styles from './styles';
import Div from '../../../shared/ui/Div';
import { LingoLiftDashboardSummaryType } from '../model/enums';
import Image from '../../../shared/ui/Image';
import watchIcon from '../../../assets/secondaryIcons/watchIcon.svg';
import learnIcon from '../../../assets/secondaryIcons/learnIcon.svg';
import speakIcon from '../../../assets/secondaryIcons/speakIcon.svg';

interface IDashboardCard {
  title: string;
  percentage: string;
  statistics: string;
  iconType: string;
}

const DashboardSummaryCard: FC<IDashboardCard> = props => {
  const { title, percentage, statistics, iconType } = props;

  return (
    <Div sx={styles.root}>
      <Div>
        <Typography variant="h5" sx={styles.title}>
          {title}
        </Typography>
        <Typography variant="h2" sx={styles.percentage}>
          {percentage}
        </Typography>
        <Typography variant="body2">{statistics}</Typography>
      </Div>
      <Div>
        {iconType === LingoLiftDashboardSummaryType.watch && <Image src={watchIcon} alt="Listenwise watch card icon" />}
        {iconType === LingoLiftDashboardSummaryType.learn && <Image src={learnIcon} alt="Listenwise learn card icon" />}
        {iconType === LingoLiftDashboardSummaryType.speak && <Image src={speakIcon} alt="Listenwise speak card icon" />}
      </Div>
    </Div>
  );
};

export default DashboardSummaryCard;
