export const parseSingleQueryFromUrl = () => {
  return Object.fromEntries(new URLSearchParams(window.location.search))
}

export const clearQueryParams = () => {
  const currentUrl = new URL(window.location.href);

  // Clear queries in url before set
  currentUrl.search = ''
  window.history.pushState({}, '', currentUrl)
}
