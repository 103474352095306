enum LingoLiftSummaryType {
  watch = 'watch',
  learn = 'learn',
  speak = 'speak',
}

enum LingoLiftSummaryAssignedItemStatus {
  Completed = 'Completed',
  Started = 'Started',
  NotStarted = "Not Started"
}

enum LingoLiftSummaryStatus {
  success = 'success',
  failure = 'failure',
}

enum LingoLiftSummaryWordStatus {
  clear = 'clear',
  satisfactory = 'satisfactory',
  poor = 'poor',
  additionalWords = 'additionalWords',
  wordsLearned = 'wordsLearned',
}

enum LingoLiftSummaryWordEvaluation {
  Insertion = 'INSERTION',
  Pause = 'PAUSE',
  Substitution = 'SUBSTITUTION',
  Match = 'MATCH',
  Deletion = 'DELETION',
}

enum LingoLiftSummaryWordStatus {
  Good = 'GOOD',
  Bad = 'BAD'
}

export {
  LingoLiftSummaryType,
  LingoLiftSummaryAssignedItemStatus,
  LingoLiftSummaryStatus,
  LingoLiftSummaryWordStatus,
  LingoLiftSummaryWordEvaluation,
};
