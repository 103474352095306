const styles = {
  container: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    padding: '24px',
    borderRadius: '8px',
  },
  optionSection: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    paddingBottom: '20px',
  },

  transparentButton: {
    backgroundColor: 'white',
    color: 'primary.main',
    padding: '5px 10px',
    border: '1px solid',
    borderColor: 'semiGray',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'secondary.main',
      color: 'white',
    },
  },
  actionLink: {
    color: 'secondary.dark',
  },

  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  formItemCheckbox: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  formItemLabel: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
  },
  formItemCheckboxLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    width: '100%',
  },
  formItemLabelRequiredSymbol: {
    '&::before': {
      position: 'absolute',
      left: '-10px',
      top: 0,
      content: '"*"',
      color: 'red',
      fontSize: '14px',
    },
  },
};

export default styles;
