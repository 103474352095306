import React, { FC } from 'react';
import ContentLayout from '../../shared/ui/ContentLayout';
import { MainLayout } from '../../widgets';
import Div from '../../shared/ui/Div';
import { Grid, Link, Divider, Typography } from '@mui/material';
import styles from './styles';
import { UserRole } from '../../entities/user';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  LingoLiftClassesAscSummaryTable,
  useGetLingoLiftTeacherClassesSummaryLLEcAscReportQuery,
} from '../../entities/lingoliftClasses';
import {
  ILingoLiftTeacherClassesSummaryLLEcAscReport,
} from '../../entities/lingoliftClasses/module/types';
import Image from "../../shared/ui/Image";

interface LingoLiftClassesTeacherSummaryLLAscReportPageProps {
  llSchoolClassId?: string;
  llEcAscId?: string;
}

const LingoLiftClassesTeacherSummaryLLEcAscReportPage:FC<LingoLiftClassesTeacherSummaryLLAscReportPageProps> = (props) => {
  const { llEcAscId, llSchoolClassId } = props;
  const { data: llEcAscReport, isLoading: isLLEcAscReportLoading } = useGetLingoLiftTeacherClassesSummaryLLEcAscReportQuery(
    {
      llEcAscId: llEcAscId as string,
      llSchoolClassId: llSchoolClassId as string
    },
    {
      skip: (!llEcAscId || !llSchoolClassId)
    }
  );

  return (
    <MainLayout userRole={UserRole.Teacher} isLoading={isLLEcAscReportLoading}>
      <Div sx={styles.header}>
        <Link sx={styles.link} href={llEcAscReport?.seeAllAssignedUrl}>
          See All Assigned
        </Link>
      </Div>
      <Divider variant="fullWidth" sx={styles.divider} />
      { llEcAscReport && <ContentLayout>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={8}>
            <Div sx={styles.previewWrapper}>
              <Image sx={styles.previewWrapper.image} src={llEcAscReport?.photoSrc} alt={"asc report image"} />
              <Div>
                <Typography variant="h1">
                  { llEcAscReport.title }</Typography>
                <Link sx={styles.link} href={llEcAscReport.collectionLinkLabel}>
                  { llEcAscReport.collectionLinkLabel }
                </Link>
              </Div>
            </Div>
          </Grid>
          <Grid display="flex" justifyContent="flex-end" item xs={4}>
            <Link href={llEcAscReport?.shareLinkUrl} sx={{
              ...styles.transparentButton,
              ...styles.borderRadiusLeft,
            }}>
              { llEcAscReport.shareLinkLabel }
            </Link>
            <Link download href={llEcAscReport?.downloadReportUrl} sx={{
              ...styles.transparentButton,
              ...styles.borderRadiusRight,
            }}>
              <FileDownloadOutlinedIcon fontSize="small" /> Download Report
            </Link>
          </Grid>
        </Grid>
        <Div sx={styles.wrapper}>
          <LingoLiftClassesAscSummaryTable llEcAscReport={llEcAscReport as ILingoLiftTeacherClassesSummaryLLEcAscReport} />
        </Div>
      </ContentLayout> }
    </MainLayout>
  );
};

export default LingoLiftClassesTeacherSummaryLLEcAscReportPage;
