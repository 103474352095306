import React, { type FC, useEffect, useState } from 'react';

import { LessonGoBackLine, LingoLiftLessonHeader, MainLayout, LingoLiftLessonFooter } from '../../widgets';

import styles from './styles';
import Div from '../../shared/ui/Div';
import {
  useGetLingoLiftStudentCollectionAscLessonDetailsQuery,
  useGetLingoLiftStudentCollectionLessonDetailsQuery,
  usePostLingoLiftStudentCollectionAssignmentCompletionsMutation
} from '../../entities/lingoLiftCollection';
import LingoLiftCollectionLessonCard from '../../entities/lingoLiftCollection/ui/CollectionLessonCard';
import { ILingoLiftCollectionLesson } from '../../entities/lingoLiftCollection/model/types';
import sx from 'mui-sx';

interface ILingoLiftCollectionsLessonStudentShowProps {
  id: number;
  accessToken: string;
  partnerKey: string;
  dialogId: number;
  collectionId: number;
  ecAscId?: number;
  languageCode: string;
}

const LingoLiftCollectionsLessonStudentShowPage: FC<ILingoLiftCollectionsLessonStudentShowProps> = props => {
  const { id, accessToken, partnerKey, dialogId, collectionId, ecAscId, languageCode } = props;

  const [expand, setExpand] = useState<boolean>(true);

  const { data: lessonDetails, isLoading: isDefaultLessonDetails } = useGetLingoLiftStudentCollectionLessonDetailsQuery({
    collectionId,
    lessonId: id,
    },
    {
    skip: Boolean(ecAscId)
  });

  const { data: lessonAscDetails, isLoading: isAscLessonLoading } = useGetLingoLiftStudentCollectionAscLessonDetailsQuery({
    lessonId: id,
    ecAscId: ecAscId as number
    },
    {
      skip: !ecAscId
    })

  const [onCreateAssignmentCompletions, { data: result }] = usePostLingoLiftStudentCollectionAssignmentCompletionsMutation();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (window.ECSDK && (lessonDetails || lessonAscDetails)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.ECSDK.loadWidget('player', {
        partnerKey,
        partnerSdkToken: accessToken,
        dialogId,
        container: 'player-container',
        lang: languageCode,
        settings: {
          autoStart: false,
          isComprehensionQuizEnabled: false,
        },
      });
    }
  }, [lessonDetails, lessonAscDetails]);

  useEffect(() => {
    onCreateAssignmentCompletions({ llCollectionAssignmentCompletion: { enhancedCollectionId: Number(collectionId) } })
  }, []);

  const handleExpand = () => setExpand(!expand);

  const isLoading = (isAscLessonLoading || isDefaultLessonDetails);

  return (
    <MainLayout isLoading={isLoading} backgroundColor="white">
      <LessonGoBackLine title="See All Lessons" />
      {(lessonDetails || lessonAscDetails) && (
        <>
          <Div
            sx={sx({
              sx: styles.wrapper,
              condition: expand,
            })}
          >
            {expand && (
              (lessonDetails || lessonAscDetails) && <Div sx={styles.sideBar}>
                {(lessonDetails || lessonAscDetails)?.queue.map((card: ILingoLiftCollectionLesson, index: number) => (
                  <LingoLiftCollectionLessonCard
                    key={card.index}
                    card={card}
                    index={index + 1}
                    isRounded={index === 0}
                  />
                ))}
              </Div>
            )}
            <Div sx={styles.container}>
              { lessonDetails && <LingoLiftLessonHeader {...lessonDetails.item} /> }
              { lessonAscDetails && <LingoLiftLessonHeader {...lessonAscDetails.item} /> }
              <Div sx={styles.iframe} id="player-container" />
            </Div>
          </Div>
          { lessonDetails && <LingoLiftLessonFooter
            url={ lessonDetails.nextItem.url }
            nextItemLabel={ lessonDetails.nextItemLabel }
            lessonName={ lessonDetails.item.name }
            allLessons={ lessonDetails.queue }
            onExpand={ handleExpand }
            assignmentCompletionId={ result?.id }
          /> }

          { lessonAscDetails && <LingoLiftLessonFooter
            url={ lessonAscDetails.nextItem.url }
            nextItemLabel={ lessonAscDetails.nextItemLabel }
            lessonName={ lessonAscDetails.item.name }
            allLessons={ lessonAscDetails.queue }
            onExpand={ handleExpand }
            assignmentCompletionId={ result?.id }
          /> }
        </>
      )}
    </MainLayout>
  );
};

export default LingoLiftCollectionsLessonStudentShowPage;
