const styles = {
  list: {
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 2px 8px -1px #1B242C14, 0px 2px 2px -1px #1B242C0A',
    backgroundColor: 'white',
    overflow: 'hidden'
  },
  listItem: {
    justifyContent: 'space-between',
    padding: '16px',
  },
  listItemBordered: {
    borderBottom: '1px solid',
    borderColor: 'semiGray'
  },
  listItemContent: {
    display: 'grid',
    flexDirection: 'column',
    gap: '5px',
  },
  listItemLink: {
    color: 'secondary.dark',
  },
  listItemColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    alignItems: 'center'
  },
  listItemColumnAlignStart: {
    alignItems: 'flex-start'
  },
  listItemStatWrapper: {
    display: 'flex',
    gap: '20px',
  },
}

export default styles;
