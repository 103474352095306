import React from 'react';

import { enqueueSnackbar, closeSnackbar } from 'notistack';
import SnackbarToast from '../../widgets/SnackbarToast/index';

const ERROR = 'Something went wrong';
const SUCCESS = 'The operation has finished succesfully';

export const useSnackbar = () => {
  const showNotification = (message, isError = false, props = {}) => {
    const defaultMessage = isError ? ERROR : SUCCESS;
    const key = enqueueSnackbar(message ?? defaultMessage, {
      content: (id: number, text: string) => (
        <SnackbarToast
          onClose={() => isError && closeSnackbar(id)}
          id={id}
          message={text}
          isError={isError}
        />
      ),
      onClick: () => closeSnackbar(key),
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
      ...props,
    });
  };

  const showErrorNotification = (message: string) => {
    showNotification(message, true, { persist: true });
  };

  return {
    showSuccessNotification: showNotification,
    showErrorNotification,
  };
};
