const styles = {
  wrapper: {
    boxSizing: 'border-box',
    padding: {
      xs: '24px 16px',
      md: '40px 44px',
    },
  },
};

export default styles;
