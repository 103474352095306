const styles = {
  container: {
    display: 'flex',
    gap: '40px',
    alignItems: 'center',
  },
  centeredContainer: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center'
    }
  },
  containerBottomBorder: {
    borderBottom: '1px solid',
    borderColor: 'semiGray',
  },
  tabItemDefault: {
    textTransform: 'none'
  },
  tabItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    paddingBottom: 0,
    '& .MuiBox-root': {
      marginBottom: 0,
    },
  },
  tabItemActiveIcon: {
    color: 'secondary.dark',
  },
};

export default styles;
