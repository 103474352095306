import ContentLayout from '../../shared/ui/ContentLayout';
import { MainLayout } from '../../widgets';
import React, { type FC } from 'react';
import Div from '../../shared/ui/Div';
import { Grid, Link, Typography } from '@mui/material';
import styles from './styles';
import appRoutes from '../../shared/store/appRoutes';
import { LihgoLiftCreateClassForm, usePostLingoLiftManageClassMutation, ILingoLiftManageClassEntity } from "../../features/lingoLiftClasses";
import { UserRole } from "../../entities/user";
import Image from "../../shared/ui/Image";
import lingoLiftLogo from '../../assets/lingoLiftLessonLogo.svg';

interface ILingoLiftClassesTeacherAddLingoLiftClassProps {
  schoolName: string;
  googleClassroomLink: string;
}

const LingoLiftClassesTeacherAddLingoLiftClassPage: FC<ILingoLiftClassesTeacherAddLingoLiftClassProps> = props => {
  const { schoolName, googleClassroomLink } = props;

  const [onCreateClass] = usePostLingoLiftManageClassMutation();

  const handleCreateClass = async (values: ILingoLiftManageClassEntity) => {
    await onCreateClass(values).unwrap();
    window.location.href = appRoutes.addClassRedirect;
  };

  return (
    <MainLayout userRole={UserRole.Teacher} isLoading={false}>
      <ContentLayout>
        <Grid container spacing={4}>
          <Grid item sx={styles.container} lg={8}>
            <Div>
              <Grid container spacing={2}>
                <Grid display="grid" gap={1} item xs={12}>
                  <Typography variant="h1">Classes</Typography>
                  <Typography>
                    Your school: <b>{schoolName}</b>
                  </Typography>
                  <Image src={lingoLiftLogo} alt="lingoLift logo" />
                  <Div sx={styles.divider} />
                </Grid>
                <Grid item xs={12}>
                  <Div sx={styles.optionSection}>
                    <Typography variant="h2">Option 1: Import from Google Classroom</Typography>
                    <Typography>Import class rosters from Google Classroom.</Typography>

                    <Link href={googleClassroomLink} sx={styles.transparentButton}>Import Google Classroom</Link>
                  </Div>
                  <Div sx={styles.divider} />
                </Grid>

                <Grid item xs={12}>
                  <LihgoLiftCreateClassForm onChange={handleCreateClass} />
                </Grid>
              </Grid>
            </Div>
          </Grid>
        </Grid>
      </ContentLayout>
    </MainLayout>
  );
};

export default LingoLiftClassesTeacherAddLingoLiftClassPage;
