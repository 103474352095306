import { SignUpFormFieldType, SignUpFormType, TeacherSignUpPageStep } from "../model/enums";

export const isTextField = (type: SignUpFormFieldType) =>
  type !== SignUpFormFieldType.CheckBox &&
  type !== SignUpFormFieldType.Password &&
  type !== SignUpFormFieldType.Select;

export const isPasswordField = (type: SignUpFormFieldType) => type === SignUpFormFieldType.Password;

export const isSelectField = field =>
  field.type === SignUpFormFieldType.Select && field.name !== 'teacher[state]';

export const isSelectStateField = field =>
  field.type === SignUpFormFieldType.Select && field.name === 'teacher[state]';

export const isCheckboxField = (type: SignUpFormFieldType) => type === SignUpFormFieldType.CheckBox;

export const isStudentPage = (formType: SignUpFormType) => formType === SignUpFormType.Student;

export const isOmniAuthPage = (formType: SignUpFormType) =>
  formType === SignUpFormType.TeacherOmniAuth || formType === SignUpFormType.StudentOmniAuth;

export const isTeacherPremiumPageSignUpStep = (
  formType: SignUpFormType,
  step: TeacherSignUpPageStep,
) => formType === SignUpFormType.TeacherPremium && step === TeacherSignUpPageStep.signUp;

export const isTeacherPremiumPageCompleteAccountStep = (step: TeacherSignUpPageStep) =>
  step === TeacherSignUpPageStep.completeAccountSetup;

export const isTeacherPassworfField = (fieldName: string) =>
  fieldName === 'teacher[password]' || fieldName === 'student[password]';

export const isTeacherCountryField = (name: string) => name === 'teacher[country]';

