import React, { FC, useEffect, useState } from "react";
import Div from "../../../../../../shared/ui/Div";
import { FormControlLabel, Checkbox } from "@mui/material";
import styles from "./styles";

interface ICheckBoxListProps {
  label: string;
  defaultChecked: boolean;
  children: React.ReactNode;
  onChange: (value: boolean) => void;
}
const CheckboxList: FC<ICheckBoxListProps> = props => {
  const { label, defaultChecked, children, onChange } = props;
  const [checked, setChecked] = useState<boolean>(defaultChecked);

  useEffect(() => {
    onChange(checked);
  }, [checked]);

  return (
    <Div sx={styles.container}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={() => setChecked(prev => !prev)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={label}
        />
        { checked && children }
    </Div>
  );
}

export default CheckboxList;
