import * as React from 'react';
import Div from '../../../../shared/ui/Div';
import sx from 'mui-sx';
import styles from './styles';
import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Link,
  Stack,
  Typography
} from '@mui/material';
import Image from '../../../../shared/ui/Image';
import lingoLiftLessonLogo from '../../../../assets/lingoLiftLessonLogo.svg';
import listenWiseLessonLogo from '../../../../assets/listenWiseLessonLogo.svg';
import { sliceTextContent } from '../../../../shared/utils/sliceTextContent';
import { AddRounded, CloseRounded } from '@mui/icons-material';
import { FC } from 'react';
import LingoLiftMyLibraryAddTagsPopover from '../../../../features/lingoLiftMyLibrary/ui/MyLibraryAddTagsPopover';
import { LingoLiftProduct } from '../../../../shared/types/common-enums';
import { ILingoLiftTeacherMyLibraryAssignment, ILingoLiftTeacherMyLibraryTeacherAssignmentTag } from '../../model/types';
import { isLingoLiftProduct, isListenWiseProduct } from '../../../../shared/utils/lingoLiftCompareConditions';

interface ILingoLiftMyLibraryCardProps extends ILingoLiftTeacherMyLibraryAssignment {
  product: LingoLiftProduct;
  tagsList: Array<ILingoLiftTeacherMyLibraryTeacherAssignmentTag>;
  onAssignableUnTag: (assignableId: number, assignableType: string, tagId: number, type: LingoLiftProduct) => void;
}

const LingoLiftMyLibraryCard: FC<ILingoLiftMyLibraryCardProps> = (props) => {
  const {
    product,
    assignable,
    assignments,
    quizAssignments,
    tagsList,
    onAssignableUnTag
  } = props;

  const handleDelete = (tagId: number) => {
    onAssignableUnTag(assignable?.id, assignable?.assignableType, tagId, product)
  };

  return <Card
    sx={sx(
      styles.card,
      {
        sx: styles.lingoLiftBorder,
        condition: isLingoLiftProduct(product),
      },
      {
        sx: styles.listenWiseBorder,
        condition: isListenWiseProduct(product),
      }
    )}
  >
    <Div sx={styles.wrapper}>
      <Div sx={styles.posterGrid}>
        <CardMedia
          image={assignable?.photoSrc}
          component="div"
          sx={styles.poster} />
      </Div>
      <Div sx={styles.contentWrapper}>
        <CardContent sx={styles.content}>
          <Link sx={styles.logo}>
            {isLingoLiftProduct(product) ? (
              <Image height={16} src={lingoLiftLessonLogo} alt="lingolift lesson logo" />
            ) : (
              <Image height={16} src={listenWiseLessonLogo} alt="listenwise lesson logo" />
            )}
          </Link>
          <Link href={assignable?.url} sx={styles.link}>
            {assignable?.name}
          </Link>
          <Typography
            color="primary.dark"
            sx={styles.description}>
            {sliceTextContent(assignable?.description, 300)}
          </Typography>

          <Stack sx={styles.chipWrapper} direction="row" spacing={1}>
            { assignable?.tags.map((tag) => <Chip
              key={tag.id}
              label={tag.name}
              onDelete={() => handleDelete(tag.id)}
              sx={styles.chip}
              deleteIcon={<CloseRounded />}
            />) }
            <LingoLiftMyLibraryAddTagsPopover
              assignableId={assignable?.id}
              assignableType={assignable?.assignableType}
              product={product}
              tagsList={tagsList} />
          </Stack>
        </CardContent>
      </Div>
    </Div>
    <Div sx={styles.assignWrapper}>
      { assignments?.map((assignment, index) => <Div key={assignment?.id} sx={sx(
        {
          condition: ((index + 1) === assignments.length),
          sx:styles.assignItemBottomBordered
        },
        styles.assignItem,
      )}>
        <Link href={assignment?.url} sx={styles.link}>
          { assignment?.name }
        </Link>
        <Typography>
          {assignment?.schoolClassesCountLabel}
        </Typography>
      </Div>) }

      { quizAssignments?.map((quizAssignment, index) => <Div key={quizAssignment?.quizUuid} sx={sx(
        {
          condition: ((index + 1) === quizAssignments?.length),
          sx:styles.assignItemBottomBordered
        },
        styles.assignItem,
      )}>
        <Link href={quizAssignment?.url} sx={styles.link}>
          { quizAssignment?.name }
        </Link>
        <Typography>
          {quizAssignment?.schoolClassesCount}
        </Typography>
      </Div>) }
      <Link href={assignable?.createAssignmentUrl} sx={styles.createNewAssign}>
        <AddRounded />
        Create New Assignment
      </Link>
    </Div>
  </Card>
}

export default LingoLiftMyLibraryCard;
