import React, { FC } from 'react';
import styles from './styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Link, IconButton } from '@mui/material';
import googleClassroomIcon from '../../../../assets/socialIcons/googleClassroomIcon.svg';
import Image from '../../../../shared/ui/Image';
import { ILingoLiftTeacherClassesLLAssignmentsReportASCS } from "../../module/types";

interface LingoLiftClassesAssignmentsTableProps {
  ascs: Array<ILingoLiftTeacherClassesLLAssignmentsReportASCS>
}
const LingoLiftClassesAssignmentsTable:FC<LingoLiftClassesAssignmentsTableProps> = (props) => {
  const { ascs } = props
  return (
    <TableContainer component={Paper} sx={styles.container}>
      <Table>
        <TableHead sx={styles.tableHead}>
          <TableRow sx={styles.headRow}>
            <TableCell>
              <Typography variant="h5" sx={styles.titleCell}>
                Assigned Video
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="h5">Student Status</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="h5">Due</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h5">Share to Google Classroom</Typography>
            </TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {ascs.map((row) => (
            <TableRow key={row.name} sx={styles.tableLastChildRow}>
              <TableCell component="th" scope="row">
                <Link sx={{ ...styles.name, ...styles.link }} href={row.url}>
                  {row.lessonName}
                </Link>
              </TableCell>
              <TableCell align="left">
                <Typography variant="body1">
                  {`${row.results.submitted} Submitted, ${row.results.started} Started, ${row.results.notStarted} Not Started, ${row.results.new} New, ${row.results.returned} Returned`}
                </Typography>
                <Link sx={{ ...styles.name, ...styles.entitySemiName }} href={row.seeResponsesUrl}>
                  See Responses
                </Link>
              </TableCell>
              <TableCell align="left">
                <Typography variant="body1">{row.dueDate}</Typography>
                <Typography color="primary.dark">{row.dueDateLabel}</Typography>
              </TableCell>
              <TableCell align="center">
                <IconButton component={Link} href={row.shareUrl} sx={styles.button}>
                  <Image src={googleClassroomIcon} alt="googleclassroom icon" sx={styles.icon} />
                </IconButton>
              </TableCell>
              <TableCell align="right">
                <Link sx={styles.transparentButton} href={row.updateUrl}>Update</Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LingoLiftClassesAssignmentsTable;
