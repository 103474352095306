import React, { FC } from 'react';
import Div from '../../../../shared/ui/Div';
import { Grid, Modal, Typography, List, ListItem, ListItemText, SvgIcon, Link } from '@mui/material';
import styles from './styles';
import LLLWLogo from '../../../../assets/LlLwLogo';
import { appRoutes } from '../../../../shared/store';
import { ReportProblemRounded } from "@mui/icons-material";

interface ILingoLiftClassesManageClassStudentProblemsConnectingModalProps {
  isOpenModal: boolean;
  onClose: () => void;
}

const LingoLiftClassesManageClassStudentProblemsConnectingModal: FC<
  ILingoLiftClassesManageClassStudentProblemsConnectingModalProps
> = props => {
  const { isOpenModal, onClose } = props;

  return (
    <Modal open={isOpenModal} onClose={onClose} closeAfterTransition>
      <Div sx={styles.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              If you are getting the following error when importing or updating Google Classroom rosters in Listenwise,
              please follow the instructions, below.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Div sx={styles.modalWarningWrapper}>
              <ReportProblemRounded color="error" />
              <Typography variant="h5">Sign in with Google temporarily disabled for this app</Typography>
              <Typography variant="body2">This app has not been verified yet by Google in order to use Google Sign In</Typography>
            </Div>
          </Grid>
          <Grid item xs={12}>
            <List sx={styles.modalWarningList.decimalList}>
              <ListItem>
                <ListItemText primary="Log out of Listenwise" />
              </ListItem>
              <ListItem sx={styles.modalWarningList.item}>
                <ListItemText
                  primary={
                    <>
                      Remove Listenwise access from your Google account. Follow the below steps or see{' '}
                      <Link sx={styles.link} href={appRoutes.googleClassroomVideo} target="_blank">
                        this video
                      </Link>{' '}
                      to guide you through the process:
                    </>
                  }
                />
              </ListItem>
              <List sx={styles.modalWarningList.lowerAlphalList}>
                <ListItem sx={styles.modalWarningList.item}>
                  <ListItemText
                    primary="Click your Google Account icon in the top-right hand corner of your browser and click the blue “Google
              Account” button"
                  />
                </ListItem>
                <ListItem sx={styles.modalWarningList.item}>
                  <ListItemText primary="Click 'Security'" />
                </ListItem>
                <ListItem sx={styles.modalWarningList.item}>
                  <ListItemText
                    primary="Scroll down to “Third-party apps with account access” on the right-hand side and click “manage third-party
              access”"
                  />
                </ListItem>
                <ListItem sx={styles.modalWarningList.item}>
                  <ListItemText primary="SFind Listenwise and click “Remove Access”" />
                </ListItem>
              </List>
              <ListItem sx={styles.modalWarningList.item}>
                <ListItemText primary="Log into Listenwise again and import your Google Classroom rosters" />
              </ListItem>
            </List>
          </Grid>
          <Grid item>
            <Typography>
              If you continue to see the error message after following these steps, please contact{' '}
              <Link sx={styles.link} href={appRoutes.mailListenWise}>
                support@listenwise.com
              </Link>{' '}
              .
            </Typography>
          </Grid>
          <Grid item>
            <SvgIcon sx={styles.doubleLogo} component={LLLWLogo} />
          </Grid>
        </Grid>
      </Div>
    </Modal>
  );
};

export default LingoLiftClassesManageClassStudentProblemsConnectingModal;
