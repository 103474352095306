import React, { FC } from 'react';
import styles from './styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Link } from '@mui/material';
import { ILingoLiftTeacherClassesSummaryLLReport } from "../../module/types";

interface ILingoLiftClassesSummaryTableProps extends ILingoLiftTeacherClassesSummaryLLReport {}

const LingoLiftClassesSummaryTable:FC<ILingoLiftClassesSummaryTableProps> = (props) => {
  const { items } = props;

  return (
    <TableContainer component={Paper} sx={styles.container}>
      <Table>
        <TableHead sx={styles.tableHead}>
          <TableRow sx={styles.headRow}>
            <TableCell sx={styles.titleCell}>
              <Typography variant="h5">Students</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="h5">Avg. Watch Completion</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="h5">Avg. Learn Completion</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="h5">Avg. Speak Score</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                <Link href={row.link} sx={styles.name}>
                  {row.name}
                </Link>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1">{row.averageData.averageWatch.percentage}%</Typography>
                <Typography variant="body2">{row.averageData.averageWatch.countLabel}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1">{row.averageData.averageLearn.percentage}%</Typography>
                <Typography variant="body2">{row.averageData.averageLearn.percentage}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1">{row.averageData.averageSpeak.percentage}%</Typography>
                <Typography variant="body2">{row.averageData.averageSpeak.percentage}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LingoLiftClassesSummaryTable;
