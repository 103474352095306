import * as React from 'react';
import { Tabs, Tab, Typography } from '@mui/material';

import { a11yProps } from './TabContent';
import { FC } from 'react';
import Div from '../../Div';
import sx from 'mui-sx';
import styles from './styles';
import Image from '../../Image';

interface ITabsContainerProps {
  tabs: Array<{
    name: string;
    icon?: string;
    activeIcon?: string;
  }>;
  tabIndex: number;
  setTabIndex: (value: number) => void;
  children: React.ReactNode;
  isBordered?: boolean;
  sectionTitle?: string | boolean;
  isCentered?: boolean;
}
const TabsContainer: FC<ITabsContainerProps> = props => {
  const { tabs, children, tabIndex, setTabIndex, sectionTitle, isCentered } = props;
  const handleChange = (_: React.SyntheticEvent, value: number) => {
    setTabIndex(value);
  };

  return (
    <>
      <Div
        sx={sx(styles.containerBottomBorder, {
          sx: styles.container,
          condition: Boolean(sectionTitle),
        })}
      >
        {sectionTitle && <Typography variant="h1">{sectionTitle}</Typography>}
        <Div>
          <Tabs sx={sx({
            sx: styles.centeredContainer,
            condition: Boolean(isCentered)
          })} variant="scrollable" scrollButtons="auto" value={tabIndex} onChange={handleChange}>
            {tabs.map((tab, index) => (
              <Tab
                icon={
                  tab?.icon && (
                    <Image
                      src={((tab.activeIcon && index) === tabIndex && tab.activeIcon) || tab.icon}
                      alt="tab icon"
                    />
                  )
                }
                label={tab.name}
                key={index}
                sx={sx(styles.tabItemDefault, {
                  sx: styles.tabItem,
                  condition: Boolean(tab?.icon),
                })}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Div>
      </Div>
      {children}
    </>
  );
};

export default TabsContainer;
