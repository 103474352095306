import * as React from "react";
import ReduxProvider from "../reduxProvider";
import { createRoot } from "react-dom/client";
import LingoLiftTeacherDashboardBrowsePage from '../../pages/LingoLiftTeacherDashboardBrowse';
import LingoLiftStudentDashboardBrowsePage from '../../pages/LingoLiftStudentDashboardBrowse';
import LingoLiftDashboardStudentJoinClassPage from '../../pages/LingoLiftDashboardStudentJoinClass';
import {
  selectLingoLiftStudentDashboardParamsFromDomObject
} from '../../shared/utils/selectLingoLiftStudentDashboardParamsFromDomObject';
import {
  selectLingoLiftTeacherDashboardParamsFromDomObject
} from '../../shared/utils/selectLingoLiftTeacherDashboardParamsFromDomObject';

const lingoLiftTeacherDashboardRoutesRender = () => {
  const lingoLiftTeacherDashboardBrowseElement = document.querySelector(
    '#lingolift_dashboard_teachers_browse',
  );

  const lingoLiftStudentDashboardBrowseElement = document.querySelector(
    '#lingolift_dashboard_students_browse',
  );

  const lingoLiftDashboardTeacherJoinClassesElement = document.querySelector('#lingolift_dashboard_students_join');

  const renderElement = (
    lingoLiftTeacherDashboardBrowseElement ||
    lingoLiftStudentDashboardBrowseElement ||
    lingoLiftDashboardTeacherJoinClassesElement
  )

  if (renderElement) {
    const root = createRoot(renderElement);

    return root.render(<ReduxProvider>
      { lingoLiftTeacherDashboardBrowseElement && <LingoLiftTeacherDashboardBrowsePage
        {...selectLingoLiftTeacherDashboardParamsFromDomObject(lingoLiftTeacherDashboardBrowseElement as HTMLElement)} /> }
      { lingoLiftStudentDashboardBrowseElement && <LingoLiftStudentDashboardBrowsePage
        {...selectLingoLiftStudentDashboardParamsFromDomObject(lingoLiftStudentDashboardBrowseElement as HTMLElement)} /> }
      { lingoLiftDashboardTeacherJoinClassesElement && <LingoLiftDashboardStudentJoinClassPage />}
    </ReduxProvider>)
  }
}

export default lingoLiftTeacherDashboardRoutesRender;
