const studentSignUpFormFields = [
  {
    name: 'student[school_class_token]',
    label: 'Class invite code',
    placeholder: 'Class invite code',
    required: true,
    requiredText: 'Required',
    labelLink: {
      name: 'no code?',
      href: 'http://blog.listenwise.com/2016/11/students-heres-access-stories/',
    },
    underInputText: 'Your teacher will give you this code.',
  },
  {
    name: 'student[username]',
    label: 'Username',
    placeholder: 'Username',
    required: true,
    requiredText: 'Required',
  },
  {
    name: 'student[first_name]',
    label: 'First Name',
    placeholder: 'First Name',
    required: true,
    requiredText: 'Required',
  },
  {
    name: 'student[last_name]',
    label: 'Last Name',
    placeholder: 'Last Name',
    required: true,
    requiredText: 'Required',
  },
  {
    name: 'student[email]',
    label: 'Email',
    placeholder: 'Email',
    type: 'email',
  },
  {
    name: 'student[password]',
    label: 'Password',
    placeholder: 'Password',
    required: true,
    requiredText: 'Password example: Password123#',
    type: 'password',
  },
  {
    name: 'student[password_confirmation]',
    label: 'Password Confirmation',
    placeholder: 'Password Confirmation',
    required: true,
    requiredText: 'Password example: Password123#',
    type: 'password',
  },
  {
    name: 'student[product]',
    type: 'hidden',
  },
  {
    name: 'authenticity_token',
    label: 'token',
    type: 'hidden',
  },
];

const teacherTrialSignUpFormFields = [
  {
    name: 'teacher[first_name]',
    label: 'First Name',
    placeholder: 'First Name',
    required: true,
    requiredText: 'Required',
  },
  {
    name: 'teacher[last_name]',
    label: 'Last Name',
    placeholder: 'Last Name',
    required: true,
    requiredText: 'Required',
  },
  {
    name: 'teacher[email]',
    label: 'Email',
    placeholder: 'Email',
    required: true,
    requiredText: 'Required',
    type: 'email',
  },
  {
    name: 'teacher[password]',
    label: 'Password',
    placeholder: 'Password',
    required: true,
    type: 'password',
  },
  {
    name: 'teacher[password_confirmation]',
    label: 'Password Confirmation',
    placeholder: 'Password Confirmation',
    required: true,
    type: 'password',
  },
  {
    name: 'teacher[policy_accepted]',
    labelLink: {
      name: 'I accept the Listenwise Terms and Privacy Policy',
      link: '/',
    },
    required: true,
    type: 'checkbox',
  },
  {
    name: 'teacher[role]',
    label: 'Role',
    placeholder: 'Role',
    required: true,
    requiredText: 'Required',
    type: 'select',
    completeAccountSetup: true,
  },
  {
    name: 'teacher[significant_english_learners]',
    label: '30% or more of my students are English learners',
    type: 'checkbox',
    completeAccountSetup: true,
  },
  {
    name: 'teacher[school_name]',
    label: 'Name of School',
    placeholder: 'Name of School',
    required: true,
    requiredText: 'Required',
    completeAccountSetup: true,
  },
  {
    name: 'teacher[country]',
    label: 'Country',
    placeholder: 'Country',
    required: true,
    requiredText: 'Required',
    type: 'select',
    completeAccountSetup: true,
  },
  {
    name: 'teacher[state]',
    label: 'State',
    placeholder: 'State',
    required: true,
    requiredText: 'Required',
    type: 'select',
    completeAccountSetup: true,
  },
  {
    name: 'teacher[school_access_token]',
    label: 'Premium Code',
    placeholder: 'Premium Code',
    required: true,
    requiredText: 'Required',
    completeAccountSetup: true,
  },
  {
    name: 'teacher[product]',
    type: 'hidden',
    completeAccountSetup: true,
  },
  {
    name: 'authenticity_token',
    label: 'token',
    type: 'hidden',
    completeAccountSetup: true,
  },
];

const teacherOmniAuthFormFields = [
  {
    name: 'teacher[first_name]',
    label: 'First Name',
    placeholder: 'First Name',
    required: true,
    requiredText: 'Required',
  },
  {
    name: 'teacher[last_name]',
    label: 'Last Name',
    placeholder: 'Last Name',
    required: true,
    requiredText: 'Required',
  },
  {
    name: 'teacher[policy_accepted]',
    labelLink: {
      name: 'I accept the Listenwise Terms and Privacy Policy',
      link: '/',
    },
    required: true,
    type: 'checkbox',
  },
  {
    name: 'teacher[role]',
    label: 'Role',
    placeholder: 'Role',
    required: true,
    requiredText: 'Required',
    type: 'select',
  },
  {
    name: 'teacher[significant_english_learners]',
    label: '30% or more of my students are English learners',
    type: 'checkbox',
  },
  {
    name: 'teacher[school_name]',
    label: 'Name of School',
    placeholder: 'Name of School',
    required: true,
    requiredText: 'Required',
  },
  {
    name: 'teacher[country]',
    label: 'Country',
    placeholder: 'Country',
    required: true,
    requiredText: 'Required',
    type: 'select',
  },
  {
    name: 'teacher[state]',
    label: 'State',
    placeholder: 'State',
    required: true,
    requiredText: 'Required',
    type: 'select',
  },
  {
    name: 'teacher[school_access_token]',
    label: 'Premium Code',
    placeholder: 'Premium Code',
    required: true,
    requiredText: 'Required',
  },
  {
    name: 'teacher[product]',
    type: 'hidden',
  },
  {
    name: 'authenticity_token',
    label: 'token',
    type: 'hidden',
  },
];

const studentOmniAuthFormFields = [
  {
    name: 'student[school_class_token]',
    label: 'Class invite code',
    placeholder: 'Class invite code',
    required: true,
    requiredText: 'Required',
    labelLink: {
      name: 'no code?',
      href: 'http://blog.listenwise.com/2016/11/students-heres-access-stories/',
    },
    underInputText: 'Your teacher will give you this code.',
  },
  {
    name: 'student[username]',
    label: 'Username',
    placeholder: 'Username',
    required: true,
    requiredText: 'Required',
  },
  {
    name: 'student[first_name]',
    label: 'First Name',
    placeholder: 'First Name',
    required: true,
    requiredText: 'Required',
  },
  {
    name: 'student[last_name]',
    label: 'Last Name',
    placeholder: 'Last Name',
    required: true,
    requiredText: 'Required',
  },
  {
    name: 'student[product]',
    type: 'hidden',
  },
  {
    name: 'authenticity_token',
    label: 'token',
    type: 'hidden',
  },
];

const formFields = {
  student: studentSignUpFormFields,
  teacherTrial: teacherTrialSignUpFormFields,
  teacherPremium: teacherTrialSignUpFormFields,
  teacherOmniAuth: teacherOmniAuthFormFields,
  studentOmniAuth: studentOmniAuthFormFields,
};

export { formFields };
