const styles = {
  wrapper: {
    height: '100%',
    padding: 0,
    background: 'transparent',
  },
  card: {
    borderRadius: '12px',
    border: '1px solid #B6C5CC',
    boxSizing: 'border-box',
    height: '100%',
  },
  actionArea: {
    display: 'flex',
    height: '100%',
    cursor: 'default',
    flexDirection: {
      lg: 'column',
      sm: 'column',
      xs: 'row',
    },
  },
  poster: {
    objectFit: 'cover',
    width: '100%',
    position: 'relative',
    height: {
      lg: '200px',
      sm: '200px',
      xs: '100%',
    },
  },
  subjectsWrapper: {
    position: 'absolute',
    bottom: '5%',
    left: '5%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px'
  },
  badge: {
    position: 'absolute',
    bottom: '3%',
    left: '3%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px',
    width: '100%',
    flex: 1,
  },
  description: {
    fontSize: '10px',
  },
  link: {
    cursor: 'pointer',
    fontSize: {
      xs: '16px',
      sm: '18px',
    },
    fontWeight: '600',
    color: 'secondary.dark',
  },
  lessonLogo: {
    cursor: 'default',
    width: '150px',
    height: 'auto',
    display: 'inline-block',
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  tagWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    flex: 1,
    gap: '5px',
  },
  tagIcon: {
    width: '15px',
    height: 'auto',
    objectFit: 'contain',
  },
  horizontalSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 'auto',
  },
  lingoLiftPoster: {
    borderBottom: {
      xs: 'none',
      sm: '6px solid',
    },
    borderColor: {
      xs: 'none',
      sm: 'hibiscus',
    },
  },
  listenWisePoster: {
    borderBottom: {
      xs: 'none',
      sm: '6px solid',
    },
    borderColor: {
      xs: 'none',
      sm: 'secondary.dark',
    },
  },
};

export default styles;
