const styles = {
  card: {
    borderLeft: '8px solid',
    borderRadius: '12px',
  },
  lingoLiftBorder: {
    borderColor: 'hibiscus',
  },
  listenWiseBorder: {
    borderColor: 'secondary.dark',
  },
  cardElementRounded: {
    borderTop: 'none',
  },
  wrapper: {
    display: 'flex',
    gap: '10px',
    padding: '10px',
  },
  poster: {
    objectFit: 'cover',
    borderRadius: '5px',
    width: '100px',
    height: '100px',
    backgroundColor: 'geyser',
    display: {
      xs: 'none',
      sm: 'block',
    },
  },
  posterGrid: {
    display: {
      xs: 'none',
      sm: 'block',
    },
  },
  contentWrapper: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    width: 'fit-content',
    boxSizing: 'border-box',
    height: 'auto',
    '&.MuiCardContent-root': {
      padding: 0,
    },
  },
  lessonLogo: {
    cursor: 'default',
    width: '150px',
    height: 'auto',
    display: 'inline-block',
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    width: '150px',
    marginLeft: {
      md: 'auto',
      xs: 0,
    },
  },
  description: {
    fontSize: '12px',
  },
  badgesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
    marginTop: '5px',
  },
  link: {
    cursor: 'pointer',
    fontSize: {
      xs: '14px',
      sm: '16px',
    },
    fontWeight: '600',
    color: 'secondary.dark',
  },
  tagTitle: {
    fontSize: '10px',
    fontWeight: 600,
    color: 'primary.dark',
  },
  tagWrapper: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    width: 'max-content'
  },
  tagIcon: {
    width: '15px',
    height: 'auto',
    objectFit: 'contain',
  },
  lexileWrapper: {
    display: 'flex',
    gap: '2px',
    alignItems: 'flex-end'
  },
  lexileTagDefault: {
    borderRadius: '1px',
    backgroundColor: 'primary.main',
  },
  lexileTagLowLine: {
    width: '4px',
    height: '8px',
  },
  lexileTagMediumLine: {
    width: '4px',
    height: '12px',
  },
  lexileTagHighLine: {
    width: '4px',
    height: '16px',
  },
  lexileFilledTag: {
    backgroundColor: 'green'
  },
  lexileTag: {
    border: '1.5px solid',
    borderColor: 'green',
    borderRadius: '4px',
    padding: '4px 8px',
  },
  linkedLessonWrapper: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    backgroundColor: 'whitePointer',
    padding: '16px 24px',
  },
  linkedLessonPrefix: {
    fontWeight: 600,
    color: 'honeyFlower',
  }
};

export default styles;
