const styles = {
  container: {
    maxWidth: '650px',
    marginTop: '10px',
    '& .MuiAutocomplete-inputRoot': {
      minHeight: '50px',
      padding: '0px 10px !important'
    },
  },
  parentOption: {
    paddingLeft: 0,
  },
  defaultOption: {
    paddingLeft: '30px',
  }
}

export default styles;
