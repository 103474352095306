import React, { type FC } from 'react';
import { Typography, Grid } from '@mui/material';

import styles from './styles';
import Div from '../../../../../../shared/ui/Div';
import { LingoLiftSummaryStatus } from '../../../../model/enums';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import sx from 'mui-sx';

interface IProgressRowProps {
  description: string;
  title: string;
  response: string;
  attempts: number;
  status: boolean;
}

const ProgressRow: FC<IProgressRowProps> = props => {
  const { description, title, response, attempts, status } = props;

  return (
    <Div sx={styles.root}>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item xs={6}>
          <Typography variant="body2" sx={styles.description}>
            {description}
          </Typography>
          <Typography variant="h5" sx={styles.title}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={styles.description}>
            Response
          </Typography>
          <Div
            sx={sx(
              {
                ...styles.wrapperResponse,
              },
              {
                sx: styles.statusSuccess,
                condition: status,
              },
              {
                sx: styles.statusFailure,
                condition: !status,
              },
            )}
          >
            {status ? (
              <CheckCircleIcon sx={styles.iconSuccess} />
            ) : (
              <CancelIcon sx={styles.iconFailure} />
            )}
            <Typography variant="h5" sx={styles.response}>
              {response}
            </Typography>
          </Div>
        </Grid>
        {/* <Grid item xs={1} sx={styles.attemptsWrapper}>
          <Typography variant="body2" sx={styles.description}>
            Attempts
          </Typography>
          <Typography variant="h5" sx={styles.title}>
            {attempts}
          </Typography>
        </Grid> */}
      </Grid>
    </Div>
  );
};

export default ProgressRow;
