const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    backgroundColor: 'white',
    margin: '0 auto',
    width: {
      lg: '70%',
    },
    padding: '30px 0px 20px 0px',
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '50px',
    margin: '0 auto',
    textAlign: 'center',
  },
  link: {
    color: 'secondary.dark',
  },
  socialLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    border: '1px solid',
    borderColor: 'primary.main',
    borderRadius: '4px',
    fontWeight: 600,
    width: '50%',
    margin: '0 auto',
    justifyContent: 'center',
  },
  socialLinkText: {
    fontWeight: 600,
    marginRight: 'auto',
  },
  wrapper: {
    width: '100%',
    backgroundColor: 'fruitSalad',
    marginBottom: '30px',
    alignContent: 'center',
    color: 'white',
    padding: '10px',
    borderRadius: '4px',
  },
};

export default styles;
