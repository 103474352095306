import React, { FC, useEffect, useState } from 'react';
import Div from "../../../../shared/ui/Div"
import styles from "./styles";
import { Grid, Link, Typography } from "@mui/material";
import DropdownMenu from "../../../../shared/ui/DropdownMenu";
import { Line } from 'react-chartjs-2';
import {
  useGetLingoLiftTeacherDashboardLLClassesQuery,
  useGetLingoLiftTeacherDashboardLLReportsQuery,
  useGetLingoLiftTeacherDashboardLWReportsQuery
} from '../../api';
import { IOption } from '../../../../shared/types/common';
import Loader from '../../../../shared/ui/Loader';
import { monthsGenerator } from "../../../../shared/utils/monthsGenerator";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      max: 100,
      ticks: {
        callback: function(value) {
          return value + '%';
        }
      }
    }
  }
};

const months = monthsGenerator();

interface ILingoLiftReportDashboardProps {
  isListenwise?: boolean;
  isLingolift?: boolean;
  setViewReportsLink: (link: string) => void;
}


const LingoLiftReportDashboard:FC<ILingoLiftReportDashboardProps> = (props) => {
  const { isListenwise, isLingolift, setViewReportsLink } = props;
  const [currentLLClassId, setCurrentLLClassId] = useState<number | null>(null);

  const { data: reports } = useGetLingoLiftTeacherDashboardLWReportsQuery(null, {
    skip: isLingolift
  });

  const { data: llReports, isLoading: isLLReportsLoading } = useGetLingoLiftTeacherDashboardLLReportsQuery({
      llSchoolClassId: currentLLClassId as number
    },
    {
      skip: isListenwise
    });
  const { data: llReportClasses } = useGetLingoLiftTeacherDashboardLLClassesQuery(null, {
    skip: isListenwise
  });

  useEffect(() => {
    if (llReports?.classSummaryReportUrl) {
      setViewReportsLink(llReports.classSummaryReportUrl)
    }
  }, [llReports]);

  const dataValues = months.map(() => 0);

  if (reports?.data?.data) {
    reports.data.data.forEach(item => {
      const monthIndex = months.indexOf(item.month);
      if (monthIndex !== -1) {
        dataValues[monthIndex] = item.value;
      }
    });
  }

  const data = {
    months,
    datasets: [
      {
        data: dataValues,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  const llClassesOptions = llReportClasses?.items.map((option) => ({
    label: option.name,
    value: option.id
  }))

  const handleChangeClass = (option: IOption) => {
    if (option) {
      setCurrentLLClassId(option.value as number)
    }
  }

  return <Div sx={styles.container}>
    <Div sx={styles.panel}>
      <Typography variant="h3">
        { isListenwise ? 'Average Quiz Grades' : 'Average Lingolift Completion' }
      </Typography>
      { (isLingolift && (llClassesOptions && llClassesOptions?.length > 0)) &&
      <Div sx={styles.dropDown}>
        <DropdownMenu onChange={handleChangeClass} initialValue={llClassesOptions[0] ?? ''} optionsWithId={llClassesOptions ?? []} />
      </Div> }
    </Div>
    { isListenwise && <Line options={ options } data={ data }/> }
    { isLingolift && ( isLLReportsLoading ? <Loader /> : <Grid container spacing={2}>
      <Grid lg={4} item>
        <Div sx={styles.averageWrapper}>
          <Typography sx={styles.averageTitle} variant="h3">
            {llReports?.data.averageWatch.label}
          </Typography>
          <Typography sx={styles.averagePercentage} variant="h3">
            {llReports?.data.averageWatch.percentage}%
          </Typography>
          <Typography sx={styles.averageCountLabel} variant="h4">
            {llReports?.data.averageWatch.countLabel}
          </Typography>
        </Div>
      </Grid>
      <Grid lg={4} item>
        <Div sx={styles.averageWrapper}>
          <Typography sx={styles.averageTitle} variant="h3">
            {llReports?.data.averageLearn.label}
          </Typography>
          <Typography sx={styles.averagePercentage} variant="h3">
            {llReports?.data.averageLearn.percentage}%
          </Typography>
          <Typography sx={styles.averageCountLabel} variant="h4">
            {llReports?.data.averageLearn.countLabel}
          </Typography>
        </Div>
      </Grid>
      <Grid lg={4} item>
        <Div sx={styles.averageWrapper}>
          <Typography sx={styles.averageTitle} variant="h3">
            {llReports?.data.averageSpeak.label}
          </Typography>
          <Typography sx={styles.averagePercentage} variant="h3">
            {llReports?.data.averageSpeak.percentage}%
          </Typography>
          <Typography sx={styles.averageCountLabel} variant="h4">
            {llReports?.data.averageSpeak.countLabel}
          </Typography>
        </Div>
      </Grid>
      <Grid item xs={12}>
        { llReports?.classSummaryReportUrl &&
          <Link sx={styles.transparentButton} href={llReports?.classSummaryReportUrl}>
            View Class Summary Report
          </Link>
        }
      </Grid>
    </Grid> ) }
  </Div>
}

export default LingoLiftReportDashboard;
