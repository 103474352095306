import React, { type FC, useEffect } from 'react';
import { Button, IconButton, Typography, Link } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { isNil } from 'ramda';

import styles from './styles';
import Div from '../../../../shared/ui/Div';
import Image from '../../../../shared/ui/Image';
import listOrderedIcon from '../../../../assets/listOrderedIcon.svg';
import { ILingoLiftCollectionLesson } from '../../../../entities/lingoLiftCollection/model/types';
import { usePutLingoLiftStudentCollectionAssignmentCompletionMutation } from '../../../../entities/lingoLiftCollection';
import { useSnackbar } from '../../../../shared/hooks/useSnackbar';
import { appRoutes } from '../../../../shared/store';

const TIMEOUT = 3000;

interface ILingoLiftLessonFooterProps {
  nextItemLabel: string;
  url: string;
  lessonName: string;
  allLessons: Array<ILingoLiftCollectionLesson>;
  onExpand: () => void;
  assignmentCompletionId?: number;
}

const LingoLiftLessonFooter: FC<ILingoLiftLessonFooterProps> = props => {
  const { url, nextItemLabel, lessonName, allLessons, onExpand, assignmentCompletionId } = props;
  const currentLessonNumber =
    allLessons.findIndex((item: ILingoLiftCollectionLesson) => item.current) + 1;

  const [onSubmitCompletion, { isSuccess, error, isLoading }] =
    usePutLingoLiftStudentCollectionAssignmentCompletionMutation();

  const { showSuccessNotification, showErrorNotification } = useSnackbar();

  const handleSubmit = () => {
    onSubmitCompletion({ id: assignmentCompletionId, stateAction: 'complete' });
  };

  useEffect(() => {
    if (isSuccess) {
      showSuccessNotification();
      setTimeout(() => {
        window.location.href = appRoutes.studentDashboard;
      }, TIMEOUT);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      showErrorNotification(error?.message);
    }
  }, [error]);

  return (
    <Div sx={styles.wrapper}>
      <Div>
        <IconButton sx={styles.button} onClick={onExpand}>
          <Image src={listOrderedIcon} alt="list ordered icon" />
        </IconButton>
      </Div>
      <Div sx={styles.description}>
        <Typography variant="h5" color="darkGray" sx={styles.name}>
          {lessonName}
        </Typography>
        <Typography variant="body2" color="darkGray">
          Collection, Lesson {currentLessonNumber} of {allLessons?.length}
        </Typography>
      </Div>
      <Div>
        {isNil(url) ? (
          <Button onClick={handleSubmit} sx={styles.submitButton} disabled={isLoading}>
            Submit
          </Button>
        ) : (
          <Button component={Link} href={url} endIcon={<EastIcon sx={styles.buttonIcon} />}>
            {nextItemLabel}
          </Button>
        )}
      </Div>
    </Div>
  );
};

export default LingoLiftLessonFooter;
