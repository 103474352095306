import React, { type FC, forwardRef } from 'react';

import { Paper, Typography } from '@mui/material';
import sx from 'mui-sx';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import styles from './styles';
import Div from '../../shared/ui/Div';

interface ISnackbarToastProps {
  message: string;
  isError: boolean;
  id: number;
  onClose: () => void;
}

const SnackbarToast: FC<ISnackbarToastProps> = forwardRef((props, ref) => {
  const { message, isError, id, onClose } = props;

  return (
    <Paper onClick={onClose} ref={ref} elevation={3} sx={styles.container} id={id}>
      <Div sx={sx(styles.iconWrap, { sx: styles.errorWrap, condition: isError })}>
        {isError ? <ErrorOutlineOutlinedIcon /> : <CheckCircleOutlineIcon />}
      </Div>
      <Typography sx={styles.text}>{message}</Typography>
    </Paper>
  );
});

export default SnackbarToast;
