import React, { FC, useState } from 'react';

import Div from '../../shared/ui/Div';
import {
  Autocomplete,
  Button,
  IconButton,
  Popover,
  TextField,
  Typography
} from '@mui/material';
import styles from './styles';
import { Add, Close } from '@mui/icons-material';
import { ITag } from '../../entities/lingoLiftFavorite';


interface ILingoLiftFavoritesAddTagsPopoverProps {
  onCreate: () => void;
  id: number;
  tagsList?: Array<ITag>;
  isLoading: boolean;
  isCreateTagLoading: boolean;
}

const LingoLiftFavoritesAddTagsPopover: FC<ILingoLiftFavoritesAddTagsPopoverProps> = (props) => {
  const { onCreate, id, tagsList = [], isLoading, isCreateTagLoading } = props;

  const [tags, setTags] = useState<Array<string>>([]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const options = tagsList.map(({ name }) => name);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = async () => {
    await onCreate({ id, data: { tagsOnFavorites: tags } }).unwrap();
    handleClose();
  };

  return (
    <Div>
      <Button
        sx={styles.addTagButton}
        onClick={handleClick}
        startIcon={<Add sx={styles.addTagButtonIcon} />}
      >
        Add tag
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={styles.popoverContainer}
      >
        <Div sx={styles.popoverContent}>
          <Div sx={styles.popoverHeader}>
            <Typography variant="h2">Add tags</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Div>
          <Div sx={styles.tagsSelectWrapper}>
            <Autocomplete
              multiple
              options={options}
              freeSolo
              autoSelect
              loading={isLoading}
              onChange={(_, value) => setTags(value)}
              renderInput={(params) => <TextField {...params} placeholder="Tags..." value={tags} sx={styles.tagsInput} />}
            />
            <Button disabled={isCreateTagLoading} onClick={onSubmit}>
              Add Tags
            </Button>
          </Div>
        </Div>
      </Popover>
    </Div>
  );
};

export default LingoLiftFavoritesAddTagsPopover;
