const styles = {
  container: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '12px',
  },
  inputIcon: {
    paddingLeft: '20px',
  },
  bottomSpace: {
    marginBottom: '20px',
  },
};

export default styles;
