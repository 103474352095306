import React, { FC, useState, useEffect, useRef } from 'react';
import { isNil } from 'ramda';
import { Typography, Link, Button } from '@mui/material';
import Div from '../../shared/ui/Div';
import styles from './styles';
import ContentLayout from '../../shared/ui/ContentLayout';
import { LessonGoBackLine, LingoLiftAssign, MainLayout } from '../../widgets';
import {
  ILingoLiftLessonAssignment,
  ILingoLiftManageClass,
  useGetLingoLiftManageLLClassesListQuery,
  useGetLingoLiftTeacherLessonAssignmentSchoolClassesQuery,
  usePatchLingoLiftTeacherLessonAddAssignmentSchoolClassesMutation,
} from '../../entities/lingoliftClasses';
import { UserRole } from '../../entities/user';
import { useSnackbar } from '../../shared/hooks/useSnackbar';
import { isEmpty, values } from 'ramda';
import { UseFormReturn } from 'react-hook-form';

const SUCCESS_MESSAGE = 'Assignment was successfully created!';

interface ILingoLiftLessonAssignPageProps {
  id: number;
}

const LingoLiftLessonAssignPage: FC<ILingoLiftLessonAssignPageProps> = props => {
  const { id } = props;

  const [records, setRecords] = useState<Array<ILingoLiftLessonAssignment>>([]);

  const formRefs = useRef({});

  const registerForm = (classId: number, formInstance: UseFormReturn<any>) => {
    formRefs.current[classId] = formInstance;
  };

  const { showSuccessNotification, showErrorNotification } = useSnackbar();

  const { data: lessonAssignment, isFetching: isLessonAssignmentLoading } =
    useGetLingoLiftTeacherLessonAssignmentSchoolClassesQuery({ llLessonId: id });

  const { data: llClassesList, isLoading: isLlClassesLoading } =
    useGetLingoLiftManageLLClassesListQuery();

  const [
    patchLingoLiftTeacherLessonAddAssignmentSchoolClasses,
    {
      isLoading: isAddLessonAssignmentLoading,
      error: addLessonAssignmentError,
    },
  ] = usePatchLingoLiftTeacherLessonAddAssignmentSchoolClassesMutation();

  const handleChange = (data: ILingoLiftLessonAssignment) => {
    const existingRecord = records.find(record => record.llSchoolClassId === data.llSchoolClassId);
    if (existingRecord) {
      setRecords(
        records.map(record => (record.llSchoolClassId === data.llSchoolClassId ? data : record)),
      );
    } else {
      setRecords([...records, data]);
    }
  };

  useEffect(() => {
    if (lessonAssignment?.items) {
      setRecords(lessonAssignment.items);
    }
  }, [lessonAssignment]);

  const handleSubmit = async () => {
    // Filter checked entities
    const activeRecords = records?.filter((record) => record.isActive);

    const errors: Array<string> = [];
    for (const record of activeRecords) {
      const form = formRefs.current[record.llSchoolClassId];
      if (form) {
        const isValid = await form.trigger();
        if (!isValid) {
          values(form.formState.errors).forEach(error => errors.push(error.message));
        }
      }
    }

    if (isEmpty(errors)) {
      try {
        await patchLingoLiftTeacherLessonAddAssignmentSchoolClasses({
          params: { llLessonId: id },
          body: { llLessonAssignmentSchoolClasses: activeRecords },
        }).unwrap();
        showSuccessNotification(SUCCESS_MESSAGE);
        if (lessonAssignment?.llLessonUrl) {
          window.location.href = lessonAssignment?.llLessonUrl;
        }
      } catch {
        showErrorNotification(addLessonAssignmentError?.message);
      }
    } else {
      errors.forEach(error => showErrorNotification(error));
    }
  };

  const isLoading = isLessonAssignmentLoading || isLlClassesLoading || isAddLessonAssignmentLoading;

  return (
    <MainLayout
      backgroundColor="athensGray"
      isLoading={isLlClassesLoading || isLessonAssignmentLoading}
      userRole={UserRole.Teacher}
    >
      <LessonGoBackLine title="Back to Lesson" />
      <ContentLayout>
        <Div sx={styles.info}>
          <Div sx={styles.announce}>
            <Typography sx={styles.title} variant="h1">
              Assign Lesson
            </Typography>
            <Button component={Link} href={lessonAssignment?.llLessonUrl} sx={styles.previewButton}>
              Preview Lesson
            </Button>
          </Div>
          <Div sx={styles.description}>
            <Typography sx={styles.descriptionParagraph} variant="h4" fontWeight={400}>
              You’re assigning the video:
            </Typography>
            <Typography sx={styles.descriptionParagraph} variant="h4" color="primary.dark">
              {lessonAssignment?.llLessonName}
            </Typography>
          </Div>
        </Div>
        {llClassesList?.items.map((llClass: ILingoLiftManageClass) => (
          <LingoLiftAssign
            key={llClass.id}
            llClass={llClass}
            assigments={!isNil(lessonAssignment) ? lessonAssignment.items : []}
            handleChange={handleChange}
            fieldName="lessonAssignment"
            lessonId={id}
            registerForm={form => registerForm(llClass.id, form)}
          />
        ))}
        <Div sx={styles.bottomActions}>
          <Button onClick={handleSubmit} disabled={isLoading}>
            Assign
          </Button>
        </Div>
      </ContentLayout>
    </MainLayout>
  );
};

export default LingoLiftLessonAssignPage;
