import { createApi, setupListeners } from '@reduxjs/toolkit/query/react';
import { configureStore } from '@reduxjs/toolkit';
import axiosBaseQuery from './baseQuery';
import { CacheTags } from "./commonEnums";

export const apiContainer = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(),
  tagTypes: Object.values(CacheTags),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
});

const { reducerPath, reducer, middleware } = apiContainer;

export const store = configureStore({
  reducer: {
    [reducerPath]: reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware),
});

setupListeners(store.dispatch);
