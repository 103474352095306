const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '81px',
    paddingRight: '16px',
    borderTop: '1px solid',
    borderColor: 'semiGray',
    paddingTop: '10px',
    paddingBottom: '10px',
    boxSizing: 'border-box',
  },
  column: {
    display: 'grid',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
  linkItem: {
    color: 'secondary.dark',
    fontWeight: 600,
  },
  dotedIcon: {
    fontSize: '20px',
  },
  dotedMenu: {
    '& .MuiMenu-paper': {
      borderRadius: '8px',
    },
  },
};

export default styles;
