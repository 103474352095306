const styles = {
  card: {
    borderLeft: '8px solid',
    borderRadius: '12px',
  },
  lingoLiftBorder: {
    borderColor: 'hibiscus',
  },
  listenWiseBorder: {
    borderColor: 'secondary.dark',
  },
  cardElementRounded: {
    borderTop: 'none',
  },
  wrapper: {
    display: 'flex',
    gap: '10px',
    padding: '10px',
    borderColor: 'semiGray',
  },
  poster: {
    objectFit: 'cover',
    borderRadius: '5px',
    width: '100px',
    height: '100px',
    backgroundColor: 'geyser',
    display: {
      xs: 'none',
      sm: 'block',
    },
  },
  posterGrid: {
    display: {
      xs: 'none',
      sm: 'block',
    },
  },
  contentWrapper: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    width: 'fit-content',
    boxSizing: 'border-box',
    height: 'auto',
    '&.MuiCardContent-root': {
      padding: 0,
    },
  },
  logo: {
    cursor: 'default',
    width: '150px',
    height: 'auto',
    display: 'inline-block',
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  description: {
    fontSize: '12px',
  },
  link: {
    cursor: 'pointer',
    fontSize: {
      xs: '14px',
      sm: '16px',
    },
    fontWeight: '600',
    color: 'secondary.dark',
  },
  chipWrapper: {
    paddingTop: '10px',
  },
  chip: {
    borderRadius: '5px',
    fontSize: '14px',
    fontWeight: 400,
    height: 'fit-content',
    padding: '2px 0px',
    boxSizing: 'border-box',
    color: 'black',
    '& .MuiChip-deleteIcon': {
      color: 'black',
      fontSize: '18px'
    }
  },
  assignWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  assignItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    borderTop: '1px solid',
    borderColor: 'semiGray',
  },
  assignItemBottomBordered: {
    borderBottom: '1px solid',
  },
  createNewAssign: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    padding: '20px',
    justifyContent: 'center',
    color: 'secondary.dark',
    '&:hover': {
      color: 'secondary.main'
    }
  }
};

export default styles;
