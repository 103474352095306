import React, { type FC } from 'react';

import styles from './styles';
import Div from '../Div';

interface IContentLayoutProps {
  children: React.ReactNode;
}

const ContentLayout: FC<IContentLayoutProps> = ({ children }) => <Div sx={styles.wrapper}>{children}</Div>;

export default ContentLayout;
