import React, { FC } from 'react';
import Div from '../../../../../shared/ui/Div';
import styles from './styles';
import { Typography } from '@mui/material';
import { ILingoLiftStandardItem } from "../../../model/types";
import RelevantListItem from './RelevantListItem';
import { isEmpty } from 'ramda';

interface IStandardItemProps {
  standards: Array<ILingoLiftStandardItem>;
}

const StandardItem: FC<IStandardItemProps> = (props) => {
  const { standards } = props;

  return <Div sx={styles.container}>
    { standards.map((standardChild) =>  <Div
      sx={styles.standardChildItemContainer}
      key={standardChild.id}>

      <Typography variant="h3">
        { standardChild.label }
      </Typography>

      <Typography 
        variant={isEmpty(standardChild.label) ? "h3" : undefined}
        sx={styles.standardContent}
      >
        {standardChild.content}
      </Typography>
   
      { standardChild.publisherUnits && standardChild.publisherUnits.map((unit, index) => <RelevantListItem
        key={index}
        title={unit.title}
        links={unit.items}
      />) }

      { standardChild.standards && <StandardItem key={standardChild.id} standards={standardChild.standards} /> }

    </Div>) }
  </Div>
}

export default StandardItem;
