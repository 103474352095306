export default {
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '& .MuiAutocomplete-tag': {
          borderRadius: '6px'
        }
      },
    },
  },
};
