import React, { type FC } from 'react';
import { Collapse, Drawer, Link, List, ListItem, ListItemText, Typography } from '@mui/material';
import { ArrowDropDownRounded, ArrowRightRounded } from '@mui/icons-material';

import ProfileMenu from '../ProfileMenu';

import styles from './styles';
import sx from 'mui-sx';
import Div from '../../../../shared/ui/Div';
import { IHeaderMenuItem } from '../../model/types';
import { UserRole } from '../../../../entities/user';

interface IMobileNavigationProps {
  isOpen: boolean;
  onClose: () => void;
  menuList?: Array<IHeaderMenuItem>;
  userRole: UserRole;
}

const MobileNavigation: FC<IMobileNavigationProps> = props => {
  const { isOpen, onClose, menuList = [], userRole } = props;

  const [openList, setOpenList] = React.useState<Record<string, boolean>>({});
  const [openSecondLevelList, setOpenSecondLevelList] = React.useState<Record<string, boolean>>({});
  const handleClick = (index: string) => {
    setOpenList({
      ...openList,
      [index]: index in openList ? (openList[index] = !openList[index]) : (openList[index] = true),
    });
  };

  const handleClickSecondLevel = (index: string) => {
    setOpenSecondLevelList({
      ...openSecondLevelList,
      [index]:
        index in openSecondLevelList
          ? (openSecondLevelList[index] = !openSecondLevelList[index])
          : (openSecondLevelList[index] = true),
    });
  };
  return (
    <Drawer sx={styles.drawer} anchor="right" open={isOpen} onClose={onClose}>
      <Div sx={styles.listSection}>
        <List component="nav" aria-labelledby="nested-list-subheader">
          {menuList?.map(
            (item, index) =>
              (item.link && (
                <ListItem key={index} sx={styles.menuItem}>
                  <Link href={item.link}>{item.name}</Link>
                </ListItem>
              )) ||
              (item.children && (
                <Div key={index}>
                  <ListItem
                    sx={sx(
                      {
                        ...styles.menuItem,
                        ...styles.mainDropDownItem,
                      },
                      {
                        sx: styles.activeParent,
                        condition: openList[`main-${item.name}`],
                      },
                      {
                        sx: styles.inActiveParent,
                        condition: !openList[`main-${item.name}`],
                      }
                    )}
                    onClick={() => {
                      handleClick(`main-${item.name}`);
                    }}
                  >
                    {openList[`main-${item.name}`] ? (
                      <ArrowDropDownRounded sx={styles.itemArrowIcon} />
                    ) : (
                      <ArrowRightRounded sx={styles.itemArrowIcon} />
                    )}
                    <ListItemText primary={item.name} />
                  </ListItem>
                  <Collapse in={openList[`main-${item.name}`]} timeout="auto" unmountOnExit>
                    {item.children.map((child, index) => (
                      <Div key={index}>
                        <List component="div" disablePadding>
                          {child?.children && (
                            <ListItem
                              sx={sx(
                                {
                                  ...styles.menuItem,
                                  ...styles.secondDropDownItem,
                                },
                                {
                                  sx: styles.activeParent,
                                  condition: openSecondLevelList[`second-${child.name}`],
                                },
                                {
                                  sx: styles.inActiveParent,
                                  condition: !openSecondLevelList[`second-${child.name}`],
                                }
                              )}
                              onClick={() => {
                                handleClickSecondLevel(`second-${child.name}`);
                              }}
                            >
                              {openSecondLevelList[`second-${child.name}`] ? (
                                <ArrowDropDownRounded sx={styles.itemArrowIcon} />
                              ) : (
                                <ArrowRightRounded sx={styles.itemArrowIcon} />
                              )}
                              <ListItemText primary={child.name} />
                            </ListItem>
                          )}
                          <Collapse in={openSecondLevelList[`second-${child.name}`]} timeout="auto" unmountOnExit>
                            {child?.children &&
                              child?.children.map((dropChild, index) => (
                                <List key={index} component="div" disablePadding>
                                  <ListItem sx={{ ...styles.menuItem, ...styles.defaultDropDownItem }}>
                                    <Link href={dropChild.link}>{dropChild.name}</Link>
                                  </ListItem>
                                </List>
                              ))}
                          </Collapse>
                        </List>
                        {!child.children && (
                          <List component="div" disablePadding>
                            <ListItem sx={{ ...styles.menuItem, ...styles.defaultDropDownItem }}>
                              <Link href={child.link}>{child.name}</Link>
                            </ListItem>
                          </List>
                        )}
                      </Div>
                    ))}
                  </Collapse>
                </Div>
              ))
          )}
        </List>
      </Div>
      { (userRole && userRole.Public) && <Div sx={styles.actionsPanel}>
        <Typography sx={styles.accountTitle}>My Account</Typography>
        <ProfileMenu />
      </Div> }
    </Drawer>
  );
};

export default MobileNavigation;
