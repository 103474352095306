import { palette } from '../palette';

export default {
  MuiIconButton: {
    styleOverrides: {
      root: {
        padding: '8px',
        borderRadius: '8px',
        // can be optional focus
        // border: '1px solid transparent',
        '&:hover': {
          backgroundColor: palette.primary.light,
        },
        '&:focus': {
          // can be optional focus
          // border: '1px solid #2893BE'
        },
      },
    },
  },
};
