const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
  },
  showButton: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid',
    borderColor: '#CFD6DD',
    '&.MuiButton-root': {
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '&:focus': {
        backgroundColor: 'transparent'
      }
    }
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    padding: '0px 20px'
  },
  link: {
    color: 'secondary.dark',
  }
}

export default styles;
