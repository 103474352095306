const styles = {
  iframe: {
    height: {
      xs: '55vh',
      sm: 'max-content',
    },
    padding: {
      xs: '0px',
      md: '30px 0px 10px 0px',
    },
  },
};

export default styles;
