import type { FC } from 'react';
import * as React from 'react';
import { Card, CardContent, CardMedia, Link, Tooltip, Typography } from '@mui/material';

import styles from './styles';
import Div from '../../../../shared/ui/Div';
import { LingoLiftBadgeType, LingoLiftLessonBadge } from '../../../lingoLiftLesson';
import { sliceTextContent } from '../../../../shared/utils/sliceTextContent';
import sx from 'mui-sx';
import Image from '../../../../shared/ui/Image';
import lingoLiftLessonLogo from '../../../../assets/lingoLiftLessonLogo.svg';
import listenWiseLessonLogo from '../../../../assets/listenWiseLessonLogo.svg';
import { isLingoLiftSearchProduct, isListenWiseSearchProduct } from '../../lib/compareConditions';
import { ILingoLiftSearch } from '../../../lingoLiftSearch';
import videoIcon from '../../../../assets/secondaryIcons/videoIcon.svg';
import quizIcon from '../../../../assets/secondaryIcons/quizIcon.svg';
import audioIcon from '../../../../assets/secondaryIcons/audioIcon.svg';
import langIcon from '../../../../assets/secondaryIcons/langIcon.svg';
import linkIcon from '../../../../assets/secondaryIcons/linkIcon.svg';
import { parseToPascaleCase } from '../../../../shared/utils/parseToPascaleCase';
import { displayLexileLevel } from "../../lib/displayLexileLevel";

const LingoLiftSearchCard: FC<ILingoLiftSearch> = props => {
  const {
    name,
    description,
    photoSrc,
    url,
    product,
    subjects,
    topicTags,
    video,
    elpLevelRange,
    quiz,
    audio,
    lexile,
    lexileLevel,
    elpLevelLabel,
    spanish,
    linkedLesson,
    category,
  } = props;

  return (
    <Card
      sx={ sx(
        styles.card,
        {
          sx: styles.lingoLiftBorder,
          condition: isLingoLiftSearchProduct(product),
        },
        {
          sx: styles.listenWiseBorder,
          condition: isListenWiseSearchProduct(product),
        }
      ) }
    >
      <Div sx={ styles.wrapper }>
        <Div sx={ styles.posterGrid }>
          <CardMedia image={ photoSrc } component="div" sx={ styles.poster }/>
        </Div>
        <Div sx={ styles.contentWrapper }>
          <CardContent sx={ styles.content }>
            <Link sx={ styles.lessonLogo }>
              { isLingoLiftSearchProduct(product) && <Image src={ lingoLiftLessonLogo } alt="lingo lift lesson logo"/> }
              { isListenWiseSearchProduct(product) &&
                <Image src={ listenWiseLessonLogo } alt="listen wise lesson logo"/> }
            </Link>
            <Link href={ url } sx={ styles.link }>
              { name }
            </Link>
            <Typography sx={ styles.description }>{ sliceTextContent(description, 200) }</Typography>
            <Div sx={ styles.badgesWrapper }>
              { category && (
                  <LingoLiftLessonBadge type={ LingoLiftBadgeType.ShuttleGray } title={ category }/>
              ) }

              { subjects.map((subject, index) => (
                <LingoLiftLessonBadge key={ index } type={ LingoLiftBadgeType[parseToPascaleCase(subject)] }
                                      title={ subject }/>
              )) }

              { topicTags.map((tag, index) => (
                <LingoLiftLessonBadge key={ index } type={ LingoLiftBadgeType[parseToPascaleCase(tag)] } title={ tag }/>
              )) }

            </Div>
          </CardContent>
          <Div sx={ styles.info }>
            { (elpLevelRange && !elpLevelLabel) && (
              <LingoLiftLessonBadge
                title={ `ELP ${ elpLevelRange }` }
                type={ LingoLiftBadgeType.Eucalyptus }/>
            ) }

            { elpLevelLabel && <LingoLiftLessonBadge
              title={ elpLevelLabel?.split('-')[0] }
              secondary={ elpLevelLabel?.split('-')[1] }
              type={ LingoLiftBadgeType.Eucalyptus }/> }


            { lexile && (
              <Div sx={ {
                ...styles.tagWrapper,
                ...styles.lexileTag
              } }>
                <Tooltip title="Lexile Audio Measure" placement="top" arrow>
                  <Div sx={styles.lexileWrapper}>
                    <Div sx={sx(
                      styles.lexileTagLowLine,
                      styles.lexileTagDefault,
                      {
                        sx: styles.lexileFilledTag,
                        condition: (lexileLevel === 1 || lexileLevel === 2 || lexileLevel === 3)
                      },
                    )}></Div>
                    <Div sx={sx(
                      styles.lexileTagMediumLine,
                      styles.lexileTagDefault,
                      {
                        sx: styles.lexileFilledTag,
                        condition: (lexileLevel === 2 || lexileLevel === 3)
                      }
                    )}></Div>
                    <Div sx={sx(
                      styles.lexileTagHighLine,
                      styles.lexileTagDefault,
                      {
                        sx: styles.lexileFilledTag,
                        condition: (lexileLevel === 3)
                      }
                    )}></Div>
                  </Div>
                </Tooltip>
                <Tooltip title={`Listening level: ${displayLexileLevel(lexileLevel)}`} placement="top" arrow>
                  <Typography sx={ styles.tagTitle } color="black" fontWeight={ 600 }>
                    { lexile } L
                  </Typography>
                </Tooltip>
              </Div>
            ) }

            { video && (
              <Div sx={ styles.tagWrapper }>
                <Image sx={ styles.tagIcon } src={ videoIcon } alt="Listenwise watch card icon"/>
                <Typography sx={ styles.tagTitle } fontWeight={ 600 }>
                  Interactive Video
                </Typography>
              </Div>
            ) }

            { quiz && (
              <Div sx={ styles.tagWrapper }>
                <Image sx={ styles.tagIcon } src={ quizIcon } alt="Listenwise watch card icon"/>
                <Typography sx={ styles.tagTitle } fontWeight={ 600 }>
                  Comprehension Quiz
                </Typography>
              </Div>
            ) }

            { spanish && (
              <Div sx={ styles.tagWrapper }>
                <Image sx={ styles.tagIcon } src={ langIcon } alt="Listenwise watch card icon"/>
                <Typography sx={ styles.tagTitle } fontWeight={ 600 }>
                  Spanish version
                </Typography>
              </Div>
            ) }

            { audio && (
              <Div sx={ styles.tagWrapper }>
                <Image sx={ styles.tagIcon } src={ audioIcon } alt="Listenwise watch card icon"/>
                <Typography sx={ styles.tagTitle } fontWeight={ 600 }>
                  Audio
                </Typography>
              </Div>
            ) }
          </Div>
        </Div>
      </Div>
      { linkedLesson && <Div sx={styles.linkedLessonWrapper}>
        <Image src={linkIcon} alt="share link icon" />
        <Typography sx={styles.linkedLessonPrefix} fontWeight={600}>
          { linkedLesson.prefixText }
        </Typography>
        <Link
          sx={styles.link}
          href={linkedLesson.url}>
          { linkedLesson.name }
        </Link>
      </Div> }
    </Card>
  );
};

export default LingoLiftSearchCard;
