import type { FC } from 'react';
import * as React from 'react';
import { Card, CardContent, CardMedia, Grid, Link, SvgIcon, Typography } from '@mui/material';

import styles from './styles';
import { sliceTextContent } from '../../../../../shared/utils/sliceTextContent';
import Div from '../../../../../shared/ui/Div';
import { LingoLiftBadgeType, LingoLiftLessonBadge } from '../../../../lingoLiftLesson';
import { ILingoLiftCollectionItem } from '../../../model/types';
import sx from 'mui-sx';
import TimeIcon from "../../../../../assets/TimeIcon";

interface ILingoLiftCollectionChildCardProps extends ILingoLiftCollectionItem {
  elementRounded?: boolean;
}

const LingoLiftCollectionChildCard: FC<ILingoLiftCollectionChildCardProps> = props => {
  const { name, description, photoSrc, elpLevelRange, url, elementRounded, durationMmSs } = props;
  return (
    <Card
      sx={sx(styles.card, {
        sx: styles.cardElementRounded,
        condition: Boolean(elementRounded),
      })}
    >
      <Grid sx={styles.wrapper} container spacing={2}>
        <Grid item xs={3} md={1.5}>
          <CardMedia image={photoSrc} component="div" sx={styles.poster} />
        </Grid>
        <Grid item xs={8} md={10.5}>
          <Div sx={styles.contentWrapper}>
            <CardContent sx={styles.content}>
              <Link href={url} sx={styles.link}>
                {name}
              </Link>
              <Typography sx={styles.description}>{sliceTextContent(description)}</Typography>
            </CardContent>
            <Div sx={styles.info}>
              <LingoLiftLessonBadge title={`ELP ${elpLevelRange}`} type={LingoLiftBadgeType.Eucalyptus} />
              <Div sx={styles.timeWrapper}>
                <SvgIcon
                sx={styles.timeIcon}
                component={TimeIcon} />
              <Typography sx={styles.timeMinutes}>
                { durationMmSs } min
              </Typography>
              </Div>
            </Div>
          </Div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default LingoLiftCollectionChildCard;
