const styles = {
  header: {
    display: 'flex',
    gap: '20px',
    alignItems: 'flex-start',
    padding: '6px 24px',
    width: '100%',
    boxSizing: 'border-box',
    borderBottom: '1px solid #EAEDF0',
    background: 'white',
    boxShadow: 'none',
    position: 'relative',
  },
  linkList: {
    display: 'flex',
    gap: '0px 20px',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flex: 1,
  },
  toolBar: {
    padding: 0,
    gap: '20px',
    boxSizing: 'border-box',
    width: '100%',
  },
  headerLogo: {
    height: 24,
    width: 'auto',
    marginRight: '10px',
    img: {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
  },
  doubleLogo: {
    width: {
      sm: '238px',
      xs: '160px',
    },
    height: 'auto'
  },
  headerOption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px !important',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    color: 'primary.main',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '5px 10px',
    minHeight: 'auto',
    textTransform: 'capitalize',
    '&.MuiButton-root': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      '&:hover': {
        backgroundColor: 'secondary.light',
        color: 'secondary.main',
        borderRadius: '8px',
      },
    },
    '&:hover': {
      backgroundColor: 'secondary.light',
      color: 'secondary.main',
      borderRadius: '6px',
    },
  },
  headerOptionDropdown: {
    '& .MuiPaper-root': {
      backgroundColor: 'white',
      border: '1px solid #EAEDF0',
      padding: '10px 0',
      borderRadius: '10px',
      fontSize: 14,
      boxShadow: 'none',
    },
  },
  headerTools: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    gap: '10px',
  },
  accountIconButton: {
    width: '40px',
    height: '40px',
    padding: '4px',
    borderRadius: '8px',
  },
  accountCircleIcon: {
    width: '100%',
    height: '100%',
  },
  authToogleIconButton: {
    backgroundColor: 'white',
    marginLeft: 'auto',
    '&.MuiButton-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
    }
  },
  authSvgToggleIcon: {
    width: '80px',
    height: '24px'
  }
};

export default styles;
