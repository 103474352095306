import { TabContent } from '../../shared/ui/Tabs/ui/TabContent';
import ContentLayout from '../../shared/ui/ContentLayout';
import { MainLayout } from '../../widgets';
import React, { FC, useEffect, useState } from 'react';
import TabsContainer from '../../shared/ui/Tabs/ui';
import Div from '../../shared/ui/Div';
import { Button, Grid, Link, SvgIcon, Typography, Divider } from '@mui/material';
import {
  LingoLiftClassesAssignmentCard,
  LingoLiftClassesManageCollapseItem,
  LingoLiftClassesProgressCard,
  useGetLingoLiftManageLLClassesListQuery,
  useGetLingoLiftManageLWClassesListQuery,
  useGetLingoLiftTeacherLWClassesRecentAssignmentsListQuery,
  useGetLingoLiftTeacherLLClassesRecentAssignmentsListQuery, useGetLingoLiftTeacherClassesLWStudentProgressQuery,
} from '../../entities/lingoliftClasses';
import styles from './styles';
import { AddRounded } from '@mui/icons-material';
import {
  LingoLiftClassesManageClassStudentResetPasswordModalForm,
  LingoLiftClassesManageClassUpdateClassModalForm,
  ModalClassType,
  useDeleteLingoLiftManageLLClassMutation,
  useDeleteLingoLiftManageLWClassMutation,
  useDeleteLingoLiftStudentFromLLClassMutation,
  useDeleteLingoLiftStudentFromLWClassMutation
} from '../../features/lingoLiftClasses';
import { appRoutes } from '../../shared/store';
import { getUrlSearchParams } from '../../shared/utils/urlParsers';
import lingoLiftLogo from '../../assets/lingoLiftLessonLogo.svg';
import listenWiseLogo from '../../assets/listenWiseLessonLogo.svg';
import Image from '../../shared/ui/Image';
import { UserRole } from "../../entities/user";
import AttentionIcon from '../../assets/AttentionIcon';
import LingoLiftClassesManageClassStudentProblemsConnectingModal from '../../features/lingoLiftClasses/ui/ManageClassProblemModal';
import { LingoLiftProduct } from "../../shared/types/common-enums";

interface ILingoLiftClassesTeacherBrowsePage {
  isListenwise: boolean;
}

const classesTabs = [
  {
    name: 'Assignments',
  },
  {
    name: 'Student Progress',
  },
  {
    name: 'Manage Classes',
  },
];

const classesTabQuery = {
  assignments: 0,
  progress: 1,
  manage: 2
}

const LingoLiftClassesTeacherBrowsePage:FC<ILingoLiftClassesTeacherBrowsePage> = (props) => {
  const { isListenwise } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const [currentLLClassId, setCurrentLLClassId] = useState<null | number>(null);
  const [currentLWClassId, setCurrentLWClassId] = useState<null | number>(null);
  const [currentLLStudentId, setCurrentLLStudentId] = useState<null | number>(null);
  const [currentLWStudentId, setCurrentLWStudentId] = useState<null | number>(null);
  const [isOpenModal, setOpenModal] = useState<boolean>(false);

  const { data: manageLLClassesList, isLoading: isLoadingLLClasses} = useGetLingoLiftManageLLClassesListQuery();

  const { data: manageLWClassesList, isLoading: isLoadingLWClasses} = useGetLingoLiftManageLWClassesListQuery();

  const [onDeleteLLClass] = useDeleteLingoLiftManageLLClassMutation();

  const [onDeleteLWClass] = useDeleteLingoLiftManageLWClassMutation()

  const [onDeleteLLStudent] = useDeleteLingoLiftStudentFromLLClassMutation();

  const [onDeleteLWStudent] = useDeleteLingoLiftStudentFromLWClassMutation();

  const { data: LWClassesRecentAssignmentsList, isLoading: isLoadingLWClassesRecentAssignmentsList} =
    useGetLingoLiftTeacherLWClassesRecentAssignmentsListQuery();

  const { data: LLClassesRecentAssignmentsList, isLoading: isLoadingLLClassesRecentAssignmentsList} =
    useGetLingoLiftTeacherLLClassesRecentAssignmentsListQuery();

  const { data: lwStudentProgress, isLoading: isLWStudentProgressLoading } = useGetLingoLiftTeacherClassesLWStudentProgressQuery();

  const { data: llStudentProgress, isLoading: isLLStudentProgressLoading } = useGetLingoLiftTeacherClassesLWStudentProgressQuery();

  useEffect(() => {
    // set tab url query params
   setTabIndex(classesTabQuery[getUrlSearchParams(window.location.search, 'type')] ?? 0)
  }, []);

  const onChangeTab = (index: number) => {
    setTabIndex(index)

    // Change query params without rerender page
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('type', Object.keys(classesTabQuery)[index])
    window.history.pushState({}, '', currentUrl)
  }

  const isLoading =
    isLoadingLLClasses ||
    isLoadingLWClasses ||
    isLoadingLWClassesRecentAssignmentsList ||
    isLoadingLLClassesRecentAssignmentsList ||
    isLLStudentProgressLoading ||
    isLWStudentProgressLoading;

  return (
    <MainLayout userRole={UserRole.Teacher} isLoading={isLoading} backgroundColor='white'>
      <Div sx={styles.header}>
        <Typography variant="h1">Classes</Typography>
      </Div>
      <Divider variant="fullWidth" sx={styles.divider} />
      <ContentLayout>
        { currentLLClassId && <LingoLiftClassesManageClassUpdateClassModalForm
          id={currentLLClassId}
          onClose={() => setCurrentLLClassId(null)}
          type={ModalClassType.LL}
        /> }

        { currentLWClassId && <LingoLiftClassesManageClassUpdateClassModalForm
          id={currentLWClassId}
          onClose={() => setCurrentLWClassId(null)}
          type={ModalClassType.LW}
        /> }

        { currentLLStudentId && <LingoLiftClassesManageClassStudentResetPasswordModalForm
          id={currentLLStudentId}
          onClose={() => setCurrentLLStudentId(null)}
          type={ModalClassType.LL}
        /> }

        { currentLWStudentId &&  <LingoLiftClassesManageClassStudentResetPasswordModalForm
          id={currentLWStudentId}
          onClose={() => setCurrentLWStudentId(null)}
          type={ModalClassType.LW}
        /> }

        <LingoLiftClassesManageClassStudentProblemsConnectingModal
          isOpenModal={isOpenModal}
          onClose={() => setOpenModal(false)}
        />

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TabsContainer tabs={classesTabs} tabIndex={tabIndex} setTabIndex={onChangeTab} isBordered>
              <TabContent index={0} value={tabIndex}>
                {LLClassesRecentAssignmentsList?.items.map(schoolClass => (
                  <LingoLiftClassesAssignmentCard schoolClass={schoolClass} key={schoolClass.llSchoolClassId} />
                ))}
                {LWClassesRecentAssignmentsList?.items.map(schoolClass => (
                  <LingoLiftClassesAssignmentCard schoolClass={schoolClass} key={schoolClass.schoolClassId} isListenWiseClass />
                ))}
              </TabContent>
              <TabContent index={1} value={tabIndex}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    { lwStudentProgress?.items?.map((progress) => <LingoLiftClassesProgressCard
                      key={progress.schoolClassId}
                      {...progress}
                      product={LingoLiftProduct.LingoLift} />) }
                  </Grid>
                  <Grid item xs={12}>
                    { lwStudentProgress?.items?.map((progress) => <LingoLiftClassesProgressCard
                      key={progress.schoolClassId}
                      {...progress}
                      product={LingoLiftProduct.ListenWise} />) }
                  </Grid>
                </Grid>
              </TabContent>
              <TabContent index={2} value={tabIndex}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Div sx={styles.manageContainer.info}>

                      <SvgIcon
                        sx={styles.manageContainer.attentionIcon}
                        component={AttentionIcon} />

                      <Div sx={styles.manageContainer.infoContent}>
                        <Typography variant="h5" color="toreaBay">
                          How to Invite Students
                        </Typography>
                        <Typography>
                          Students need to sign up or login at:{' '}
                          <Link href="https://app.listenwise.com/students" sx={styles.manageContainer.actionLink}>
                            https://app.listenwise.com/students
                          </Link>
                        </Typography>
                        <Typography>
                          If you imported your rosters from Google Classroom, students click "Login With Google". If you
                          created a class, students click "Sign Up" and enter their class code. If they already have a
                          Listenwise account from a prior class, they can join your class from their dashboard using the
                          class code.
                        </Typography>
                      </Div>
                    </Div>
                  </Grid>

                  <Grid item xs={1}>
                    <Div sx={styles.wrapperLogo}>
                      <Image src={lingoLiftLogo} alt="lingolift logo" />
                    </Div>
                  </Grid>

                  <Grid item xs={11}>
                    <Div sx={styles.manageContainer.actions}>
                      <Button
                        component={Link}
                        href={appRoutes.addLLClass}
                        startIcon={<AddRounded />}>
                        Add Class
                      </Button>
                      <Link
                        href={manageLLClassesList?.importButton.link}
                        sx={styles.manageContainer.transparentButton}>
                        { manageLLClassesList?.importButton.name }
                      </Link>
                      <Button variant="text" sx={styles.manageContainer.actionLink} onClick={() => setOpenModal(true)}>
                        Problems Connecting to Google Classroom?
                      </Button>
                    </Div>
                  </Grid>

                  {manageLLClassesList?.items.map(manageClass => (
                    <Grid key={manageClass.id} item xs={12}>
                      <LingoLiftClassesManageCollapseItem
                        onDeleteClass={onDeleteLLClass}
                        onDeleteStudent={onDeleteLLStudent}
                        setCurrentClassId={setCurrentLLClassId}
                        setCurrentStudentId={setCurrentLLStudentId}
                        {...manageClass}
                        howToInviteStudents={manageLLClassesList.howToInviteStudents}
                      />
                    </Grid>
                  ))}

                  {isListenwise &&
                    <Grid item xs={1}>
                      <Div sx={styles.wrapperLogo}>
                        <Image src={listenWiseLogo} alt="lingolift logo" />
                      </Div>
                    </Grid> }

                  { isListenwise && <Grid item xs={11}>
                    <Div sx={styles.manageContainer.actions}>
                      <Button
                        component={Link}
                        href={appRoutes.addLWClass}
                        startIcon={<AddRounded />}>
                        Add Class
                      </Button>
                      <Link
                        href={manageLWClassesList?.importButton.link}
                        sx={styles.manageContainer.transparentButton}>
                        { manageLWClassesList?.importButton.name }
                      </Link>
                      <Button variant="text" sx={styles.manageContainer.actionLink} onClick={() => setOpenModal(true)}>
                        Problems Connecting to Google Classroom?
                      </Button>
                    </Div>
                  </Grid> }

                    { isListenwise && manageLWClassesList?.items.map(manageClass => (
                      <Grid key={manageClass.id} item xs={12}>
                        <LingoLiftClassesManageCollapseItem
                          onDeleteClass={onDeleteLWClass}
                          onDeleteStudent={onDeleteLWStudent}
                          setCurrentClassId={setCurrentLWClassId}
                          setCurrentStudentId={setCurrentLWStudentId}
                          {...manageClass}
                          isListenwise={isListenwise}
                          howToInviteStudents={manageLWClassesList.howToInviteStudents}
                        />
                      </Grid>
                    ))}

                </Grid>
              </TabContent>
            </TabsContainer>
          </Grid>
        </Grid>
      </ContentLayout>
    </MainLayout>
  );
};

export default LingoLiftClassesTeacherBrowsePage;
