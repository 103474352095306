import * as React from 'react';
import { FC } from 'react';
import { Button, Link, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, CircularProgress } from '@mui/material';
import { LogoutRounded, SettingsRounded } from '@mui/icons-material';

import styles from './styles';
import { IHeaderProfileMenuItem } from '../../model/types';
import { HeaderMenuItems } from '../../model/enums';

interface IProfileMenu {
  menuList?: Array<IHeaderProfileMenuItem>;
  isLoading: boolean;
}

const ProfileMenu: FC<IProfileMenu> = props => {
  const { menuList = [], isLoading } = props;

  const handleDelete = async (url: string) => {
    await fetch(url, { method: 'DELETE' });
    window.location.reload();
  };

  const getMenuItem = menuItem => {
    if (menuItem.name === HeaderMenuItems.Settings)
      return (
        <>
          <ListItemIcon>
            <SettingsRounded fontSize="small" />
          </ListItemIcon>
          <Link href={menuItem.link} sx={styles.optionLink}>
            <ListItemText sx={styles.optionLink}>{menuItem.name}</ListItemText>
          </Link>
        </>
      );
    return (
      <>
        <ListItemIcon>
          <LogoutRounded fontSize="small" />
        </ListItemIcon>
        <Button variant="text" sx={styles.button} onClick={() => handleDelete(menuItem.link)}>
          <ListItemText sx={styles.optionLink}>{menuItem.name}</ListItemText>
        </Button>
      </>
    );
  };

  if (isLoading) return <CircularProgress size={20} />;

  return (
    <Paper sx={styles.profileMenu}>
      <MenuList>
        {menuList.map(menuItem => (
          <MenuItem key={menuItem.id} sx={styles.option}>{getMenuItem(menuItem)}</MenuItem>
        ))}
      </MenuList>
    </Paper>
  );
};

export default ProfileMenu;
