enum SignInFormType {
  Student = 'student',
  Teacher = 'teacher',
}

enum SignInFormFieldType {
  Password = 'password',
  Email = 'email',
  CheckBox = 'checkbox',
  Hidden = 'hidden',
}

export { SignInFormType, SignInFormFieldType };
