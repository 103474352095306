const styles = {
  option: {
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'primary.light',
    },
  },
  optionLink: {
    fontSize: '14px',
  },
  profileMenu: {
    minWidth: '215px',
    '&.MuiPaper-root': {
      padding: '0px 10px',
      border: 'none',
      boxShadow: 'none',
    },
  },
  button: {
    minWidth: 'unset',
  },
};

export default styles;
