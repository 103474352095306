import axios from 'axios';
import { keysToCamelCase, serializeKeysToSnakeCase } from '@bigbinary/neeto-cist';

const apiInstance = axios.create({
  baseURL: '/',
});

apiInstance.interceptors.request.use(
  config => {
    if (config.headers['Content-Type'] === 'multipart/form-data') return config;

    if (config.params) {
      config.params = serializeKeysToSnakeCase(config.params);
    }

    if (config.data) {
      config.data = serializeKeysToSnakeCase(config.data);
    }

    return config;
  },
  async error => Promise.reject(error)
);

apiInstance.interceptors.response.use(
  response => {
    if (response.data && response.headers['content-type'].includes('application/json')) {
      response.data = keysToCamelCase(response.data);
    }

    return response;
  },
  async error => Promise.reject(error)
);

export default apiInstance;
