import React, { FC } from 'react';
import styles from './styles';
import { Button, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import Div from "../../../shared/ui/Div";
import sx from "mui-sx";
import { formFields } from "../model/formFields";
import { ForgotPasswordFormFieldType, ForgotPasswordFormType } from "../model/enums";
import { getFormDefaultValues, getNestedFormErrorMessage } from '../../../shared/utils/helpers';
import { IDefaultSignInInput } from "../../SignInForm/model/types";

interface IForgotPasswordFormProps {
  formType: ForgotPasswordFormType;
  url?: string;
  defaultErrors: Array<string>;
  defaultInputs: Array<IDefaultSignInInput>;
}

const isHidden = (type: string) => type === ForgotPasswordFormFieldType.Hidden;

const ForgotPasswordForm: FC<IForgotPasswordFormProps> = (props) => {
  const { formType, url, defaultErrors, defaultInputs } = props;

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues: getFormDefaultValues(defaultInputs),
  });

  return (
    <form action={url} method="post">
      <Div sx={styles.formContainer}>
        {formFields[formType]?.map((field, index) => ((Boolean(field.type)) && (
          <Div
            key={index}
            sx={sx(styles.formItem, {
              sx: styles.hiddenItem,
              condition: isHidden(field.type),
            })}
          >
            <Div
              sx={sx(styles.formItemLabel, {
                sx: styles.formItemLabelRequiredSymbol,
                condition: field.required,
              })}
            >
              <Typography variant="h5">{field.label}</Typography>
            </Div>
            <TextField
              type={isHidden(field.type) ? 'text' : field.type}
              {...register(field.name)}
              error={!!getNestedFormErrorMessage(errors, field.name)}
              helperText={getNestedFormErrorMessage(errors, field.name) || ''}
              placeholder={field.placeholder}
              required={field.required}
              fullWidth
            />
          </Div>
        )))}
        <Button sx={styles.submitButton} type="submit" variant="contained">
          I Want to Reset My Password
        </Button>
        {defaultErrors && (
          <Typography sx={styles.error}>
            {Array.isArray(defaultErrors) ? defaultErrors.join('; ') : defaultErrors}
          </Typography>
        )}
      </Div>
    </form>
  );
};

export default ForgotPasswordForm;
