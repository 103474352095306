import React, { type FC } from 'react';
import Div from "../../../../shared/ui/Div"
import styles from "./styles";
import { Grid, Link, Typography } from "@mui/material";
import Image from "../../../../shared/ui/Image";
import lingoLiftLessonLogo from '../../../../assets/lingoLiftLessonLogo.svg';
import listenWiseLessonLogo from '../../../../assets/listenWiseLessonLogo.svg';
import ProgressLine from "../../../../shared/ui/ProgressLine";
import { ILingoLiftTeacherDashboardClass } from '../../model/types';
import { isLingoLiftProduct } from '../../../../shared/utils/lingoLiftCompareConditions';

const LingoLiftDashboardClassesList: FC<ILingoLiftTeacherDashboardClass> = (props) => {
  const {
    name,
    product,
    classUrl,
    studentsUrl,
    studentsCount,
    assignmentsUrl,
    assignmentsCount,
    progress
  } = props;

  return (
    <Div sx={styles.list}>
      <Grid
        container
        sx={{
          ...styles.listItem,
          ...styles.listItemBordered,
        }}
      >
        <Grid sx={styles.listItemContent} item>
          {isLingoLiftProduct(product) ? (
            <Image height={16} src={lingoLiftLessonLogo} alt="lingolift lesson logo" />
          ) : (
            <Image height={16} src={listenWiseLessonLogo} alt="listenwise lesson logo" />
          )}
          <Link fontWeight="600" href={classUrl} sx={styles.listItemLink}>
            {name}
          </Link>
        </Grid>
        <Grid sx={styles.listItemStatWrapper} item>
          <Div sx={styles.listItemColumn}>
            <Typography variant="body2" fontWeight={600} color="primary.dark">
              Students
            </Typography>
            <Link fontWeight="600" href={studentsUrl} sx={styles.listItemLink}>
              {studentsCount}
            </Link>
          </Div>
          <Div sx={styles.listItemColumn}>
            <Typography variant="body2" fontWeight={600} color="primary.dark">
              Assigned
            </Typography>
            <Link fontWeight="600" href={assignmentsUrl} sx={styles.listItemLink}>
              {assignmentsCount}
            </Link>
          </Div>
          <Div
            sx={{
              ...styles.listItemColumn,
              ...styles.listItemColumnAlignStart,
            }}
          >
            <Typography variant="body2" fontWeight={600} color="primary.dark">
              {progress.name}
            </Typography>
            <ProgressLine percentage={progress.percentage} color={progress.color} />
          </Div>
        </Grid>
      </Grid>
    </Div>
  );
}

export default LingoLiftDashboardClassesList;
