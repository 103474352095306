import React, { type FC } from 'react';
import Div from '../Div';
import styles from './styles';
import { Typography } from '@mui/material';

interface ILingoLiftTeacherDashboardClassesProgressProps {
  percentage: number;
  color: string;
}

export const ProgressLine: FC<ILingoLiftTeacherDashboardClassesProgressProps> = props => {
  const { percentage, color } = props;

  return (
    <Div sx={styles.container}>
      <Div sx={styles.progressContainer}>
        <Div
          sx={{
            ...styles.filledZone,
            backgroundColor: color,
            width: `${percentage}%`,
          }}
        />
      </Div>
      <Typography fontWeight={600}>{percentage}%</Typography>
    </Div>
  );
}

export default ProgressLine;
