import * as React from 'react';
import ReduxProvider from '../reduxProvider';
import { createRoot } from 'react-dom/client';
import LingoLiftStandardsPage from "../../pages/LingoLiftStandards";
import { standardsParsePropParams } from "../../entities/lingoLiftStandard/lib/standardsParsePropParams";

interface ILingoLiftStandardsRoutesRenderDataset {
  dataset: {
    subjects: Array<string>;
    // rails pass this prop as lowercase, it doesn't fix on front-end
    schoolstate: string;
    grades: Array<number>;
  }
}

const lingoLiftStandardsRoutesRender = () => {
  const lingoLiftStandardBrowseElement = document.querySelector('#lingolift_standards');

  const renderElement = lingoLiftStandardBrowseElement;

  if (renderElement) {
    const root = createRoot(renderElement);
    const standards = standardsParsePropParams((renderElement as unknown as ILingoLiftStandardsRoutesRenderDataset).dataset)

    return root.render(
      <ReduxProvider>
        { lingoLiftStandardBrowseElement && <LingoLiftStandardsPage
            {...standards}
           />}
      </ReduxProvider>
    );
  }
};

export default lingoLiftStandardsRoutesRender;
