import * as React from 'react';
import { ElpLevel } from "../model/enums";
import { Typography } from "@mui/material";

const generateElpContentByElpLevel = (elpContent: Record<string, Record<string, Array<string>>>, subCategory: string, elpLevel: ElpLevel) => {
  return Object.keys(elpContent[subCategory]).sort().map((key) =>
    key === elpLevel &&
    Object.values(elpContent[subCategory][key]).map((elpValue: string, index: number) => <Typography key={index}>
      { elpValue }
    </Typography>))
}

export { generateElpContentByElpLevel }
