import React, { FC, useState, useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import { Button, Link } from '@mui/material';
import ContentLayout from '../../shared/ui/ContentLayout';
import Div from '../../shared/ui/Div';
import styles from './styles';
import { LessonGoBackLine, LingoLiftAssign, MainLayout } from '../../widgets';
import {
  ILingoLiftCollectionAssignment,
  useGetLingoLiftTeacherCollectionAssignmentSchoolClassesQuery,
  usePatchLingoLiftTeacherCollectionAddAssignmentSchoolClassesMutation,
} from '../../entities/lingoLiftCollection';
import { ILingoLiftManageClass, useGetLingoLiftManageLLClassesListQuery } from '../../entities/lingoliftClasses';
import { UserRole } from '../../entities/user';
import { useSnackbar } from '../../shared/hooks/useSnackbar';
import { isEmpty, values } from 'ramda';
import { UseFormReturn } from 'react-hook-form';

const SUCCESS_MESSAGE = 'Assignment was successfully created!';

interface ILingoLiftCollectionAssignPageProps {
  id: number;
}

const LingoLiftCollectionAssignPage: FC<ILingoLiftCollectionAssignPageProps> = props => {
  const { id } = props;

  const [records, setRecords] = useState<Array<ILingoLiftCollectionAssignment>>([]);

  const formRefs = useRef({});

  const registerForm = (classId: number, formInstance: UseFormReturn<any>) => {
    formRefs.current[classId] = formInstance;
  };

  const { data: collectionAssignment, isLoading: isCollectionAssignmentLoading } =
    useGetLingoLiftTeacherCollectionAssignmentSchoolClassesQuery({ enhancedCollectionId: id });

  const { data: llClassesList, isLoading: isLlClassesLoading } =
    useGetLingoLiftManageLLClassesListQuery();

  const [
    patchLingoLiftTeacherCollectionAddAssignmentSchoolClasses,
    {
      error: addCollectionAssignmentError,
      isLoading: isAddCollectionAssignmentLoading,
    },
  ] = usePatchLingoLiftTeacherCollectionAddAssignmentSchoolClassesMutation();

  const { showSuccessNotification, showErrorNotification } = useSnackbar();

  const handleChange = (data: ILingoLiftCollectionAssignment) => {
    const existingRecord = records.find(record => record.llSchoolClassId === data.llSchoolClassId);
    if (existingRecord) {
      setRecords(
        records.map(record => (record.llSchoolClassId === data.llSchoolClassId ? data : record)),
      );
    } else {
      setRecords([...records, data]);
    }
  };

  useEffect(() => {
    if (collectionAssignment?.items) {
      setRecords(collectionAssignment.items);
    }
  }, [collectionAssignment]);

  const handleSubmit = async () => {
    // Filter checked entities
    const activeRecords = records?.filter((record) => record.isActive);

    const errors: Array<string> = [];
    for (const record of activeRecords) {
      const form = formRefs.current[record.llSchoolClassId];
      if (form) {
        const isValid = await form.trigger();
        if (!isValid) {
          values(form.formState.errors).forEach(error => errors.push(error.message));
        }
      }
    }

    if (isEmpty(errors)) {
      try {
        await patchLingoLiftTeacherCollectionAddAssignmentSchoolClasses({
          params: { enhancedCollectionId: id },
          body: { llEnhancedCollectionAssignmentSchoolClasses: activeRecords },
        }).unwrap();
        showSuccessNotification(SUCCESS_MESSAGE);
        if (collectionAssignment?.enhancedCollectionUrl) {
          window.location.href = collectionAssignment?.enhancedCollectionUrl;
        }
      } catch {
        showErrorNotification(addCollectionAssignmentError?.message);
      }
    } else {
      errors.forEach(error => showErrorNotification(error));
    }
  };

  const isLoading =
    isCollectionAssignmentLoading || isLlClassesLoading || isAddCollectionAssignmentLoading;

  return (
    <MainLayout
      backgroundColor="athensGray"
      isLoading={isLlClassesLoading || isCollectionAssignmentLoading}
      userRole={UserRole.Teacher}
    >
      <LessonGoBackLine title="Back to Lesson" />
      <ContentLayout>
        <Div sx={styles.info}>
          <Div sx={styles.announce}>
            <Typography sx={styles.title} variant="h1">
              Assign Collection
            </Typography>
            <Button
              component={Link}
              href={collectionAssignment?.enhancedCollectionUrl}
              sx={styles.previewButton}
            >
              Preview Collection
            </Button>
          </Div>
          <Div sx={styles.description}>
            <Typography sx={styles.descriptionParagraph} variant="h4" fontWeight={400}>
              You’re assigning the Collection:
            </Typography>
            <Typography sx={styles.descriptionParagraph} variant="h4" color="primary.dark">
              {collectionAssignment?.enhancedCollectionName}
            </Typography>
          </Div>
        </Div>
        {llClassesList?.items.map((llClass: ILingoLiftManageClass) => (
          <LingoLiftAssign
            key={llClass.id}
            llClass={llClass}
            assigments={collectionAssignment?.items}
            collectionId={id}
            handleChange={handleChange}
            fieldName="collectionAssignment"
            registerForm={form => registerForm(llClass.id, form)}
          />
        ))}
        <Div sx={styles.bottomActions}>
          <Button onClick={handleSubmit} disabled={isLoading}>
            Assign
          </Button>
        </Div>
      </ContentLayout>
    </MainLayout>
  );
};

export default LingoLiftCollectionAssignPage;
