import { palette } from '../palette';

export default {
  MuiCard: {
    styleOverrides: {
      root: {
        boxShadow: '0px 2px 8px -1px #1B242C14, 0px 2px 2px -1px #1B242C0A',
      },
    },
  },
};
