const styles = {
  card: {
    backgroundColor: 'white',
    borderRadius: 0,
    width: '360px',
  },
  currentCard: {
    backgroundColor: 'secondary.light',
  },
  cardElementRounded: {
    borderTop: 'none',
  },
  wrapper: {
    padding: '20px',
    borderTop: '1px solid',
    borderColor: 'pantone',
  },
  poster: {
    objectFit: 'cover',
    borderRadius: '5px',
    width: '80px',
    height: '80px',
    backgroundColor: 'geyser',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    width: 'fit-content',
    boxSizing: 'border-box',
    '&.MuiCardContent-root': {
      padding: 0,
    },
  },
  category: {
    fontSize: '10px',
    color: 'primary.dark',
    fontWeight: 600,
  },
  item: {
    alignContent: 'center',
  },
  index:{
    fontSize: '12px',
    color: 'primary.dark',
    fontWeight: 600,
  },
  description: {
    fontSize: '10px',
    color: 'primary.dark',
    fontWeight: 450,
  },
  link: {
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '600',
    color: 'secondary.dark',
  },
  currentLink: {
    color: 'black',
  }
};

export default styles;
