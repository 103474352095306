const styles = {
  announce: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  announceHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  announceDescription: {
    fontSize: '16px',
    fontWeight: 400,
    color: 'primary.dark',
  },
};

export default styles;
