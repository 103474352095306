const styles = {
  container: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  progressContainer: {
    backgroundColor: 'semiGray',
    borderRadius: '12px',
    height: '10px',
    width: '128px',
    overflow: 'hidden'
  },
  filledZone: {
    height: '100%',
  }
}

export default styles;
