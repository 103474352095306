enum HeaderProduct {
  LingoLift = 'lingolift',
  ListenWise = 'listenwise',
}

enum HeaderMenuItems {
  Settings = 'Settings',
  Logout = 'Logout',
}

export { HeaderProduct, HeaderMenuItems };
