const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: {
      sm: 'row',
      xs: 'column',
    },
    gap: {
      sm: '20px',
      xs: '10px',
    },
    justifyContent: 'center',
    paddingTop: '20px',
    alignItems: 'center',
    '& .MuiPagination-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  button: {
    color: 'primary.main',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: 'semiGray',
    '&:hover': {
      color: 'white',
    },
  },
  buttonIcon: {
    '&.MuiSvgIcon-root': {
      fontSize: '24px',
    },
  },
  prevIcon: {
    transform: 'rotate(180deg)',
  },
};

export default styles;
