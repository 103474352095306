import { MainLayout } from "../../widgets";
import { UserRole } from "../../entities/user";
import ContentLayout from "../../shared/ui/ContentLayout";
import {
  Grid,
  Typography
} from "@mui/material";
import styles from "../LingoLiftClassesTeacherAddLingoLiftClass/styles";
import Div from "../../shared/ui/Div";
import React, { FC } from "react";
import LingoLiftClassroomImportForm from '../../features/lingoLiftClasses/ui/ClassroomImportForm';

import {
  ILingoLiftImportGoogleroomClass,
  usePatchLingoLiftLLClassRoomImportMutation,
  usePatchLingoLiftLWClassRoomImportMutation,
} from '../../features/lingoLiftClasses';
import appRoutes from '../../shared/store/appRoutes';
import Loader from '../../shared/ui/Loader';

interface ILingoLiftGoogleClassroomImportAddPageProps {
  courses: Array<ILingoLiftImportGoogleroomClass>;
  isLingoLift?: boolean;
  isListenWise?: boolean;
}

const LingoLiftGoogleClassroomImportAddPage:FC<ILingoLiftGoogleClassroomImportAddPageProps> = (props) => {
  const { courses, isLingoLift, isListenWise } = props;

  const [onImportLWClasses, { isLoading: isLwLoading }] = usePatchLingoLiftLWClassRoomImportMutation();

  const [onImportLLClasses, { isLoading: isLlLoading }] = usePatchLingoLiftLLClassRoomImportMutation();

  const onChange = async (data: Array<ILingoLiftImportGoogleroomClass>) => {

    const parseImports = data.map((item) => ({
      id: item.id,
      elementary: item.elementary,
      studentStartQuiz: item.studentStartQuiz
    })) as Array<ILingoLiftImportGoogleroomClass>

    if (isListenWise && parseImports.length) {
      await onImportLWClasses({ googleClassroomCourses: parseImports }).unwrap()
    }

    if (isLingoLift && parseImports.length) {
      await onImportLLClasses({ googleClassroomCourses: parseImports }).unwrap()
    }

    window.location.href = appRoutes.addClassRedirect;
  }

  return (
    <MainLayout userRole={UserRole.Teacher} isLoading={false}>
      <ContentLayout>
          <Div sx={styles.container}>
              <Grid container spacing={2}>
                <Grid display="grid" gap={1} item xs={12}>
                  <Typography variant="h1">Import from Google Classroom</Typography>
                  <Typography fontWeight={600}>
                    Select classes to import:
                  </Typography>
                  <Typography>
                    Student rosters for selected classes will also be imported automatically
                  </Typography>
                  <Div sx={styles.divider} />
                </Grid>
                <Grid item xs={12}>
                  { (isLlLoading || isLwLoading) ? <Loader /> : <LingoLiftClassroomImportForm
                    courses={courses}
                    isListenwise={isListenWise}
                    onChange={onChange} /> }
                </Grid>
              </Grid>
            </Div>
      </ContentLayout>
    </MainLayout>
  );
};

export default LingoLiftGoogleClassroomImportAddPage;
