import * as React from 'react';
import { Box, type BoxProps } from '@mui/material';

interface IImageProps extends BoxProps {
  src: string;
  alt: string;
}

const Image = (props: IImageProps) => {
  return <Box component="img" {...props} />;
};

export default Image;
