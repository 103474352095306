export const selectLingoLiftTeacherDashboardParamsFromDomObject = (object: HTMLElement) => {
  const teacherName = object.dataset.teacherName as string
  const isListenwise = (object.dataset.isListenwise === 'true' ? true : false) as boolean
  const isLingolift = (object.dataset.isLingolift === 'true' ? true : false) as boolean

  return {
    teacherName,
    isListenwise,
    isLingolift,
  };
};
