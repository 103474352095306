const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
    alignItems: 'center'
  },
  badge: {
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'green',
    borderRadius: '4px',
    color: 'white'
  }
}

export default styles;
