enum CacheTags {
  ManageClassList = 'ManageClassesList',
  SearchList = 'SearchList',
  TeacherDashboardTaskList = 'TeacherDashboardTaskList',
  Favorites = 'Favorites',
  StudentClassList = 'StudentClassList',
  ClassesAssignment = 'ClassesAssignment',
  StudentLLClassList = 'StudentLLClassList',
  StudentLWClassList = 'StudentLWClassList',
  StudentLWEventList = 'StudentLWEventList',
  StudentDashboardAssignmentQuizList = 'StudentDashboardAssignmentQuizList',
  StudentDashboardLWEvents = 'StudentDashboardLWEvents',
  StudentProfileLanguage = 'StudentProfileLanguage',
  StudentLessonDetails = 'StudentLessonDetails',
  TeacherLessonDetails = 'TeacherLessonDetails',
  Feed = 'Feed',
  TeacherDashboardClasses = 'TeacherDashboardClasses',
  HeaderMenu = 'HeaderMenu',
  FooterMenu = 'FooterMenu',
  StudentProgressReports = 'StudentProgressReports',
  TeacherMyLibraryAssignments = 'TeacherMyLibraryAssignments',
  TeacherMyLibraryAssignmentTags = 'TeacherMyLibraryAssignmentTags',
  EnhancedCollections = 'EnhancedCollections',
  TeacherDashboardReports = 'TeacherDashboardReports',
}

enum ProductType {
  Lingolift = 'lingolift',
  Listenwise = 'listenwise',
}

export { CacheTags, ProductType };
