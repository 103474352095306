import * as React from 'react';
import ReduxProvider from '../reduxProvider';
import { createRoot } from 'react-dom/client';
import LingoLiftFavoritesTeacherBrowsePage from '../../pages/LingoLiftFavoritesTeacherBrowse';


const lingoLiftFavoritesRoutesRender = () => {
  const lingoLiftFavoritesTeacherBrowseElement = document.querySelector('#lingolift_favorites');

  const renderElement = lingoLiftFavoritesTeacherBrowseElement;
  if (renderElement) {
    const root = createRoot(renderElement);

    return root.render(
      <ReduxProvider>
        {lingoLiftFavoritesTeacherBrowseElement && <LingoLiftFavoritesTeacherBrowsePage />}
      </ReduxProvider>,
    );
  }
};

export default lingoLiftFavoritesRoutesRender;
