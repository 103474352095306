import { selectResponse } from '../../../shared/utils/selectFromResponseObjects';
import { apiContainer, apiRoutes, IPaginatedResponse } from '../../../shared/store';
import {
  ILingoLiftTeacherDashboardFeed,
  ILingoLiftTeacherDashboardFilter,
  ILingoLiftTeacherDashboardTask,
  ILingoLiftTeacherDashboardTaskHide,
  ILingoLiftTeacherDashboardClasses,
  ILingoLiftTeacherDashboardLLReport,
  ILingoLiftTeacherDashboardLLReportClasses, ILingoLiftDashboardFeedParams,
} from '../model/types';
import { CacheTags } from "../../../shared/store/commonEnums";

export const lingoLiftTeacherDashboardBrowseApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLingoLiftTeacherDashboardFeed: builder.query<
      IPaginatedResponse<ILingoLiftTeacherDashboardFeed>,
      ILingoLiftDashboardFeedParams>({
      query: ({url, params}) => ({
        url: url ?? apiRoutes.lingoLiftTeacherDashboardFeedPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectResponse,
      providesTags: [{ type: CacheTags.Feed, id: 'LIST' }],
    }),
    getLingoLiftTeacherDashboardFilter: builder.query<Array<ILingoLiftTeacherDashboardFilter>, void>({
      query: () => ({
        url: apiRoutes.lingoLiftTeacherDashboardFilterPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherDashboardTasks: builder.query<IPaginatedResponse<ILingoLiftTeacherDashboardTask>, void>({
      query: () => ({
        url: apiRoutes.lingoLiftTeacherDashboardTasksPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.TeacherDashboardTaskList, id: 'LIST' }]
    }),
    putLingoLiftTeacherDashboardTaskHide: builder.mutation<ILingoLiftTeacherDashboardTaskHide, { taskCode: string }>({
      query: (params) => ({
        url: apiRoutes.lingoLiftTeacherDashboardTaskHidePath(),
        method: 'PUT',
        params,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.TeacherDashboardTaskList, id: 'LIST' }]
    }),
    getLingoLiftTeacherDashboardClasses: builder.query<ILingoLiftTeacherDashboardClasses, void>({
      query: () => ({
        url: apiRoutes.lingoLiftTeacherDashboardSchoolClassesPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.TeacherDashboardClasses, id: 'LIST' }]
    }),
    getLingoLiftTeacherDashboardLWReports: builder.query<any, null>({
      query: () => ({
        url: apiRoutes.lingoLiftTeacherDashboardLWReportsPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.TeacherDashboardReports, id: 'LIST' }]
    }),
    getLingoLiftTeacherDashboardLLClasses: builder.query<ILingoLiftTeacherDashboardLLReportClasses, null>({
      query: () => ({
        url: apiRoutes.lingoLiftTeacherDashboardLLReportClassesPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherDashboardLLReports: builder.query<ILingoLiftTeacherDashboardLLReport, { llSchoolClassId: number }>({
      query: (params) => ({
        url: apiRoutes.lingoLiftTeacherDashboardLLReportsPath(),
        method: 'GET',
        params
      }),
      transformResponse: selectResponse,
    }),
  }),
});

export const {
  useGetLingoLiftTeacherDashboardFeedQuery,
  useGetLingoLiftTeacherDashboardFilterQuery,
  useGetLingoLiftTeacherDashboardTasksQuery,
  usePutLingoLiftTeacherDashboardTaskHideMutation,
  useGetLingoLiftTeacherDashboardClassesQuery,
  useGetLingoLiftTeacherDashboardLWReportsQuery,
  useGetLingoLiftTeacherDashboardLLReportsQuery,
  useGetLingoLiftTeacherDashboardLLClassesQuery,
} = lingoLiftTeacherDashboardBrowseApi;
