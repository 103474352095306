import React, { type FC } from 'react';
import { Link, MenuItem, MenuList, Typography } from '@mui/material';

import styles from './styles';
import { IFooterLink } from '../../model/types';
import sx from 'mui-sx';
import Div from '../../../../shared/ui/Div';

interface IFooterListProps {
  category: string;
  links: Array<IFooterLink>;
}

const FooterList: FC<IFooterListProps> = props => {
  const { category, links } = props;

  return (
    <Div sx={styles.list}>
      <Typography variant="h4" sx={styles.category}>
        {category}
      </Typography>
      <MenuList sx={styles.categoryItemsList}>
        {links.map((link, index) => (
          <MenuItem
            key={index}
            sx={sx(
              {
                ...styles.categoryItem,
              },
              {
                sx: styles.categoryItemBold,
                condition: link.strong,
              }
            )}
          >
            <Link sx={styles.link} href={link.href}>
              {link.text}
            </Link>
          </MenuItem>
        ))}
      </MenuList>
    </Div>
  );
};

export default FooterList;
