import * as React from 'react';

const LlLwLogo = (props) => (
  <svg {...props} width="228" height="24" viewBox="0 0 228 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.9391 18.7245C32.9391 18.8382 32.8633 18.9709 32.7496 18.9898C32.257 19.0656 30.8359 19.1603 29.0548 19.1603C27.0273 19.1603 25.0188 18.7624 25.0188 15.636V5.32818C25.0188 5.11975 25.1893 4.94922 25.3978 4.94922H26.3641C26.5725 4.94922 26.7431 5.11975 26.7431 5.32818V15.636C26.7431 17.076 27.0652 17.6066 29.0737 17.6066H32.7307C32.8633 17.6066 32.9391 17.7013 32.9391 17.834V18.7245Z" fill="#09212A"/>
    <path d="M36.0973 6.55888C36.0973 6.76731 35.9267 6.93784 35.7183 6.93784H34.7519C34.5435 6.93784 34.373 6.76731 34.373 6.55888V5.04303C34.373 4.8346 34.5435 4.66406 34.7519 4.66406H35.7183C35.9267 4.66406 36.0973 4.8346 36.0973 5.04303V6.55888ZM36.0594 18.6478C36.0594 18.8562 35.8888 19.0268 35.6804 19.0268H34.7709C34.5625 19.0268 34.3919 18.8562 34.3919 18.6478V8.98424C34.3919 8.77581 34.5625 8.60528 34.7709 8.60528H35.6804C35.8888 8.60528 36.0594 8.77581 36.0594 8.98424V18.6478Z" fill="#09212A"/>
    <path d="M45.08 16.4119C45.08 18.3447 43.6589 19.1594 41.5367 19.1594C40.8356 19.1594 39.4714 19.0836 38.5429 18.8752C38.2966 18.8184 38.145 18.7047 38.145 18.4962V17.833C38.145 17.6625 38.2966 17.5488 38.4861 17.5488C38.524 17.5488 38.5429 17.5488 38.5618 17.5488C39.4714 17.6625 40.8546 17.7573 41.5367 17.7573C42.7683 17.7573 43.4126 17.4351 43.4126 16.4119C43.4126 15.8435 43.0715 15.4835 41.9915 14.8392L40.0777 13.7023C38.9219 13.0013 38.145 12.1865 38.145 10.9928C38.145 9.02215 39.6229 8.47266 41.5746 8.47266C42.6357 8.47266 43.64 8.5674 44.5305 8.71898C44.7768 8.75688 44.8905 8.88952 44.8905 9.09795V9.72324C44.8905 9.95061 44.7579 10.0454 44.5684 10.0454C44.5495 10.0454 44.5305 10.0454 44.5116 10.0454C43.8484 9.98851 42.6357 9.89377 41.5746 9.89377C40.5135 9.89377 39.8124 10.0264 39.8124 10.9928C39.8124 11.5612 40.2482 11.9212 41.1199 12.4328L42.9957 13.5508C44.5684 14.4792 45.08 15.294 45.08 16.4119Z" fill="#09212A"/>
    <path d="M61.399 13.9297C61.399 14.1761 61.2663 14.3466 61.039 14.3466H54.6913V14.574C54.6913 16.753 55.7145 17.7573 57.4578 17.7573C58.5568 17.7573 59.8263 17.6246 60.6979 17.492C60.7358 17.492 60.7547 17.492 60.7926 17.492C60.9442 17.492 61.0958 17.5678 61.0958 17.7573V18.4015C61.0958 18.6478 61.02 18.7805 60.7168 18.8373C59.6368 19.0457 58.7273 19.1594 57.4578 19.1594C55.2787 19.1594 53.0239 18.1173 53.0239 14.4792V13.1529C53.0239 10.4622 54.5208 8.47266 57.2304 8.47266C59.9968 8.47266 61.399 10.4433 61.399 13.1339V13.9297ZM59.7126 13.0771V12.9823C59.7126 10.9549 58.8978 9.93167 57.2304 9.93167C55.6008 9.93167 54.6913 11.0307 54.6913 12.9823V13.0771H59.7126Z" fill="#09212A"/>
    <path d="M51.9358 18.7232C51.9358 18.8937 51.8411 19.0074 51.5569 19.0453C51.1969 19.1021 50.42 19.159 49.7379 19.159C47.9567 19.159 46.9335 18.5716 46.9335 16.5252V6.74793C46.9335 6.55845 47.1041 6.42581 47.3125 6.38791L48.241 6.23633C48.2599 6.23633 48.2789 6.23633 48.2789 6.23633C48.4683 6.23633 48.601 6.40686 48.601 6.59634V8.60485H51.5569C51.7653 8.60485 51.9169 8.73748 51.9169 8.94591V9.59015C51.9169 9.77963 51.7653 9.93122 51.5569 9.93122H48.601V16.5252C48.601 17.6242 48.961 17.8137 50.0031 17.8137H51.5379C51.8032 17.8137 51.9358 17.8894 51.9358 18.0789V18.7232Z" fill="#09212A"/>
    <path d="M71.232 18.6478C71.232 18.8563 71.0994 19.0268 70.891 19.0268H69.9057C69.6972 19.0268 69.5646 18.8563 69.5646 18.6478V11.8833C69.5646 10.4622 69.2046 9.91272 67.9161 9.91272H66.5708C65.2823 9.91272 64.9223 10.4622 64.9223 11.8833V18.6478C64.9223 18.8563 64.7897 19.0268 64.5812 19.0268H63.5959C63.3875 19.0268 63.2549 18.8563 63.2549 18.6478V11.8833C63.2549 8.96531 65.2823 8.47266 66.5708 8.47266H67.9161C69.2046 8.47266 71.232 8.96531 71.232 11.8833V18.6478Z" fill="#09212A"/>
    <path d="M86.0995 14.9152C86.0995 18.269 84.2237 19.1596 82.272 19.1596C81.3246 19.1596 80.5667 18.9891 79.7709 18.7048C78.975 18.9891 78.1982 19.1596 77.2507 19.1596C75.2991 19.1596 73.4232 18.269 73.4232 14.9152V8.98443C73.4232 8.776 73.5938 8.60547 73.8022 8.60547H74.6927C74.9012 8.60547 75.0717 8.776 75.0717 8.98443V14.9152C75.0717 16.9806 75.8107 17.7385 77.4023 17.7385C77.876 17.7385 78.3687 17.6248 78.9182 17.4922V8.98443C78.9182 8.776 79.0887 8.60547 79.2971 8.60547H80.2256C80.434 8.60547 80.6046 8.776 80.6046 8.98443V17.4922C81.1541 17.6248 81.6467 17.7385 82.1204 17.7385C83.6742 17.7385 84.451 16.9806 84.451 14.9152V8.98443C84.451 8.776 84.6216 8.60547 84.83 8.60547H85.7206C85.929 8.60547 86.0995 8.776 86.0995 8.98443V14.9152Z" fill="#09212A"/>
    <path d="M90.2054 6.55888C90.2054 6.76731 90.0348 6.93784 89.8264 6.93784H88.86C88.6516 6.93784 88.4811 6.76731 88.4811 6.55888V5.04303C88.4811 4.8346 88.6516 4.66406 88.86 4.66406H89.8264C90.0348 4.66406 90.2054 4.8346 90.2054 5.04303V6.55888ZM90.1675 18.6478C90.1675 18.8562 89.9969 19.0268 89.7885 19.0268H88.879C88.6706 19.0268 88.5 18.8562 88.5 18.6478V8.98424C88.5 8.77581 88.6706 8.60528 88.879 8.60528H89.7885C89.9969 8.60528 90.1675 8.77581 90.1675 8.98424V18.6478Z" fill="#09212A"/>
    <path d="M99.1881 16.4119C99.1881 18.3447 97.767 19.1594 95.6448 19.1594C94.9438 19.1594 93.5795 19.0836 92.651 18.8752C92.4047 18.8184 92.2531 18.7047 92.2531 18.4962V17.833C92.2531 17.6625 92.4047 17.5488 92.5942 17.5488C92.6321 17.5488 92.651 17.5488 92.67 17.5488C93.5795 17.6625 94.9627 17.7573 95.6448 17.7573C96.8765 17.7573 97.5207 17.4351 97.5207 16.4119C97.5207 15.8435 97.1796 15.4835 96.0996 14.8392L94.1858 13.7023C93.03 13.0013 92.2531 12.1865 92.2531 10.9928C92.2531 9.02215 93.7311 8.47266 95.6827 8.47266C96.7438 8.47266 97.7481 8.5674 98.6386 8.71898C98.885 8.75688 98.9987 8.88952 98.9987 9.09795V9.72324C98.9987 9.95061 98.866 10.0454 98.6765 10.0454C98.6576 10.0454 98.6386 10.0454 98.6197 10.0454C97.9565 9.98851 96.7438 9.89377 95.6827 9.89377C94.6216 9.89377 93.9206 10.0264 93.9206 10.9928C93.9206 11.5612 94.3564 11.9212 95.228 12.4328L97.1038 13.5508C98.6765 14.4792 99.1881 15.294 99.1881 16.4119Z" fill="#09212A"/>
    <path d="M109.114 13.9297C109.114 14.1761 108.981 14.3466 108.754 14.3466H102.406V14.574C102.406 16.753 103.429 17.7573 105.172 17.7573C106.271 17.7573 107.541 17.6246 108.412 17.492C108.45 17.492 108.469 17.492 108.507 17.492C108.659 17.492 108.81 17.5678 108.81 17.7573V18.4015C108.81 18.6478 108.735 18.7805 108.431 18.8373C107.351 19.0457 106.442 19.1594 105.172 19.1594C102.993 19.1594 100.738 18.1173 100.738 14.4792V13.1529C100.738 10.4622 102.235 8.47266 104.945 8.47266C107.711 8.47266 109.114 10.4433 109.114 13.1339V13.9297ZM107.427 13.0771V12.9823C107.427 10.9549 106.612 9.93167 104.945 9.93167C103.315 9.93167 102.406 11.0307 102.406 12.9823V13.0771H107.427Z" fill="#09212A"/>
    <ellipse cx="14.5663" cy="11.6845" rx="3.07908" ry="3.07908" fill="#09212A"/>
    <path  d="M14.5663 6.47385C11.6885 6.47385 9.35556 8.80678 9.35556 11.6846C9.35556 13.2936 10.084 14.7319 11.2317 15.6888C11.5331 15.9401 11.5737 16.3882 11.3224 16.6896C11.0711 16.991 10.623 17.0316 10.3216 16.7803C8.86398 15.5649 7.93445 13.733 7.93445 11.6846C7.93445 8.02192 10.9036 5.05273 14.5663 5.05273C16.4414 5.05273 18.1358 5.83188 19.3412 7.08219C19.6136 7.3647 19.6053 7.81453 19.3228 8.0869C19.0403 8.35927 18.5905 8.35104 18.3181 8.06852C17.3695 7.08453 16.0398 6.47385 14.5663 6.47385Z" fill="#35A8D5"/>
    <path  d="M14.5663 3.6301C11.2499 3.6301 8.40012 5.63493 7.16593 8.50163C7.01075 8.86207 6.59275 9.02847 6.23231 8.87329C5.87187 8.71811 5.70547 8.30011 5.86065 7.93967C7.31128 4.57025 10.6622 2.20898 14.5663 2.20898C17.0713 2.20898 19.3506 3.18213 21.0442 4.76965C21.3305 5.03802 21.345 5.48769 21.0767 5.774C20.8083 6.06031 20.3586 6.07485 20.0723 5.80648C18.6316 4.45604 16.6962 3.6301 14.5663 3.6301Z" fill="#35A8D5"/>
    <path  d="M11.4483 0.478831C11.5764 0.849769 11.3795 1.25431 11.0086 1.38239C6.73736 2.85725 3.67111 6.91331 3.67111 11.6837C3.67111 17.7009 8.54906 22.5789 14.5663 22.5789C16.1516 22.5789 17.6561 22.2407 19.013 21.6333C19.3712 21.473 19.7915 21.6333 19.9519 21.9915C20.1122 22.3497 19.9518 22.77 19.5937 22.9304C18.0576 23.618 16.3556 24 14.5663 24C7.7642 24 2.25 18.4858 2.25 11.6837C2.25 6.28873 5.71831 1.70568 10.5448 0.0391071C10.9157 -0.0889781 11.3202 0.107893 11.4483 0.478831Z" fill="#35A8D5"/>
    <path d="M124.666 17.2106C124.874 17.2106 125.044 17.04 125.044 16.8316V12.8301H129.043C129.251 12.8301 129.421 12.6595 129.421 12.4511V11.5416C129.421 11.3332 129.251 11.1626 129.043 11.1626H125.044V7.16803C125.044 6.9596 124.874 6.78906 124.666 6.78906H123.756C123.548 6.78906 123.377 6.9596 123.377 7.16803V11.1626H119.379C119.171 11.1626 119 11.3332 119 11.5416V12.4511C119 12.6595 119.171 12.8301 119.379 12.8301H123.377V16.8316C123.377 17.04 123.548 17.2106 123.756 17.2106H124.666Z" fill="#8A9498"/>
    <path d="M173.04 18.2019C172.554 18.2767 171.154 18.3704 169.399 18.3704C167.401 18.3704 165.422 17.9763 165.422 14.8817V4.67784C165.422 4.4713 165.589 4.30273 165.795 4.30273H166.747C166.953 4.30273 167.121 4.4713 167.121 4.67784V14.8805C167.121 16.3062 167.439 16.8308 169.417 16.8308H173.021C173.152 16.8308 173.226 16.9246 173.226 17.0564V17.9383C173.226 18.0511 173.152 18.1817 173.04 18.2007V18.2019Z" fill="#09212A"/>
    <path d="M176.326 6.27213H175.373C175.168 6.27213 175 6.10357 175 5.89702V4.39659C175 4.19005 175.168 4.02148 175.373 4.02148H176.326C176.531 4.02148 176.699 4.19005 176.699 4.39659V5.89702C176.699 6.10357 176.531 6.27213 176.326 6.27213ZM176.288 18.2388H175.392C175.187 18.2388 175.019 18.0702 175.019 17.8636V8.29842C175.019 8.09187 175.187 7.92331 175.392 7.92331H176.288C176.494 7.92331 176.661 8.09187 176.661 8.29842V17.8636C176.661 18.0702 176.494 18.2388 176.288 18.2388Z" fill="#09212A"/>
    <path d="M186.103 18.2363H185.131C184.926 18.2363 184.796 18.0677 184.796 17.8612V11.165C184.796 9.75837 184.441 9.21471 183.171 9.21471H181.845C180.576 9.21471 180.22 9.75837 180.22 11.165V17.8612C180.22 18.0677 180.089 18.2363 179.885 18.2363H178.914C178.708 18.2363 178.578 18.0677 178.578 17.8612V11.165C178.578 8.27694 180.576 7.78906 181.845 7.78906H183.171C184.44 7.78906 186.438 8.27694 186.438 11.165V17.8612C186.438 18.0677 186.307 18.2363 186.103 18.2363Z" fill="#09212A"/>
    <path d="M192.494 21.9315C191.075 21.9315 189.862 21.744 189.32 21.5944C189.059 21.5196 188.947 21.4449 188.947 21.2003V20.6567C188.947 20.4691 189.096 20.3563 189.283 20.3563H189.338C189.992 20.4311 191.504 20.5819 192.493 20.5819C194.229 20.5819 194.64 20.151 194.64 18.2932V18.1057C194.192 18.2185 193.109 18.368 192.549 18.368C190.215 18.368 188.386 17.2617 188.386 13.754V12.3841C188.386 9.2705 189.748 7.78906 192.549 7.78906C193.65 7.78906 195.014 8.0146 195.89 8.21996C196.226 8.29474 196.283 8.38852 196.283 8.65086V18.2161C196.283 21.0484 195.031 21.9304 192.492 21.9304L192.494 21.9315ZM194.641 9.38327C194.137 9.28949 193.334 9.17672 192.55 9.17672C190.702 9.17672 190.029 10.0765 190.029 12.3841V13.754C190.029 16.1542 190.888 16.9804 192.55 16.9804C193.072 16.9804 194.062 16.9056 194.641 16.7738V9.38445V9.38327Z" fill="#09212A"/>
    <path d="M202.376 18.3688C199.631 18.3688 198.231 16.5491 198.231 13.7927V12.3671C198.231 9.60957 199.631 7.79102 202.376 7.79102C205.121 7.79102 206.539 9.61076 206.539 12.3671V13.7927C206.539 16.5502 205.12 18.3688 202.376 18.3688ZM204.897 12.3671C204.897 10.4536 204.131 9.23446 202.376 9.23446C200.622 9.23446 199.875 10.4346 199.875 12.3671V13.7927C199.875 15.7062 200.622 16.9253 202.376 16.9253C204.131 16.9253 204.897 15.7252 204.897 13.7927V12.3671Z" fill="#09212A"/>
    <path d="M209.745 18.2373H208.774C208.587 18.2373 208.438 18.0688 208.438 17.8622V4.48893C208.438 4.30137 208.569 4.13281 208.774 4.13281H209.745C209.932 4.13281 210.081 4.28238 210.081 4.48893V17.8622C210.081 18.0498 209.95 18.2373 209.745 18.2373Z" fill="#09212A"/>
    <path d="M213.355 6.27213H212.403C212.197 6.27213 212.029 6.10357 212.029 5.89702V4.39659C212.029 4.19005 212.197 4.02148 212.403 4.02148H213.355C213.561 4.02148 213.729 4.19005 213.729 4.39659V5.89702C213.729 6.10357 213.561 6.27213 213.355 6.27213ZM213.317 18.2388H212.422C212.216 18.2388 212.048 18.0702 212.048 17.8636V8.29842C212.048 8.09187 212.216 7.92331 212.422 7.92331H213.317C213.523 7.92331 213.691 8.09187 213.691 8.29842V17.8636C213.691 18.0702 213.523 18.2388 213.317 18.2388Z" fill="#09212A"/>
    <path d="M220.264 5.33305H218.603C217.576 5.33305 217.37 5.50162 217.37 6.60794V7.92082H220.283C220.489 7.92082 220.638 8.05258 220.638 8.25794V8.89538C220.638 9.08294 220.489 9.23251 220.283 9.23251H217.37V17.86C217.37 18.0665 217.203 18.2351 216.997 18.2351H216.101C215.896 18.2351 215.728 18.0665 215.728 17.86V6.60676C215.728 4.59946 216.848 4 218.603 4C219.275 4 219.929 4.05579 220.283 4.11277C220.563 4.15076 220.657 4.26234 220.657 4.43209V5.06953C220.657 5.23809 220.525 5.33187 220.264 5.33187V5.33305Z" fill="#09212A"/>
    <path d="M227.047 18.2577C226.692 18.3135 225.927 18.3705 225.254 18.3705C223.5 18.3705 222.492 17.7889 222.492 15.7638V6.08576C222.492 5.89821 222.659 5.76644 222.865 5.72965L223.78 5.58008H223.817C224.004 5.58008 224.135 5.74864 224.135 5.93619V7.9245H227.048C227.254 7.9245 227.403 8.05626 227.403 8.26162V8.89906C227.403 9.08662 227.254 9.23618 227.048 9.23618H224.135V15.7638C224.135 16.8511 224.49 17.0386 225.517 17.0386H227.029C227.29 17.0386 227.422 17.1134 227.422 17.301V17.9384C227.422 18.107 227.328 18.2198 227.048 18.2577H227.047Z" fill="#09212A"/>
    <path d="M152.624 22.875C152.5 22.875 152.191 22.8403 152.002 22.5164L151.087 20.9416L150.853 20.541L150.393 20.5873C149.984 20.6292 149.568 20.6495 149.153 20.6495C147.658 20.6495 146.208 20.3834 144.845 19.857C144.191 19.6054 143.56 19.2931 142.971 18.9301C142.388 18.5715 141.839 18.1594 141.341 17.7067C140.845 17.2541 140.394 16.7567 140.003 16.2289C139.608 15.6967 139.27 15.1284 138.996 14.5398C138.429 13.3194 138.141 12.0222 138.141 10.6861C138.141 9.3499 138.429 8.05278 138.996 6.8323C139.27 6.24375 139.608 5.67544 140.003 5.14329C140.394 4.61547 140.845 4.11803 141.341 3.66541C141.839 3.21134 142.386 2.80066 142.971 2.44203C143.561 2.07907 144.191 1.76672 144.845 1.5151C146.208 0.990179 147.658 0.722656 149.153 0.722656C150.649 0.722656 152.099 0.988733 153.462 1.5151C154.115 1.76672 154.747 2.07907 155.336 2.44203C155.919 2.80066 156.467 3.21279 156.964 3.66541C157.461 4.11803 157.911 4.61547 158.303 5.14329C158.697 5.67544 159.036 6.24375 159.309 6.8323C159.876 8.05278 160.164 9.3499 160.164 10.6861C160.164 11.5103 160.053 12.3303 159.833 13.1227C159.619 13.892 159.302 14.6396 158.89 15.3424C158.484 16.0351 157.986 16.6858 157.412 17.2758C156.836 17.8658 156.184 18.3936 155.474 18.8448L155.326 18.9388L155.238 19.0906L153.246 22.5164C153.057 22.8388 152.749 22.875 152.624 22.875Z" fill="white"/>
    <path d="M152.624 22.1509L154.793 18.4215L155.09 18.2335C157.818 16.4996 159.446 13.6784 159.446 10.6865C159.446 5.59195 154.829 1.44607 149.153 1.44607C143.477 1.44607 138.861 5.5905 138.861 10.6865C138.861 15.7824 143.477 19.9268 149.153 19.9268C149.548 19.9268 149.936 19.9066 150.319 19.8675L151.239 19.775L152.617 22.1509C152.617 22.1509 152.62 22.1509 152.623 22.1509M152.623 23.597C152.14 23.597 151.658 23.3584 151.38 22.8812L150.465 21.3064C150.034 21.3498 149.597 21.3729 149.153 21.3729C142.674 21.3729 137.422 16.5893 137.422 10.6865C137.422 4.7836 142.674 0 149.153 0C155.633 0 160.885 4.7836 160.885 10.6865C160.885 14.3175 158.897 17.5249 155.859 19.4554L153.866 22.8812C153.588 23.3584 153.106 23.597 152.624 23.597H152.623Z" fill="#09212A"/>
    <path d="M145.699 6.62305C146.215 6.62305 146.634 7.04385 146.634 7.56299V13.6365C146.634 14.1556 146.215 14.5764 145.699 14.5764C145.182 14.5764 144.763 14.1556 144.763 13.6365V7.56299C144.763 7.04385 145.182 6.62305 145.699 6.62305Z" fill="#F04779"/>
    <path d="M148.324 8.64662C148.841 8.65529 149.253 9.08333 149.244 9.60247L149.21 11.627C149.201 12.1461 148.775 12.5597 148.258 12.551C147.741 12.5423 147.33 12.1143 147.338 11.5952L147.373 9.57066C147.381 9.05152 147.807 8.63794 148.324 8.64662Z" fill="#C3325D"/>
    <path d="M150.881 5.61133C151.398 5.61133 151.816 6.03213 151.816 6.55127V14.6493C151.816 15.1684 151.398 15.5892 150.881 15.5892C150.364 15.5892 149.945 15.1684 149.945 14.6493V6.55127C149.945 6.03213 150.364 5.61133 150.881 5.61133Z" fill="#C3325D"/>
    <path d="M153.616 5.03125C154.133 5.03125 154.551 5.45206 154.551 5.97119V15.3706C154.551 15.8898 154.133 16.3106 153.616 16.3106C153.099 16.3106 152.68 15.8898 152.68 15.3706V5.97119C152.68 5.45206 153.099 5.03125 153.616 5.03125Z" fill="#C3325D"/>
    <path d="M143.108 8.06836C143.624 8.06836 144.043 8.48917 144.043 9.0083V12.1897C144.043 12.7088 143.624 13.1296 143.108 13.1296C142.591 13.1296 142.172 12.7088 142.172 12.1897V9.0083C142.172 8.48917 142.591 8.06836 143.108 8.06836Z" fill="#C3325D"/>
  </svg>
)

export default LlLwLogo;
