const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '8px',
      padding: '24px 24px 14px 24px'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  actionsWrapper: {
    marginTop: '10px',
    padding: 0,
  },
  cancelButton: {
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: 'semiGray',
    color: 'black'
  },
}

export default styles;
