const styles = {
  container: {
    paddingLeft: '20px',
  },
  standardChildItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '10px'
  },
  standardContent: {
    marginTop: '10px'
  },
  publisherUnitItem: {
    width: 'max-content',
    borderRadius: '3px',
    padding: '0px 5px',
    color: 'white'
  },
  publisherUnitItemLessonType: {
    backgroundColor: 'fruitSalad'
  },
  publisherUnitItemElLessonType: {
    backgroundColor: 'vividViolet'
  }
}

export default styles;
