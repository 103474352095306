const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '40px'
  },
  preview: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    textAlign: 'center'
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: {
      sm: '60%',
      xs: '100%'
    }
  },
  navigationCard: {
    display: 'block',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'semiGray',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    zIndex: 1,
    'img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      zIndex: 0,
    }
  },
  joinWrapper: {
    display: 'flex',
    gap: '5px',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '600',
    color: 'secondary.dark',
  },
};

export default styles;
