import React, { FC, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { SwapVertRounded } from '@mui/icons-material';

interface ILingoLiftDashboardSectionHeaderProps {
  index: number;
  onMoveUp: (index: number) => void;
  onMoveDown: (index: number) => void;
  sectionOrder: Array<string>;
}

const LingoLiftDashboardSectionHeader: FC<ILingoLiftDashboardSectionHeaderProps> = (props) => {
  const { index, onMoveUp, onMoveDown, sectionOrder } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMoveUp = () => {
    onMoveUp(index);
    handleClose();
  };

  const handleMoveDown = () => {
    onMoveDown(index);
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <SwapVertRounded />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleMoveUp} disabled={index === 0}>Move up</MenuItem>
        <MenuItem onClick={handleMoveDown} disabled={index === sectionOrder.length - 1}>Move down</MenuItem>
      </Menu>
    </>
  );
};

export default LingoLiftDashboardSectionHeader;
