import React, { FC } from 'react';
import ContentLayout from '../../shared/ui/ContentLayout';
import { MainLayout } from '../../widgets';
import Div from '../../shared/ui/Div';
import { Grid, Link, Divider, Typography } from '@mui/material';
import styles from './styles';
import { UserRole } from '../../entities/user';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  ILingoLiftTeacherClassesSummaryLLAscReport,
  LingoLiftClassesAscSummaryTable,
  useGetLingoLiftTeacherClassesSummaryLLAscReportQuery,
} from '../../entities/lingoliftClasses';
import Image from "../../shared/ui/Image";

interface LingoLiftClassesTeacherSummaryLLAscReportPageProps {
  llSchoolClassId?: string;
  llLessonAscId?: string;
}

const LingoLiftClassesTeacherSummaryLLAscReportPage:FC<LingoLiftClassesTeacherSummaryLLAscReportPageProps> = (props) => {
  const { llLessonAscId, llSchoolClassId } = props;
  const { data: llAscReport, isLoading: isLLAscReportLoading } = useGetLingoLiftTeacherClassesSummaryLLAscReportQuery(
      {
        llLessonAscId: llLessonAscId as string,
        llSchoolClassId: llSchoolClassId as string
      },
      {
        skip: (!llSchoolClassId || !llLessonAscId)
      }
    );

  return (
    <MainLayout userRole={UserRole.Teacher} isLoading={isLLAscReportLoading}>
      <Div sx={styles.header}>
        <Link sx={styles.link} href={llAscReport?.seeAllAssignedUrl}>
          See All Assigned
        </Link>
      </Div>
      <Divider variant="fullWidth" sx={styles.divider} />
      { llAscReport && <ContentLayout>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={8}>
            <Div sx={styles.previewWrapper}>
              <Image sx={styles.previewWrapper.image} src={llAscReport?.photoSrc} alt={"asc report image"} />
              <Div>
                <Typography variant="h1">
                  { llAscReport.title }</Typography>
                <Link sx={styles.link} href={llAscReport.lessonLinkLabel}>
                  { llAscReport.lessonLinkLabel }
                </Link>
              </Div>
            </Div>
          </Grid>
          <Grid display="flex" justifyContent="flex-end" item xs={4}>
            <Link href={llAscReport?.shareLinkUrl} sx={{
              ...styles.transparentButton,
              ...styles.borderRadiusLeft,
            }}>
              { llAscReport.shareLinkLabel }
            </Link>
            <Link download href={llAscReport?.downloadReportUrl} sx={{
              ...styles.transparentButton,
              ...styles.borderRadiusRight,
            }}>
              <FileDownloadOutlinedIcon fontSize="small" /> Download Report
            </Link>
          </Grid>
        </Grid>
        <Div sx={styles.wrapper}>
          <LingoLiftClassesAscSummaryTable llAscReport={llAscReport as ILingoLiftTeacherClassesSummaryLLAscReport} />
        </Div>
      </ContentLayout> }
    </MainLayout>
  );
};

export default LingoLiftClassesTeacherSummaryLLAscReportPage;
