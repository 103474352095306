enum LingoLiftLessonType {
  Lesson = 'Lesson',
  ElLesson = 'ElLesson',
  LLLesson = 'LlLesson'
}

enum LingoLiftBadgeType {
  ShuttleGray = 'shuttleGray',
  AthensGray = 'athensGray',
  Eucalyptus = 'eucalyptus',
  ELA = 'ela',
  ELD = 'eld',
  SocialStudies = 'socialStudies',
  Social = 'social',
  Science = 'science'
}

enum LingoLiftFavoriteableType {
  Lesson = 'Lesson',
  LLLesson = 'LlLesson',
  ELLesson = 'ElLesson',
  Event = 'Event',
  EnhancedCollection = 'EnhancedCollection',
}

export { LingoLiftLessonType, LingoLiftBadgeType, LingoLiftFavoriteableType }
