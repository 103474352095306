import { type BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosError, type AxiosRequestConfig } from 'axios';
import { keysToCamelCase } from '@bigbinary/neeto-cist';

import { apiInstance } from './';

export const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    try {
      const response = await apiInstance({
        method,
        url,
        headers,
        data,
        params,
        withCredentials: true,
      });
      return { data: response };
    } catch (axiosError) {
      const error = axiosError as AxiosError;
      return {
        error: {
          response: {
            status: error.response?.status,
            data: keysToCamelCase(error.response?.data) || error.message,
          },
        },
      };
    }
  };

export default axiosBaseQuery;
