import React, { FC, useEffect, useRef } from 'react';
import Div from '../../../../../shared/ui/Div';
import styles from './styles';
import TreeSelect from "../../../../../shared/ui/TreeSelect";
import CheckboxList from "./CheckBoxList";
import { Grid, FormControlLabel, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { DateTimeField } from '../../../../../widgets';
import { useForm, FormProvider, useWatch, UseFormReturn } from 'react-hook-form';
import { resolver } from '../model/formFields';
import dayjs from 'dayjs';
import { equals, isEmpty } from 'ramda';
import { ILingoLiftLessonAssignment, ILingoLiftManageClass } from '../../../../../entities/lingoliftClasses';
import { ILingoLiftCollectionAssignment } from '../../../../../entities/lingoLiftCollection';

const DEFAULT_FORMAT = 'YYYY-MM-DD HH:mm:ss';

interface ILingoLiftAssignProps {
  llClass: ILingoLiftManageClass;
  assigments: Array<ILingoLiftLessonAssignment | ILingoLiftCollectionAssignment>;
  fieldName: string;
  collectionId?: number;
  lessonId?: number;
  handleChange: (data: ILingoLiftLessonAssignment | ILingoLiftCollectionAssignment) => void;
  registerForm: (form: UseFormReturn<any>) => void;
}

const LingoLiftAssign: FC<ILingoLiftAssignProps> = (props) => {
  const { llClass, assigments, fieldName, collectionId, handleChange, lessonId, registerForm } = props;

  const assign = assigments?.find(item => item.llSchoolClassId === llClass.id);

  const methods = useForm({
    resolver,
    defaultValues: {
      isActive: Boolean(assign?.studentItems.length),
      dueDateTime: assign?.dueDateTime ? dayjs(assign?.dueDateTime, DEFAULT_FORMAT).format(DEFAULT_FORMAT) : null,
      releaseDateTime: assign?.releaseDateTime
        ? dayjs(assign?.releaseDateTime, DEFAULT_FORMAT).format(DEFAULT_FORMAT)
        : null,
      selectedStudents: assign?.selectedStudents ?? false,
      selectedStudentIds: [0],
      syncToGoogleClassroom: assign?.syncToGoogleClassroom ?? false,
      syncWithGradesToGoogleClassroom: assign?.syncWithGradesToGoogleClassroom ?? false,
      [fieldName === 'lessonAssignment' ? 'llLessonId' : 'enhancedCollectionId']:
        fieldName === 'lessonAssignment' ? Number(lessonId) : Number(collectionId),
      llSchoolClassId: llClass.id,
      googleClassroomData: assign?.googleClassroomData
        ? dayjs(assign?.googleClassroomData, DEFAULT_FORMAT).format(DEFAULT_FORMAT)
        : null,
    },
  });

  useEffect(() => {
    registerForm(methods);
  }, [registerForm, methods]);

  const defaultValues = useRef(methods.getValues());

  const handleFormChange = values => {
    handleChange(values);
  };

  const watchedValues = useWatch({
    control: methods.control,
  });

  const hasChanged = (newValues, oldValues) => !equals(newValues, oldValues);

  useEffect(() => {
    if (hasChanged(watchedValues, defaultValues.current)) {
      handleFormChange(watchedValues);
      defaultValues.current = methods.getValues();
    }
  }, [watchedValues]);

  const handleDueDateTimeChange = (date: string) => {
    methods.setValue('dueDateTime', date);
  };

  const handleReleaseDateTimeChange = (date: string) => {
    methods.setValue('releaseDateTime', date);
  };

  const defaultTreeSelectValue =
    assign?.selectedStudents && isEmpty(assign?.studentItems)
      ? [{ value: 'all', label: 'Entire class' }]
      : assign?.studentItems.map(student => ({
          value: student.id,
          label: `${student.firstName} ${student.lastName}`,
        })) ?? [];

  const getErrors = (isError, message) => {
    if (isError) {
      return <Typography color="error">{message}</Typography>;
    }
  };

  return (
    <Div sx={styles.container}>
      <FormProvider {...methods}>
        <form>
          <CheckboxList
            {...methods.register('isActive')}
            onChange={(value) => methods.setValue('isActive', value)}
            label={llClass.name} defaultChecked={!!assign}>
            <TreeSelect
              {...methods.register('selectedStudents', { required: true })}
              label="Assign to"
              students={llClass?.studentItems ?? []}
              setValue={methods.setValue}
              defaultValue={defaultTreeSelectValue}
              isError={!!methods.formState.errors.selectedStudents}
            />
            {getErrors(!!methods.formState.errors.selectedStudents, methods.formState?.errors?.selectedStudents?.message)}
            <DateTimeField
              name="dueDateTime"
              {...methods.register('dueDateTime', { required: true })}
              datePlaceholder="Due Date"
              timePlaceholder="Due time"
              onChange={handleDueDateTimeChange}
              initialValue={methods.getValues('dueDateTime')}
              isError={!!methods.formState.errors.dueDateTime}
            />
            {getErrors(!!methods.formState.errors.dueDateTime, methods?.formState?.errors?.dueDateTime?.message)}
            <DateTimeField
              name="releaseDateTime"
              {...methods.register('releaseDateTime')}
              datePlaceholder="Available Date"
              timePlaceholder="Available time"
              onChange={handleReleaseDateTimeChange}
              initialValue={methods.getValues('releaseDateTime')}
            />
            <Grid container>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('syncToGoogleClassroom')}
                      defaultChecked={assign?.syncToGoogleClassroom ?? false}
                    />
                  }
                  label="Share to Google Classroom"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...methods.register('syncWithGradesToGoogleClassroom')}
                      defaultChecked={assign?.syncWithGradesToGoogleClassroom ?? false}
                    />
                  }
                  label="Send scores to Google Classroom"
                />
              </Grid>
            </Grid>
          </CheckboxList>
        </form>
      </FormProvider>
    </Div>
  );
};

export default LingoLiftAssign;
