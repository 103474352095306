const styles = {
  container: {
    display: 'flex',
    gap: '5px',
    flexWrap: 'wrap',
    padding: '10px 0px',
  },
  defaultChipState: {
    fontSize: '14px',
    backgroundColor: 'primary.light',
    color: 'black',
  },
  activeChipState: {
    fontWeight: 600,
    backgroundColor: 'secondary.dark',
    color: 'white',
  },
};

export default styles;
