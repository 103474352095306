import React, { FC, useState, useEffect, useRef } from 'react';
import styles from './styles';
import { Button, Checkbox, Link, TextField, Typography, IconButton, InputAdornment, Select, MenuItem } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import Div from '../../../shared/ui/Div';
import { SignUpFormFieldType, SignUpFormType, TeacherSignUpPageStep } from '../model/enums';
import sx from 'mui-sx';
import { formFields } from '../model/formFields';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getFormDefaultValues, getNestedFormErrorMessage } from '../../../shared/utils/helpers';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  isCheckboxField,
  isPasswordField,
  isSelectField,
  isSelectStateField,
  isStudentPage,
  isTeacherCountryField,
  isOmniAuthPage,
  isTeacherPassworfField,
  isTeacherPremiumPageCompleteAccountStep,
  isTeacherPremiumPageSignUpStep,
  isTextField,
} from '../lib/compareConditions';
import { IDefaultSignUpInput } from '../model/types';
import { passwordFieldPattern } from '../../../shared/utils/regpExpRules';
import { ProductType } from '../../../shared/store/commonEnums';

const REQUIRED_TEXT = 'Field is required';
const MIN_LENGTH_TEXT = 'Password must be at least 8 characters';
const PATTERN_TEXT = 'Password must contain at least 1 symbol, 1 uppercase letter and 1 lowercase letter';
const INCORRECT_CAPTCHA = 'Incorrect CAPTCHA';

interface ISignUpFormProps {
  formType: SignUpFormType;
  url?: string;
  isLingoLiftStatus: boolean;
  defaultInputs: Array<IDefaultSignUpInput>;
  defaultErrors: Array<string>;
  recaptchaSitekey: string;
  onChangeStatus: (value: boolean) => void;
  onChangeStep: (value: TeacherSignUpPageStep) => void;
  currentStep: TeacherSignUpPageStep;
}

const SignUpForm: FC<ISignUpFormProps> = props => {
  const {
    formType,
    url,
    isLingoLiftStatus,
    defaultInputs,
    defaultErrors,
    recaptchaSitekey,
    onChangeStatus,
    onChangeStep,
    currentStep,
  } = props;

  const [isPasswordShows, setPasswordShows] = useState<boolean>(false);
  const [isPasswordConfirmationShows, setPasswordConfirmationShows] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<Array<string>>(defaultErrors);

  const recaptcha = useRef();

  const { register, control, getValues, setValue,  trigger, formState: { errors } } = useForm({
    defaultValues: getFormDefaultValues(defaultInputs),
    mode: 'onChange',
  });

  const product = isLingoLiftStatus ? ProductType.Lingolift : ProductType.Listenwise;
  const productFieldName =
    formType === SignUpFormType.Student || formType === SignUpFormType.StudentOmniAuth
      ? 'student[product]'
      : 'teacher[product]';

  useEffect(() => {
    setValue(productFieldName, product);
  }, [isLingoLiftStatus, setValue]);

  useEffect(() => {
    onChangeStatus(getFormDefaultValues(defaultInputs)[productFieldName] === ProductType.Lingolift);
  }, [defaultInputs]);

  const handleNextStep = async () => {
    const isValid = await trigger();
    if (isValid) {
      onChangeStep(TeacherSignUpPageStep.completeAccountSetup);
    }
  };

  const handleClickShowPassword = () => {
    setPasswordShows(!isPasswordShows);
  };

  const handleClickShowPasswordConfirmation = () => {
    setPasswordConfirmationShows(!isPasswordConfirmationShows);
  };

  useWatch({ control });

  const isFieldShows = (name: string) =>
    isTeacherPassworfField(name) ? isPasswordShows : isPasswordConfirmationShows;

  const isHidden = (type: string) => type === SignUpFormFieldType.Hidden;

  const checkboxValue = (value) => value ? '1' : '0';

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setValue(name, checkboxValue(checked));
  };

  const onSubmit = (event: React.FormEvent) => {
    if (!isStudentPage(formType) && recaptchaSitekey) {
      const captchaValue = recaptcha.current?.getValue();
      if (!captchaValue) {
        event.preventDefault();
        setFormErrors([INCORRECT_CAPTCHA]);
      }
    }
  };

  return (
    <form action={url} method="post" onSubmit={onSubmit}>
      <Div sx={styles.formContainer}>
        <Typography
          sx={{
            ...styles.formItemLabelRequiredSymbol,
            ...styles.formItemLabel,
          }}
        >
          indicates a required field.
        </Typography>

        {formFields[formType]?.map((field, index) => (
          <Div
            key={index}
            sx={sx({
              sx: styles.hiddenItem,
              condition:
                (currentStep === TeacherSignUpPageStep.signUp && field.completeAccountSetup) ||
                (currentStep === TeacherSignUpPageStep.completeAccountSetup && !field.completeAccountSetup),
            })}
          >
            <Div
              sx={sx(styles.formItem, {
                sx: styles.hiddenItem,
                condition: isHidden(field.type),
              })}
            >
              {!isSelectStateField(field) && (
                <Div
                  sx={sx(styles.formItemLabel, {
                    sx: styles.formItemLabelRequiredSymbol,
                    condition: field.required,
                  })}
                >
                  <Typography variant="h5">{field.label}</Typography>
                  {isStudentPage(formType) && field.labelLink && (
                    <Link sx={styles.formItemLabelLink} target="_blank" href={field.labelLink.href}>
                      {field.labelLink.name}
                    </Link>
                  )}
                </Div>
              )}
              {(!Boolean(field.type) || isTextField(field.type)) && (
                <>
                  <TextField
                    type={Boolean(field.type) && !isHidden(field.type) ? field.type : 'text'}
                    {...register(field.name)}
                    error={!!errors[field.name]}
                    helperText={errors[field.name] ? errors[field.name]?.message : ''}
                    placeholder={field.placeholder}
                    required={field.required}
                    fullWidth
                  />
                  {field.underInputText && <Typography>{field.underInputText}</Typography>}
                </>
              )}
              {isPasswordField(field.type) && (
                <TextField
                  type={isFieldShows(field.name) ? 'text' : field.type}
                  {...register(field.name, {
                    required: REQUIRED_TEXT,
                    minLength: {
                      value: 8,
                      message: MIN_LENGTH_TEXT,
                    },
                    pattern: {
                      value: passwordFieldPattern,
                      message: PATTERN_TEXT,
                    },
                  })}
                  error={!!getNestedFormErrorMessage(errors, field.name)}
                  helperText={getNestedFormErrorMessage(errors, field.name) || ''}
                  placeholder={field.placeholder}
                  required={field.required}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password"
                          onClick={
                            isTeacherPassworfField(field.name)
                              ? handleClickShowPassword
                              : handleClickShowPasswordConfirmation
                          }
                        >
                          {isFieldShows(field.name) ? (
                            <VisibilityOffIcon sx={styles.icon} />
                          ) : (
                            <VisibilityIcon sx={styles.icon} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {isSelectField(field) && (
                <Select
                  {...register(field.name)}
                  error={!!errors[field.name]}
                  placeholder={field.placeholder}
                  required={field.required}
                  fullWidth
                  value={getValues(field.name) || ''}
                >
                  {defaultInputs
                    .find((item) => item.name === field.name)
                    ?.collection.map((option) => {
                      const optionToShow = isTeacherCountryField(field.name) ? option[0] : option;
                      return (
                        <MenuItem key={optionToShow} value={optionToShow}>
                          {optionToShow}
                        </MenuItem>
                      );
                    })}
                </Select>
              )}
              {isSelectStateField(field) && getValues('teacher[country]') === 'United States' && (
                <>
                  <Div
                    sx={sx(styles.formItemLabel, {
                      sx: styles.formItemLabelRequiredSymbol,
                      condition: field.required,
                    })}
                  >
                    <Typography variant="h5">{field.label}</Typography>
                  </Div>
                  <Select
                    {...register(field.name)}
                    error={!!errors[field.name]}
                    helperText={errors[field.name] ? errors[field.name]?.message : ''}
                    placeholder={field.placeholder}
                    required={field.required}
                    fullWidth
                    value={getValues(field.name) || ''}
                  >
                    {Object.entries(defaultInputs.find((item) => item.name === field.name)?.collection).map(
                      ([stateName, stateCode]) => (
                        <MenuItem key={stateCode} value={stateCode}>
                          {stateName}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </>
              )}
            </Div>
            {isCheckboxField(field.type) && (
              <Div sx={styles.formItemCheckbox}>
                <input type="hidden" name={field.name} value="0" />
                <Checkbox
                  {...register(field.name)}
                  value={getValues(field.name)}
                  checked={['1', true].includes(getValues(field.name))}
                  onChange={handleCheckboxChange}
                  required={field.required}
                />
                <Div sx={styles.formItemCheckboxLabel}>
                  {field.labelLink && (
                    <Link sx={styles.formItemLabelLink} href={field.labelLink.link}>
                      {field.labelLink.name}
                    </Link>
                  )}
                </Div>
              </Div>
            )}
          </Div>
        ))}
        {isTeacherPremiumPageSignUpStep(formType, currentStep) && (
          <Button sx={styles.submitButton} type="submit" variant="contained" onClick={handleNextStep}>
            Sign up
          </Button>
        )}
        {isTeacherPremiumPageCompleteAccountStep(currentStep) && (
          <>
            <ReCAPTCHA ref={recaptcha} sitekey={recaptchaSitekey} />
            <Button sx={styles.submitButton} type="submit" variant="contained">
              Complete Sign up
            </Button>
          </>
        )}
        {(isStudentPage(formType) || isOmniAuthPage(formType)) && (
          <Button sx={styles.submitButton} type="submit" variant="contained">
            Sign up
          </Button>
        )}
        {formErrors && formErrors.map((error, index) =>
          <Typography key={index} sx={styles.error}>{error}</Typography>
        )}
      </Div>
    </form>
  );
};

export default SignUpForm;
