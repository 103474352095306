import React, { type FC } from 'react';
import Dropdown from 'react-multilevel-dropdown';
import { Link } from '@mui/material';
import './styles.css';
import { KeyboardArrowDownSharp, KeyboardArrowRightSharp } from '@mui/icons-material';
import Div from '../../../../shared/ui/Div';

interface IDesktopNavigationProps {
  menuList?: Array<{
    name: string;
    link?: string;
    children?: Array<{
      name: string;
      link: string;
      children?: Array<{
        name: string;
        link: string;
      }>;
    }>;
  }>;
}

const DesktopNavigation: FC<IDesktopNavigationProps> = props => {
  const { menuList } = props;

  return (
    <Div className="navigation">
      {menuList?.map((menu, index) =>
        menu?.link ? (
          <Link key={index} className="option-link" href={menu.link}>
            {menu.name}
          </Link>
        ) : (
          <Dropdown
            key={index}
            title={
              <>
                {menu.name} <KeyboardArrowDownSharp />
              </>
            }
            buttonClassName="button"
            menuClassName="dropdown-list"
          >
            {menu.children?.map((item, index) => (
              <Dropdown.Item key={index} className="item">
                {item?.children && (
                  <Link className="item-link">
                    {item?.name} <KeyboardArrowRightSharp />{' '}
                  </Link>
                )}
                {!item?.children && (
                  <Link href={item.link} className="item-link">
                    {item?.name}
                  </Link>
                )}
                { item.children && <Dropdown.Submenu key={index} className="dropdown-list" position="right">
                  {item.children?.map((submenu, subIndex) => (
                    <Dropdown.Item key={subIndex} className="item">
                      <Link className="item-link" href={submenu.link}>
                        {submenu.name}
                      </Link>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Submenu> }

              </Dropdown.Item>
            ))}
          </Dropdown>
        )
      )}
    </Div>
  );
};

export default DesktopNavigation;
