import * as React from 'react';

const AttentionIcon = (props) => (
  <svg {...props} width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M7.9998 2.00078C4.6861 2.00078 1.9998 4.68707 1.9998 8.00078C1.9998 11.3145 4.6861 14.0008 7.9998 14.0008C11.3135 14.0008 13.9998 11.3145 13.9998 8.00078C13.9998 4.68707 11.3135 2.00078 7.9998 2.00078ZM0.799805 8.00078C0.799805 4.02433 4.02335 0.800781 7.9998 0.800781C11.9763 0.800781 15.1998 4.02433 15.1998 8.00078C15.1998 11.9772 11.9763 15.2008 7.9998 15.2008C4.02335 15.2008 0.799805 11.9772 0.799805 8.00078ZM7.7998 4.00078C8.13117 4.00078 8.3998 4.26941 8.3998 4.60078V7.60078H9.7998C10.1312 7.60078 10.3998 7.86941 10.3998 8.20078C10.3998 8.53215 10.1312 8.80078 9.7998 8.80078H7.7998C7.46843 8.80078 7.1998 8.53215 7.1998 8.20078V4.60078C7.1998 4.26941 7.46843 4.00078 7.7998 4.00078Z"
          fill="currentFill"/>
  </svg>
)

export default AttentionIcon
