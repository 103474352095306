import styles from '../StandardAccordionCard/styles';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useLazyGetLingoLiftStandardsQuery } from '../../api';
import Div from '../../../../shared/ui/Div';
import StandardItem from './StandardItem';
import { ILingoLiftStandardParams } from "../../model/types";
import { isEmpty } from "ramda";
import { ArrowForwardIosSharp, ExpandMore, KeyboardArrowDownRounded } from '@mui/icons-material';
import sx from "mui-sx";

interface ILingoLiftStandardAccordionCardProps {
  isFirst: boolean;
  isLast: boolean;
  grade: number;
  subject: string;
  schoolState: string;
}


const LingoLiftStandardAccordionCard: FC<ILingoLiftStandardAccordionCardProps> = (props) => {
  const { grade, subject, schoolState, isLast, isFirst } = props;
  const [getLingoLiftStandards, { data, isLoading }] = useLazyGetLingoLiftStandardsQuery();

  const handleClickShowHideStandards = (params: ILingoLiftStandardParams) => {
    if (!data) {
      getLingoLiftStandards(params)
    }
  };

  return <Accordion
    slotProps={{
      transition: {
        unmountOnExit: false,
        timeout: 500
      }
    }}
    sx={sx(
      styles.accordion,
      {
        sx: styles.firstAccordion,
        condition: isFirst,
      },
      {
        sx: styles.lastAccordion,
        condition: isLast,
      }
    )}>
    <AccordionSummary
      aria-controls="panel2-content"
      id="panel2-header"
      expandIcon={<ExpandMore />}
      sx={styles.summary}
      onClick={() => handleClickShowHideStandards({
        subject: subject,
        state: schoolState,
        grade: grade,
      })}
    >
      <Typography sx={styles.gradeTitle}>
        Grade:{ grade }
      </Typography>
    </AccordionSummary>
    <AccordionDetails>

        { isLoading ? <Typography>Loading standards please wait</Typography> : <Div sx={styles.gradeContainer}>
          <Typography variant="h2" color="orange">
            { data && !isEmpty(data) && data[0]?.stateStandardsName }
          </Typography>

          { data && !isEmpty(data) && data[0]?.stateStandards?.map((item) => (
            <Div key={item.id}>
              <Typography sx={styles.standardParentTitle} variant="h3">
                {item.content}
              </Typography>
              <StandardItem standards={item.standards} />
            </Div>
          )) }
        </Div> }

    </AccordionDetails>
  </Accordion>
}


export default LingoLiftStandardAccordionCard;
