const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderTop: '1px solid #EAEDF0',
    boxShadow: '0px 2px 8px -1px #1B242C14, 0px 2px 2px -1px #1B242C0A',
    height: '80px',
    width: '100%',
    padding: '0 20px',
    alignItems: 'center',
  },
  name: {
    marginBottom: '4px',
  },
  description: {
    textAlign: 'center',
  },
  button: {
    border: '1px solid #9EA8B3',
    borderRadius: '8px',
    height: '48px',
    width: '48px',
  },
  buttonIcon: {
    color: 'white',
    '&.MuiSvgIcon-root': {
      fontSize: '16px',
    },
  },
  submitButton: {
    backgroundColor: 'green',
    '&:hover, &:focus': {
      backgroundColor: 'green',
    },
  },
};

export default styles;
