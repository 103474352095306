const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    borderRadius: '4px',
    padding: '4px 8px',
  },
  defaultBadge: {
    color: 'black',
    backgroundColor: 'primary.light',
  },
  secondary: {
    color: 'black',
  },
  secondaryBadge: {
    marginRight: '5px',
  },
};

const budgeProperties = {
  shuttleGray: {
    backgroundColor: 'primary.dark',
    color: 'white'
  },
  athensGray: {
    backgroundColor: 'primary.light',
  },
  eucalyptus: {
    color: 'white',
    backgroundColor: 'green',
  },
  ela: {
    backgroundColor: 'ela',
    color: 'black'
  },
  socialStudies: {
    backgroundColor: 'socialStudies',
    color: 'white'
  },
  eld: {
    backgroundColor: 'eld',
    color: 'white'
  },
  science: {
    backgroundColor: 'science',
    color: 'white'
  }
};

export { styles, budgeProperties };
