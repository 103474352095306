const styles = {
  accordion: {
    borderLeft: '8px solid',
    borderColor: 'secondary.dark',
    marginTop: '10px',
  },
  gradeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '0px 20px'
  },
  standardChildItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  gradeTitle: {
    fontSize: '18px',
    color: 'secondary.dark',
    fontWeight: 600
  },
  standardContent: {
    marginBottom: '10px',
    marginLeft: '20px',
  }
}

export default styles;
