const styles = {
  manageContainer: {
    actions: {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
    },
    transparentButton: {
      backgroundColor: 'white',
      color: 'primary.main',
      padding: '5px 10px',
      border: '1px solid',
      borderColor: 'semiGray',
      borderRadius: '8px',
      '&:hover': {
        backgroundColor: 'secondary.main',
        color: 'white',
      },
    },
    actionLink: {
      color: 'secondary.dark',
    },

    info: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '10px',
      borderRadius: '12px',
      backgroundColor: 'zircon',
      border: '1px solid #CDDDFF',
      padding: '20px',
    },

    infoContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },

    attentionIcon: {
      width: '18px',
      height: '18px',
      color: 'mariner',
    },
  },
  wrapperLogo: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  divider: {
    height: '10px',
    backgroundColor: 'celadonBlue',
    border: 'none',
  },
  header: {
    backgroundColor: 'athensGray',
    padding: '15px 44px',
  },
};

export default styles;
