import React, { type FC } from 'react';
import { Link, Typography } from '@mui/material';

import styles from './styles';
import Div from '../../../../../shared/ui/Div';
import {
  LingoLiftSummaryAssignedItemStatus,
  LingoLiftSummaryType
} from '../../../model/enums';
import Image from '../../../../../shared/ui/Image';
import watchIcon from '../../../../../assets/secondaryIcons/watchIcon.svg';
import learnIcon from '../../../../../assets/secondaryIcons/learnIcon.svg';
import speakIcon from '../../../../../assets/secondaryIcons/speakIcon.svg';
import sx from 'mui-sx';

interface IProgressCard {
  type: string;
  statistic: LingoLiftSummaryAssignedItemStatus | string | null;
  onToggleExpand: (value: boolean) => void;
  disabledExpand: boolean;
  isExpanded: boolean;
  setType: (value: string) => void;
  onCloseCard: () => void;
}

const LingoLiftDashboardSummaryProgressCard: FC<IProgressCard> = props => {
  const { type, statistic, disabledExpand, isExpanded, onToggleExpand, setType, onCloseCard } = props;

  const toggleExpand = () => {
    onToggleExpand(!isExpanded);
    setType(type);
    onCloseCard();
  };

  return (
    <Div
      sx={sx(
        {
          ...styles.root,
        },
        {
          sx: styles.expandedRoot,
          condition: isExpanded,
        },
      )}
    >
      <Div sx={styles.rootContentWrapper}>
        <Typography
          variant="body2"
          sx={sx(
            {
              ...styles.title,
            },
            {
              sx: styles.text,
              condition: isExpanded,
            },
          )}
        >
          {type}
        </Typography>
        <Typography
          variant="h4"
          sx={sx(
            {
              ...styles.statistics,
            },
            {
              sx: styles.text,
              condition: isExpanded,
            },
          )}
        >
          {statistic}
        </Typography>
        { (statistic && statistic !== LingoLiftSummaryAssignedItemStatus.NotStarted && !disabledExpand && statistic != '⨯') && <Link
          onClick={ toggleExpand }
          sx={ sx(
            {
              ...styles.button,
            },
            {
              sx: styles.text,
              condition: isExpanded,
            },
          ) }
        >
          { isExpanded ? 'Hide Details' : 'View Details' }
        </Link> }
      </Div>
      <Div>
        {type === LingoLiftSummaryType.watch && (
          <Image sx={styles.icon} src={watchIcon} alt="Listenwise watch card icon" />
        )}
        {type === LingoLiftSummaryType.learn && (
          <Image sx={styles.icon} src={learnIcon} alt="Listenwise learn card icon" />
        )}
        {type === LingoLiftSummaryType.speak && (
          <Image sx={styles.icon} src={speakIcon} alt="Listenwise speak card icon" />
        )}
      </Div>
    </Div>
  );
};

export default LingoLiftDashboardSummaryProgressCard;
