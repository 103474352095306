export enum ForgotPasswordFormType {
  studentForgotPassword = 'studentForgotPassword',
  teacherForgotPassword = 'teacherForgotPassword',
  teacherEditPassword = 'teacherEditPassword',
  studentEditPassword = 'studentEditPassword',
}

export enum ForgotPasswordFormFieldType {
  Email = 'email',
  Hidden = 'hidden',
}
