const styles = {
  layout: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    boxSizing: 'border-box',
  },
  content: {
    flex: 1,
  },
};

export default styles;
