const styles = {
  poster: {
    borderRadius: '8px',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  title: {
    color: 'secondary.dark',
    fontSize: '14px',
    fontWeight: 600
  },
  description: {
    fontSize: '10px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
  },
  tagWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: '5px',
    marginTop: 'auto',
  },
  tagIcon: {
    width: '15px',
    height: 'auto',
    objectFit: 'contain',
  },
  favoriteIcon: {
    fontSize: '20px',
    color: 'primary.main'
  }
}

export default styles;
