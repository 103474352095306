import React, { FC, useState } from 'react';

import { MainLayout, SignInFormType, IDefaultSignInInput } from '../../../widgets';
import ContentLayout from '../../../shared/ui/ContentLayout';
import Div from '../../../shared/ui/Div';
import styles from './styles';
import { Link, Typography } from '@mui/material';
import Image from '../../../shared/ui/Image';
import googleIcon from '../../../assets/socialIcons/googleIcon.svg';
import cleverIcon from '../../../assets/socialIcons/cleverIcon.svg';
import SignInForm from '../../../widgets/SignInForm/ui';
import { UserRole } from '../../../entities/user';
import { appRoutes } from '../../../shared/store';

interface ISignInProps {
  formType: SignInFormType;
  url?: string;
  defaultInputs: IDefaultSignInInput;
  defaultErrors: Array<string>;
  googleUrl: string;
  googleQueryWho: string;
  googleQueryCommon: boolean;
  googleAlt: string;
  cleverUrl: string;
  cleverQueryType: string;
  cleverQueryCommon: boolean;
  cleverAlt: string;
  forgotPasswordUrl: string;
  notice: string;
}

const SignInPage: FC<ISignInProps> = (props) => {
  const {
    formType,
    url,
    defaultInputs,
    defaultErrors,
    googleUrl,
    googleQueryWho,
    googleQueryCommon,
    googleAlt,
    cleverUrl,
    cleverQueryType,
    cleverQueryCommon,
    cleverAlt,
    forgotPasswordUrl,
    notice,
  } = props;

  const [isLingoLiftStatus, setIsLingoLiftStatus] = useState(false);

  //@ts-ignore
  const googleQueryParams = new URLSearchParams({
    who: googleQueryWho ?? '',
    common: googleQueryCommon ?? '',
    product: isLingoLiftStatus ? 'lingolift' : 'listenwise'
  }).toString()

  const cleverQueryParams = new URLSearchParams({
    type: cleverQueryType ?? '',
    common: cleverQueryCommon ?? '',
    product: isLingoLiftStatus ? 'lingolift' : 'listenwise'
  }).toString()

  return (
    <MainLayout
      userRole={UserRole.Public}
      onChangeStatus={setIsLingoLiftStatus}
      isLingoLiftStatus={isLingoLiftStatus}
    >
      <ContentLayout>
        <Div sx={styles.container}>
          {notice && (
            <Div sx={styles.wrapper}>
              <Typography>{notice}</Typography>
            </Div>
          )}
          <Div sx={styles.content}>
            {formType === SignInFormType.Student && (
              <Typography variant="h1">Student Log In</Typography>
            )}

            {formType === SignInFormType.Teacher && (
              <Typography variant="h1">Teacher Sign In</Typography>
            )}

            {formType === SignInFormType.Student && (
              <Typography>
                Need to set up a student account?{' '}
                <Link sx={styles.link} href={appRoutes.studentSignUp}>
                  Sign up
                </Link>
              </Typography>
            )}

            {formType === SignInFormType.Teacher && (
              <Link sx={styles.link} href={appRoutes.studentSignIn}>
                Switch to Student Log In
              </Link>
            )}
          </Div>
          <Link href={`${googleUrl}?${googleQueryParams}`} sx={styles.socialLink}>
            <Image marginRight="auto" src={googleIcon} alt="{googleAlt}" />
            <Typography fontWeight={600} marginRight="auto">
              {googleAlt}
            </Typography>
          </Link>
          <Link href={`${cleverUrl}?${cleverQueryParams}`} sx={styles.socialLink}>
            <Image marginRight="auto" src={cleverIcon} alt="{cleverAlt}" />
            <Typography fontWeight={600} marginRight="auto">
              {cleverAlt}
            </Typography>
          </Link>

          <SignInForm
            formType={formType}
            url={url}
            isLingoLiftStatus={isLingoLiftStatus}
            defaultInputs={defaultInputs}
            defaultErrors={defaultErrors}
            onChangeStatus={setIsLingoLiftStatus}
          />

          {formType === SignInFormType.Student && (
            <>
              <Link sx={styles.link} href={appRoutes.teacherSignIn}>
                <Typography sx={styles.socialLinkText}>Switch to Teacher Log In</Typography>
              </Link>
              <Link sx={styles.link} href={forgotPasswordUrl}>
                <Typography sx={styles.socialLinkText}>Forgot your password?</Typography>
              </Link>
            </>
          )}
          {formType === SignInFormType.Teacher && (
            <>
              <Typography>
                Have your Premium Code?{' '}
                <Link sx={styles.link} href={appRoutes.teacherSignUp}>
                  Create an account
                </Link>
              </Typography>
              <Link sx={styles.link} href={forgotPasswordUrl}>
                <Typography sx={styles.socialLinkText}>Forgot your password?</Typography>
              </Link>
            </>
          )}
        </Div>
      </ContentLayout>
    </MainLayout>
  );
};

export default SignInPage;
