import lingoLiftLessonRoutesRender from './app/pageRoutes/lingoLiftLessons';
import lingoLiftCollectionsRoutesRender from './app/pageRoutes/lingoLiftCollections';
import lingoLiftSearchRoutesRender from './app/pageRoutes/lingoLiftSearch';
import lingoLiftClassesRoutesRender from './app/pageRoutes/lingoLiftClasses';
import lingoLiftStandardsRoutesRender from "./app/pageRoutes/lingoLiftStandards";
import lingoLiftFavoritesRoutesRender from './app/pageRoutes/lingoLiftFavorites';
import lingoLiftTeacherDashboardRoutesRender from './app/pageRoutes/lingoLiftDashboard';
import authRoutesRender from './app/pageRoutes/auth';
import lingoLiftDashboardSummaryRoutesRender from './app/pageRoutes/lingoLiftSummary';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import lingoLiftMyLibraryRoutesRender from "./app/pageRoutes/lingoLiftMyLibrary";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const App = () => {
  // Every function return conditional render by route
  lingoLiftLessonRoutesRender();
  lingoLiftCollectionsRoutesRender();
  lingoLiftSearchRoutesRender();
  lingoLiftClassesRoutesRender();
  lingoLiftStandardsRoutesRender();
  lingoLiftFavoritesRoutesRender();
  lingoLiftTeacherDashboardRoutesRender();
  authRoutesRender();
  lingoLiftMyLibraryRoutesRender();
  lingoLiftDashboardSummaryRoutesRender();
};

App();

export default App;
