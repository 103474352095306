const styles = {
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px'
  },
  sidePanel: {
    borderRadius: '16px',
    backgroundColor: 'white'
  },
  sidePanelSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '16px',
    boxSizing: 'border-box'
  },
  divider: {
    border: '0.5px solid',
    borderColor: 'semiGray',
  },
  sidePanelSectionTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    '& .MuiTypography-root': {
      fontSize: '12px',
      fontWeight: 600,
      textTransform: 'uppercase'
    }
  },
  sideActionButton: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid',
    borderColor: 'semiGray',
    width: '100%',
    '&:hover': {
      color: 'white',
      borderColor: 'secondary.main'
    }
  },
  sideActionButtonLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid',
    borderColor: 'semiGray',
    width: '100%',
    padding: '6px',
    borderRadius: '8px',
    textAlign: 'center'
  },
  eventsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  linkIcon: {
    fontSize: '18px',
    color: 'primary.dark'
  }
}

export default styles;
