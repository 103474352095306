const styles = {
  container: {
    borderRadius: '12px',
    boxShadow: '0px 2px 8px -1px #1B242C14, 0px 2px 2px -1px #1B242C0A',
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  titleWrapper: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center'
  },
  title: {
    fontSize: '18px',
    color: 'secondary.dark',
    fontWeight: 600,
  },

  row: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '5px',
    backgroundColor: 'athensGray',
    padding: '16px'
  },
  contentRow: {
    backgroundColor: 'white',
  },
  progressLineColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center'
  },
  borderedRow: {
    borderBottom: '1px solid',
    borderColor: 'semiGray',
  },
  rowSpace: {
    padding: '16px',
  },
  coverageRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderCollapse: 'collapse',
  },
  actionButton: {
    backgroundColor: 'white',
    color: 'primary.main',
    padding: '8px 10px',
    border: '1px solid',
    borderColor: 'semiGray',
    borderRadius: '8px',
    borderCollapse: 'collapse',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'secondary.main',
      color: 'white',
    },
  },
  viewSummary: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 'none'
  },
  viewAssigned: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  progressContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  progressWrapper: {
    display: 'flex',
    backgroundColor: 'semiGray',
    height: '20px',
    width: '676px',
    overflow: 'hidden'
  },
  progressFilledZone: {
    height: '100%',
    position: 'relative',
    boxSizing: 'boreder-box'
  },
  progressPercent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  roosterButton: {
    '&.MuiButton-root': {
      backgroundColor: 'transparent'
    },
    marginLeft: '-16px',
    color: 'black',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:focus': {
      backgroundColor: 'transparent'
    },
    '& .MuiButton-icon': {
      marginLeft: 0,
      '& .MuiSvgIcon-root': {
        fontSize: '25px'
      }
    }
  },
  tableHead: {
    backgroundColor: 'athensGray'
  },

  lineType: {
    cinderella: {
      backgroundColor: 'cinderella',
      border: '1px solid',
      borderColor: 'flushMahogany'
    }
  }
};

export default styles;
