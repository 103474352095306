import * as React from 'react';
import Div from '../../../../../../shared/ui/Div';
import { FC, useState } from 'react';
import { Button, Link } from '@mui/material';
import styles from './styles';
import { KeyboardArrowDownRounded } from '@mui/icons-material';

interface RelevantListItemProps {
  title: string;
  links: Array<{
    id: string;
    title: string;
    url: string;
  }>
}

const RelevantListItem: FC<RelevantListItemProps> = (props) => {
  const { title, links } = props;

  const [isShow, setIsShow] = useState(false);

  return <Div sx={styles.container}>
    <Button
      sx={styles.showButton}
      endIcon={<KeyboardArrowDownRounded />}
      onClick={() => setIsShow(!isShow)}>
      Relevant { title }
    </Button>
    { isShow && <Div sx={styles.list}>
      { links?.map((link) => <Link key={link.id} href={link.url} sx={styles.link}>
        { link.title }
      </Link>) }
    </Div> }
  </Div>
}

export default RelevantListItem;
