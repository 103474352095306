import React, { FC } from 'react';
import Div from '../../../../shared/ui/Div'
import styles from './styles';
import { Grid, IconButton, Link, Typography } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { ILingoLiftTeacherDashboardTask } from '../../model/types';
import { usePutLingoLiftTeacherDashboardTaskHideMutation } from '../../api';
import sx from "mui-sx";

interface ILingoLiftTeacherDashboardTaskListProps {
  tasks?: Array<ILingoLiftTeacherDashboardTask>;
  tasksDisplayedCount: number;
}
const LingoLiftTeacherDashboardTaskList: FC<ILingoLiftTeacherDashboardTaskListProps> = (props) => {
  const { tasks = [], tasksDisplayedCount } = props;

  const [onHideTask] = usePutLingoLiftTeacherDashboardTaskHideMutation();

  return <Div sx={styles.list}>
    { tasks.slice(0, tasksDisplayedCount).map((task, index) => <Grid key={index} container sx={sx(
      {
        ...styles.listItem,
        ...styles.listItemBordered,
      },
      {
        sx: styles.itemExpired,
        condition: task?.dueDateStatus < 0
      }
    )}>
      <Grid sx={styles.listItemContent} item>
        <Link fontWeight="600" href={task.titleUrl} sx={sx(
          styles.listItemLink,
          {
            sx: styles.itemTitleExpired,
            condition: task?.dueDateStatus < 0
          }
        )}>
          { task.title }
        </Link>
        { task?.description?.length > 0 &&
          <Typography color="primary.dark">
            { task?.description }
          </Typography>
        }
      </Grid>
      <Grid sx={styles.itemActionsWrapper}>
        <Link href={task.url} sx={styles.listItemLink}>
          View {task.urlName}
        </Link>
        <IconButton onClick={() => onHideTask({
          taskCode: task.code
        })}>
          <CloseRounded fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>) }
  </Div>
}

export default LingoLiftTeacherDashboardTaskList;
