import { differenceWith, eqProps, flatten, uniqWith } from 'ramda';

const getUpdatedOptions = (options, selectedOptions) => {
  const newOptions = options.filter(item => !selectedOptions.some(selected => selected.value === item.value));
  const removedOptions = differenceWith(eqProps('value'), selectedOptions, options);
  const duplicatesInOptions = uniqWith(
    eqProps('value'),
    flatten(
      options.filter(
        (option, index, array) => array.findIndex(item => item.value === option.value && array.indexOf(item) !== index) !== -1,
      ),
    ),
  );
  const updatedOptions = [...selectedOptions, ...newOptions].filter(
    item => ![...removedOptions, ...duplicatesInOptions].some(removed => removed.value === item.value),
  );
  return updatedOptions;
};

export default getUpdatedOptions;
