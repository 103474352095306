const styles = {
  iframe: {
    height: {
      xs: '60vh',
      sm: '83vh',
      md: 'max-content',
    },
    padding: {
      xs: '0 16px 10px 16px',
      md: '0 44px 20px 44px',
    },
    backgroundColor: 'white'
  },
};

export default styles;
