import * as React from 'react';
import { FC, useState } from 'react';
import { AppBar, Button, IconButton, Link, Menu, SvgIcon, Toolbar } from '@mui/material';
import { AccountCircle, Close as CloseIcon, Menu as MenuIcon } from '@mui/icons-material';

import listenWiselogo from '../../../assets/listenWiseLessonLogo.svg';
import lingoLiftlogo from '../../../assets/lingoLiftLessonLogo.svg';
import { useDesktop } from '../../../shared/hooks/useDesktop';

import styles from './styles';
import ProfileMenu from './ProfileMenu';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigationMenu from './MobileNavigation';
import sx from 'mui-sx';
import Div from '../../../shared/ui/Div';
import Image from '../../../shared/ui/Image';
import {
  useGetLingoLiftPublicHeaderListQuery,
  useGetLingoLiftStudentHeaderListQuery,
  useGetLingoLiftTeacherHeaderListQuery
} from '../api';
import Loader from '../../../shared/ui/Loader';
import { isPublic, isStudent, isTeacher, UserRole } from '../../../entities/user';
import { HeaderProduct } from '../model/enums';
import LLLWLogo from '../../../assets/LlLwLogo';
import { useAuthPageCheck} from "../../../shared/hooks/useAuthCheck";
import LingoLiftLogo from '../../../assets/LingoLiftLogo';
import ListenWiseLogo from '../../../assets/ListenWiseLogo';
import { IHeaderProfileMenuItem } from '../model/types';
import SearchAppBar from "./Search";

interface IDropDownMenuProps {
  category?: string;
  arrowDirection?: 'right' | 'down';
  menuList: Array<IHeaderProfileMenuItem>;
  isLoading: boolean;
}

interface IHeaderProps {
  userRole?: UserRole;
  isLingoLiftStatus?: boolean;
  onChangeStatus?: (value: boolean) => void;
}

const ProfileDropDownMenu: FC<IDropDownMenuProps> = props => {
  const { category = 'Empty', arrowDirection = 'down', menuList, isLoading } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Div>
      <IconButton
        id={`menu-item${category}`}
        onClick={handleClick}
        sx={{
          ...styles.headerOption,
          ...styles.accountIconButton,
        }}
      >
        <AccountCircle sx={styles.accountCircleIcon} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={styles.headerOptionDropdown}
        MenuListProps={{ disablePadding: true }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: arrowDirection === 'right' ? 'left' : 'right',
        }}
      >
        <ProfileMenu menuList={menuList} isLoading={isLoading} />
      </Menu>
    </Div>
  );
};
const Header: FC<IHeaderProps> = props => {
  const { userRole = UserRole.Student, isLingoLiftStatus, onChangeStatus } = props;

  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const isDesktop = useDesktop();
  const isAuthPage = useAuthPageCheck();

  const { data: menuStudentList, isLoading: isStudentMenuListLoading } = useGetLingoLiftStudentHeaderListQuery(null, {
    skip: !isStudent(userRole),
  });

  const { data: menuTeacherList, isLoading: isTeacherMenuListLoading } = useGetLingoLiftTeacherHeaderListQuery(null, {
    skip: !isTeacher(userRole),
  });

  const { data: menuPublicList, isLoading: isPublicMenuListLoading } = useGetLingoLiftPublicHeaderListQuery(null, {
    skip: (userRole !== UserRole.Public),
  });

  const menuListByRole = {
    [UserRole.Student]: menuStudentList,
    [UserRole.Teacher]: menuTeacherList,
    [UserRole.Public]: menuPublicList
  }

  const isLoading = (isStudentMenuListLoading || isTeacherMenuListLoading || isPublicMenuListLoading)

  const searchPlaceholder = isTeacher(userRole) ? menuTeacherList?.search?.placeholder : menuStudentList?.search?.placeholder;

  return (
    <AppBar
      position="static"
      sx={sx(
        {
          ...styles.header,
        },
        {
          sx: {
            zIndex: 'modal',
          },
          condition: !isDesktop,
        }
      )}
    >
      <Toolbar sx={styles.toolBar}>
        <Link sx={styles.headerLogo} href={(menuStudentList || menuTeacherList)?.homeUrl}>
          { (menuStudentList || menuTeacherList)?.products?.length === 1 && <>
            { (menuStudentList || menuTeacherList)?.products.includes(HeaderProduct.ListenWise) &&
              <Image src={listenWiselogo} alt="Listenwise logo" /> }
            { (menuStudentList || menuTeacherList)?.products.includes(HeaderProduct.LingoLift) &&
              <Image src={lingoLiftlogo} alt="Lingolift logo" /> }
          </> }

          { ((menuStudentList || menuTeacherList)?.products?.length === 2 || isPublic(userRole)) && <SvgIcon sx={styles.doubleLogo} component={LLLWLogo} />}
        </Link>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            {isDesktop ? (
              <DesktopNavigation menuList={(menuListByRole[userRole])?.items} />
            ) : (
              <MobileNavigationMenu
                userRole={userRole}
                menuList={(menuListByRole[userRole])?.items}
                isOpen={isOpenMobileMenu}
                onClose={() => {
                  setIsOpenMobileMenu(false);
                }}
              />
            )}
          </>
        )}

        <Div sx={styles.headerTools}>
          { (isAuthPage && onChangeStatus) && <Button sx={styles.authToogleIconButton} onClick={() => onChangeStatus(!isLingoLiftStatus)}>
            { isLingoLiftStatus ? <SvgIcon
                component={LingoLiftLogo}
                sx={styles.authSvgToggleIcon}/> :
              <SvgIcon
                sx={styles.authSvgToggleIcon}
                component={ListenWiseLogo} /> }
          </Button> }

          { <Div sx={styles.headerTools}>
            { userRole !== UserRole.Public && <SearchAppBar userRole={userRole} searchPlaceholder={searchPlaceholder} />}
            {!isDesktop && !isOpenMobileMenu && (
              <IconButton
                onClick={() => {
                  setIsOpenMobileMenu(true);
                }}
              >
                <MenuIcon />
              </IconButton>
            )}

            {!isDesktop && isOpenMobileMenu && (
              <IconButton
                onClick={() => {
                  setIsOpenMobileMenu(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            )}

            {(isDesktop && userRole !== UserRole.Public) && <ProfileDropDownMenu menuList={menuListByRole[userRole]?.profileMenuItems} isLoading={isLoading} />}
          </Div> }
        </Div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
