const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    backgroundColor: 'white',
    margin: '0 auto',
    width: {
      lg: '70%',
    },
    padding: '30px 0px 20px 0px',
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    margin: '0 auto',
    textAlign: 'center',
  },
};

export default styles;
