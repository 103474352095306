const styles = {
  feedContainer: {
    flexDirection: 'column',
    gap: '10px',
    backgroundColor: 'white',
    padding: '24px',
    minHeight: '100%',
    paddingBottom: '50px'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px'
  },
  contentSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  contentSectionPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  contentSectionPanelActions: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  contentReportsSection: {
    backgroundColor: 'white',
    borderRadius: '12px'
  },
  loader: {
    textAlign: 'center',
    padding: '20px',
  },
  listItemLink: {
    color: 'secondary.dark',
    fontWeight: 450,
  },
}

export default styles;
