export default {
  MuiLink: {
    styleOverrides: {
      root: {
        fontSize: '14px',
        textDecoration: 'none',
      },
    },
  },
};
