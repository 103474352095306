const styles = {
  root: {
    borderRadius: '4px',
    background: 'white',
    color: 'primary.dark',
    border: '2px solid',
    borderColor: 'secondary.dark',
    marginTop: '10px',
  },
  wrapper: {
    height: '60px',
    padding: '16px',
    backgroundColor: 'secondary.dark',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: 'white',
  },
  closeButton: {
    backgroundColor: 'transparent !important',
  },
  wrapperKeys: {
    display: 'flex',
    justifyContent: 'center',
    margin: '24px',
  },
  key: {
    color: 'black',
  },
  clear: {
    backgroundColor: '#E5EEFF',
    padding: '2px 10px',
    margin: '0 15px',
    borderRadius: '5px',
    '& h5': {
      color: 'black',
      fontWeight: 450,
    },
  },
  satisfactory: {
    color: '#7A4510',
    backgroundColor: '#FCDEC0',
    padding: '2px 10px',
    borderRadius: '5px',
  },
  poor: {
    color: 'white',
    backgroundColor: 'hibiscus',
    padding: '2px 10px',
    borderRadius: '5px',
    margin: '0 15px',
  },
  pause: {
    fontWeight: 450,
  },
  words: {
    textDecoration: 'underline',
    fontWeight: 450,
    margin: '0 15px',
  },
  pauseWrapper: {
    position: 'relative',
    padding: '0 10px',
  },
  pauseContainer: {
    position: 'absolute',
    top: '-18px',
    left: '-3px',
  },
  pauseIconContainer: {
    position: 'relative',
    display: 'inline-block',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '2px',
      left: 'calc(50% - 2px)',
      backgroundColor: '#8040BF',
      width: '5px',
      height: '5px',
      clipPath: 'polygon(0 0, 100% 0, 50% 100%)',
    },
  },
  pauseIcon: {
    color: 'white',
    backgroundColor: '#8040BF',
    borderRadius: '2px',
    fontSize: '14px',
  },
  addWrapper: {
    position: 'relative',
    paddingLeft: '10px',
  },
  addContainer: {
    position: 'absolute',
    top: '-16px',
    left: '-3px',
  },
  addIconContainer: {
    position: 'relative',
    display: 'inline-block',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '4px',
      left: 'calc(50% - 2px)',
      backgroundColor: 'hibiscus',
      width: '5px',
      height: '5px',
      clipPath: 'polygon(0 0, 100% 0, 50% 100%)',
    },
  },
  addIcon: {
    color: 'hibiscus',
    borderRadius: '5px',
    fontSize: '19px',
  },
};

export default styles;
