import React, { FC, useEffect } from 'react';
import Div from '../../../../shared/ui/Div';
import { Button, Grid, Modal, TextField, Typography } from '@mui/material';
import styles from './styles';
import { useForm } from 'react-hook-form';
import { ILingoLiftManageClassResetStudentPassword } from '../../model/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalClassType } from "../../model/enums";
import {
  usePatchLingoLiftResetLLStudentPasswordMutation,
  usePatchLingoLiftResetLWStudentPasswordMutation
} from "../../api";
import { manageClassResetStudentPasswordFormSchema } from "../../lib/validationSchemas";

interface ILingoLiftClassesManageClassResetStudentPasswordModalFormProps {
  id: number | null;
  onClose: () => void;
  type: ModalClassType
}

const LingoLiftClassesManageClassStudentResetPasswordModalForm: FC<
  ILingoLiftClassesManageClassResetStudentPasswordModalFormProps
> = props => {
  const { id, onClose, type } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(manageClassResetStudentPasswordFormSchema)
  });

  const [onResetLLStudentPassword, { isSuccess: isResetLLStudentPasswordSuccess }] = usePatchLingoLiftResetLLStudentPasswordMutation({});

  const [onResetLWStudentPassword, { isSuccess: isResetLWStudentPasswordSuccess }] = usePatchLingoLiftResetLWStudentPasswordMutation();

  useEffect(() => {
    if (isResetLLStudentPasswordSuccess || isResetLWStudentPasswordSuccess) {
      onClose();
    }
  }, [isResetLLStudentPasswordSuccess, isResetLWStudentPasswordSuccess]);

  const onSubmit = (data: ILingoLiftManageClassResetStudentPassword) => {
    if (id && type === ModalClassType.LL) {
      onResetLLStudentPassword({
        id,
        student: data,
      });
    }

    if (id && type === ModalClassType.LW) {
      onResetLWStudentPassword({
        id,
        student: data,
      });
    }
    reset()
  };

  return (
    <Modal open={Boolean(id)} onClose={onClose} closeAfterTransition>
      <Div sx={styles.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3">Reset student password</Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Div sx={styles.formContainer}>
                <Div sx={styles.formItem}>
                  <Div
                    sx={{
                      ...styles.formItemLabel,
                      ...styles.formItemLabelRequiredSymbol,
                    }}
                  >
                    <Typography variant="h5">Password</Typography>
                  </Div>
                  <TextField
                    {...register('password')}
                    type="password"
                    error={Boolean(errors.password)}
                    helperText={(errors?.password ? errors?.password?.message : '') as string}
                    required
                  />
                </Div>

                <Div sx={styles.formItem}>
                  <Div
                    sx={{
                      ...styles.formItemLabel,
                      ...styles.formItemLabelRequiredSymbol,
                    }}
                  >
                    <Typography variant="h5">Confirm password</Typography>
                  </Div>
                  <TextField
                    {...register('passwordConfirmation')}
                    type="password"
                    error={Boolean(errors.passwordConfirmation)}
                    helperText={(errors.passwordConfirmation ? errors.passwordConfirmation?.message : '') as string}
                    required
                  />
                </Div>

                <Button type="submit" variant="contained">
                  Reset
                </Button>
              </Div>
            </form>
          </Grid>
        </Grid>
      </Div>
    </Modal>
  );
};

export default LingoLiftClassesManageClassStudentResetPasswordModalForm;
