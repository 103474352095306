import { debounce, FormGroup, Slider } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import styles from './styles';
import Div from '../../../../../shared/ui/Div';
import { TimePicker } from '@mui/x-date-pickers';
import { parseInputMinMaxValue } from '../../../lib/parseTime';
import { parseTimeToMmSs, parseInputTimeToMm } from '../../../../../shared/utils/parseTime';

interface ISingleSliderProps {
  options: Array<{
    label: string;
    value: number;
  }>;
  sectionKey: string;
  handleChangeSliderFilter: (key: string, value: [number, number]) => void;
  filteredParams: Record<string, string>;
}

const SingleSlider: FC<ISingleSliderProps> = props => {
  const { options, sectionKey, handleChangeSliderFilter, filteredParams } = props;
  const {
    control,
    watch,
    setValue,
    reset
  } = useForm();

  const sliderWatch = watch('slider');
  const minInputWatch = watch('minInput');
  const maxInputWatch = watch('maxInput');

  useEffect(() => {
    if (!filteredParams[sectionKey]?.length) {
      reset();
      setValue('minInput', parseTimeToMmSs(0))
      setValue('maxInput', parseTimeToMmSs(0))
    }
  }, [filteredParams]);

  const handleChangeInputs = debounce((value: number | null, type: 'min' | 'max') => {

    if (value && (type === 'min' || type === 'max')) {

      const sliderValue: [number, number] = type === 'min' ? [
          parseInputMinMaxValue(value, options[1].value, type),
          sliderWatch[1]] :
        [
          sliderWatch[0],
          parseInputMinMaxValue(value, options[1].value, type)
        ]

      setValue(
        'slider',
        sliderValue
      )
      handleChangeSliderFilter(sectionKey, sliderValue)
    }
  }, 500)


  return (
    <FormGroup>
      <Div sx={styles.sliderContainer}>
        <Controller
          name={`minInput`}
          control={control}
          render={({ field }) => (
            <TimePicker
              disableOpenPicker
              {...field}
              views={['minutes', 'seconds']}
              value={minInputWatch}
              sx={styles.input}
              onChange={(value) => {
                  handleChangeInputs(parseInputTimeToMm(value), 'min');
                }
              }
              format="mm:ss" />
          )}
        />
        <Controller
          name={`slider`}
          control={control}
          defaultValue={[0, 0]}
          render={({ field }) => (
            <Slider
              {...field}
              valueLabelDisplay="auto"
              getAriaLabel={() => 'range'}
              min={options.length === 2 ? options[0].value : 0}
              max={options.length === 2 ? options[1].value : 0}
              onChange={(_, value: [number, number]) => {
                handleChangeSliderFilter(sectionKey, value);
                setValue('minInput', parseTimeToMmSs(value[0]))
                setValue('maxInput', parseTimeToMmSs(value[1]))
                field.onChange(value)
              }}
            />
          )}
        />

        <Controller
          name={`maxInput`}
          control={control}
          render={({ field }) => (
            <TimePicker
              disableOpenPicker
              {...field}
              views={['minutes', 'seconds']}
              value={maxInputWatch}
              sx={styles.input}
              onChange={(value) => {
                handleChangeInputs(parseInputTimeToMm(value), 'max');
               }
              }
              format="mm:ss" />
          )}
        />
      </Div>
    </FormGroup>
  );
};

export default SingleSlider;
