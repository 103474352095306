import React, { type FC } from 'react';
import { Button, IconButton, Typography, Link } from '@mui/material';

import { PageToolActions } from '../../../../../widgets';

import styles from './styles';
import Div from '../../../../../shared/ui/Div';
import Image from "../../../../../shared/ui/Image";
import editIcon from "../../../../../assets/editIcon.svg";
import { isTeacher, UserRole } from "../../../../../entities/user";
import { HelpOutlineRounded } from "@mui/icons-material";
import {
  LingoLiftBadgeType,
  LingoLiftFavoriteableType,
  LingoLiftLessonBadge,
} from '../../../../../entities/lingoLiftLesson';
import { ILingoLiftFavoriteParams } from '../../../../../entities/lingoLiftFavorite';
import { useSnackbar } from '../../../../../shared/hooks/useSnackbar';

const SUCCESS_MESSAGE = 'Link copied to clipboard';

interface ILingoLiftLessonHeadersProps {
  id: number;
  favorited?: boolean;
  name: string;
  description: string;
  elpLevel: string;
  topicList: Array<string>;
  userRole?: UserRole;
  setOpenElpModal: () => void;
  assignUrl?: string;
  onToggleFavorite: (params: ILingoLiftFavoriteParams) => void;
  shareUrl?: string;
  llLessonCategory?: {
    name: string;
  };
}

const LingoLiftLessonHeader: FC<ILingoLiftLessonHeadersProps> = props => {
  const {
    id,
    name,
    description,
    elpLevel,
    topicList,
    userRole = UserRole.Student,
    setOpenElpModal,
    onToggleFavorite,
    favorited = false,
    assignUrl = null,
    shareUrl = '',
    llLessonCategory,
  } = props;

  const { showSuccessNotification } = useSnackbar();

  const handleShare = () => {
    navigator.clipboard.writeText(shareUrl);
    showSuccessNotification(SUCCESS_MESSAGE);
  };

  return (
    <Div sx={ styles.wrapper }>
      <Div sx={ styles.section }>
        <Typography variant="h1" color="darkGray">
          { name }
        </Typography>
        <Typography color="primary.dark" sx={ styles.description }>
          { description }
        </Typography>
        <Div sx={ styles.badgesWrapper }>
          { elpLevel &&
            <LingoLiftLessonBadge
              title={elpLevel.split('-')[0]}
              secondary={elpLevel.split('-')[1]}
              type={LingoLiftBadgeType.Eucalyptus}
              userRole={userRole}
              actionButton={<IconButton onClick={setOpenElpModal}>
                <HelpOutlineRounded sx={styles.helpOutlinedIcon} />
              </IconButton>}
            />
          }
          {llLessonCategory && (
            <LingoLiftLessonBadge
              title={llLessonCategory.name}
              type={LingoLiftBadgeType.AthensGray}
            />
          )}
          {topicList.map((topic, index) => (
            <LingoLiftLessonBadge key={index} type={LingoLiftBadgeType.AthensGray} title={topic} />
          ))}
        </Div>
      </Div>
      <Div sx={styles.actions}>
        { onToggleFavorite && <PageToolActions
          onFavorite={ () => onToggleFavorite({
            favoritableId: id,
            favoritableType: LingoLiftFavoriteableType.LLLesson
          }, favorited)}
          onShare={handleShare}
          isFavorite={favorited}
        />}
        { isTeacher(userRole) && <Button
          startIcon={<Image sx={styles.assignButtonIcon} src={editIcon} alt="icon" />}
          sx={styles.assignButton}
          component={Link}
          href={assignUrl}
        >
          Assign Lesson
        </Button> }
      </Div>
    </Div>
  );
};

export default LingoLiftLessonHeader;
