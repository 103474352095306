import React, { FC } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import Div from '../../../../shared/ui/Div';
import styles from './styles';
import { ExpandMoreRounded, HelpRounded } from '@mui/icons-material';
import ManageChildItem from './ManageChildItem';
import ManageChildColumns from './ManageChildColumns';
import { IHowToInviteStudents, ILingoLiftManageClass } from '../../module/types';
import {
  ILingoLiftDeleteManageClassStudent,
  ILingoLiftManageClassCollapseItemDropDownMenu
} from '../../../../features/lingoLiftClasses';
import {
  usePatchLingoLiftGoogleClassRoomImportLLStudentsMutation,
  usePatchLingoLiftGoogleClassRoomImportLWStudentsMutation
} from '../../api';
import { useSnackbar } from "../../../../shared/hooks/useSnackbar";
import sx from 'mui-sx';

interface ILingoLiftClassesManageClassCollapseItemProps extends ILingoLiftManageClass {
  onDeleteClass: (id: number) => void;
  onDeleteStudent: (value: ILingoLiftDeleteManageClassStudent) => void;
  setCurrentClassId: (value: number | null) => void;
  setCurrentStudentId: (value: number | null) => void;
  isListenwise?: boolean;
  howToInviteStudents: IHowToInviteStudents;
}

const LingoLiftClassesManageClassCollapseItem: FC<ILingoLiftClassesManageClassCollapseItemProps> = props => {
  const { id, name, accessToken, studentItems, setCurrentStudentId, onDeleteStudent, isListenwise, howToInviteStudents } = props;
  const { showSuccessNotification, showErrorNotification } = useSnackbar();

  const [expand, setExpand] = React.useState(false);

  const [onUpdateLLStudents, { isLoading: isUpdateLLStudentsLoading }] = usePatchLingoLiftGoogleClassRoomImportLLStudentsMutation();
  const [onUpdateLWStudents, { isLoading:isUpdateLWStudentsLoading }] =  usePatchLingoLiftGoogleClassRoomImportLWStudentsMutation();

  const onUpdateStudents = async () => {
    try {
      if (isListenwise) {
       await onUpdateLWStudents({
          schoolClassId: id
        });
      } else {
        await onUpdateLLStudents({
          llSchoolClassId: id
        });
      }
      showSuccessNotification(`Roster of ${name} updated`)
    } catch(error) {
      showErrorNotification(error.response.data.error);
    }
  }

  const isExpands = studentItems.length > 0;
  return (
    <Div sx={styles.container}>
      <Accordion expanded={expand}>
        <AccordionSummary
          sx={styles.summary}
          expandIcon={isExpands && (
            <IconButton onClick={() => setExpand(prev => !prev)}>
              <ExpandMoreRounded />
            </IconButton>
          )}
          aria-controls="panel1-content"
        >
          <Grid container>
            <Grid
              xs={12}
              sm={3}
              item
              sx={sx(styles.column, {
                sx: styles.indentName,
                condition: !isExpands,
              })}
            >
              <Link
                sx={sx(styles.entityName, {
                  sx: styles.indentName,
                  condition: !isExpands,
                })}
              >
                {name}
              </Link>
            </Grid>
            <Grid xs={12} sm={4} item sx={styles.column}>
              <Typography
                sx={sx({
                  sx: styles.indent,
                  condition: !isExpands,
                })}
              >
                {studentItems.length} students
              </Typography>
            </Grid>
            <Grid display="flex" alignItems="center" gap={1} xs={12} sm={4} item>
              <Tooltip title={<Div>
                        <Typography variant="h3" fontWeight={600}>
                          {howToInviteStudents.title}
                        </Typography>
                        <Divider sx={styles.divider} />
                        <Typography>
                          { howToInviteStudents.beforeText }
                        </Typography>
                        <Link
                          sx={styles.link}
                          target="_blank"
                          href={howToInviteStudents.url}>
                          {howToInviteStudents.url}
                        </Link>
                        {' '}
                        <Typography>
                          { howToInviteStudents.afterText }
                        </Typography>
                      </Div>}
                   placement="top"
                   arrow>
                <HelpRounded sx={styles.questionIcon} />
              </Tooltip>
              <Div sx={styles.column}>
                <Typography>
                  Class Code: <b>{accessToken}</b>
                </Typography>
                <Link
                  onClick={() => {
                    navigator.clipboard.writeText(accessToken);
                    showSuccessNotification('Class code copied to clipboard');
                  }}
                  sx={{
                    ...styles.entitySemiName,
                    ...styles.clipBoardButton,
                  }}
                >
                  Copy Code
                </Link>
              </Div>
            </Grid>
          </Grid>

          <ILingoLiftManageClassCollapseItemDropDownMenu {...props} />
        </AccordionSummary>
        <AccordionDetails sx={styles.details}>
          <ManageChildColumns
            isLoadingUpdateStudents={(isUpdateLLStudentsLoading || isUpdateLWStudentsLoading)}
            onUpdateStudents={onUpdateStudents}  />
          {studentItems.map((studentItem, index) => (
            <ManageChildItem
              key={index}
              {...studentItem}
              setCurrentStudentId={setCurrentStudentId}
              onDeleteStudent={() =>
                onDeleteStudent({
                  llSchoolClassId: id,
                  studentId: studentItem.id,
                })
              }
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </Div>
  );
};

export default LingoLiftClassesManageClassCollapseItem;