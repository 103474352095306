import React, { FC } from 'react';
import { Grid, Typography, Link } from '@mui/material';

import ContentLayout from '../../shared/ui/ContentLayout';
import { MainLayout } from '../../widgets';
import LingoLiftSummaryCard from '../../entities/lingoLiftSummary/ui/SummaryCard';
import styles from './styles';
import DashboardSummaryHeader from '../../entities/lingoLiftSummary/ui/SummaryHeader';
import VideoCard from '../../entities/lingoLiftSummary/ui/VideoCard';
import {
  useGetLingoLiftClassesSummaryStudentReportQuery,
} from "../../entities/lingoLiftSummary";
import { UserRole } from "../../entities/user";

interface ILingoLiftDashboardClassesSummaryStudentReportPageProps {
  llSchoolClassId: string | undefined;
  studentId: string | undefined;
}

const LingoLiftClassesSummaryStudentReportPage:FC<ILingoLiftDashboardClassesSummaryStudentReportPageProps> = (props) => {
  const { llSchoolClassId, studentId } = props;

  const { data: report, isLoading: isReportLoading } = useGetLingoLiftClassesSummaryStudentReportQuery(
    {
      llSchoolClassId: llSchoolClassId as string ,
      studentId: studentId as string
    },
    {
      skip: (!llSchoolClassId || !studentId)
    }
  );

  return (
    <MainLayout userRole={UserRole.Teacher} backgroundColor="athensGray" isLoading={isReportLoading}>
      <ContentLayout>
        <DashboardSummaryHeader name={report?.title ?? ''} />
      </ContentLayout>
      <ContentLayout>
        <Typography variant="h2" sx={styles.title}>
          { report?.sectionLabel }
        </Typography>
        <Grid container spacing={2} justifyContent="space-between">
          { report?.summary && Object.keys(report.summary).map((summary: keyof typeof report.summary ) => (<Grid key={summary} item xs={4}>
            <LingoLiftSummaryCard
              title={report.summary[summary].label}
              percentage={report.summary[summary].percentage}
              statistics={report.summary[summary].countLabel}
              iconType={summary}
            />
          </Grid>)) }
        </Grid>
      </ContentLayout>
      { report && report?.assignedItems?.length > 0 && <ContentLayout>

        <Grid container spacing={ 2 } justifyContent="space-between" sx={ styles.wrapper }>

          <Grid item xs={ 10 }>
            <Typography variant="h3" sx={ styles.title }>
              Assigned Videos
            </Typography>
          </Grid>
          <Grid item xs={ 2 } sx={ styles.button }>
            <Link sx={ styles.transparentButton } href={ report?.allAssignmentsLink }>
              { report?.allAssignmentsLabel }
            </Link>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          { report?.assignedItems.map((assigned) => (<Grid
            item
            xs={12}
            key={assigned.id}>
            <VideoCard
              { ...assigned }
            />
          </Grid>)) }
        </Grid>
      </ContentLayout> }
      { report?.notAssignedItems && report?.notAssignedItems?.length > 0 && <ContentLayout>
          <Typography variant="h3" sx={ styles.title }>
            Not Assigned Videos
          </Typography>
        <Grid container spacing={2} xs={12}>
          { report?.notAssignedItems.map((assigned) => <Grid
            key={assigned.id}
            xs={12}
            item>
            <VideoCard
              { ...assigned }
            />
          </Grid>) }
        </Grid>
      </ContentLayout> }
    </MainLayout>
  );
};

export default LingoLiftClassesSummaryStudentReportPage;
