import React, { FC, useEffect, useState } from 'react';
import Div from '../../../../shared/ui/Div';
import styles from './styles';
import { Button, Checkbox, FormControlLabel, FormGroup, Tooltip, Typography } from '@mui/material';
import { ErrorRounded, ExpandLess, ExpandMore, FilterListRounded } from '@mui/icons-material';
import sx from 'mui-sx';
import { isEqualIndex, isNotEqualIndex } from '../../../../shared/utils/indexIsEqual';
import MultipleSelectChips from '../../../../shared/ui/MultipleChipsSelect';
import {
  isCheckbox,
  isGeneralSearchProduct,
  isLingoLiftSearchProduct,
  isListenWiseSearchProduct,
  isRangeSlider,
  isSliderWithPresets,
  isTopicSearchFilter,
} from '../../../lingoLiftSearch/lib/compareConditions';
import DoubleOptionedSlider from './DoubleOptionedSlider';
import SingleSlider from './SingleSlider';
import LingoLiftLessonLogo from '../../../../assets/untitledLogos/lingoLiftUntitledLogo.svg';
import ListenWiseLessonLogo from '../../../../assets/untitledLogos/lintenWiseUntitledLogo.svg';
import { LingoLiftSearchProduct } from '../../../lingoLiftSearch';
import Image from '../../../../shared/ui/Image';
import { ILingoLiftSearchFilterOption, ILingoLiftSearchFullFieldInput } from '../../model/types';

interface ILingoLiftCollapsedFilterSectionProps {
  type: string;
  label: string;
  filters: Array<ILingoLiftSearchFilterOption>;
  productType: LingoLiftSearchProduct;
  onChangeFilter: (key: string, value: number | string | Array<number> | undefined) => void;
  onChangeSliderFilter: (key: string, value: Array<number>) => void;
  filteredParams: Record<string, string>;
}

const LingoLiftFilter: FC<ILingoLiftCollapsedFilterSectionProps> = props => {
  const { filters, productType, onChangeFilter, onChangeSliderFilter, filteredParams } = props;

  const [openedFilters, setOpenedFilters] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (filters) {
      const filterSections = {};
      filters.map(section => (filterSections[section.label] = false));
      setOpenedFilters(filterSections);
    }
  }, [filters]);

  useEffect(() => {
    const filters = {...filteredParams};
    delete filters.query
    // collapse filters if clicked reset action
   if (!Object.keys(filters).length) {
     setOpenedFilters({})
   }
  }, [filteredParams]);

  const handleClick = (key: string) => {
    setOpenedFilters({
      ...openedFilters,
      [key]: !openedFilters[key],
    });
  };

  return (
    <Div sx={styles.container}>
      <Div sx={styles.title}>
        {isGeneralSearchProduct(productType) && (
          <>
            <FilterListRounded />
            <Typography variant="body2" fontWeight={600}>
              FILTERS
            </Typography>
          </>
        )}
        {isLingoLiftSearchProduct(productType) && (
          <>
            <Image src={LingoLiftLessonLogo} alt="lingilift logo" />
            <Typography sx={styles.lingoLiftTitle} variant="body2" fontWeight={600}>
              LINGOLIFT FILTERS
            </Typography>
          </>
        )}
        {isListenWiseSearchProduct(productType) && (
          <>
            <Image src={ListenWiseLessonLogo} alt="listenwise logo" />
            <Typography sx={styles.listenWiseTitle} variant="body2" fontWeight={600}>
              LISTENWISE FILTERS
            </Typography>
          </>
        )}
      </Div>

      {filters?.map((filterSection, index) => (
        <Div
          key={index}
          sx={sx(
            styles.filterSection,
            {
              sx: styles.filterSection.bottomVoidPadding,
              condition: isEqualIndex(Object.keys(filters).length, index),
            },
            {
              sx: styles.filterSection.bordered,
              condition: isNotEqualIndex(Object.keys(filters).length, index),
            }
          )}
        >
          <Button sx={styles.category} onClick={() => handleClick(filterSection.label)}>
            <Typography sx={styles.categoryTitle} variant="h5">
              {filterSection.label}{' '}
              {filterSection.attention && (
                <Tooltip title={filterSection.attention} placement="top-start">
                  <ErrorRounded sx={styles.categoryTitle.icon} />
                </Tooltip>
              )}
            </Typography>{' '}
            {openedFilters[filterSection.label] ? <ExpandLess /> : <ExpandMore />}
          </Button>

          <Div
            sx={sx(styles.content.defaultState, {
              sx: styles.content.collapsedState,
              condition: openedFilters[filterSection.label],
            })}
          >
            <FormGroup sx={styles.filtersFormGroup}>
              {isSliderWithPresets(filterSection.type) && (
                <DoubleOptionedSlider
                  sectionKey={filterSection.key}
                  handleChangeFilter={onChangeSliderFilter}
                  options={filterSection.inputs as Array<ILingoLiftSearchFullFieldInput>}
                  presetValues={filterSection.presetValues}
                  filteredParams={filteredParams}
                />
              )}

              {isTopicSearchFilter(filterSection.key) && (
                <MultipleSelectChips
                  sectionKey={filterSection.key}
                  handleChange={onChangeFilter}
                  chips={filterSection.inputs as Array<string>}
                  filteredParams={filteredParams}
                />
              )}

              {isRangeSlider(filterSection.type) && (
                <SingleSlider
                  key={index}
                  sectionKey={filterSection.key}
                  options={filterSection.inputs as Array<ILingoLiftSearchFullFieldInput>}
                  filteredParams={filteredParams}
                  handleChangeSliderFilter={onChangeSliderFilter}
                />
              )}

              {!isSliderWithPresets(filterSection.type) &&
                filterSection.inputs?.map((option, index) => (
                  <Div key={index}>
                    {isCheckbox(filterSection.type) && (
                      <FormControlLabel
                        key={index}
                        onClick={() => false}
                        control={
                          <Checkbox
                            checked={Boolean(filteredParams[filterSection.key]?.includes(option.value))}
                            onChange={() => onChangeFilter(filterSection.key, option.value)}
                          />
                        }
                        label={option.label}
                        sx={styles.formGroupFilterOption}
                      />
                    )}
                  </Div>
                ))}
            </FormGroup>
          </Div>
        </Div>
      ))}
    </Div>
  );
};

export default LingoLiftFilter;
