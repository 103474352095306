const styles = {
  container: {
    borderRadius: '5px',
    fontSize: '14px',
    fontWeight: 400,
    height: 'fit-content',
    padding: '1px',
    boxSizing: 'border-box',
    color: 'black',
    cursor: 'pointer',
    '& .MuiChip-deleteIcon': {
      color: 'black',
      fontSize: '18px'
    }
  },
  active: {
    backgroundColor: 'secondary.dark',
    color: 'white'
  }
}

export default styles;
