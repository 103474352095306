const styles = {
  headerOption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px !important',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    color: 'primary.main',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '5px 10px',
    minHeight: 'auto',
    textTransform: 'capitalize',
    '&.MuiButton-root': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      '&:hover': {
        backgroundColor: 'secondary.light',
        color: 'secondary.main',
        borderRadius: '8px',
      },
    },
    '&:hover': {
      backgroundColor: 'secondary.light',
      color: 'secondary.main',
      borderRadius: '6px',
    },
  },
  searchInput: {
    marginLeft: 'auto',
    width: '250px',
  },
  searchWrapper: {
    marginLeft: 'auto',
  },
};

export default styles;
