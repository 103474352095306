import { selectResponse } from '../../../shared/utils/selectFromResponseObjects';
import { apiContainer, apiRoutes } from '../../../shared/store';
import { ILingoLiftFavoriteParams, ILingoLiftFavorite } from '../model/types';
import { CacheTags } from '../../../shared/store/commonEnums';

export const lingoLiftFavoritesApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLingoLiftTeacherFavorites: builder.query<IPaginatedResponse<ILingoLiftFavorites>, { page: number } & ILingoLiftFavoritesTagParams>({
      query: params => ({
        url: apiRoutes.lingoLiftToggleFavoritePath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.Favorites, id: 'LIST' }],
    }),
    postLingoLiftTeachersFavoritesAddTag: builder.mutation<ILingoLiftFavoritesTagResponse, { id: number, data: { tagsOnFavorites: Array<string> }}>({
      query: ({ id, data }) => ({
        url: apiRoutes.lingoLiftTeacherFavoritesAddTagPath(id),
        method: 'POST',
        data,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.Favorites, id: 'LIST' }],
    }),
    deleteLingoLiftTeachersFavoritesTag: builder.mutation<void, { id: number, params: { tagId: number }}>({
      query: ({ id, params }) => ({
        url: apiRoutes.lingoLiftTeacherFavoritesUntagPath(id),
        method: 'DELETE',
        params,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.Favorites, id: 'LIST' }],
    }),
    deleteLingoLiftTeacherFavorite: builder.mutation<void, { favoritableId: number, favoritableType: string }>({
      query: params => ({
        url: apiRoutes.lingoLiftDeleteFavoriteByParamsPath(),
        method: 'DELETE',
        params,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.Favorites, id: 'LIST' }, { type: CacheTags.Feed, id: 'LIST' }, { type: CacheTags.EnhancedCollection, id: 'LIST' }],
    }),
    getLingoLiftTeachersFavoritesTags: builder.query<Array<ITag>, void>({
      query: () => ({
        url: apiRoutes.lingoLiftFavoritesTagsIndexPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.Favorites, id: 'LIST' }],
    }),
    deleteLingoLiftTeachersFavoriteTag: builder.mutation<void, { tagId: number }>({
      query: params => ({
        url: apiRoutes.lingoLiftDeleteFavoriteTagPath(),
        method: 'DELETE',
        params,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.Favorites, id: 'LIST' }],
    }),
    postLingoLiftTeacherAddFavorite: builder.mutation<ILingoLiftFavorite, ILingoLiftFavoriteParams>({
      query: (data) => ({
        url: apiRoutes.lingoLiftAddFavoritePath(),
        method: 'POST',
        data,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.Feed, id: 'LIST' }, { type: CacheTags.EnhancedCollection, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetLingoLiftTeacherFavoritesQuery,
  usePostLingoLiftTeachersFavoritesAddTagMutation,
  useDeleteLingoLiftTeachersFavoritesTagMutation,
  useDeleteLingoLiftTeacherFavoriteMutation,
  useGetLingoLiftTeachersFavoritesTagsQuery,
  useDeleteLingoLiftTeachersFavoriteTagMutation,
  usePostLingoLiftTeacherAddFavoriteMutation,
} = lingoLiftFavoritesApi;
