import * as React from 'react';
import { FC } from 'react';
import { Chip } from '@mui/material';
import deleteIcon from '../../../../assets/secondaryIcons/deleteIcon.svg';
import Image from '../../../../shared/ui/Image';
import styles from './styles';

interface ILingoLiftMyLibraryTagProps {
  label: string;
  onDelete: (id: number) => void;
  onParamsChange: (name: string) => void;
}

const LingoLiftMyFavoritesTag: FC<ILingoLiftMyLibraryTagProps> = (props) => {
  const { label, onDelete, onParamsChange } = props;

  return (
    <Chip
      sx={styles.container}
      deleteIcon={<Image src={deleteIcon} alt="deleteIcon" />}
      label={label}
      onDelete={onDelete}
      onClick={onParamsChange}
    />
  );
};

export default LingoLiftMyFavoritesTag;
