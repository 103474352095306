export const selectLingoLiftAuthParamsFromDomObject = (object: HTMLElement) => {
  const url = JSON.parse(object.dataset.url ?? '');
  const error = JSON.parse(object.dataset.errorMessages ?? '');
  const recaptchaSiteKey = object.dataset.recaptchaSitekey ? JSON.parse(object.dataset.recaptchaSitekey ?? '') : '';
  const inputs = JSON.parse(object.dataset.inputs ?? '');
  const googleUrl = object.dataset.googleUrl ?? '';
  const googleQueryWho = object.dataset.googleQueryWho ?? '';
  const googleQueryCommon = object.dataset.googleQueryCommon ? JSON.parse(object.dataset.googleQueryCommon ?? '') : false;
  const googleAlt = object.dataset.googleAlt ?? '';
  const cleverUrl = object.dataset.cleverUrl ?? '';
  const cleverQueryType = object.dataset.cleverQueryType ?? '';
  const cleverQueryCommon = object.dataset.cleverQueryCommon ? JSON.parse(object.dataset.cleverQueryCommon ?? '') : false;
  const cleverAlt = object.dataset.cleverAlt ?? '';
  const forgotPasswordUrl = object.dataset.forgotPasswordUrl ?? '';
  const notice = JSON.parse(object.dataset.notice ?? '');

  return {
    url,
    error,
    inputs,
    recaptchaSiteKey,
    googleUrl,
    googleQueryWho,
    googleQueryCommon,
    googleAlt,
    cleverUrl,
    cleverQueryType,
    cleverQueryCommon,
    cleverAlt,
    forgotPasswordUrl,
    notice,
  };
};
