import React, { FC, useState } from 'react';

import TabsContainer from '../../../../../shared/ui/Tabs/ui';
import { TabContent } from '../../../../../shared/ui/Tabs/ui/TabContent';

import styles from './styles';
import markDownStyles from './markDownStyles.module.scss';
import { Typography } from '@mui/material';
import Div from '../../../../../shared/ui/Div';
import {
  ILingoLiftLessonStandard, ILingoLiftLessonStandardItem, ILingoLiftStandardsRecursive,
  ILLTeachersGuide,
  LingoLiftLessonStandardAccordionCard
} from '../../../../../entities/lingoLiftLesson';
import Loader from "../../../../../shared/ui/Loader";

const guideTabs = [
  {
    name: 'Before Viewing',
  },
  {
    name: 'During Viewing',
  },
  {
    name: 'After Viewing',
  },
  {
    name: 'Standards',
  },
];

interface ILingoLiftGuideProps {
  name: string;
  teachersGuide: ILLTeachersGuide;
  standards: Array<ILingoLiftLessonStandard<ILingoLiftLessonStandardItem, ILingoLiftLessonStandardItem>>;
  isLoadingStandardsDetails: boolean;
}

const LingoLiftGuide: FC<ILingoLiftGuideProps> = props => {
  const { name, teachersGuide, standards, isLoadingStandardsDetails } = props;

  const [tabIndex, setTabIndex] = useState(0);

  const cleanedName = name ? name.replace(/ TG$/, '') : '';
  const title = cleanedName ? `Teacher's Guide - ${cleanedName}` : `Teacher's Guide`;

  return (
    <Div sx={styles.wrapper}>
      <Div style={styles.contentSpace}>
        <Typography variant="h2">{title}</Typography>
      </Div>

      <Div className={markDownStyles.markDownContainer} dangerouslySetInnerHTML={{ __html: teachersGuide?.overview }} />

      <TabsContainer tabs={guideTabs} tabIndex={tabIndex} setTabIndex={setTabIndex}>
        <TabContent index={0} value={tabIndex}>
          <Div
            className={markDownStyles.markDownContainer}
            dangerouslySetInnerHTML={{ __html: teachersGuide?.beforeViewing }}
          />
          <Div
            className={markDownStyles.markDownContainer}
            dangerouslySetInnerHTML={{ __html: teachersGuide?.beforeViewingCustom }}
          />
        </TabContent>
        <TabContent index={1} value={tabIndex}>
          <Div
            className={markDownStyles.markDownContainer}
            dangerouslySetInnerHTML={{ __html: teachersGuide?.duringViewing }}
          />
          <Div
            className={markDownStyles.markDownContainer}
            dangerouslySetInnerHTML={{ __html: teachersGuide?.duringViewingCustom }}
          />
        </TabContent>
        <TabContent index={2} value={tabIndex}>
          <Div
            className={markDownStyles.markDownContainer}
            dangerouslySetInnerHTML={{ __html: teachersGuide?.afterViewing }}
          />
          <Div
            className={markDownStyles.markDownContainer}
            dangerouslySetInnerHTML={{ __html: teachersGuide?.afterViewingCustom }}
          />
        </TabContent>
        <TabContent index={3} value={tabIndex}>
          { isLoadingStandardsDetails ? <Loader /> : standards?.map((item) => Object.keys(item.stateStandards).sort().map((section) => <Div
            sx={styles.standardsGradeContainer} key={section}>
            <Typography variant="h3">
              { section }
            </Typography>
            { Object.keys(item.stateStandards[section]).map((grade) => <LingoLiftLessonStandardAccordionCard
              key={grade}
              grade={grade}
              title={item.stateStandardsName}

              standards={(item.stateStandards[section][grade] as unknown as Array<ILingoLiftLessonStandardItem & ILingoLiftStandardsRecursive>)}
            />) }
          </Div>)) }
        </TabContent>
      </TabsContainer>
    </Div>
  );
};

export default LingoLiftGuide;
