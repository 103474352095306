import React, { type FC, useRef, useState } from 'react';
import { Typography, Grid, IconButton, Tooltip, Link } from '@mui/material';

import styles from './styles';
import Div from '../../../../../../shared/ui/Div';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InfoIcon from '@mui/icons-material/Info';
import sx from 'mui-sx';
import { LingoLiftSummaryWordEvaluation, LingoLiftSummaryWordStatus } from '../../../../model/enums';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import { ILingoLiftSummarySpokenDialogWord } from "../../../../model/types";
import { VolumeOffRounded } from '@mui/icons-material';

interface ISpeakRow {
  words?: Array<ILingoLiftSummarySpokenDialogWord>;
  score: string;
  attempts: string;
  audioUrl: string;
  transcript: string;
  grade: number;
}

const TOOLTIP_LEAVE_DELAY = 200;

const SpeakRow: FC<ISpeakRow> = props => {
  const { words, transcript, score, grade, attempts, audioUrl } = props;
  const [isAudioPlayer, setIsAudioPlayer] = useState(false);
  const audioRef = useRef<{ current: HTMLAudioElement }>();

  const onStartAudio = () => {
    setIsAudioPlayer(!isAudioPlayer);
    //@ts-ignore
    audioRef.current.play()
  }

  const onStopAudio = () => {
    setIsAudioPlayer(!isAudioPlayer);
    //@ts-ignore
    audioRef.current.pause()
  }

  return (
    <Div sx={ styles.root }>
      <audio
        ref={audioRef as unknown as { current: HTMLAudioElement }}
        id="myAudio">
        <source src={audioUrl} type="audio/mpeg"/>
      </audio>

      <Grid container spacing={ 3 } justifyContent="space-between">
        <Grid item xs={ 9 }>
          <Div sx={ styles.sentenceWrapper }>

            { !words && transcript && <Typography sx={ sx(
              styles.title
            ) }>{ transcript }</Typography> }

            { words?.map((item, index) => {
              const {label, status, evaluation} = item;

              if ((evaluation === LingoLiftSummaryWordEvaluation.Pause || (evaluation === LingoLiftSummaryWordEvaluation.Match && label.includes('#SIL#')))) {
                return <Typography key={ index } sx={ sx(styles.pause) }>
                  <Div sx={ styles.pauseWrapper }>
                    <Div sx={ styles.pauseContainer }>
                      <Div sx={ styles.pauseIconContainer }>
                        <LocalParkingIcon sx={ styles.pauseIcon }/>
                      </Div>
                    </Div>
                  </Div>
                </Typography>
              }

              return (
                <Typography
                  key={ index }
                  variant="h5"
                  sx={ sx(
                    {
                      ...styles.title,
                    },
                    {
                      sx: styles.clear,
                      condition: evaluation === LingoLiftSummaryWordEvaluation.Match && status === 'GOOD'
                    },
                    {
                      sx: styles.satisfactory,
                      condition: evaluation === LingoLiftSummaryWordEvaluation.Substitution,
                    },
                    {
                      sx: styles.poor,
                      condition: (evaluation === LingoLiftSummaryWordEvaluation.Deletion || evaluation === LingoLiftSummaryWordEvaluation.Match && status === 'BAD'),
                    },
                    {
                      sx: styles.learned,
                      condition: status.includes(LingoLiftSummaryWordStatus.wordsLearned),
                    },
                  ) }
                >
                  { evaluation === LingoLiftSummaryWordEvaluation.Insertion && (
                    <Div sx={ styles.addWrapper }>
                      <Div sx={ styles.addContainer }>
                        <Div sx={ styles.addIconContainer }>
                          <AddBoxRoundedIcon sx={ styles.addIcon }/>
                        </Div>
                      </Div>
                    </Div>
                  ) }
                  { evaluation !== LingoLiftSummaryWordEvaluation.Insertion && label }
                </Typography>
              );
            }) }
          </Div>
        </Grid>
        <Grid item xs={ 1.5 }>
          <Typography variant="body2" sx={ styles.description }>
            Pronunciation Score
          </Typography>
          <Div sx={ styles.wrapper }>
            { grade && <Typography variant="h5" sx={ styles.level }>
              { grade }
            </Typography> }
            <Div sx={ styles.wrapper }>
              <Typography variant="h5" sx={ styles.score }>
                { score }
              </Typography>
              <Tooltip
                title={ 'tooltip text' }
                leaveDelay={ TOOLTIP_LEAVE_DELAY }
                placement='top-end'
                arrow
              >
                <InfoIcon sx={ styles.icon }/>
              </Tooltip>
            </Div>
          </Div>
        </Grid>
        <Grid item xs={ 1.5 }>
          <Div sx={ styles.wrapper }>
            {/* <Div sx={ styles.attemptsWrapper }>
              <Typography variant="body2" sx={ styles.description }>
                Attempts
              </Typography>
              <Typography variant="h5" sx={ styles.title }>
                { attempts }
              </Typography>
            </Div> */}
            <Div>
              { !isAudioPlayer && <IconButton onClick={onStartAudio} sx={ styles.iconButton }>
                <VolumeUpOutlinedIcon/>
              </IconButton> }

              { isAudioPlayer && <IconButton onClick={onStopAudio} sx={ styles.iconButton }>
                <VolumeOffRounded/>
              </IconButton> }
              <IconButton sx={ styles.iconButton }>
                <Link target="_blank" download href={ audioUrl }>
                  <FileDownloadOutlinedIcon/>
                </Link>
              </IconButton>
            </Div>
          </Div>
        </Grid>
      </Grid>
    </Div>
  );
};

export default SpeakRow;
