const forgotPasswordStudentFormFields = [
  {
    name: 'student[email]',
    label: 'Email',
    placeholder: 'Email',
    required: true,
    requiredText: 'Required',
    type: 'email',
  },
  {
    name: 'authenticity_token',
    label: 'token',
    type: 'hidden',
  },
];

const forgotPasswordTeacherFormFields = [
  {
    name: 'teacher[email]',
    label: 'Email',
    placeholder: 'Email',
    required: true,
    requiredText: 'Required',
    type: 'email',
  },
  {
    name: 'authenticity_token',
    label: 'token',
    type: 'hidden',
  },
];

const editPasswordStudentFormFields = [
  {
    name: 'student[password]',
    label: 'New password',
    placeholder: 'New password',
    required: true,
    type: 'password',
  },
  {
    name: 'student[password_confirmation]',
    label: 'Confirm your new password',
    placeholder: 'Confirm your new password',
    required: true,
    type: 'password',
  },
  {
    name: 'student[reset_password_token]',
    label: 'token',
    type: 'hidden',
  },
  {
    name: 'authenticity_token',
    label: 'token',
    type: 'hidden',
  },
  {
    name: '_method',
    label: 'token',
    type: 'hidden',
  },
];

const editPasswordTeacherFormFields = [
  {
    name: 'teacher[password]',
    label: 'New password',
    placeholder: 'New password',
    required: true,
    type: 'password',
  },
  {
    name: 'teacher[password_confirmation]',
    label: 'Confirm your new password',
    placeholder: 'Confirm your new password',
    required: true,
    type: 'password',
  },
  {
    name: 'teacher[reset_password_token]',
    label: 'token',
    type: 'hidden',
  },
  {
    name: 'authenticity_token',
    label: 'token',
    type: 'hidden',
  },
  {
    name: '_method',
    label: 'token',
    type: 'hidden',
  },
];

const formFields = {
  studentForgotPassword: forgotPasswordStudentFormFields,
  teacherForgotPassword: forgotPasswordTeacherFormFields,
  teacherEditPassword: editPasswordTeacherFormFields,
  studentEditPassword: editPasswordStudentFormFields,
};

export { formFields };
