const styles = {
  container: {
    border: '1px solid',
    borderColor: 'semiGray',
    borderRadius: '12px',
    overflow: 'hidden',
  },
  summary: {
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded ': {
      transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
      paddingLeft: '20px',
    },
  },
  details: {
    padding: '8px 0 0 0',
    backgroundColor: 'athensGray',
  },
  entityName: {
    color: 'secondary.dark',
    fontWeight: 600,
    width: 'max-content',
  },
  entitySemiName: {
    fontSize: '14px',
    color: 'black',
    fontWeight: 600,
  },
  clipBoardButton: {
    '&:hover': {
      color: 'secondary.dark',
    },
  },
  column: {
    display: 'grid',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
  questionIcon: {
    color: 'secondary.dark',
  },
  dotedIcon: {
    fontSize: '20px',
  },
  dotedMenu: {
    '& .MuiMenu-paper': {
      borderRadius: '8px',
    },
  },
};

export default styles;
