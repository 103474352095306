import React, { FC } from 'react';
import Div from '../../../../shared/ui/Div';
import styles from "./styles";
import { CardMedia, Link, Typography } from "@mui/material";
import Image from "../../../../shared/ui/Image";
import videoIcon from "../../../../assets/secondaryIcons/videoIcon.svg";
import { EastRounded } from "@mui/icons-material";
import { ILingoLiftStudentDashboardAssignmentQuiz } from "../../model/types";
import audioIcon from "../../../../assets/secondaryIcons/audioIcon.svg";
import bookIcon from "../../../../assets/secondaryIcons/bookIcon.svg";
import calendarIcon from "../../../../assets/secondaryIcons/calendarIcon.svg";
import sx from "mui-sx";
import { isContinueButton, isReviewButton, isStartButton } from "../../lib/checkCardButtons";
import LessonBadge from "../../../lingoLiftLesson/ui/LessonBadge";
import { LingoLiftBadgeType } from "../../../lingoLiftLesson";

interface ILingoLiftStudentDashboardCardProps extends ILingoLiftStudentDashboardAssignmentQuiz {
  quizScore?: string;
  subStatusText?: string;
}

const LingoLiftStudentDashboardCard:FC<ILingoLiftStudentDashboardCardProps> = (props) => {
  const {
    relatedName,
    relatedDescription,
    relatedPhotoSrc,
    relatedQuiz,
    relatedAudio,
    relatedVideo,
    btnUrl,
    mainUrl,
    due,
    schoolClassName,
    btnLabel,
    status,
    statusLabel,
  } = props;

  return <Div sx={styles.container}>
    <CardMedia
      sx={styles.poster}
      component="img"
      image={relatedPhotoSrc}
      alt="card related poster"
    />
    <Div sx={styles.content}>
      <Link sx={styles.title} href={mainUrl}>
        { relatedName }
      </Link>
      <Typography variant="body2" color="primary.dark">
        { relatedDescription }
      </Typography>
      { relatedQuiz && <LessonBadge title="Quiz" type={LingoLiftBadgeType.ShuttleGray} /> }
    </Div>
    <Div sx={styles.panel}>
      <Link href={btnUrl} sx={sx(
        {
          sx: styles.startButton,
          condition: isStartButton(status)
        },
        {
          sx: styles.reviewButton,
          condition: isReviewButton(status) || isContinueButton(status)
        },
      )}>
        { btnLabel } <EastRounded sx={styles.startButtonIcon} />
      </Link>

      { statusLabel && <Div sx={ styles.tagWrapper }>
        <Typography>
          Status:
        </Typography>
        <Typography>
          { statusLabel }
        </Typography>
      </Div> }
      { relatedVideo && <Div sx={ styles.tagWrapper }>
        <Image
          sx={ styles.tagIcon }
          src={ videoIcon }
          alt="Listenwise watch card icon"
        />
        <Typography>
          Interactive Video
        </Typography>
      </Div> }
      { relatedAudio && <Div sx={ styles.tagWrapper }>
        <Image
          sx={ styles.tagIcon }
          src={ audioIcon }
          alt="Listenwise audio card icon"
        />
        <Typography>
          Audio
        </Typography>
      </Div> }
      { due && <Div sx={ styles.tagWrapper }>
        <Image
          sx={ styles.tagIcon }
          src={ calendarIcon }
          alt="Listenwise due card icon"
        />
        <Typography>
          { due }
        </Typography>
      </Div> }

      { schoolClassName && <Div sx={ styles.tagWrapper }>
        <Image
          sx={ styles.tagIcon }
          src={ bookIcon }
          alt="Listenwise due card icon"
        />
        <Typography>
          { schoolClassName }
        </Typography>
      </Div> }
    </Div>
  </Div>
}

export default LingoLiftStudentDashboardCard;
