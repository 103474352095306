export const selectLingoLiftCollectionLessonParamsFromDomObject = (object: HTMLElement) => {
  const accessToken = JSON.parse(object.dataset.access ?? '');
  const key = JSON.parse(object.dataset.key ?? '');
  const dialogId = JSON.parse(object.dataset.dialog ?? '');
  const lessonId = JSON.parse(object.dataset.llLessonId ?? '');
  const collectionId = JSON.parse(object.dataset.enhancedCollectionId ?? '');
  const languageCode = object.dataset.languageCode ?? '';

  return {
    lessonId,
    partnerKey: key,
    accessToken,
    dialogId,
    collectionId,
    languageCode,
  };
};
