const styles = {
  wrapper: {
    display: 'flex',
  },
  icon: {
    color: 'primary.main',
  },
};

export default styles;
