import * as React from "react";
import ReduxProvider from "../reduxProvider";
import {createRoot} from "react-dom/client";
import LingoLiftCollectionsStudentBrowsePage from "../../pages/LingoLiftCollectionsStudentBrowse";
import LingoLiftCollectionsTeacherBrowsePage from "../../pages/LingoLiftCollectionsTeacherBrowse";
import LingoLiftCollectionStudentShowPage from "../../pages/LingoLiftCollectionStudentShow";
import LingoLiftCollectionTeacherShowPage from "../../pages/LingoLiftCollectionTeacherShow";
import { selectCurrentUrlFromDomObject, selectRailsPropAscIdFromHtmlElement, selectRailsPropIdFromHtmlElement } from "../../shared/utils/selectors";
import LingoLiftCollectionAssignPage from "../../pages/LingoLiftCollectionAssignPage";
import LingoLiftCollectionsLessonStudentShowPage from '../../pages/LingoLiftCollectionsLessonStudentShow';
import { selectLingoLiftCollectionLessonParamsFromDomObject } from "../../shared/utils/selectLingoLiftCollectionLessonParamsFromDomObject";


const lingoLiftCollectionsRoutesRender = () => {
  const lingoLiftEnhancedCollectionsStudentBrowseElement = document.querySelector(
    '#lingolift_enhanced_collection_students_browse',
  )!;

  const lingoLiftEnhancedCollectionsTeacherBrowseElement = document.querySelector(
    '#lingolift_enhanced_collection_teachers_browse',
  )!;

  const lingoLiftEnhancedCollectionTeacherShowElement = document.querySelector(
    '#lingolift_enhanced_collection_teachers_show',
  )! as HTMLElement;

  const lingoLiftEnhancedCollectionStudentShowElement = document.querySelector(
    '#lingolift_enhanced_collection_students_show',
  )! as HTMLElement;

  const lingoLiftEnhancedCollectionAssignElement = document.querySelector(
    '#lingolift_enhanced_collection_teachers_assign')! as HTMLElement;

  const lingoLiftEnhancedCollectionLessonStudentShowElement = document.querySelector(
    '#lingolift_collection_lesson_students_show'
  ) as HTMLElement;

  const lingoLiftEnhancedCollectionAscStudentShowElement = document.querySelector(
    '#lingolift_enhanced_collection_asc_students_show',
  )! as HTMLElement;

  const lingoLiftEnhancedCollectionAscLessonStudentShowElement = document.querySelector(
    '#lingolift_collection_asc_lesson_students_show'
  ) as HTMLElement;

  if (lingoLiftEnhancedCollectionsStudentBrowseElement ||
    lingoLiftEnhancedCollectionsTeacherBrowseElement ||
    lingoLiftEnhancedCollectionTeacherShowElement ||
    lingoLiftEnhancedCollectionStudentShowElement ||
    lingoLiftEnhancedCollectionAssignElement ||
    lingoLiftEnhancedCollectionLessonStudentShowElement ||
    lingoLiftEnhancedCollectionAscLessonStudentShowElement ||
    lingoLiftEnhancedCollectionAscStudentShowElement
  ) {
    const root = createRoot(
      lingoLiftEnhancedCollectionsStudentBrowseElement ||
      lingoLiftEnhancedCollectionsTeacherBrowseElement ||
      lingoLiftEnhancedCollectionTeacherShowElement ||
      lingoLiftEnhancedCollectionStudentShowElement ||
      lingoLiftEnhancedCollectionAssignElement ||
      lingoLiftEnhancedCollectionLessonStudentShowElement ||
      lingoLiftEnhancedCollectionAscLessonStudentShowElement ||
      lingoLiftEnhancedCollectionAscStudentShowElement
    );

    if (lingoLiftEnhancedCollectionLessonStudentShowElement || lingoLiftEnhancedCollectionAscLessonStudentShowElement) {
      const { accessToken, partnerKey, dialogId, lessonId, collectionId, languageCode } = selectLingoLiftCollectionLessonParamsFromDomObject(
        (lingoLiftEnhancedCollectionLessonStudentShowElement || lingoLiftEnhancedCollectionAscLessonStudentShowElement) as HTMLElement
      );

      return root.render(
        <ReduxProvider>
          {lingoLiftEnhancedCollectionLessonStudentShowElement && (
            <LingoLiftCollectionsLessonStudentShowPage
              id={lessonId}
              accessToken={accessToken}
              partnerKey={partnerKey}
              dialogId={dialogId}
              collectionId={collectionId}
              languageCode={languageCode}
            />
          )}

          {lingoLiftEnhancedCollectionAscLessonStudentShowElement && (
            <LingoLiftCollectionsLessonStudentShowPage
              id={lessonId}
              accessToken={accessToken}
              partnerKey={partnerKey}
              dialogId={dialogId}
              collectionId={collectionId}
              languageCode={languageCode}
              ecAscId={selectRailsPropAscIdFromHtmlElement(lingoLiftEnhancedCollectionAscLessonStudentShowElement)}
            />
          )}
        </ReduxProvider>
      );
    }

    return root.render(<ReduxProvider>
      { lingoLiftEnhancedCollectionsStudentBrowseElement && <LingoLiftCollectionsStudentBrowsePage/>}
      { lingoLiftEnhancedCollectionsTeacherBrowseElement && <LingoLiftCollectionsTeacherBrowsePage /> }
      { lingoLiftEnhancedCollectionStudentShowElement && <LingoLiftCollectionStudentShowPage
        id={selectRailsPropIdFromHtmlElement(lingoLiftEnhancedCollectionStudentShowElement)}
        currentUrl={selectCurrentUrlFromDomObject(lingoLiftEnhancedCollectionStudentShowElement)} /> }
      { lingoLiftEnhancedCollectionTeacherShowElement && <LingoLiftCollectionTeacherShowPage
        id={selectRailsPropIdFromHtmlElement(lingoLiftEnhancedCollectionTeacherShowElement)}
        currentUrl={selectCurrentUrlFromDomObject(lingoLiftEnhancedCollectionTeacherShowElement)} /> }
      { lingoLiftEnhancedCollectionAssignElement && <LingoLiftCollectionAssignPage
        id={selectRailsPropIdFromHtmlElement(lingoLiftEnhancedCollectionAssignElement)} /> }
      { lingoLiftEnhancedCollectionAscStudentShowElement && <LingoLiftCollectionStudentShowPage
        id={selectRailsPropIdFromHtmlElement(lingoLiftEnhancedCollectionAscStudentShowElement)}
        ecAscId={selectRailsPropAscIdFromHtmlElement(lingoLiftEnhancedCollectionAscStudentShowElement)} /> }
    </ReduxProvider>)
  }
}

export default lingoLiftCollectionsRoutesRender;
