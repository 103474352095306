import React, { type FC, useEffect, useState } from 'react';

import {
  LessonGoBackLine,
  LingoLiftGuide,
  LingoLiftLessonHeader,
  MainLayout,
  RelatedLessons,
} from '../../widgets';

import styles from './styles';
import {
  useGetLingoLiftTeacherLessonDetailsQuery,
  useGetLingoLiftTeacherLessonDetailsStandardsQuery,
} from '../../entities/lingoLiftLesson';
import Div from '../../shared/ui/Div';
import { UserRole } from "../../entities/user";
import { LingoLiftLessonElpDetailsModal } from '../../features/lingoLiftLesson';
import {
  ILingoLiftFavoriteParams,
  useDeleteLingoLiftTeacherFavoriteMutation,
  usePostLingoLiftTeacherAddFavoriteMutation,
} from '../../entities/lingoLiftFavorite';
import { useSnackbar } from '../../shared/hooks/useSnackbar';
import { isTeacherGuideFilled } from '../../widgets/LingoLift/ui/LingoLiftGuide/lib/isTeacherGuideFilled';

interface ILingoLiftLessonTeacherShowProps {
  id: number;
  accessToken: string;
  partnerKey: string;
  dialogId: number;
  edGateUrl: string;
  languageCode: string;
  currentUrl: string;
}

const LingoLiftLessonTeacherShowPage: FC<ILingoLiftLessonTeacherShowProps> = props => {
  const { id, accessToken, partnerKey, dialogId, edGateUrl, languageCode, currentUrl } = props;

  const [isOpenELPModal, setIsOpenElpModal] = useState(false);

  const { showSuccessNotification, showErrorNotification } = useSnackbar();

  const { data: lessonDetails, isLoading, refetch: refreshLessonDetails } = useGetLingoLiftTeacherLessonDetailsQuery(id);

  const [postLingoLiftTeacherToggleFavorite] = usePostLingoLiftTeacherAddFavoriteMutation();

  const [deleteLingoLiftTeacherFavorite] = useDeleteLingoLiftTeacherFavoriteMutation();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (window.ECSDK && lessonDetails) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.ECSDK.loadWidget('player', {
        partnerKey,
        partnerSdkToken: accessToken,
        dialogId,
        container: 'player-container',
        lang: languageCode,
        settings: {
          autoStart: false,
          isComprehensionQuizEnabled: false,
        },
      });
    }
  }, [lessonDetails]);

  const { data: standardsDetails, isLoading: isLoadingStandardsDetails } =  useGetLingoLiftTeacherLessonDetailsStandardsQuery(edGateUrl, {
    skip: !edGateUrl
  });

  const onToggleFavorite = async (params: ILingoLiftFavoriteParams, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await deleteLingoLiftTeacherFavorite(params);
        showSuccessNotification('Removed from favorites');
      } else {
        await postLingoLiftTeacherToggleFavorite(params);
        showSuccessNotification('Added to favorites');
      }
      await refreshLessonDetails();
    } catch (error) {
      showErrorNotification(error.response.data.error);
    }
  }

  return (
    <MainLayout backgroundColor="white" userRole={UserRole.Teacher} isLoading={isLoading}>

      <LingoLiftLessonElpDetailsModal
        isOpen={isOpenELPModal}
        elpContent={lessonDetails?.pageContent.elpModalContent ?? null}
        onClose={() => setIsOpenElpModal(false)} />

      <LessonGoBackLine title="See All Lessons" />
      {lessonDetails && (
        <>
            <LingoLiftLessonHeader
              {...lessonDetails.item}
              userRole={UserRole.Teacher}
              setOpenElpModal={() => setIsOpenElpModal(true)}
              id={id}
              onToggleFavorite={onToggleFavorite}
              favorited={lessonDetails.favorited}
              shareUrl={currentUrl}
            />
            <Div sx={styles.iframe} id="player-container" />
            { isTeacherGuideFilled(lessonDetails?.item.llLessonTeachersGuide?.guides) &&  <LingoLiftGuide
              name={lessonDetails?.item.llLessonTeachersGuide?.name}
              teachersGuide={lessonDetails?.item.llLessonTeachersGuide?.guides}
              standards={standardsDetails ?? []}
              isLoadingStandardsDetails={isLoadingStandardsDetails}
            /> }
            { lessonDetails?.related?.length > 0 && <RelatedLessons related={lessonDetails.related} /> }
        </>
      )}
    </MainLayout>
  );
};

export default LingoLiftLessonTeacherShowPage;
