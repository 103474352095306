import * as React from 'react';
import { Chip } from '@mui/material';
import { FC } from 'react';
import deleteIcon from '../../../../assets/secondaryIcons/deleteIcon.svg';
import Image from '../../../../shared/ui/Image';
import styles from "./styles";
import sx from "mui-sx";

interface ILingoLiftMyLibraryTagProps {
  label: string;
  onSelectTag: () => void;
  selectedTag: string | null;
  onDelete: (id: number) => void;
}

const LingoLiftMyLibraryTag: FC<ILingoLiftMyLibraryTagProps> = (props) => {
  const {
    label,
    onDelete,
    onSelectTag,
    selectedTag
  } = props;
  return <Chip sx={sx(
    styles.container,
    {
      sx: styles.active,
      condition: label === selectedTag
    }
    )}
   deleteIcon={<Image src={deleteIcon} alt="deleteIcon" />}
   label={label}
   onClick={onSelectTag}
   onDelete={onDelete} />
}

export default LingoLiftMyLibraryTag;
