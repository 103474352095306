import Div from '../../../../shared/ui/Div';
import { Autocomplete, Button, IconButton, Popover, TextField, Typography } from '@mui/material';
import styles from './styles';
import { Add, Close } from '@mui/icons-material';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { ILingoLiftTeacherMyLibraryTeacherAssignmentTag } from '../../../../entities/lingoLiftMyLibrary/model/types';
import {
  usePostLingoLiftTeacherMyLibraryLLAssignmentTagMutation,
  usePostLingoLiftTeacherMyLibraryLWAssignmentTagMutation
} from '../../../../entities/lingoLiftMyLibrary';
import { LingoLiftProduct } from "../../../../shared/types/common-enums";

interface ILingoLiftMyLibraryAddTagsPopoverProps {
  tagsList: Array<ILingoLiftTeacherMyLibraryTeacherAssignmentTag>;
  assignableId: number;
  assignableType: string;
  product: LingoLiftProduct;
}

const LingoLiftMyLibraryAddTagsPopover:FC<ILingoLiftMyLibraryAddTagsPopoverProps> = (props) => {
  const { tagsList, assignableType, assignableId, product } = props;
  const [tags, setTags] = React.useState<Array<string>>([]);

  const [onLWAddTag] = usePostLingoLiftTeacherMyLibraryLWAssignmentTagMutation();

  const [onLLAddTag] = usePostLingoLiftTeacherMyLibraryLLAssignmentTagMutation()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    const tagNames = tagsList.map((tag) => tag.name)
    if (tagsList && tagNames.length) {
      setTags(tags.filter((tag) => tagNames.includes(tag)))
    }
  }, [tagsList]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = () => {
    const tag = {
      assignableId,
      assignableType,
      tagsOnAssignables: tags
    }
    if (product === LingoLiftProduct.ListenWise) {
      onLWAddTag(tag).unwrap();
    }

    if (product === LingoLiftProduct.LingoLift) {
      onLLAddTag(tag).unwrap();
    }
    handleClose();
  }
  const tagOptions = tagsList?.map((option) => option.name);

  return <Div>
    <Button
      sx={styles.addTagButton}
      onClick={handleClick}
      startIcon={<Add sx={styles.addTagButtonIcon} />}>
      Add tag
    </Button>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={styles.popoverContainer}
    >
      <Div
        sx={styles.popoverContent}>
        <Div sx={styles.popoverHeader}>
          <Typography variant="h2">
            Add tags
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Div>
        <Div sx={styles.tagsSelectWrapper}>
          <Autocomplete
            multiple
            options={tagOptions}
            defaultValue={[...tags]}
            freeSolo
            autoSelect
            onChange={(_, value) => setTags(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Tags..."
                value={tags}
                sx={styles.tagsInput}
              />
            )}
          />
          <Button onClick={onSubmit}>
            Add Tags
          </Button>
        </Div>
      </Div>
    </Popover>
  </Div>
}

export default LingoLiftMyLibraryAddTagsPopover;
