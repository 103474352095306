const styles = {
  container: {
    backgroundColor: 'white',
    padding: '32px'
  },
  optionSection: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    paddingBottom: '20px',
  },
  divider: {
    border: '0.5px solid',
    borderColor: 'semiGray',
  },
  transparentButton: {
    backgroundColor: 'white',
    color: 'primary.main',
    padding: '5px 10px',
    border: '1px solid',
    borderColor: 'semiGray',
    borderRadius: '8px',
    width: 'max-content',
    '&:hover': {
      backgroundColor: 'secondary.main',
      color: 'white',
    },
  },
};

export default styles;
