const styles = {
  filtersColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    gap: '15px',
  },
  snowButton: {
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: 'semiGray',
    color: 'black',
    width: {
      xs: '100%',
      sm: 'max-content',
    },
    showButtonActiveState: {
      backgroundColor: 'secondary.dark',
    },
  },
};

export default styles;
