const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    backgroundColor: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    padding: '24px',
    borderRadius: '8px',
  },
  category: {
    fontWeight: 600
  },
  smallSection: {
    padding: '5px',
    border: '1px solid black',
    borderCollapse: 'collapse'
  },
  standardSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    padding: '5px',
    minHeight: '100px',
    border: '1px solid black',
    borderCollapse: 'collapse'
  },
  actionsPanel: {
    marginLeft: 'auto'
  }
}

export default styles;
