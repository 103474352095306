const styles = {
  container: {
    width: '100%',
    borderRadius: '12px',
  },
  list: {
    padding: '7px',
    boxSizing: 'border-box',
  },
  menuItem: {
    color: 'primary.dark',
    '&:hover': {
      backgroundColor: 'primary.light',
      borderRadius: '8px',
      '& .MuiTypography-root': {
        color: 'secondary.main',
      },
    },
  },

  menuItemLabel: {
    color: 'primary.dark',
    padding: '6px 16px',
    fontWeight: 600,
    fontSize: '12px',
  },

  menuActiveItem: {
    backgroundColor: 'secondary.light',
    borderRadius: '8px',
    '& .MuiTypography-root': {
      color: 'secondary.main',
    },
  },
  dropDownMenuButton: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '320px',
    backgroundColor: 'white',
    color: 'black',
    border: `1px solid`,
    borderColor: 'semiGray',
    width: {
      xs: '100%',
      md: 'auto',
    },
    marginTop: {
      xs: '10px',
      lg: 0,
    },
    '&:hover': {
      backgroundColor: 'primary.light',
      outline: 'none',
      borderColor: 'semiGray',
    },
    '&:focus': {
      backgroundColor: 'transparent',
      border: `1px solid`,
      borderColor: 'semiBlue',
    },
  },
  dropDownButtonIcon: {
    fontSize: '25px',
  },
  dropDownList: {
    '& .MuiPaper-root': {
      minWidth: '320px',
      width: {
        xs: '100%',
        md: 'auto',
      },
      borderRadius: '12px',
    },
  },
};

export default styles;
