import { CircularProgress } from '@mui/material';
import Div from '../Div';
import React, { FC } from 'react';
import styles from './styles';
import sx from 'mui-sx';

interface ILoaderProps {
  position?: 'start' | 'default';
}

const Loader: FC<ILoaderProps> = props => {
  const { position = 'default' } = props;
  return (
    <Div
      sx={sx(styles.loader, {
        sx: styles.loaderAlignStart,
        condition: position === 'start',
      })}
    >
      <CircularProgress />
    </Div>
  );
};

export default Loader;
