const styles = {
  root: {
    borderRadius: '5px',
    background: 'white',
    padding: '10px',
    color: 'primary.dark',
    border: '1px solid',
    borderColor: 'semiGray',
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
  },
  expandedRoot: {
    backgroundColor: 'secondary.dark',
  },
  text: {
    color: 'white',
  },
  title: {
    marginBottom: '5px',
    fontWeight: 600,
    color: 'primary.dark',
    '&::first-letter': {
      textTransform: 'capitalize',
    },
  },
  statistics: {
    color: 'black',
    fontWeight: 450,
    marginBottom: '5px',
  },
  icon: {
    width: '40px',
    height: '40px',
    marginTop: '13px',
    '& svg path': {
      fill: 'secondary.dark',
    },
  },
  button: {
    fontSize: '12px',
    fontWeight: 600,
    color: 'secondary.dark',
    cursor: 'pointer',
    marginTop: 'auto',
  },
  rootContentWrapper: {
    display: 'flex',
    flexDirection: 'column'
  }
};

export default styles;
