export const composePrimitiveValues = (array: Array<string | number> = [], value: string | number) => {
  if (array.includes(value)) {
    return array.filter(param => param !== value);
  }
  return [...array, value];
};

export const composeFilledParams = (params: Record<string, string | number | Array<string | number>>) => {
  for (let key in params) {
    if (!params[key].toString().length) {
      delete params[key];
    }
  }

  return params;
};
