const styles = {
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  iconWrap: {
    alignSelf: 'stretch',
    width: '30px',
    minHeight: '30px',
    background: 'green',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    borderTopLeftRadius: 'inherit',
    borderBottomLeftRadius: 'inherit',
  },
  text: {
    display: 'block',
    padding: '5px 10px',
    fontWeight: 400,
    fontSize: '14px',
    textDecoration: 'none',
  },
  errorWrap: {
    background: 'red',
    '& svg': {
      height: 'auto',
    },
  },
};

export default styles;
