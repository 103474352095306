import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';

import ContentLayout from '../../shared/ui/ContentLayout';
import SideBar from '../../shared/ui/SideBar';
import PaginationLine from '../../shared/ui/PaginationLine';

import { MainLayout } from '../../widgets';
import { selectCategoryValueFromObject } from '../../shared/utils/selectors';
import {
  LingoLiftCollectionCard,
  useGetLingoLiftTeacherCollectionsFilterQuery,
  useGetLingoLiftTeacherCollectionsQuery,
} from '../../entities/lingoLiftCollection';
import { UserRole } from "../../entities/user";
import { parseSingleQueryFromUrl } from "../../shared/utils/parseQueriesFromUrl";

const LingoLiftCollectionsTeacherBrowsePage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectCategory] = useState<Record<string, string>>({});
  const [expandedCollection, setExpandedCollection] = useState<null | number>(null);

  const activeQuery = parseSingleQueryFromUrl()

  const { data: collections, isLoading } = useGetLingoLiftTeacherCollectionsQuery({
    page: currentPage,
    ...selectedCategory,
    ...activeQuery,
  });

  const { data: filterList, isLoading: isFiltersLoading } = useGetLingoLiftTeacherCollectionsFilterQuery();

  return (
    <MainLayout userRole={UserRole.Teacher} isLoading={isLoading}>
      <ContentLayout>
        <Grid container columnSpacing={{ lg: 2, md: 2 }} rowGap={{ lg: 0, xs: 2 }}>
          <Grid item lg={2} xs={12}>
            <SideBar
              allFiltersName={filterList?.resetFilterLabel ?? ''}
              filterOptions={filterList?.options ?? null}
              onSelectCategoryValue={setSelectCategory}
              selectedCategoryValue={selectCategoryValueFromObject(selectedCategory)}
              isLoading={isFiltersLoading}
            />
          </Grid>

          <Grid item lg={10} xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                { collections?.pageContent && <Typography variant="h1">{ collections?.pageContent.header }</Typography> }
              </Grid>
              {collections?.items?.map(collection => (
                <Grid key={collection.id} item xs={12}>
                  <LingoLiftCollectionCard
                    {...collection}
                    onToggleExpandCollection={setExpandedCollection}
                    expandedCollection={expandedCollection}
                  />
                </Grid>
              ))}
            </Grid>

            {collections && collections?.pagination.totalPages > 0 && (
              <PaginationLine
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pagination={collections?.pagination}
              />
            )}
          </Grid>
        </Grid>
      </ContentLayout>
    </MainLayout>
  );
};

export default LingoLiftCollectionsTeacherBrowsePage;
