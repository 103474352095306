const apiRoutes = {
  lingoLiftStudentLessonsPath: () => `/api/v2/lingolift/students/all_lessons`,
  lingoLiftTeacherLessonsPath: () => `/api/v2/lingolift/teachers/all_lessons`,
  lingoLiftStudentLessonsDetailsPath: (id: number) => `/api/v2/lingolift/students/ll_lessons/${id}`,
  lingoLiftTeacherLessonsDetailsPath: (id: number) => `/api/v2/lingolift/teachers/ll_lessons/${id}`,
  lingoLiftFooterPath: () => `/api/v2/lingolift/footer`,
  lingoLiftStudentHeaderPath: () => `/api/v2/lingolift/students/header_menu`,
  lingoLiftTeacherHeaderPath: () => `/api/v2/lingolift/teachers/header_menu`,
  lingoLiftStudentLessonsFilterPath: () => `/api/v2/lingolift/students/all_lessons/filter_options`,
  lingoLiftTeacherLessonsFilterPath: () => `/api/v2/lingolift/teachers/all_lessons/filter_options`,
  lingoLiftTeacherCollectionsPath: () => `/api/v2/lingolift/teachers/enhanced_collections`,
  lingoLiftStudentCollectionsPath: () => `/api/v2/lingolift/students/enhanced_collections`,
  lingoLiftStudentCollectionsFilterPath: () => `/api/v2/lingolift/students/all_lessons/filter_options`,
  lingoLiftTeacherCollectionsFilterPath: () => `/api/v2/lingolift/teachers/enhanced_collections/filter_options`,
  lingoLiftTeacherCollectionDetailsPath: (id: number) => `/api/v2/lingolift/teachers/enhanced_collections/${id}`,
  lingoLiftStudentCollectionDetailsPath: (id: number, ecAscId?: number) => `/api/v2/lingolift/students/enhanced_collections/${ ecAscId ? `${id}?ec_asc_id=${ecAscId}` : id}`,
  lingoLiftTeacherSearchFilterPath: () => `/api/v2/lingolift/teachers/search/filter_options`,
  lingoLiftTeacherSearchPath: () => `/api/v2/lingolift/teachers/search`,
  lingoLiftStudentSearchFilterPath: () => `/api/v2/lingolift/students/search/filter_options`,
  lingoLiftStudentSearchPath: () => `/api/v2/lingolift/students/search`,
  lingoLiftTeacherLLClassesPath: () => `/api/v2/lingolift/teachers/ll_school_classes`,
  lingoLiftTeacherLWClassesPath: () => `/api/v2/lingolift/teachers/school_classes`,
  lingoLiftTeacherLLClassPath: (id: number) => `/api/v2/lingolift/teachers/ll_school_classes/${id}`,
  lingoLiftTeacherLWClassPath: (id: number) => `/api/v2/lingolift/teachers/school_classes/${id}`,
  lingoLiftTeacherLLStudentPath: (id: number) => `/api/v2/lingolift/teachers/ll_students/${id}`,
  lingoLiftTeacherLWStudentPath: (id: number) => `/api/v2/lingolift/teachers/students/${id}`,
  lingoLiftLLClassStudentPath: () => `/api/v2/lingolift/teachers/ll_class_students`,
  lingoLiftLWClassStudentPath: () => `/api/v2/lingolift/teachers/class_students`,
  lingoLiftEdGateStandardsPath: () => `ed_gate_standards`,
  lingoLiftStudentEnhancedCollectionLessonPath: (enhancedCollectionId: number, id: number) => `/api/v2/lingolift/students/enhanced_collections/${enhancedCollectionId}/ll_lessons/${id}`,
  lingoLiftStudentEnhancedCollectionAssignmentCompletionsPath: () => '/api/v2/lingolift/students/ll_collection_assignment_completions',
  lingoLiftStudentEnhancedCollectionAssignmentCompletionPath: (id: number) => `/api/v2/lingolift/students/ll_collection_assignment_completions/${id}`,
  lingoLiftLWClassRoomImportPath: () => '/api/v2/lingolift/teachers/google_classroom_courses/batch_update',
  lingoLiftLLClassRoomImportPath: () => '/api/v2/lingolift/teachers/ll_google_classroom_courses/batch_update',
  lingoLiftPublicHeaderPath: () => '/api/v2/lingolift/public_header_menu',
  lingoLiftTeacherFavoritesAddTagPath: (id: number) => `/api/v2/lingolift/teachers/favorites/${id}/tag`,
  lingoLiftTeacherFavoritesUntagPath: (id: number) => `/api/v2/lingolift/teachers/favorites/${id}/untag`,
  lingoLiftToggleFavoritePath: () => `/api/v2/lingolift/teachers/favorites`,
  lingoLiftAddFavoritePath: () => `/api/v2/lingolift/teachers/favorites`,
  lingoLiftDeleteFavoritePath: (id: number) => `/api/v2/lingolift/teachers/favorites/${id}`,
  lingoLiftIsFavoritePath: (id: number) => `/api/v2/lingolift/teachers/favorites/${id}`,
  lingoLiftDeleteFavoriteByParamsPath: () => '/api/v2/lingolift/teachers/favorites/destroy_by_params',
  lingoLiftTeacherDashboardFeedPath: () => '/api/v2/lingolift/teachers/dashboard/feed',
  lingoLiftTeacherDashboardFilterPath: () => '/api/v2/lingolift/teachers/dashboard/feed/filter_options',
  lingoLiftTeacherDashboardTasksPath: () => '/api/v2/lingolift/teachers/dashboard/tasks',
  lingoLiftTeacherDashboardTaskHidePath: () => '/api/v2/lingolift/teachers/dashboard/tasks/hide',
  lingoLiftTeacherDashboardSchoolClassesPath: () => '/api/v2/lingolift/teachers/dashboard/all_school_classes',
  lingoLiftTeacherLessonAssignmentSchoolClassesPath: () => '/api/v2/lingolift/teachers/ll_lesson_assignment_school_classes',
  lingoLiftTeacherLessonAssignmentUpdateSchoolClassesPath: () => '/api/v2/lingolift/teachers/ll_lesson_assignment_school_classes/batch_update',
  lingoLiftTeacherCollectionAssignmentSchoolClassesPath: () => '/api/v2/lingolift/teachers/ll_enhanced_collection_assignment_school_classes',
  lingoLiftTeacherCollectionAssignmentUpdateSchoolClassesPath: () => '/api/v2/lingolift/teachers/ll_enhanced_collection_assignment_school_classes/batch_update',
  lingoLiftStudentDashboardJoinClass: () => '/api/v2/lingolift/students/ll_class_students',
  lingoLiftFavoritesTagsIndexPath: () => '/api/v2/lingolift/teachers/favorites/tags_index',
  lingoLiftDeleteFavoriteTagPath: () => '/api/v2/lingolift/teachers/favorites/destroy_tag',
  lingoLiftStudentDashboardJoinClassPath: () => '/api/v2/lingolift/students/ll_class_students',
  lingoLiftStudentLLClassesPath: () => '/api/v2/lingolift/students/ll_school_classes',
  lingoLiftStudentLWClassesPath: () => '/api/v2/lingolift/students/school_classes',
  lingoLiftStudentLWEvents: () => '/api/v2/lingolift/students/events',
  lingoLiftStudentLLClassPath: () => '/api/v2/lingolift/students/ll_class_students',
  lingoLiftStudentLWClassPath: () => '/api/v2/lingolift/students/class_students',
  lingoLiftStudentProfileLanguage: () => '/api/v2/lingolift/students/profile/languages_index',
  lingoLiftStudentProfileLanguageUpdate: () => '/api/v2/lingolift/students/profile/language_update',
  lingoLiftStudentUpComingLWAssignments: () => '/api/v2/lingolift/students/dashboard/assignments_quizzes',
  lingoLiftStudentUpComingLLAssignments: () => '/api/v2/lingolift/students/dashboard/ll_assignments',
  lingoLiftStudentProgressReportsPath: () => '/api/v2/lingolift/students/dashboard/progress_reports',
  lingoLiftTeacherRecentAssignmentsPath: () => '/api/v2/lingolift/teachers/recent_assignments',
  lingoLiftTeacherRecentLLAssignmentsPath: () => '/api/v2/lingolift/teachers/recent_ll_assignments',
  lingoLiftTeacherMyLibraryLWAssignmentsPath: () => '/api/v2/lingolift/teachers/my_library/assignments',
  lingoLiftTeacherMyLibraryLWAssignmentTagsPath: () => '/api/v2/lingolift/teachers/my_library/assignments/tags_index',
  lingoLiftTeacherMyLibraryLWAssignmentTagPath: () => '/api/v2/lingolift/teachers/my_library/assignments/tag',
  lingoLiftTeacherMyLibraryLWAssignmentDeleteTagPath: () => '/api/v2/lingolift/teachers/my_library/assignments/destroy_tag',
  lingoLiftTeacherMyLibraryLWAssignmentUnTagPath: () => '/api/v2/lingolift/teachers/my_library/assignments/untag',
  lingoLiftTeacherMyLibraryLLAssignmentsPath: () => '/api/v2/lingolift/teachers/my_library/ll_assignments',
  lingoLiftTeacherMyLibraryLLAssignmentTagsPath: () => '/api/v2/lingolift/teachers/my_library/ll_assignments/tags_index',
  lingoLiftTeacherMyLibraryLLAssignmentTagPath: () => '/api/v2/lingolift/teachers/my_library/ll_assignments/tag',
  lingoLiftTeacherMyLibraryLLAssignmentDeleteTagPath: () => '/api/v2/lingolift/teachers/my_library/ll_assignments/destroy_tag',
  lingoLiftTeacherMyLibraryLLAssignmentUnTagPath: () => '/api/v2/lingolift/teachers/my_library/ll_assignments/untag',
  lingoLiftGoogleClassRoomImportLLStudents: () => '/api/v2/lingolift/teachers/ll_google_classroom_courses/import_students',
  lingoLiftGoogleClassRoomImportLWStudents: () => '/api/v2/lingolift/teachers/google_classroom_courses/import_students',
  lingoLiftStudentCollectionAscDetailsPath: (ecAscId: number, id: number) => `/api/v2/lingolift/students/ec_ascs/${ecAscId}/ll_lessons/${id}`,
  lingoLiftStudentSummaryReportsPath: () => '/api/v2/lingolift/students/reports',
  lingoLiftStudentSummaryAllReportsPath: () => '/api/v2/lingolift/students/reports/all',
  lingoLiftClassesSummaryStudentReportPath: (llSchoolClassId: string, studentId: string) => `/api/v2/lingolift/teachers/ll_school_classes/${llSchoolClassId}/students/${studentId}/report`,
  lingoLiftClassesSummaryStudentAllReportsPath: (llSchoolClassId: string, studentId: string) => `/api/v2/lingolift/teachers/ll_school_classes/${llSchoolClassId}/students/${studentId}/all`,
  lingoLiftTeacherClassLLReportPath: (id: number) => `/api/v2/lingolift/teachers/ll_school_classes/${id}/report`,
  lingoLiftTeacherClassLLAscReportPath: (llLessonAscId: string, llSchoolClassId: string) => `/api/v2/lingolift/teachers/ll_school_classes/${llSchoolClassId}/ll_lesson_ascs/${llLessonAscId}/report`,
  lingoLiftTeacherClassLLEcAscReportPath: (llEcAscId: string, llSchoolClassId: string) => `/api/v2/lingolift/teachers/ll_school_classes/${llSchoolClassId}/ll_ec_ascs/${llEcAscId}/report`,
  lingoLiftTeacherClassesLLRecentAssignmentsReportPath: (id: string) => `/api/v2/lingolift/teachers/ll_school_classes/${id}/recent_assignments`,
  lingoLiftTeacherClassesLLPastAssignmentsReportPath: (id: string) => `/api/v2/lingolift/teachers/ll_school_classes/${id}/past_assignments`,
  lingoLiftTeacherClassesLWRecentAssignmentsReportPath: (id: string) => `/api/v2/lingolift/teachers/school_classes/${id}/recent_assignments`,
  lingoLiftTeacherClassesLWPastAssignmentsReportPath: (id: string) => `/api/v2/lingolift/teachers/school_classes/${id}/past_assignments`,
  lingoLiftTeacherClassesLWStudentProgressPath: () => `/api/v2/lingolift/teachers/students_progress_reports`,
  lingoLiftTeacherDashboardLWReportsPath: () => '/api/v2/lingolift/teachers/dashboard/lw_reports',
  lingoLiftTeacherDashboardLLReportsPath: () => '/api/v2/lingolift/teachers/dashboard/reports',
  lingoLiftTeacherDashboardLLReportClassesPath: () => '/api/v2/lingolift/teachers/dashboard/reports/ll_school_classes',
  lingoLiftTeacherClassesLLStudentProgressPath: () => `/api/v2/lingolift/teachers/ll_students_progress_reports`,
};

export default apiRoutes;
