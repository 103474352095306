import { selectResponse } from '../../../shared/utils/selectFromResponseObjects';
import { apiContainer, apiRoutes } from '../../../shared/store';
import {
  ILingoLiftManageClass,
  IPaginatedLingoLiftManageClassesResponse,
  ILingoLiftLessonAssignmentResponse,
  ILingoLiftStudentClassRequest,
  ILingoLiftStudentClassResponse,
  ILingoLiftTeacherClassesRecentAssignmentsResponse,
  ILingoLiftTeacherClassesSummaryLLReport,
  ILingoLiftTeacherClassesSummaryLLAscReport,
  ILingoLiftTeacherClassesSummaryLLEcAscReport,
  ILingoLiftTeacherClassesRecentPastAssignmentsReport,
  ILingoLiftTeacherClassesStudentProgressResponse,
} from '../module/types';
import { CacheTags } from '../../../shared/store/commonEnums';

export const lingoLiftClassesApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLingoLiftManageLLClassesList: builder.query<IPaginatedLingoLiftManageClassesResponse, void>({
      query: () => ({
        url: apiRoutes.lingoLiftTeacherLLClassesPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.ManageClassList, id: 'LIST' }, { type: CacheTags.ClassesAssignment, id: 'LIST' }]
    }),
    getLingoLiftManageLWClassesList: builder.query<IPaginatedLingoLiftManageClassesResponse, void>({
      query: () => ({
        url: apiRoutes.lingoLiftTeacherLWClassesPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.ManageClassList, id: 'LIST' }]
    }),
    getLingoLiftManageLLClass: builder.query<ILingoLiftManageClass, number>({
      query: id => ({
        url: apiRoutes.lingoLiftTeacherLLClassPath(id),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.ManageClassList, id: 'LIST' }]
    }),
    getLingoLiftManageLWClass: builder.query<ILingoLiftManageClass, number>({
      query: id => ({
        url: apiRoutes.lingoLiftTeacherLWClassPath(id),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherLessonAssignmentSchoolClasses: builder.query<ILingoLiftLessonAssignmentResponse, { llLessonId: number }>({
      query: params => ({
        url: apiRoutes.lingoLiftTeacherLessonAssignmentSchoolClassesPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.ClassesAssignment, id: 'LIST' }],
    }),
    patchLingoLiftTeacherLessonAddAssignmentSchoolClasses: builder.mutation<Record<string, string>, any>({
      query: ({ params, body }) => ({
        url: apiRoutes.lingoLiftTeacherLessonAssignmentUpdateSchoolClassesPath(),
        method: 'PATCH',
        params,
        data: body,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.ClassesAssignment, id: 'LIST' }],
    }),
    getLingoLiftStudentLLClasses: builder.query<ILingoLiftStudentClassResponse, null>({
      query: () => ({
        url: apiRoutes.lingoLiftStudentLLClassesPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.StudentLLClassList, id: 'LIST' }]
    }),
    getLingoLiftStudentLWClasses: builder.query<ILingoLiftStudentClassResponse, null>({
      query: () => ({
        url: apiRoutes.lingoLiftStudentLWClassesPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.StudentLWClassList, id: 'LIST' }]
    }),
    patchLingoLiftStudentLLClasses: builder.mutation<
      { hidden: boolean },
      ILingoLiftStudentClassRequest
    >({
      query: ({ data, params }) => ({
        url: apiRoutes.lingoLiftStudentLLClassPath(),
        method: 'PATCH',
        params,
        data
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [
        { type: CacheTags.StudentLLClassList, id: 'LIST' },
        { type: CacheTags.StudentDashboardAssignmentQuizList, id: 'LIST' }
      ]
    }),
    patchLingoLiftStudentLWClasses: builder.mutation<
      { hidden: boolean },
      ILingoLiftStudentClassRequest
    >({
      query: ({ data, params }) => ({
        url: apiRoutes.lingoLiftStudentLWClassPath(),
        method: 'PATCH',
        data,
        params,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [
        { type: CacheTags.StudentLWClassList, id: 'LIST' },
        { type: CacheTags.StudentDashboardAssignmentQuizList, id: 'LIST' }
      ]
    }),
    getLingoLiftTeacherLWClassesRecentAssignmentsList: builder.query<ILingoLiftTeacherClassesRecentAssignmentsResponse, void>({
      query: () => ({
        url: apiRoutes.lingoLiftTeacherRecentAssignmentsPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.ClassesAssignment, id: 'LIST' }]
    }),
    getLingoLiftTeacherLLClassesRecentAssignmentsList: builder.query<ILingoLiftTeacherClassesRecentAssignmentsResponse, void>({
      query: () => ({
        url: apiRoutes.lingoLiftTeacherRecentLLAssignmentsPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.ClassesAssignment, id: 'LIST' }]
    }),
    patchLingoLiftGoogleClassRoomImportLLStudents: builder.mutation<
      void,
      {
        llSchoolClassId: number
      }
    >({
      query: (data) => ({
        url: apiRoutes.lingoLiftGoogleClassRoomImportLLStudents(),
        method: 'PATCH',
        data,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [
        { type: CacheTags.ManageClassList, id: 'LIST' },
      ]
    }),
    patchLingoLiftGoogleClassRoomImportLWStudents: builder.mutation<
      void,
      {
        schoolClassId: number
      }
    >({
      query: (data) => ({
        url: apiRoutes.lingoLiftGoogleClassRoomImportLWStudents(),
        method: 'PATCH',
        data,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [
        { type: CacheTags.ManageClassList, id: 'LIST' }
      ]
    }),
    getLingoLiftTeacherClassesSummaryLLReport: builder.query<ILingoLiftTeacherClassesSummaryLLReport, number>({
      query: (id) => ({
        url: apiRoutes.lingoLiftTeacherClassLLReportPath(id),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherClassesSummaryLLAscReport: builder.query<ILingoLiftTeacherClassesSummaryLLAscReport, { llLessonAscId: string, llSchoolClassId: string }>({
      query: ({ llLessonAscId, llSchoolClassId }) => ({
        url: apiRoutes.lingoLiftTeacherClassLLAscReportPath(llLessonAscId, llSchoolClassId),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherClassesSummaryLLEcAscReport: builder.query<ILingoLiftTeacherClassesSummaryLLEcAscReport, { llEcAscId: string, llSchoolClassId: string }>({
      query: ({ llEcAscId, llSchoolClassId }) => ({
        url: apiRoutes.lingoLiftTeacherClassLLEcAscReportPath(llEcAscId, llSchoolClassId),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherClassesLLRecentAssignmentsReport: builder.query<ILingoLiftTeacherClassesRecentPastAssignmentsReport, string>({
      query: (id) => ({
        url: apiRoutes.lingoLiftTeacherClassesLLRecentAssignmentsReportPath(id),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherClassesLLPastAssignmentsReport: builder.query<ILingoLiftTeacherClassesRecentPastAssignmentsReport, string>({
      query: (id) => ({
        url: apiRoutes.lingoLiftTeacherClassesLLPastAssignmentsReportPath(id),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherClassesLWRecentAssignmentsReport: builder.query<ILingoLiftTeacherClassesRecentPastAssignmentsReport, string>({
      query: (id) => ({
        url: apiRoutes.lingoLiftTeacherClassesLWRecentAssignmentsReportPath(id),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherClassesLWPastAssignmentsReport: builder.query<ILingoLiftTeacherClassesRecentPastAssignmentsReport, string>({
      query: (id) => ({
        url: apiRoutes.lingoLiftTeacherClassesLWPastAssignmentsReportPath(id),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherClassesLWStudentProgress: builder.query<ILingoLiftTeacherClassesStudentProgressResponse, void>({
      query: () => ({
        url: apiRoutes.lingoLiftTeacherClassesLWStudentProgressPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherClassesLLStudentProgress: builder.query<ILingoLiftTeacherClassesStudentProgressResponse, void>({
      query: () => ({
        url: apiRoutes.lingoLiftTeacherClassesLLStudentProgressPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
  }),
});

export const {
  useGetLingoLiftManageLLClassesListQuery,
  useGetLingoLiftManageLLClassQuery,
  useGetLingoLiftManageLWClassesListQuery,
  useGetLingoLiftManageLWClassQuery,
  useGetLingoLiftTeacherLessonAssignmentSchoolClassesQuery,
  usePatchLingoLiftTeacherLessonAddAssignmentSchoolClassesMutation,
  useGetLingoLiftStudentLLClassesQuery,
  useGetLingoLiftStudentLWClassesQuery,
  usePatchLingoLiftStudentLLClassesMutation,
  usePatchLingoLiftStudentLWClassesMutation,
  useGetLingoLiftTeacherLWClassesRecentAssignmentsListQuery,
  useGetLingoLiftTeacherLLClassesRecentAssignmentsListQuery,
  usePatchLingoLiftGoogleClassRoomImportLLStudentsMutation,
  usePatchLingoLiftGoogleClassRoomImportLWStudentsMutation,
  useGetLingoLiftTeacherClassesSummaryLLReportQuery,
  useGetLingoLiftTeacherClassesSummaryLLAscReportQuery,
  useGetLingoLiftTeacherClassesSummaryLLEcAscReportQuery,
  useGetLingoLiftTeacherClassesLLRecentAssignmentsReportQuery,
  useGetLingoLiftTeacherClassesLLPastAssignmentsReportQuery,
  useGetLingoLiftTeacherClassesLWRecentAssignmentsReportQuery,
  useGetLingoLiftTeacherClassesLWPastAssignmentsReportQuery,
  useGetLingoLiftTeacherClassesLWStudentProgressQuery,
  useGetLingoLiftTeacherClassesLLStudentProgressQuery
} = lingoLiftClassesApi;
