import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import styles from './styles';
import { MoreHorizRounded } from '@mui/icons-material';
import React, { FC } from 'react';
import ConfirmModalButton from "../../../../shared/ui/ConfirmButton";

interface ILingoLiftManageClassChildItemDropDownMenuProps {
  id: number;
  firstName: string;
  lastName: string;
  setCurrentStudentId: (id: number | null) => void;
  onDeleteStudent: () => void;
}

const LingoLiftManageClassChildItemDropDownMenu: FC<ILingoLiftManageClassChildItemDropDownMenuProps> = props => {
  const { id, firstName, lastName, setCurrentStudentId, onDeleteStudent } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHorizRounded sx={styles.dotedIcon} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={styles.dotedMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem>
          <Typography
            onClick={() => {
              setCurrentStudentId(id);
              handleClose();
            }}
          >
            Reset password
          </Typography>
        </MenuItem>
       
        <ConfirmModalButton
          onSubmit={() => {
            onDeleteStudent();
            handleClose();
          }}
          handleCancel={handleClose}
          buttonTitle="Remove student"
          description="Are you sure to remove this student?"
          title={`Delete the student: ${firstName}, ${lastName}`}
          type="item" />
      </Menu>
    </>
  );
};

export default LingoLiftManageClassChildItemDropDownMenu;
