import React, { type FC, useState } from 'react';
import { Link, Typography, Grid } from '@mui/material';

import styles from './styles';
import Div from '../../../../shared/ui/Div';
import Image from '../../../../shared/ui/Image';
import sx from 'mui-sx';
import { LingoLiftSummaryAssignedItemStatus, LingoLiftSummaryType } from '../../model/enums';
import LingoLiftDashboardSummaryProgressCard from './ProgressCard';
import ProgressDetails from './ProgressDetails';
import {
  ILingoLiftStudentSummaryAssignedItem,
} from '../../model/types';

interface IVideoCardProps extends ILingoLiftStudentSummaryAssignedItem {}

const VideoCard: FC<IVideoCardProps> = props => {
  const {
    name,
    status,
    photoSrc,
    url,
    report,
  } = props;


  const [isExpandedWatchCard, setExpandedWatchCard] = useState<boolean>(false);
  const [isExpandedLearnCard, setExpandedLearnCard] = useState<boolean>(false);
  const [isExpandedSpeakCard, setExpandedSpeakCard] = useState<boolean>(false);
  const [type, setType] = useState<string>(LingoLiftSummaryType.watch);

  return (
    <Div sx={styles.root}>
      <Grid container spacing={4} justifyContent="space-between">
        <Grid item xs={1}>
          <Image sx={styles.video} src={photoSrc} alt="Listenwise video" />
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h4" sx={styles.title}>
            {name}
          </Typography>
          <Link href={url} variant="body2" sx={styles.link}>
            View video
          </Link>
        </Grid>
        <Grid item xs={1}>
          <Div
            sx={sx(
              {
                ...styles.status,
              },
              {
                sx: styles.statusCompleted,
                condition: status === LingoLiftSummaryAssignedItemStatus.Completed,
              },
              {
                sx: styles.statusStarted,
                condition: status === LingoLiftSummaryAssignedItemStatus.Started,
              },
            )}
          >
            <Typography variant="h5" sx={styles.status}>
              {status}
            </Typography>
          </Div>
        </Grid>
        <Grid item xs={5}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item xs={4}>
              <LingoLiftDashboardSummaryProgressCard
                type={LingoLiftSummaryType.watch}
                statistic={report.watchedVideosOfTotalLabel}
                onToggleExpand={setExpandedWatchCard}
                disabledExpand={true}
                isExpanded={isExpandedWatchCard}
                setType={setType}
                onCloseCard={() => {
                  setExpandedLearnCard(false);
                  setExpandedSpeakCard(false);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <LingoLiftDashboardSummaryProgressCard
                type={LingoLiftSummaryType.learn}
                statistic={report.learnQuestionsCorrectOfTotalLabel}
                onToggleExpand={setExpandedLearnCard}
                disabledExpand={false}
                isExpanded={isExpandedLearnCard}
                setType={setType}
                onCloseCard={() => {
                  setExpandedWatchCard(false);
                  setExpandedSpeakCard(false);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <LingoLiftDashboardSummaryProgressCard
                type={LingoLiftSummaryType.speak}
                statistic={`${report.spokenAverageGradePercent ? report.spokenAverageGradePercent : 0}%`}
                onToggleExpand={setExpandedSpeakCard}
                disabledExpand={false}
                isExpanded={isExpandedSpeakCard}
                setType={setType}
                onCloseCard={() => {
                  setExpandedWatchCard(false);
                  setExpandedLearnCard(false);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isExpandedWatchCard && (
        <ProgressDetails
          watchedQuestionsWithAnswers={report?.watchedQuestionsWithAnswers}
          onToggleExpand={setExpandedWatchCard}
          isExpanded={isExpandedWatchCard}
          type={type}
        />
      )}
      {isExpandedLearnCard && (
        <ProgressDetails
          learnQuestionWithAnswers={report.learnQuestionsWithAnswers}
          onToggleExpand={setExpandedLearnCard}
          isExpanded={isExpandedLearnCard}
          type={type}
        />
      )}
      {isExpandedSpeakCard && (
        <ProgressDetails
          spokenDialogLines={report.spokenDialogLines}
          onToggleExpand={setExpandedSpeakCard}
          isExpanded={isExpandedSpeakCard}
          type={type}
        />
      )}
    </Div>
  );
};

export default VideoCard;
