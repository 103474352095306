import React, { FC } from 'react';
import Div from '../../../../../shared/ui/Div';
import styles from './styles';
import { Typography } from '@mui/material';
import { ILingoLiftLessonStandardItem } from '../../../model/types';
import { isEmpty } from "ramda";

interface IStandardChildItemProps {
  standards: Array<ILingoLiftLessonStandardItem & {
    standards?: Array<ILingoLiftLessonStandardItem>
  }>;
  isContent: boolean;
}

const StandardChildItem: FC<IStandardChildItemProps> = ({ standards, isContent = true }) => {
  return <Div sx={styles.container}>
    { standards.map((standardChild, index) =>  <Div
      sx={styles.standardChildItemContainer}
      key={standardChild.id}>

      <Typography variant="h3">
        { standardChild.label }
      </Typography>

      { isContent && <Typography sx={styles.content}>
        {standardChild.content}
      </Typography> }

      { standardChild.standards && !isEmpty(standardChild.standards) && <StandardChildItem
        key={index}
        isContent
        standards={standardChild.standards} /> }

    </Div>) }
  </Div>
}

export default StandardChildItem;
