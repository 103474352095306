import React, { FC, useEffect } from 'react';
import Div from '../../../../shared/ui/Div';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  useGetLingoLiftManageLLClassQuery,
  useGetLingoLiftManageLWClassQuery,
} from '../../../../entities/lingoliftClasses';
import styles from './styles';
import { Controller, useForm } from 'react-hook-form';
import { usePatchLingoLiftManageLLClassMutation, usePatchLingoLiftManageLWClassMutation } from '../../api';
import { ILingoLiftManageClassUpdate } from '../../model/types';
import { ModalClassType } from '../../model/enums';
import { HelpOutlineRounded } from '@mui/icons-material';

interface ILingoLiftClassesManageClassUpdateClassModalFormProps {
  id: number | null;
  onClose: () => void;
  type: ModalClassType;
}

const LingoLiftClassesManageClassUpdateClassModalForm: FC<ILingoLiftClassesManageClassUpdateClassModalFormProps> = props => {
  const { id, onClose, type } = props;

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [onUpdateLLClass, { isSuccess: isLLClassSuccess }] = usePatchLingoLiftManageLLClassMutation();

  const [onUpdateLWClass, { isSuccess: isLWClassSuccess }] = usePatchLingoLiftManageLWClassMutation();

  const { data: manageLLClass } = useGetLingoLiftManageLLClassQuery(id as number, {
    skip: type !== ModalClassType.LL || !Boolean(id)
  });

  const { data: manageLWClass } = useGetLingoLiftManageLWClassQuery(id as number, {
    skip: type !== ModalClassType.LW || !Boolean(id)
  });

  useEffect(() => {
    if (manageLLClass) {
      setValue('name', manageLLClass.name);
    }

    if (manageLWClass) {
      reset(manageLWClass)
    }
  }, [manageLLClass, manageLWClass]);

  useEffect(() => {
    if (isLLClassSuccess || isLWClassSuccess) {
      onClose();
    }
  }, [isLLClassSuccess, isLWClassSuccess]);

  const onSubmit = (data: ILingoLiftManageClassUpdate) => {
    if (id && type === ModalClassType.LL) {
      onUpdateLLClass({
        id,
        data,
      });
    }

    if (id && type === ModalClassType.LW) {
      onUpdateLWClass({
        id,
        data,
      });
    }
  };

  return (
    <Modal open={Boolean(id)} onClose={onClose} closeAfterTransition>
      <Div sx={styles.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3">Class settings</Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Div sx={styles.formContainer}>
                <Div sx={styles.formItem}>
                  <Div
                    sx={{
                      ...styles.formItemLabel,
                      ...styles.formItemLabelRequiredSymbol,
                    }}
                  >
                    <Typography variant="h5">Class name</Typography>
                  </Div>
                  <TextField
                    type="text"
                    {...register('name', { required: true })}
                    error={!!errors.name}
                    helperText={errors.name ? 'Required' : ''}
                    placeholder="Enter class name"
                    fullWidth
                  />

                  { (type === ModalClassType.LW && manageLWClass) &&
                    <FormGroup>
                      <Div sx={styles.itemWithTooltip}>
                        <Controller
                          name={'elementary'}
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={field.value}
                                  {...field}
                                />
                              }
                              label="Elementary classroom"
                            />
                          )}
                        />
                        <Tooltip
                          placement="top"
                          arrow
                          title="Selecting this will restrict what content students can find on their own to items appropriate for elementary students">
                          <IconButton>
                            <HelpOutlineRounded />
                          </IconButton>
                        </Tooltip>
                      </Div>
                      <Div sx={styles.itemWithTooltip}>
                        <Controller
                          name={'studentStartQuiz'}
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={field.value}
                                  {...field}
                                />
                              }
                              label="Student-selected quizzes?"
                            />
                          )}
                        />
                        <Tooltip
                          placement="top"
                          arrow
                          title="Allow students to select stories and take quizzes without a teacher-created assignment">
                          <IconButton>
                            <HelpOutlineRounded />
                          </IconButton>
                        </Tooltip>
                      </Div>

                      <Controller
                        name={'autoReleaseQuizScores'}
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={field.value}
                                {...field}
                              />
                            }
                            label="Auto release quiz scores"
                          />
                        )}
                      />
                    </FormGroup> }

                </Div>

                <Button type="submit" variant="contained">
                  Update class
                </Button>
              </Div>
            </form>
          </Grid>
        </Grid>
      </Div>
    </Modal>
  );
};

export default LingoLiftClassesManageClassUpdateClassModalForm;
