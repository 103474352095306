export default {
  MuiAccordion: {
    defaultProps: {
      disableGutters: true,
      elevation: 0,
      square: true,
    },
    styleOverrides: {
      root: {
        border: `1px solid primary.dark`,
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&::before': {
          display: 'none',
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg)',
          marginRight: '10px',
        },
        '& .MuiSvgIcon-root': {
          fontSize: '18px',
          margin: 0,
        },
      },
    },
  },
};
