import { palette } from '../palette';

export default {
  MuiButton: {
    styleOverrides: {
      root: {
        color: palette.white,
        backgroundColor: palette.secondary.dark,
        fontSize: '14px',
        fontWeight: 400,
        borderRadius: '8px',
        padding: '5px 16px',
        textTransform: 'capitalize',
        width: 'max-content',
        height: 'fit-content',
        '&:hover': {
          backgroundColor: palette.secondary.main,
        },
        '&:focus': {
          backgroundColor: palette.secondary.dark,
        },
      },
      outlined: {
        color: palette.primary.main,
        backgroundColor: palette.white,
        fontSize: '14px',
        fontWeight: 450,
        border: '1px solid #CFD6DD',
        '&:hover': {
          backgroundColor: palette.secondary.dark,
          color: palette.white,
        },
      },
      text: {
        color: palette.primary.main,
        backgroundColor: 'transparent',
        fontSize: '14px',
        fontWeight: 450,
        border: 'none',
        padding: 0,
        '&:hover, &:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    defaultProps: {
      variant: 'root',
    },
  },
};
