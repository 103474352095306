const styles = {
  root: {
    borderRadius: '12px',
    background: 'white',
    padding: '10px',
    color: 'primary.dark',
    border: '1px solid',
    borderColor: 'semiGray',
  },
  title: {
    margin: '20px 0 10px',
    color: 'black',
    fontWeight: 450,
  },
  video: {
    width: '84px',
    height: '84px',
    borderRadius: '5px',
  },
  link: {
    color: 'secondary.dark',
    fontWeight: 600,
  },
  status: {
    textAlign: 'center',
    fontWeight: 450,
    borderRadius: '5px',
    margin: '35px 0',
    color: 'black',
  },
  statusCompleted: {
    backgroundColor: '#D8F8E7',
  },
  statusStarted: {
    backgroundColor: 'secondary.light',
  }
};

export default styles;
