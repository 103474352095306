import React, { FC, KeyboardEvent } from 'react';
import { debounce, IconButton, InputAdornment, TextField } from '@mui/material';
import { SearchRounded } from '@mui/icons-material';
import styles from './styles';
import { useDesktop } from '../../../../shared/hooks/useDesktop';
import searchIcon from '../../../../assets/searchIcon.svg';
import Image from '../../../../shared/ui/Image';
import { appRoutes } from '../../../../shared/store';
import { isTeacher, UserRole } from "../../../../entities/user";

interface ISearchAppBarProps {
  userRole: UserRole;
  searchPlaceholder: string;
}

const SearchAppBar: FC<ISearchAppBarProps> = props => {
  const { userRole, searchPlaceholder } = props;

  const isDesktop = useDesktop();

  const onHandleSearch = debounce((event: KeyboardEvent) => {
    if ('value' in event.target && event.key === 'Enter') {
      window.location.href =
        (isTeacher(userRole) ? appRoutes.teacherSearch : appRoutes.studentSearch) +
        `?query=${event.target.value}`;
    }
  }, 500);

  const onRedirectToSearch = () => {
    window.location.href = isTeacher(userRole) ? appRoutes.teacherSearch : appRoutes.studentSearch;
  };

  return (
    <>
      {!isDesktop && (
        <IconButton onClick={onRedirectToSearch}>
          <SearchRounded />
        </IconButton>
      )}
      {isDesktop && (
        <TextField
          type="search"
          sx={styles.searchInput}
          placeholder={searchPlaceholder || 'Search...'}
          onKeyDown={onHandleSearch}
          InputProps={{
            style: {
              ...styles.searchInput,
            },
            startAdornment: (
              <InputAdornment sx={styles.headerOption} position="start">
                <Image src={searchIcon} alt="search icon" />
              </InputAdornment>
            ),
          }}
        />
      )}
    </>
  );
};

export default SearchAppBar;
