const styles = {
  wrapper: {
    paddingTop: '20px',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    padding: {
      xs: '24px 16px 10px 16px',
      md: '40px 44px 10px 44px',
    }
  },
  lessons: {
    margin: '0px 0px 20px -16px',
    boxSizing: 'border-box',
  },
};

export default styles;
