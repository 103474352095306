interface IStandardsParsePropParams {
  subjects: Array<string>;
  schoolstate: string;
  grades: Array<number>;
}

export const standardsParsePropParams = (standards: IStandardsParsePropParams): IStandardsParsePropParams => {
  const data = {} as IStandardsParsePropParams

  Object.keys(standards).map((key) => {
    data[key] = JSON.parse(standards[key])
  })

  return data
}
