const styles = {
  container: {
    boxShadow: `0px 3px 7px primary.main`,
    borderRadius: '5px',
    border: '1px solid',
    borderColor: 'semiGray',
    fontSize: '14px',
    fontWeight: 400,
    height: 'fit-content',
    padding: '1px',
    color: 'black',
    '& .MuiChip-deleteIcon': {
      color: 'black',
      fontSize: '18px',
    },
  },
};

export default styles;
