const styles = {
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    paddingBottom: '40px',

  },
  announce: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: {
      xs: '24px',
      sm: '32px'
    }
  },
  description: {
    display: 'flex',
    gap: '5px',
    fontStyles: 'italic',
  },
  descriptionParagraph: {
    fontSize: {
      xs: '12px',
      sm: '16px',
    }
  },
  previewButton: {
    backgroundColor: 'white',
    color: 'primary.main',
    border: '1px solid',
    borderColor: 'semiGray',
    flex: 'none',
    '&:hover': {
      color: 'white',
    }
  },
  bottomActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '20px',
  }
}

export default styles;
