import React, { FC } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid, Link, Typography } from '@mui/material';

import ContentLayout from '../../shared/ui/ContentLayout';

import { MainLayout } from '../../widgets';

import styles from './styles';
import Div from '../../shared/ui/Div';
import {
  LingoLiftStudentDashboardCard,
  useGetLingoLiftStudentDashboardLWEventsQuery,
  useGetLingoLiftStudentDashboardLWAssignmentQuizzesQuery,
  useGetLingoLiftStudentDashboardLLAssignmentQuizzesQuery,
  useGetLingoLiftStudentDashboardProgressQuery
} from '../../entities/lingoLiftStudentDashboard';
import bookIcon from '../../assets/secondaryIcons/bookIcon.svg';
import progressIcon from '../../assets/secondaryIcons/progressIcon.svg';
import languageIcon from '../../assets/secondaryIcons/languageIcon.svg';
import Image from '../../shared/ui/Image';
import { AddRounded } from '@mui/icons-material';
import DropdownMenu from '../../shared/ui/DropdownMenu';
import LingoLiftStudentEventCard from '../../entities/lingoLiftStudentDashboard/ui/EventCard';
import { UserRole } from '../../entities/user';
import {
  useGetLingoLiftStudentLLClassesQuery,
  useGetLingoLiftStudentLWClassesQuery,
  usePatchLingoLiftStudentLLClassesMutation,
  usePatchLingoLiftStudentLWClassesMutation,
} from '../../entities/lingoliftClasses';
import Loader from '../../shared/ui/Loader';
import { appRoutes } from '../../shared/store';
import {
  useGetLingoLiftStudentProfileLanguageQuery,
  usePatchLingoLiftStudentProfileLanguageUpdateMutation
} from '../../entities/lingoLiftStudentProfile';

interface ILingoLiftStudentDashboardBrowsePageProps {
  isListenwise: boolean;
  isLingolift: boolean;
  studentName: string;
}

const LingoLiftStudentDashboardBrowsePage:FC<ILingoLiftStudentDashboardBrowsePageProps> = (props) => {
  const { isListenwise, isLingolift, studentName } = props;

  const { data: studentLLClassesList , isLoading: isStudentLLClassesListLoading } = useGetLingoLiftStudentLLClassesQuery(null, {
    skip: !isLingolift
  });

  const { data: studentLWClassesList , isLoading: isStudentLWClassesListLoading } = useGetLingoLiftStudentLWClassesQuery(null, {
    skip: !isListenwise
  });

  const { data: studentEventList, isLoading: isStudentEventListLoading } = useGetLingoLiftStudentDashboardLWEventsQuery(null, {
    skip: !isListenwise
  });

  const { data: studentLanguageList, isLoading: isStudentLanguageListLoading } = useGetLingoLiftStudentProfileLanguageQuery();

  const { data: assignmentLWQuizzesList, isLoading: isAssignmentLWQuizzesListLoading } = useGetLingoLiftStudentDashboardLWAssignmentQuizzesQuery(null, {
    skip: !isListenwise
  });

  const { data: assignmentLLQuizzesList, isLoading: isAssignmentLLQuizzesListLoading } = useGetLingoLiftStudentDashboardLLAssignmentQuizzesQuery(null, {
    skip: !isLingolift
  });

  const { data: studentProgress } = useGetLingoLiftStudentDashboardProgressQuery();

  const [onHideStudentLLClass] = usePatchLingoLiftStudentLLClassesMutation();

  const [onHideStudentLWClass] = usePatchLingoLiftStudentLWClassesMutation();

  const [onUpdateStudentLanguage] = usePatchLingoLiftStudentProfileLanguageUpdateMutation();

  const onChangeClass = (id: number, isHidden: boolean, type: 'listenwise' | 'lingolift') => {
    const data = {
      hidden: isHidden
    }

    if (type === 'lingolift') {
      onHideStudentLLClass({
        params: {
          llSchoolClassId: id
        },
        data
      })
    }

    if (type === 'listenwise') {
      onHideStudentLWClass({
        params: {
          schoolClassId: id
        },
        data
      })
    }
  }

  const onChangeLanguage = (lang: string) => {
    const language = studentLanguageList?.items?.find((lng) => lng.name === lang)

    if (language) {
      onUpdateStudentLanguage({
        uiLanguage: language.slug
      })
    }
  }

  const languageList = (studentLanguageList?.items || [])?.map((lng) => lng.name)
  const currentLanguage = (studentLanguageList?.items || []).find((lng) => lng.current);

  const getLabels = (isListenwise: boolean, isLingolift: boolean, assignmentLWQuizzesList: any, assignmentLLQuizzesList: any) => {
    if (isListenwise) {
      return {
        welcomeLabel: assignmentLWQuizzesList?.labels?.welcomeLabel,
        notCompletedAssignmentSchoolClassesLabel: assignmentLWQuizzesList?.labels?.notCompletedAssignmentSchoolClasses,
        completedAssignmentSchoolClassesLabel: assignmentLWQuizzesList?.labels?.completedAssignmentSchoolClasses,
      };
    } else if (isLingolift) {
      return {
        welcomeLabel: assignmentLLQuizzesList?.labels?.welcomeLabel,
        notCompletedAssignmentSchoolClassesLabel: assignmentLLQuizzesList?.labels?.notCompletedAssignmentSchoolClasses,
        completedAssignmentSchoolClassesLabel: assignmentLLQuizzesList?.labels?.completedAssignmentSchoolClasses,
      };
    }
    return {};
  };

  const labels = getLabels(isListenwise, isLingolift, assignmentLWQuizzesList, assignmentLLQuizzesList);
  const { welcomeLabel, notCompletedAssignmentSchoolClassesLabel, completedAssignmentSchoolClassesLabel } = labels;

  return (
    <MainLayout
      userRole={UserRole.Student}
      isLoading={(isAssignmentLLQuizzesListLoading || isAssignmentLWQuizzesListLoading)}>
      <ContentLayout>
        <Grid container spacing={6}>

          <Grid item display="grid" gap={4} lg={9}>
            <Div>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <Div sx={styles.titleWrapper}>
                    <Typography variant="h1">
                      { welcomeLabel }
                    </Typography>
                    <Typography variant="h2">
                      { notCompletedAssignmentSchoolClassesLabel}
                    </Typography>
                  </Div>
                </Grid>

                { isListenwise && assignmentLWQuizzesList?.items?.notCompletedAssignmentSchoolClasses?.map((assignClass) => <Grid key={`lw_not_completed_${assignClass.relatedType}_${assignClass.id}`} xs={12} item>
                  <LingoLiftStudentDashboardCard {...assignClass} />
                </Grid>) }

                { isLingolift && assignmentLLQuizzesList?.items?.notCompletedAssignmentSchoolClasses?.map((assignClass) => <Grid key={`ll_not_completed_${assignClass.relatedType}_${assignClass.id}`} xs={12} item>
                  <LingoLiftStudentDashboardCard {...assignClass} />
                </Grid>) }
              </Grid>
            </Div>

            <Div>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <Div sx={styles.titleWrapper}>
                    <Typography variant="h2">
                      { completedAssignmentSchoolClassesLabel }
                    </Typography>
                  </Div>
                </Grid>
                { isListenwise && assignmentLWQuizzesList?.items?.completedAssignmentSchoolClasses?.map((assignClass) => <Grid key={`lw_completed_${assignClass.relatedType}_${assignClass.id}`} xs={12} item>
                  <LingoLiftStudentDashboardCard {...assignClass} />
                </Grid>) }

                { isLingolift && assignmentLLQuizzesList?.items?.completedAssignmentSchoolClasses?.map((assignClass) => <Grid key={`ll_completed_${assignClass.relatedType}_${assignClass.id}`} xs={12} item>
                  <LingoLiftStudentDashboardCard {...assignClass} />
                </Grid>) }
              </Grid>
            </Div>

          </Grid>

          <Grid display="grid" gap={2} item lg={3}>
            <Div sx={styles.sidePanel}>
              <Div sx={styles.sidePanelSection}>
                <Div sx={styles.sidePanelSectionTitle}>
                  <Image width={20} src={bookIcon} alt="book icon"/>
                  <Typography>{ studentLLClassesList?.sectionLabel }</Typography>
                </Div>

                { (isStudentLLClassesListLoading || isStudentLWClassesListLoading) ? <Loader /> : <FormGroup>
                  { studentLLClassesList?.items?.map((studentClass) => (
                    <FormControlLabel
                      key={`ll_class_${studentClass.id}`}
                      control={<Checkbox
                        checked={!studentClass.isHidden}
                        onChange={() => onChangeClass(studentClass.id, !studentClass.isHidden, 'lingolift')} /> }
                      label={studentClass.name} />
                  )) }

                  { studentLWClassesList?.items?.map((studentClass) => (
                    <FormControlLabel
                      key={`lw_class_${studentClass.id}`}
                      control={<Checkbox
                        checked={!studentClass.isHidden}
                        onChange={() => onChangeClass(studentClass.id, !studentClass.isHidden,'listenwise')} />}
                      label={studentClass.name} />
                  )) }
                </FormGroup> }

                { isListenwise && <Link
                  href={studentLWClassesList?.btnLink}
                  sx={styles.sideActionButtonLink}
                >
                  <AddRounded sx={styles.linkIcon} /> { studentLWClassesList?.btnLabel }
                </Link> }

                { isLingolift && <Link
                  href={studentLLClassesList?.btnLink}
                  sx={styles.sideActionButtonLink}
                >
                  <AddRounded sx={styles.linkIcon} /> { studentLLClassesList?.btnLabel }
                </Link> }

              </Div>

              <Div sx={styles.divider}></Div>

              <Div sx={styles.sidePanelSection}>
                <Div sx={styles.sidePanelSectionTitle}>
                  <Image width={20} src={progressIcon} alt="book icon"/>
                  <Typography>{ studentProgress?.sectionLabel }</Typography>
                </Div>

                <Link sx={styles.sideActionButtonLink} href={studentProgress?.btnLink}>
                  { studentProgress?.btnLabel }
                </Link>
              </Div>

              <Div sx={styles.divider}></Div>

              <Div sx={styles.sidePanelSection}>
                <Div sx={styles.sidePanelSectionTitle}>
                  <Image width={20} src={languageIcon} alt="book icon"/>
                  <Typography>{ studentLanguageList?.sectionLabel }</Typography>
                </Div>

                { isStudentLanguageListLoading ? <Loader /> : <DropdownMenu
                  initialValue={currentLanguage?.name}
                  onChange={onChangeLanguage}
                  options={languageList} /> }

              </Div>
            </Div>

            { isListenwise && <Div sx={styles.eventsContainer}>
              <Typography variant="h3">
                { studentEventList?.sectionLabel }
              </Typography>
              <Grid container spacing={2}>
                { isStudentEventListLoading ? <Loader /> : studentEventList?.items?.map((event) => (
                  <Grid key={`lw_event_${event.id}`} item xs={12} lg={6}>
                    <LingoLiftStudentEventCard {...event} />
                  </Grid>
                )) }
              </Grid>
            </Div> }
          </Grid>
        </Grid>
      </ContentLayout>
    </MainLayout>
  );
};

export default LingoLiftStudentDashboardBrowsePage;
