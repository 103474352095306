const styles = {
  card: {
    position: 'relative',
    borderRadius: '12px',
    border: '1px solid',
    borderColor: 'loblolly',
    boxSizing: 'border-box',
  },
  fullScreenCard: {
    border: 'none',
    boxShadow: 'none',
  },
  wrapper: {
    padding: '10px',
  },
  poster: {
    objectFit: 'cover',
    borderRadius: '5px',
    width: '100%',
    height: '100%',
    backgroundColor: 'geyser',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    width: '100%',
    boxSizing: 'border-box',
    minHeight: {
      sm: '150px',
      xs: '100px',
    },
    '&.MuiCardContent-root': {
      padding: 0,
    },
  },
  description: {
    fontSize: '12px',
  },
  fullScreenDescription: {
    fontSize: '16px',
  },
  tools: {
    display: 'flex',
    gap: '10px',
    alignItems: {
      xs: 'center',
      md: 'flex-start',
    },
    justifyContent: 'flex-end',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
  },
  assignButton: {
    minHeight: '40px',
    width: {
      xs: '100%',
      sm: 'max-content',
    },
  },
  assignButtonIcon: {
    width: '18px',
    height: '18px',
    objectFit: 'cover',
  },
  link: {
    cursor: 'pointer',
    fontSize: {
      xs: '16px',
      sm: '18px',
    },
    fontWeight: '600',
    color: 'secondary.dark',
  },
  fullScreenLink: {
    fontSize: '32px',
  },
  lessonLogo: {
    cursor: 'default',
    width: '150px',
    height: 'auto',
    display: 'inline-block',
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  collapseAccordionAction: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    color: 'primary.main',
    cursor: 'pointer',
    marginBottom: 0,
    marginTop: 'auto',
    '&:hover': {
      color: 'secondary.main',
    },
  },
  accordionDetailsActive: {
    opacity: 1,
    height: 'auto',
    padding: 0,
    transition: '1s',
  },
  accordionDetailsListenWise: {
    borderLeft: '8px solid',
    borderColor: 'secondary.dark',
  },
  accordionDetailsLingoLift: {
    borderLeft: '8px solid',
    borderColor: 'hibiscus',
  },
  accordionDetailsInActive: {
    opacity: 0,
    height: 0,
    transition: '1s',
  },
  accordionDetailsFullScreenBorder: {
    borderRadius: '12px',
    border: '1px solid',
    borderColor: 'semiGray',
    overflow: 'hidden',
    borderLeft: 'none',
    marginTop: '20px',
  },
};

export default styles;
