const styles = {
  container: {
    backgroundColor: 'white',
    borderRadius: '12px',
    padding: '16px',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '10px',
  },
  poster: {
    borderRadius: '8px',
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    backgroundColor: 'semiGray',
  },
  title: {
    color: 'secondary.dark',
    fontSize: '14px',
    fontWeight: 600
  },
  description: {
    fontSize: '10px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    padding: '5px 0px',
    width: 'fit-content',
    flex: 1
  },
  panel: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '150px',
    marginLeft: {
      lg: 'auto'
    }
  },
  startButton: {
    width: '100%',
    backgroundColor: 'secondary.dark',
    borderRadius: '8px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    padding: '4px',
    '&:hover': {
      backgroundColor: 'secondary.main',
    },
  },
  reviewButton: {
    width: '100%',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    padding: '4px',
    color: 'primary.main',
    border: '1px solid',
    borderColor: 'semiGray',
  },
  startButtonIcon: {
    fontSize: '18px'
  },
  tagWrapper: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontSize: '10px',
      color: 'primary.dark',
      fontWeight: 600
    }
  },
  tagIcon: {
    width: '15px',
    height: 'auto',
    objectFit: 'contain',
  },
}

export default styles;
