import React, { type FC } from 'react';
import { Typography, IconButton } from '@mui/material';

import styles from './styles';
import Div from '../../../../../shared/ui/Div';
import { LingoLiftSummaryType } from '../../../model/enums';
import ProgressRow from './ProgressRow';
import CloseIcon from '@mui/icons-material/Close';
import SpeakRow from './SpeakRow';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import {
  ILingoLiftSummaryLearnQuestionWithAnswer,
  ILingoLiftSummarySpokenDialog,
  ILingoLiftSummaryWatchedQuestionsWithAnswer
} from "../../../model/types";

interface IProgressDetails {
  type: string;
  spokenDialogLines?: Array<ILingoLiftSummarySpokenDialog>;
  learnQuestionWithAnswers?: Array<ILingoLiftSummaryLearnQuestionWithAnswer>;
  watchedQuestionsWithAnswers?: Array<ILingoLiftSummaryWatchedQuestionsWithAnswer>
  onToggleExpand: (value: boolean) => void;
  isExpanded: boolean;
}

const ProgressDetails: FC<IProgressDetails> = props => {
  const { spokenDialogLines, learnQuestionWithAnswers, watchedQuestionsWithAnswers, onToggleExpand, isExpanded, type } = props;

  return (
    <Div sx={styles.root}>
      <Div sx={styles.wrapper}>
        <>
          {type === LingoLiftSummaryType.watch && <Typography variant="h4" sx={styles.title}>
            Comprehension Check Results
          </Typography>}

          {type === LingoLiftSummaryType.learn && <Typography variant="h4" sx={styles.title}>
            Word spelling results
          </Typography>}

          {type === LingoLiftSummaryType.speak && <Typography variant="h4" sx={styles.title}>
            Line Pronunciation Results
          </Typography>}
        </>
        <IconButton onClick={() => onToggleExpand(!isExpanded)} sx={styles.closeButton}>
          <CloseIcon sx={styles.title} />
        </IconButton>
      </Div>
      <Div>
        {(type === LingoLiftSummaryType.watch) &&
          watchedQuestionsWithAnswers?.map((detail, index) => {
            const { answerText, questionText, correct, } = detail;
            return (
              <ProgressRow
                key={index}
                description={
                  type === LingoLiftSummaryType.watch ? `Question ${index + 1}` : `Word ${index + 1}`
                }
                title={answerText}
                response={questionText}
                status={correct}
                attempts={1}
              />
            );
          })}

        {(type === LingoLiftSummaryType.learn) &&
          learnQuestionWithAnswers?.map((detail, index) => {
            const { learnedWords } = detail;
            return ( learnedWords.map((word) => <ProgressRow
              key={index}
              description={`Word ${index + 1}`}
              title={word.orthography}
              response={word.orthography}
              status={word.correct}
              attempts={1}
            />));
          })}

        {type === LingoLiftSummaryType.speak && (
          <>
            {spokenDialogLines?.map((detail, index) => {
              const { audioUrl, transcript, words, score, pointsScored, grade } = detail;
              const attempts = pointsScored ? 1 : 0;
              return <SpeakRow
                key={index}
                audioUrl={audioUrl}
                words={words}
                transcript={transcript}
                score={`${Math.floor(score * 100)}%`}
                grade={grade}
                attempts={attempts} />;
            })}
            <Div sx={styles.wrapperKeys}>
              <Typography variant="h5" sx={styles.key}>
                Key:
              </Typography>
              <Div sx={styles.clear}>
                <Typography variant="h5">Clear</Typography>
              </Div>
              <Div sx={styles.satisfactory}>
                <Typography variant="h5">Satisfactory</Typography>
              </Div>
              <Div sx={styles.poor}>
                <Typography variant="h5">Poor</Typography>
              </Div>
              <Div sx={styles.pauseWrapper}>
                <Typography variant="h5" sx={styles.pause}>
                  Long Pause
                </Typography>
                <Div sx={styles.pauseContainer}>
                  <Div sx={styles.pauseIconContainer}>
                    <LocalParkingIcon sx={styles.pauseIcon} />
                  </Div>
                </Div>
              </Div>
              <Div sx={styles.addWrapper}>
                <Typography variant="h5" sx={styles.pause}>
                  Additional Words
                </Typography>
                <Div sx={styles.addContainer}>
                  <Div sx={styles.addIconContainer}>
                    <AddBoxRoundedIcon sx={styles.addIcon} />
                  </Div>
                </Div>
              </Div>
              <Typography variant="h5" sx={styles.words}>
                Words Learned
              </Typography>
            </Div>
          </>
        )}
      </Div>
    </Div>
  );
};

export default ProgressDetails;
