import { selectResponse } from '../../../shared/utils/selectFromResponseObjects';
import { apiContainer, apiRoutes } from '../../../shared/store';
import {
  ILingoLiftStudentDashboardAssignmentQuizzes,
  ILingoLiftStudentDashboardEventsResponse,
  ILingoLiftStudentDashboardJoinClass,
  ILingoLiftStudentDashboardJoinCreate, ILingoLiftStudentDashboardProgress
} from '../model/types';
import { CacheTags } from "../../../shared/store/commonEnums";

export const lingoLiftStudentDashboardApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    postLingoLiftStudentDashboardJoinClass: builder.mutation<ILingoLiftStudentDashboardJoinClass, ILingoLiftStudentDashboardJoinCreate>({
      query: (data) => ({
        url: apiRoutes.lingoLiftStudentDashboardJoinClassPath(),
        method: 'POST',
        data,
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftStudentDashboardLWEvents: builder.query<ILingoLiftStudentDashboardEventsResponse, null>({
      query: () => ({
        url: apiRoutes.lingoLiftStudentLWEvents(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.StudentDashboardLWEvents, id: 'LIST' }],
    }),
    getLingoLiftStudentDashboardLWAssignmentQuizzes: builder.query<ILingoLiftStudentDashboardAssignmentQuizzes, null>({
      query: () => ({
        url: apiRoutes.lingoLiftStudentUpComingLWAssignments(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.StudentDashboardAssignmentQuizList, id: 'LIST' }],
    }),
    getLingoLiftStudentDashboardLLAssignmentQuizzes: builder.query<ILingoLiftStudentDashboardAssignmentQuizzes, null>({
      query: () => ({
        url: apiRoutes.lingoLiftStudentUpComingLLAssignments(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.StudentDashboardAssignmentQuizList, id: 'LIST' }],
    }),
    getLingoLiftStudentDashboardProgress: builder.query<ILingoLiftStudentDashboardProgress, void>({
      query: () => ({
        url: apiRoutes.lingoLiftStudentProgressReportsPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.StudentProgressReports, id: 'LIST' }],
    }),
  }),
});

export const {
  usePostLingoLiftStudentDashboardJoinClassMutation,
  useGetLingoLiftStudentDashboardLWEventsQuery,
  useGetLingoLiftStudentDashboardLWAssignmentQuizzesQuery,
  useGetLingoLiftStudentDashboardLLAssignmentQuizzesQuery,
  useGetLingoLiftStudentDashboardProgressQuery
} = lingoLiftStudentDashboardApi;
