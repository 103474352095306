import { selectResponse } from '../../../shared/utils/selectFromResponseObjects';
import { apiContainer, apiRoutes } from '../../../shared/store';
import { IFooterList } from '../model/types';
import { CacheTags } from "../../../shared/store/commonEnums";

export const lingoLiftLessonsApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLingoLiftFooterList: builder.query<IFooterList, void>({
      query: () => ({
        url: apiRoutes.lingoLiftFooterPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.FooterMenu, id: 'LIST' }],
    }),
  }),
});

export const { useGetLingoLiftFooterListQuery } = lingoLiftLessonsApi;
