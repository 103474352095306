import React, { FC, useState } from 'react';
import { Grid, Link, Typography } from '@mui/material';

import ContentLayout from '../../shared/ui/ContentLayout';

import { MainLayout } from '../../widgets';

import Div from '../../shared/ui/Div';
import {
  LingoLiftMyLibraryCard,
  LingoLiftMyLibraryTag,
  useDeleteLingoLiftTeacherMyLibraryLLAssignmentTagMutation,
  useDeleteLingoLiftTeacherMyLibraryLLAssignmentUnTagMutation,
  useDeleteLingoLiftTeacherMyLibraryLWAssignmentTagMutation,
  useDeleteLingoLiftTeacherMyLibraryLWAssignmentUnTagMutation,
  useGetLingoLiftTeacherMyLibraryLLAssignmentsQuery,
  useGetLingoLiftTeacherMyLibraryLLAssignmentTagsQuery,
  useGetLingoLiftTeacherMyLibraryLWAssignmentsQuery,
  useGetLingoLiftTeacherMyLibraryLWAssignmentTagsQuery,
} from '../../entities/lingoLiftMyLibrary';
import styles from './styles';
import { UserRole } from '../../entities/user';
import { LingoLiftProduct } from '../../shared/types/common-enums';
import Loader from '../../shared/ui/Loader';
import LingoLiftLessonLogo from './../../assets/lingoLiftLessonLogo.svg';
import ListenwiseLessonLogo from './../../assets/listenWiseLessonLogo.svg';
import Image from "../../shared/ui/Image";

interface ILingoLiftMyLibraryBrowseProps {
  title: string;
  beforeSubtitle: string;
  afterSubtitle: string;
  linkName: string;
  linkUrl: string;
  isLingolift: boolean;
  isListenwise: boolean;
}

const LingoLiftMyLibraryBrowsePage:FC<ILingoLiftMyLibraryBrowseProps> = (props) => {
  const { title, linkName, linkUrl, afterSubtitle, beforeSubtitle, isListenwise, isLingolift } = props;
  const [selectedLWTag, setSelectedLWTag] = useState<null | string>(null);
  const [selectedLLTag, setSelectedLLTag] = useState<null | string>(null);
  const { data: LWAssignments, isLoading: isLWAssginmentsLoading } = useGetLingoLiftTeacherMyLibraryLWAssignmentsQuery({
    tag: selectedLWTag
  }, {
    skip: !isListenwise
  });

  const { data: LWAssignmentTags, isLoading: isLWAssignmentTagsLoading } = useGetLingoLiftTeacherMyLibraryLWAssignmentTagsQuery(null, {
    skip: !isListenwise
  });

  const [onLWDestroyTag] = useDeleteLingoLiftTeacherMyLibraryLWAssignmentTagMutation();

  const [onLWUnTag] = useDeleteLingoLiftTeacherMyLibraryLWAssignmentUnTagMutation();

  const { data: LLAssignments, isLoading: isLLAssginmentsLoading } = useGetLingoLiftTeacherMyLibraryLLAssignmentsQuery({
    tag: selectedLLTag
  },
    {
      skip: !isLingolift
    });

  const { data: LLAssignmentTags, isLoading: isLLAssignmentTagsLoading } = useGetLingoLiftTeacherMyLibraryLLAssignmentTagsQuery(null, {
    skip: !isLingolift
  });

  const [onLLDestroyTag] = useDeleteLingoLiftTeacherMyLibraryLLAssignmentTagMutation();

  const [onLLUnTag] = useDeleteLingoLiftTeacherMyLibraryLLAssignmentUnTagMutation();

  const onDeleteTag = (id: number, type: LingoLiftProduct) => {
    if (type === LingoLiftProduct.ListenWise) {
      onLWDestroyTag({
        tagId: id
      })
    }

    if (type === LingoLiftProduct.LingoLift) {
      onLLDestroyTag({
        tagId: id
      })
    }
  };

  const onAssignableUnTag = (assignableId: number, assignableType: string, tagId: number, type: LingoLiftProduct) => {
    const tag = {
      assignableId,
      assignableType,
      tagId
    }
    if (type === LingoLiftProduct.ListenWise) {
      onLWUnTag(tag)
    }

    if (type === LingoLiftProduct.LingoLift) {
      onLLUnTag(tag)
    }
  };

  const isLoadingList = (isLWAssginmentsLoading || isLLAssginmentsLoading);

  return (
    <MainLayout userRole={UserRole.Teacher} isLoading={isLoadingList}>
      <ContentLayout>
        <Grid container spacing={5}>
          <Grid item xs={12} lg={8}>
            <Div>
              <Grid container rowGap={2}>
                <Grid display="grid" gap={1} item>
                  <Typography variant="h1">
                    { title }
                  </Typography>
                  <Typography color="primary.dark">
                    { beforeSubtitle } <Link sx={styles.link} href={linkUrl}>{ linkName }</Link> { afterSubtitle }
                  </Typography>
                </Grid>
                { LWAssignments?.assignables.map((assignment, index) => <Grid
                  key={index}
                  display="grid"
                  gap={2}
                  xs={12}
                  item>
                  <LingoLiftMyLibraryCard
                    {...assignment}
                    tagsList={LWAssignmentTags ?? []}
                    onAssignableUnTag={onAssignableUnTag}
                    product={LingoLiftProduct.ListenWise} />
                </Grid>) }

                { LLAssignments?.assignables?.map((assignment, index) => <Grid
                  key={index}
                  display="grid"
                  gap={2}
                  xs={12}
                  item>
                  <LingoLiftMyLibraryCard
                    {...assignment}
                    tagsList={LLAssignmentTags ?? []}
                    onAssignableUnTag={onAssignableUnTag}
                    product={LingoLiftProduct.LingoLift} />
                </Grid>) }

              </Grid>
            </Div>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Div sx={styles.tagsBarContainer}>
              <Typography sx={styles.tagsContainerSubTitle}>
                MY ASSIGNMENT TAGS
              </Typography>
              <Typography fontSize="10px" color="primary.dark">
                Click any tag to see tagged favorites
              </Typography>
              { (isLWAssignmentTagsLoading || isLLAssignmentTagsLoading) ? <Loader /> : <>

                { (LWAssignmentTags && LWAssignmentTags?.length > 0) && <Div sx={ styles.tagsSection }>
                  <Image src={ ListenwiseLessonLogo } alt="LingoLift Icon"/>
                  <Div sx={ styles.tagWrapper }>
                    { LWAssignmentTags?.map((tag) => <LingoLiftMyLibraryTag
                      key={ tag.id }
                      label={ tag.name }
                      selectedTag={ selectedLWTag }
                      onSelectTag={ () => setSelectedLWTag(selectedLWTag !== tag.name ? tag.name : null) }
                      onDelete={ () => {
                        selectedLWTag === tag.name && setSelectedLWTag(null)
                        onDeleteTag(tag.id, LingoLiftProduct.ListenWise)
                      } }/>) }
                  </Div>
                </Div> }

                { (LLAssignmentTags && LLAssignmentTags?.length > 0) && <Div sx={ styles.tagsSection }>
                  <Image src={ LingoLiftLessonLogo } alt="LingoLift Icon"/>
                  <Div sx={ styles.tagWrapper }>
                    { LLAssignmentTags?.map((tag) => <LingoLiftMyLibraryTag
                      key={ tag.id }
                      label={ tag.name }
                      selectedTag={ selectedLLTag }
                      onSelectTag={ () => setSelectedLLTag(selectedLLTag !== tag.name ? tag.name : null) }
                      onDelete={ () => {
                        selectedLLTag === tag.name && setSelectedLLTag(null)
                        onDeleteTag(tag.id, LingoLiftProduct.LingoLift);
                      } }/>) }
                  </Div>
                </Div> }
              </>}
            </Div>
          </Grid>
        </Grid>
      </ContentLayout>
    </MainLayout>
  );
};

export default LingoLiftMyLibraryBrowsePage;
