const subjectColors = {
  ela: '#F59638',
  eld: '#9939AC',
  socialStudies: '#097C69',
  social: '#555F6D',
  science: '#3062D4'
}

export const colors = {
  semiBlue: '#2893BE',
  semiGray: '#CFD6DD',
  white: '#FFFFFF',
  black: '#272E35',
  darkGray: '#3B3B3B',
  green: '#1D7C4D',
  hibiscus: '#C03060',
  codGray: '#151515',
  porcelain: '#E9ECEE',
  athensGray: '#F5F7F9',
  geyser: '#C6D4D9',
  loblolly: '#B6C5CC',
  chathamsBlue: '#154F65',
  elephant: '#0F3848',
  wineBerry: '#671E36',
  flushMahogany: '#C53434',
  jaffa: '#F59638',
  bridesmaid: '#FEE6E6',
  karry: '#FFE8D1',
  grannyApple: '#D8F8E7',
  zircon: '#F5F8FF',
  toreaBay: '#113997',
  mariner: '#3062D4',
  fruitSalad: '#55A555',
  vividViolet: '#9939AC',
  whitePointer: '#FDF5FF',
  honeyFlower: '#5B1E67',
  pantone: '#EAEDF0',
  riverBed: '#465463',
  silverSand: '#BCC1C7',
  chateauGreen: '#34A853',
  celadonBlue: '#037395',
  gray: '#404040',
  bananaMania: '#FCDEC0',
  rawUmber: '#7A4510',
  bottleGreen: '#0E4E30',
  crownOfThorns: '#6F2020',
  gray: '#404040',
  aquaHaze: '#F3F6F8',
  cinderella: '#FCD9D9',
  ...subjectColors,
};

export const palette = {
  primary: {
    main: '#4A545E',
    dark: '#555F6D',
    light: '#F0F3F5',
  },
  secondary: {
    main: '#1B6683',
    dark: '#227EA2',
    light: '#E0F3FA',
  },
  ...colors,
};
