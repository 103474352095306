const styles = {
  pickerItem: {
    width: '100%',
    '& .MuiInputBase-root': {
      paddingRight: '20px',
    },
  },
};

export default styles;
