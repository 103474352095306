import React, { FC } from 'react';
import Div from '../../../../shared/ui/Div';
import styles from "./styles";
import { Link, Typography } from "@mui/material";
import { ILingoLiftStudentDashboardEvent } from "../../model/types";
import dayjs from "dayjs";

interface ILingoLiftEventCardProps extends ILingoLiftStudentDashboardEvent {}

const LingoLiftStudentEventCard:FC<ILingoLiftEventCardProps> = (props) => {
  const { url, photoSrc, publishDate, name } = props;
  return <Div sx={{
    ...styles.container,
    backgroundImage: `url(${photoSrc})`
  }}>
    <Link href={url} sx={styles.content}>
      <Typography sx={styles.contentDateTitle}>
        { dayjs(publishDate).format('MMMM D') }
      </Typography>
      <Typography sx={styles.contentDescription}>
        { name }
      </Typography>
    </Link>
  </Div>
}

export default LingoLiftStudentEventCard;
