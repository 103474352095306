import { palette } from '../palette';

export default {
  MuiSlider: {
    styleOverrides: {
      root: {
        color: palette.secondary.dark,
        '& .MuiSlider-thumb': {
          backgroundColor: palette.chathamsBlue,
          '&:focus': {
            backgroundColor: 'red',
          },
        },
      },
    },
  },
};
