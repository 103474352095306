import { UserRole } from "../model/enums";

const isTeacher = (userRole: UserRole) => {
  return userRole === UserRole.Teacher;
}

const isStudent = (userRole: UserRole) => {
  return userRole === UserRole.Student
}

const isPublic = (userRole: UserRole) => {
  return userRole === UserRole.Public
}

export { isStudent, isTeacher, isPublic }
