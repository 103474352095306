const styles = {
  drawer: {
    width: '280px',
  },
  listSection: {
    height: '84vh',
    overflow: 'scroll',
    width: '260px',
    boxSizing: 'border-box',
    padding: '75px 10px 0px 10px',
  },
  actionsPanel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flex: 1,
    padding: '10px 0px',
    boxShadow: `0px 3px 7px primary.main`,
  },
  accountTitle: {
    padding: '0px 20px',
    fontSize: '14px',
    fontWeight: 600,
    color: '#7F8C9A',
  },
  menuItem: {
    padding: '5px 9px 5px 30px',
    borderRadius: '8px',
    '& .MuiTypography-root': {
      fontSize: '14px',
      color: 'primary.main',
    },
  },
  itemArrowIcon: {
    minWidth: 'auto',
    fontSize: '30px',
    color: '#7F8C9A',
  },
  mainDropDownItem: {
    padding: '5px 0px',
  },
  secondDropDownItem: {
    padding: '5px 10px',
  },
  defaultDropDownItem: {
    padding: '5px 9px 5px 40px',
  },
  activeParent: {
    backgroundColor: 'porcelain',
  },
  inActiveParent: {
    backgroundColor: 'transparent',
  },
};

export default styles;
