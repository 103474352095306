const styles = {
  footer: {
    display: 'flex',
    backgroundColor: '#0F3848',
    padding: {
      xs: '40px 16px',
      sm: '60px 40px',
      lg: '90px 120px 70px 120px',
    },
    gap: '20px',
    marginTop: 'auto',
  },
  logoSectionWrapper: {
    display: 'flex',
    gap: '10px',
  },
  sectionWrapper: {
    marginLeft: {
      xs: '49px',
      sm: 0,
      md: 0,
    },
  },
  footerLogo: {
    height: 49,
    width: 'auto',
    objectFit: 'cover',
  },
  footerCategories: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: {
      xs: '20px',
      sm: '40px',
      md: '20px',
    },
    flex: 1,
    flexDirection: {
      lg: 'row',
      md: 'row',
      sm: 'row',
      xs: 'column',
    },
  },
};

export default styles;
