const styles = {
  wrapper: {
    padding: '20px 40px 0px 40px',
    borderBottom: `1px solid semiGray`,
    backgroundColor: '#F5F7F9',
  },
  contentSpace: {
    margin: '20px 0px'
  },
  standardsGradeContainer: {
    paddingTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  standardGradeTitle: {
    fontSize: '18px',
    color: 'secondary.dark',
    fontWeight: 600
  },
};

export default styles;
