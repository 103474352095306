const styles = {
  container: {
    backgroundColor: 'white',
    borderRadius: '12px',
    padding: '16px 7px',
    width: '100%',
  },
  title: {
    display: 'flex',
    gap: '5px',
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: 600,
    alignItems: 'center',
    padding: '0px 9px',
  },
  lingoLiftTitle: {
    color: 'wineBerry',
  },
  listenWiseTitle: {
    color: 'elephant',
  },
  filterSection: {
    padding: '10px 0px',
    bordered: {
      borderBottom: '1px solid',
      borderColor: 'semiGray',
    },
    bottomVoidPadding: {
      paddingBottom: 0,
    },
  },
  category: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 14px',
    cursor: 'pointer',
    width: '100%',
    backgroundColor: 'transparent',
    color: 'black',
    '&:hover': {
      backgroundColor: 'primary.light',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  categoryTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    textAlign: 'left',
    icon: {
      fontSize: '18px',
      transform: 'rotate(180deg)',
      color: 'primary.dark',
    },
  },
  content: {
    defaultState: {
      opacity: 0,
      height: 0,
      pointerEvents: 'none',
    },
    collapsedState: {
      pointerEvents: 'auto',
      transition: '.3s',
      opacity: 1,
      height: 'auto',
    },
  },
  filtersFormGroup: {
    padding: '0px 14px',
  },
  formGroupFilterOption: {
    '& .MuiTypography-root': {
      fontSize: '14px !important',
    },
  },
  formGroupRangeFilterOption: {
    display: 'flex',
    flexDirection: 'column-reverse',
    margin: 0,
  },
};

export default styles;
