import { palette } from '../palette';

export default {
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: '14px',
        '&.Mui-selected': {
          color: palette.secondary.dark,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        backgroundColor: palette.secondary.dark,
        height: 3,
      },
    },
  },
};
