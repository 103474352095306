import styles from './styles';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { FC } from 'react';
import Div from '../../../../shared/ui/Div';
import StandardChildItem from "./StandardChildItem";
import { ILingoLiftLessonStandardItem, ILingoLiftStandardsRecursive } from "../../model/types";

interface ILingoLiftLessonStandardAccordionCardProps {
  grade: string;
  standards: Array<ILingoLiftLessonStandardItem & ILingoLiftStandardsRecursive>;
  title: string;
}


const LingoLiftLessonStandardAccordionCard: FC<ILingoLiftLessonStandardAccordionCardProps> = (props) => {
  const { grade, standards , title } = props;

  return <Accordion
    slotProps={{
      transition: {
        unmountOnExit: false,
        timeout: 500
      }
    }}
    sx={styles.accordion}>
    <AccordionSummary
      aria-controls="panel2-content"
      id="panel2-header"
    >
      <Typography sx={styles.gradeTitle}>
        Grade:{ grade }
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Div sx={styles.gradeContainer}>
        <Typography variant="h2" color="orange">
          { title }
        </Typography>

        { standards.map((standardChild, index) => <Div sx={styles.standardChildItemContainer} key={index}>
          <Typography variant="h3">
            { standardChild.label }
          </Typography>

          <Typography variant="h3" sx={styles.standardContent}>
            { standardChild.content }
          </Typography>

          <StandardChildItem standards={standardChild.standards ?? []} isContent={false} />

        </Div>) }
      </Div>
    </AccordionDetails>
  </Accordion>
}


export default LingoLiftLessonStandardAccordionCard;
