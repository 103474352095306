import * as React from 'react';
import Button from '@mui/material/Button';
import {
  Dialog,
  DialogActions,
  MenuItem,
  Typography
} from '@mui/material';
import Div from '../Div';
import { FC } from 'react';
import styles from "./styles";

interface IConfirmModalProps {
  title: string;
  description: string;
  type: 'item' | 'button';
  buttonTitle: string;
  onSubmit: () => void;
  handleCancel: () => void;
}

const ConfirmModalButton:FC<IConfirmModalProps> = (props) => {
  const { title, type, description, buttonTitle, onSubmit, handleCancel } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleCancel();
  };

  return (
    <Div>
      { type === 'button' &&
      <Button variant="outlined" onClick={handleClickOpen}>
        { buttonTitle }
      </Button> }

      { type === 'item' && <MenuItem
        onClick={handleClickOpen}
      >
        <Typography color="primary.main">
          { buttonTitle }
        </Typography>
      </MenuItem> }
      <Dialog
        sx={styles.dialog}
        open={open}
        onClose={handleClose}
      >
        <Div sx={styles.content}>
          <Typography variant="h3">
            { title }
          </Typography>
          <Typography>
            { description }
          </Typography>
        </Div>
        <DialogActions sx={styles.actionsWrapper}>
          <Button sx={styles.cancelButton} onClick={handleClose}>No</Button>
          <Button onClick={onSubmit} autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>
    </Div>
  );
}

export default ConfirmModalButton;
