const styles = {
  root: {
    margin: '-30px 0 !important',
  },
  title: {
    color: 'secondary.dark',
  },
  student: {
    fontSize: '33px',
    color: '#404040',
  },
  divider: {
    height: '10px',
    background: 'celadonBlue',
    margin: '0 -44px !important',
    border: 'none',
  },
};

export default styles;
