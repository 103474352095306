const styles = {
  container: {
    boxShadow: '0px 2px 8px -1px #1B242C14, 0px 2px 2px -1px #1B242C0A',
    backgroundColor: 'athensGray',
    overflow: 'hidden',
    border: '1px solid',
    borderColor: 'semiGray',
    marginBottom: '15px',
  },
  title: {
    fontSize: '18px',
    color: 'secondary.dark',
    fontWeight: 600,
    marginRight: '10px',
  },
  entityName: {
    fontSize: '14px',
    fontWeight: 500,
    color: 'black',
  },
  entitySemiName: {
    fontSize: '14px',
    color: 'secondary.dark',
    fontWeight: 600,
  },
  column: {
    display: 'grid',
    flexDirection: 'column',
    gap: '10px',
  },
  rowTitle: {
    backgroundColor: 'athensGray',
  },
  row: {
    backgroundColor: 'white',
    padding: '16px',
    alignItems: 'center',
  },
  borderedRow: {
    borderBottom: '1px solid',
    borderColor: 'semiGray',
  },
  rowSpace: {
    padding: '16px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  actionButton: {
    backgroundColor: 'white',
    color: 'primary.main',
    padding: '8px 10px',
    border: '1px solid',
    borderColor: 'semiGray',
    '&:hover': {
      backgroundColor: 'secondary.main',
      color: 'white',
    },
  },
  borderRadiusLeft: {
    borderRadius: '8px 0 0 8px',
  },
  borderRadiusRight: {
    borderRadius: '0 8px 8px 0',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'end',
  },
  subtitle: {
    paddingBottom: '3px',
  },
  italic: {
    fontStyle: 'italic',
    fontWeight: 450,
  },
};

export default styles;
