const styles = {
  container: {
    width: '100%',
  },
  menuItem: {
    color: 'primary.dark',
    '&:hover': {
      backgroundColor: 'primary.light',
      borderRadius: '8px',
      '& .MuiTypography-root': {
        color: 'secondary.main',
      },
    },
  },

  menuItemLabel: {
    color: 'primary.dark',
    padding: '6px 16px',
    fontWeight: 600,
    fontSize: '12px',
  },

  menuActiveItem: {
    backgroundColor: 'secondary.light',
    borderRadius: '8px',
    '& .MuiTypography-root': {
      color: 'secondary.main',
    },
  },
  dropDownMenuButton: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    color: 'black',
    border: `1px solid`,
    borderColor: 'semiGray',
    width: '100%',
    padding: '6px 12px',
    marginTop: {
      xs: '10px',
      lg: 0,
    },
    '& .MuiButton-endIcon': {
      marginLeft: 'auto'
    },
    '&:hover': {
      backgroundColor: 'primary.light',
      outline: 'none',
      borderColor: 'semiGray',
    },
    '&:focus': {
      backgroundColor: 'transparent',
      border: `1px solid`,
      borderColor: 'semiBlue',
    },
  },
  dropDownButtonIcon: {
    fontSize: '25px',
  },
  dropDownList: {
    '& .MuiPaper-root': {
      borderRadius: '12px',
    },
  },
  loader: {
    textAlign: 'center',
    padding: '20px',
  },
};

const calculationDropdownList = (offsetWidth = 320) => {
  return {
    '& .MuiPaper-root': {
      borderRadius: '12px',
      width: `${offsetWidth}px`
    },
  }
}

export { styles, calculationDropdownList }
