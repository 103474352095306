import MuiLink from './MuiLink';
import MuiTextField from './MuiTextField';
import MuiTypography from './MuiTypography';
import MuiButton from './MuiButton';
import MuiTab from './MuiTab';
import MuiAccordion from './MuiAccordion';
import MuiCssBaseLine from './MuiCssBaseLine';
import MuiIconButton from "./MuiIconButton";
import MuiCheckBox from "./MuiCheckBox";
import MuiAutocomplete from "./MuiAutocomplete";
import MuiCard from './MuiCard';
import MuiSlider from './MuiSlider';
import MuiSelect from './MuiSelect';

export default {
  ...MuiLink,
  ...MuiTextField,
  ...MuiTypography,
  ...MuiButton,
  ...MuiIconButton,
  ...MuiTab,
  ...MuiAccordion,
  ...MuiCssBaseLine,
  ...MuiCheckBox,
  ...MuiAutocomplete,
  ...MuiCheckBox,
  ...MuiCard,
  ...MuiSlider,
  ...MuiSelect,
};
