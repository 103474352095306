import React, { FC, ReactNode, useState } from 'react';
import Div from '../../../shared/ui/Div';
import { Button } from '@mui/material';
import styles from './styles';
import { FilterListRounded } from '@mui/icons-material';
import { useDesktop } from '../../../shared/hooks/useDesktop';
import sx from 'mui-sx';

interface ILingoLiftSearchCollapsedFiltersSectionWrapperProps {
  children: ReactNode;
}
const LingoLiftFiltersWrapper: FC<ILingoLiftSearchCollapsedFiltersSectionWrapperProps> = props => {
  const { children } = props;
  const [isShow, setIsShow] = useState(false);
  const isDesktop = useDesktop();

  return (
    <Div sx={styles.filtersColumn}>
      {!isDesktop && (
        <Button
          sx={sx(styles.snowButton, {
            sx: styles.snowButton.showButtonActiveState,
            condition: isShow,
          })}
          startIcon={<FilterListRounded />}
          onClick={() => setIsShow(!isShow)}
        >
          Filters
        </Button>
      )}
      {isDesktop && children}

      {!isDesktop && isShow && children}
    </Div>
  );
};

export default LingoLiftFiltersWrapper;
