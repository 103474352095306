const styles = {
  root: {
    background: 'white',
    color: 'primary.dark',
    padding: '16px',
    borderBottom: '1px solid #EAEDF0',
  },
  sentenceWrapper: {
    display: 'flex',
    marginTop: '12px',
  },
  description: {
    fontWeight: 600,
    marginBottom: '5px',
  },
  level: {
    color: 'black',
  },
  score: {
    fontWeight: 450,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  attemptsWrapper: {
    textAlign: 'right',
  },
  iconButton: {
    color: 'primary.main',
    border: '1px solid #CFD6DD',
    width: '32px',
    height: '32px',
    margin: '8px 5px 0 0',
    boxShadow: `0px 3px 7px primary.main`,
  },
  icon: {
    color: 'primary.main',
    fontSize: '20px',
    marginRight: '12px',
  },
  title: {
    color: 'black',
    fontWeight: 450,
    padding: '2px 5px',
    borderRadius: '5px',
    marginRight: '5px'
  },
  empty: {
    background: 'transparent'
  },
  clear: {
    backgroundColor: '#E5EEFF',
  },
  satisfactory: {
    color: '#7A4510',
    backgroundColor: '#FCDEC0',
    margin: '0 5px',
    fontWeight: 600,
  },
  poor: {
    color: 'white',
    backgroundColor: 'hibiscus',
    margin: '0 5px',
    fontWeight: 600,
  },
  learned: {
    textDecoration: 'underline',
  },
  pause: {
    marginLeft: '10px',
  },
  pauseWrapper: {
    position: 'relative',
  },
  pauseContainer: {
    position: 'absolute',
    top: '-18px',
    left: '-16px',
  },
  pauseIconContainer: {
    position: 'relative',
    display: 'inline-block',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 'calc(50% - 2px)',
      backgroundColor: '#8040BF',
      width: '5px',
      height: '5px',
      clipPath: 'polygon(0 0, 100% 0, 50% 100%)',
    },
  },
  pauseIcon: {
    color: 'white',
    backgroundColor: '#8040BF',
    borderRadius: '2px',
    fontSize: '14px',
  },
  addWrapper: {
    position: 'relative',
  },
  addContainer: {
    position: 'absolute',
    top: '-21px',
    left: '-18px',
  },
  addIconContainer: {
    position: 'relative',
    display: 'inline-block',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '2px',
      left: 'calc(50% - 2px)',
      backgroundColor: 'hibiscus',
      width: '5px',
      height: '5px',
      clipPath: 'polygon(0 0, 100% 0, 50% 100%)',
    },
  },
  addIcon: {
    color: 'hibiscus',
    borderRadius: '5px',
    fontSize: '19px',
  },
};

export default styles;
