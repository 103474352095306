import * as React from 'react';
import { type FC } from 'react';

import { MainLayout } from '../../widgets';
import ContentLayout from '../../shared/ui/ContentLayout';

import {
  LingoLiftCollectionCard,
  useGetLingoLiftTeacherCollectionDetailsQuery,
} from '../../entities/lingoLiftCollection';
import { UserRole } from "../../entities/user";

interface ILingoLiftCollectionTeacherShowPageProps {
  id: number;
  currentUrl: string;
}

const LingoLiftCollectionTeacherShowPage: FC<ILingoLiftCollectionTeacherShowPageProps> = props => {
  const { id, currentUrl } = props;

  const { data: collection, isLoading } = useGetLingoLiftTeacherCollectionDetailsQuery(id);

  return (
    <MainLayout userRole={UserRole.Teacher} isLoading={isLoading}>
      <ContentLayout>{collection && <LingoLiftCollectionCard
        {...collection}
        userRole={UserRole.Teacher}
        shareUrl={currentUrl}
        fullScreen />}</ContentLayout>
    </MainLayout>
  );
};

export default LingoLiftCollectionTeacherShowPage;
