import React from 'react';
import { type FC } from 'react';
import { Card, CardMedia, Grid, Link, Typography, IconButton, Stack, Chip } from '@mui/material';
import { StarRounded } from '@mui/icons-material';
import Div from '../../../../shared/ui/Div';
import styles from './styles';
import Image from '../../../../shared/ui/Image';
import videoIcon from '../../../../assets/secondaryIcons/videoIcon.svg';
import quizIcon from '../../../../assets/secondaryIcons/quizIcon.svg';
import lingoLiftLessonLogo from '../../../../assets/untitledLogos/lingoLiftUntitledLogo.svg';
import listenWiseLessonLogo from '../../../../assets/untitledLogos/lintenWiseUntitledLogo.svg';
import { CloseRounded } from '@mui/icons-material';
import LingoLiftFavoritesAddTagsPopover from '../../../../features/lingoLiftFavorites';
import {
  useDeleteLingoLiftTeacherFavoriteMutation,
  useDeleteLingoLiftTeachersFavoritesTagMutation,
  useGetLingoLiftTeachersFavoritesTagsQuery,
  usePostLingoLiftTeachersFavoritesAddTagMutation,
} from '../../api/index';
import { type ILingoLiftFavorites } from '../../model/types';
import { isLingoLiftProduct, isListenWiseProduct } from '../../../../shared/utils/compareConditions';

interface ILingoLiftFavoritesTeacherCardProps {
  card: ILingoLiftFavorites;
}

const LingoLiftFavoritesTeacherCard: FC<ILingoLiftFavoritesTeacherCardProps> = props => {
  const {
    card: {
      id,
      tags,
      favoritable: {
        id: favoritableId,
        type: favoritableType,
        url,
        name,
        photoSrc,
        category,
        durationMmSs,
        description,
        product,
        video,
        quiz,
      },
    },
  } = props;

  const [createTag, { isLoading: isCreateTagLoading }] = usePostLingoLiftTeachersFavoritesAddTagMutation();
  const [deleteTag] = useDeleteLingoLiftTeachersFavoritesTagMutation();
  const [deleteLingoLiftTeacherFavorite] = useDeleteLingoLiftTeacherFavoriteMutation();
  const { data: tagsList, isLoading: isTagsListLoading } = useGetLingoLiftTeachersFavoritesTagsQuery();

  const handleDelete = (lessonId: number, tagId: number) => deleteTag({ id: lessonId, params: { tagId } });

  return (
    <Card sx={styles.card}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <CardMedia sx={styles.poster} component="img" image={photoSrc} alt="preview" />
        </Grid>
        <Grid sx={styles.content} item xs={8}>
          <Div sx={styles.tagWrapper}>
            <Typography variant="body2" sx={styles.category}>
              {category ? `${category} | ` : ''}Length: {durationMmSs}
            </Typography>
            {isLingoLiftProduct(product) && <Image src={lingoLiftLessonLogo} alt="Lingo lift lesson logo" />}
            {isListenWiseProduct(product) && <Image src={listenWiseLessonLogo} alt="Listen wise lesson logo" />}
            {video && <Image sx={styles.tagIcon} src={videoIcon} alt="Watch icon" />}
            {isListenWiseProduct(product) && quiz && (
              <Image sx={styles.tagIcon} src={quizIcon} alt="Quiz icon" />
            )}
          </Div>
          <Link sx={styles.title} href={url}>
            {name}
          </Link>
          <Typography variant="body2" color="primary.dark">
            {description}
          </Typography>
          <Stack sx={styles.chipWrapper} direction="row" spacing={1}>
            {tags &&
              tags.map((tag) => (
                <Chip
                  key={tag.id}
                  label={tag.name}
                  onDelete={() => handleDelete(id, tag.id)}
                  sx={styles.chip}
                  deleteIcon={<CloseRounded />}
                />
              ))}
            <LingoLiftFavoritesAddTagsPopover
              onCreate={createTag}
              id={id}
              tagsList={tagsList}
              isLoading={isTagsListLoading}
              isCreateTagLoading={isCreateTagLoading}
            />
          </Stack>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            arial-label="favorite"
            onClick={() => deleteLingoLiftTeacherFavorite({ favoritableId, favoritableType })}
          >
            <StarRounded sx={{ ...styles.favoriteIcon, color: 'secondary.dark' }} />
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  );
};

export default LingoLiftFavoritesTeacherCard;
