import * as React from 'react';
import ReduxProvider from '../reduxProvider';
import { createRoot } from 'react-dom/client';
import LingoLiftDashboardSummaryStudentPage from '../../pages/LingoLiftDashboardSummaryStudent';
import LingoLiftSummaryStudentAllReportsPage from '../../pages/LingoLiftSummaryStudentAllReports';
import LingoLiftClassesSummaryStudentReportPage
  from "../../pages/LingoLiftClassesSummaryStudentReport";
import LingoLiftClassesSummaryStudentAllReportsPage from "../../pages/LingoLiftClassesSummaryStudentAllReports";

const lingoLiftDashboardSummaryRoutesRender = () => {
  const lingoLiftDashboardSummaryStudentElement = document.querySelector('#lingolift_dashboard_students_summary') as HTMLElement;
  const lingoLiftSummaryStudentAllReportsElement = document.querySelector('#lingolift_dashboard_students_all_reports') as HTMLElement;
  const lingoLiftClassesSummaryStudentReportElement = document.querySelector('#lingolift_teachers_ll_school_classes_students_report') as HTMLElement;
  const lingoLiftClassesSummaryStudentAllReportsElement = document.querySelector('#lingolift_teachers_ll_school_classes_students_all') as HTMLElement;

  const renderElement = (
    lingoLiftDashboardSummaryStudentElement ||
    lingoLiftSummaryStudentAllReportsElement ||
    lingoLiftClassesSummaryStudentReportElement ||
    lingoLiftClassesSummaryStudentAllReportsElement)

  if (renderElement) {
    const root = createRoot(renderElement);
    return root.render(
      <ReduxProvider>
        { lingoLiftDashboardSummaryStudentElement && <LingoLiftDashboardSummaryStudentPage
          assignedVideosTitle={(lingoLiftDashboardSummaryStudentElement as HTMLElement).dataset?.assignedVideosTitle}/> }
        { lingoLiftSummaryStudentAllReportsElement && <LingoLiftSummaryStudentAllReportsPage /> }
        { lingoLiftClassesSummaryStudentReportElement && <LingoLiftClassesSummaryStudentReportPage
          studentId={(lingoLiftClassesSummaryStudentReportElement as HTMLElement).dataset?.studentId}
          llSchoolClassId={(lingoLiftClassesSummaryStudentReportElement as HTMLElement).dataset?.llSchoolClassId}/> }
        { lingoLiftClassesSummaryStudentAllReportsElement && <LingoLiftClassesSummaryStudentAllReportsPage
          studentId={(lingoLiftClassesSummaryStudentAllReportsElement as HTMLElement).dataset?.studentId}
          llSchoolClassId={(lingoLiftClassesSummaryStudentAllReportsElement as HTMLElement).dataset?.llSchoolClassId}/> }
      </ReduxProvider>
    );
  }
};

export default lingoLiftDashboardSummaryRoutesRender;
