const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    padding: {
      xs: '24px 16px 10px 16px',
      md: '40px 44px 10px 44px',
    },
  },
  section: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
  },
  description: {
    fontSize: '16px',
    fontWeight: 400,
  },
  badgesWrapper: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: {
      xs: '5px',
    },
    gap: {
      lg: '20px',
      xs: '10px',
    },
    flexDirection: {
      sm: 'row',
      xs: 'column',
    },
  },
  assignButton: {
    width: {
      xs: '100%',
      sm: 'max-content',
    },
  },
  assignButtonIcon: {
    width: '18px',
    height: '18px',
    objectFit: 'cover',
  },
  helpOutlinedIcon: {
    color: 'secondary.dark',
  },
};

export default styles;
