const styles = {
  title: {
    marginBottom: '16px',
    color: 'gray',
  },
  borderRadiusLeft: {
    borderRadius: '8px 0 0 8px',
  },
  borderRadiusRight: {
    borderRadius: '0 8px 8px 0',
  },
  wrapper: {
    marginTop: '20px',
  },
  divider: {
    height: '10px',
    backgroundColor: 'celadonBlue',
    border: 'none',
  },
  header: {
    backgroundColor: 'athensGray',
    padding: '15px 44px',
  },
  link: {
    fontSize: '14px',
    color: 'secondary.dark',
    fontWeight: 700,
  },
  linkAssignments: {
    fontSize: '16px',
    color: 'primary.dark',
    fontWeight: 500,
    marginLeft: '10px',
  },
  container: {
    display: 'flex',
    alignItems: 'end',
  },
  transparentButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    padding: '5px 10px',
    width: 'max-content',
    border: '1px solid',
    borderColor: 'semiGray',
    boxSizing: 'border-box',
    height: 'max-content',
    '&:hover': {
      backgroundColor: 'secondary.main',
      color: 'white',
    },
  },
  previewWrapper: {
    display: 'flex',
    gap: '10px',
    image: {
      width: '100px',
      height: 'auto'
    }
  },
};

export default styles;
