import React from 'react';
import { Grid, Link, Typography } from '@mui/material';

import ContentLayout from '../../shared/ui/ContentLayout';
import { MainLayout } from '../../widgets';
import LingoLiftSummaryCard from '../../entities/lingoLiftSummary/ui/SummaryCard';
import styles from './styles';
import DashboardSummaryHeader from '../../entities/lingoLiftSummary/ui/SummaryHeader';
import VideoCard from '../../entities/lingoLiftSummary/ui/VideoCard';
import { useGetLingoLiftStudentSummaryReportsQuery } from "../../entities/lingoLiftSummary";
import { UserRole } from '../../entities/user';

interface LingoLiftDashboardSummaryStudentPageProps {
  assignedVideosTitle?: string;
}

const LingoLiftDashboardSummaryStudentPage: React.FC<LingoLiftDashboardSummaryStudentPageProps> = (props) => {
  const { assignedVideosTitle } = props;
  const { data: reportsList, isLoading: isReportsListLoading } = useGetLingoLiftStudentSummaryReportsQuery();

  return (
    <MainLayout userRole={UserRole.Student} backgroundColor="athensGray" isLoading={isReportsListLoading}>
      <ContentLayout>
        <DashboardSummaryHeader name={reportsList?.title ?? ''} />
      </ContentLayout>
      <ContentLayout>
        <Typography variant="h2" sx={styles.title}>
          { reportsList?.sectionLabel }
        </Typography>
        <Grid container spacing={2} justifyContent="space-between">
          { reportsList?.summary && Object.keys(reportsList.summary).map((summary: keyof typeof reportsList.summary) => (<Grid key={summary} item xs={4}>
            <LingoLiftSummaryCard
              title={reportsList.summary[summary].label}
              percentage={reportsList.summary[summary].percentage}
              statistics={reportsList.summary[summary].countLabel}
              iconType={summary}
            />
          </Grid>)) }
        </Grid>
      </ContentLayout>
      { reportsList && reportsList?.assignedItems?.length > 0 && <ContentLayout>
        <Grid container spacing={ 2 } justifyContent="space-between" sx={ styles.wrapper }>
          <Grid item xs={ 10 }>
            <Typography variant="h3" sx={ styles.title }>
              { assignedVideosTitle || 'Assigned Videos' }
            </Typography>
          </Grid>
          <Grid item xs={ 2 } sx={ styles.button }>
            <Link sx={ styles.transparentButton } href={ reportsList?.allAssignmentsLink }>
              { reportsList?.allAssignmentsLabel }
            </Link>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          { reportsList?.assignedItems.map((assigned) => (<Grid
            item
            xs={12}
            key={assigned.id}>
            <VideoCard
              { ...assigned }
            />
          </Grid>)) }
        </Grid>
      </ContentLayout> }
      { reportsList?.notAssignedItems && reportsList?.notAssignedItems?.length > 0 && <ContentLayout>
          <Typography variant="h3" sx={ styles.title }>
            Not Assigned Videos
          </Typography>
        <Grid item container spacing={2} xs={12}>
          { reportsList?.notAssignedItems.map((assigned) => <Grid
            key={assigned.id}
            xs={12}
            item>
            <VideoCard
              { ...assigned }
            />
          </Grid>) }
        </Grid>
      </ContentLayout> }
    </MainLayout>
  );
};

export default LingoLiftDashboardSummaryStudentPage;