import * as React from 'react';
import ReduxProvider from '../reduxProvider';
import { createRoot } from 'react-dom/client';
import LingoLiftSearchTeacherBrowsePage from '../../pages/LingoLiftSearchTeacherBrowse';
import LingoLiftSearchStudentBrowsePage from '../../pages/LingoLiftSearchStudentBrowse';

const lingoLiftSearchRoutesRender = () => {
  const lingoLiftSearchTeacherBrowseElement = document.querySelector('#lingolift_search_teachers_browse');

  const lingoLiftSearchStudentBrowseElement = document.querySelector('#lingolift_search_students_browse');

  const renderElement = lingoLiftSearchTeacherBrowseElement || lingoLiftSearchStudentBrowseElement;
  if (renderElement) {
    const root = createRoot(renderElement);

    return root.render(
      <ReduxProvider>
        {lingoLiftSearchTeacherBrowseElement && <LingoLiftSearchTeacherBrowsePage />}
        {lingoLiftSearchStudentBrowseElement && <LingoLiftSearchStudentBrowsePage />}
      </ReduxProvider>
    );
  }
};

export default lingoLiftSearchRoutesRender;
