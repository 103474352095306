const styles = {
  popoverContainer: {
    '& .MuiPaper-root': {
      borderRadius: '8px'
    }
  },
  popoverContent: {
    borderRadius: '12px',
    width: '300px',
    backgroundColor: 'white',
    padding: '10px 0px',
    boxSizing: 'border-box',
  },
  popoverHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 5px 0px 10px'
  },
  tagsSelectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '5px 10px 10px 10px',
    borderRadius: '20px'
  },
  tagsInput: {
    width: '100%',
    '& .MuiInputBase-root': {
      height: 'auto'
    }
  },
  addTagButton: {
    backgroundColor: 'transparent',
    color: 'secondary.dark',
    height: 'fit-content',
    padding: 0,
    '& .MuiButton-startIcon': {
      marginRight: 0
    },
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  addTagButtonIcon: {
    fontSize: '14px',
  },
}

export default styles;
