import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';

import ContentLayout from '../../shared/ui/ContentLayout';
import { MainLayout } from '../../widgets';
import LingoLiftSummaryCard from '../../entities/lingoLiftSummary/ui/SummaryCard';
import styles from './styles';
import DashboardSummaryHeader from '../../entities/lingoLiftSummary/ui/SummaryHeader';
import VideoCard from '../../entities/lingoLiftSummary/ui/VideoCard';
import { useGetLingoLiftStudentSummaryAllReportsQuery, } from '../../entities/lingoLiftSummary';
import PaginationLine from '../../shared/ui/PaginationLine';
import { UserRole } from '../../entities/user';

const LingoLiftSummaryStudentAllReportsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data: reportsList, isLoading: isReportsListLoading } = useGetLingoLiftStudentSummaryAllReportsQuery();

  return (
    <MainLayout userRole={UserRole.Student} backgroundColor="athensGray" isLoading={isReportsListLoading}>
      <ContentLayout>
        <DashboardSummaryHeader name={reportsList?.title ?? ''} />
      </ContentLayout>
      <ContentLayout>
        <Typography variant="h2" sx={styles.title}>
          { reportsList?.sectionLabel }
        </Typography>
        <Grid container spacing={2} justifyContent="space-between">
          { reportsList?.summary && Object.keys(reportsList.summary).map((summary: keyof typeof reportsList.summary) => (<Grid key={summary} item xs={4}>
            <LingoLiftSummaryCard
              title={reportsList.summary[summary].label}
              percentage={reportsList.summary[summary].percentage}
              statistics={reportsList.summary[summary].countLabel}
              iconType={summary}
            />
          </Grid>)) }
        </Grid>
      </ContentLayout>
      { reportsList && reportsList?.assignedItems?.length > 0 && <ContentLayout>

        <Grid container spacing={ 2 } justifyContent="space-between" sx={ styles.wrapper }>

          <Grid item xs={ 10 }>
            <Typography variant="h3" sx={ styles.title }>
              Assigned Videos
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          { reportsList?.assignedItems.map((assigned) => (<Grid
            item
            xs={12}
            key={assigned.id}>
            <VideoCard
              { ...assigned }
            />
          </Grid>)) }
        </Grid>
      </ContentLayout> }

      { (reportsList && reportsList?.pagination?.totalPages > 0) && <ContentLayout>
        <PaginationLine
          currentPage={ currentPage }
          onCurrentPageChange={ setCurrentPage }
          pagination={reportsList.pagination} />
      </ContentLayout> }
    </MainLayout>
  );
};

export default LingoLiftSummaryStudentAllReportsPage;
