import React, { FC, useEffect } from 'react';
import Div from '../../../../../shared/ui/Div';
import { Checkbox, debounce, FormControlLabel, FormGroup, Slider, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import styles from './styles';

interface IDoubleOptionedSliderProps {
  options: Array<{
    value: number;
  }>;
  presetValues?: Array<{
    label: string;
    values: Array<string>;
  }>;
  sectionKey: string;
  handleChangeFilter: (key: string, value: Array<number>) => void;
  filteredParams: Record<string, string>;
}
const DoubleOptionedSlider: FC<IDoubleOptionedSliderProps> = props => {
  const { options, handleChangeFilter, sectionKey, presetValues, filteredParams } = props;
  const { control, getValues, watch, setValue, reset } = useForm();

  useEffect(() => {
    if (!filteredParams[sectionKey]?.length) {
      reset();
      setValue('slider', [0, 0]);
      presetValues?.map((_, index) => {
        setValue(`checkbox:${index}`, false)
      })
    }
  }, [filteredParams]);

  const handleCheckboxChange = () => {
    // hashmap with array [number, number]
    const hashCheckedOptionsMap = {};

    // sort and inject to hashmap => mappedCheckedOptions
    Object.keys(getValues())
      .filter(key => key.includes('checkbox:'))
      .map((checkboxKey, index) => {
        if (getValues()[checkboxKey].length) {
          hashCheckedOptionsMap[index] = getValues()[checkboxKey];
        }
      });

    // parse numbers
    const numbers = Object.entries(hashCheckedOptionsMap)
      .flatMap(([key, value]) => value)
      .map(Number);
    // find values
    const min = numbers.length ? Math.min(...numbers) : 0;
    const max = numbers.length ? Math.max(...numbers) : 0;

    setValue('slider', [min, max]);
    handleChangeFilter(sectionKey, [min, max]);
  };

  const handleChangeSlider = debounce((value: [number, number]) => {
    const hashCheckedOptionsMap = {};

    // fill checked checkboxes
    presetValues?.map((preset, index) => {
      if (parseInt(preset.values[0] as string) >= value[0] && parseInt(preset.values[1] as string) <= value[1]) {
        hashCheckedOptionsMap[`checkbox:${index}`] = preset.values.map((value: string) => parseInt(value));
      } else {
        hashCheckedOptionsMap[`checkbox:${index}`] = false;
      }
    });

    Object.keys(hashCheckedOptionsMap).map(key => setValue(key, hashCheckedOptionsMap[key]));
    handleChangeFilter(sectionKey, value);
  }, 500);

  return (
    <FormGroup>
      <Div sx={styles.sliderContainer}>
        <Controller
          name={`minInput`}
          control={control}
          defaultValue={0}
          render={({ field }) => (
            <TextField
              type="tel"
              placeholder="min"
              sx={styles.input}
              value={watch('slider') ? watch('slider')[0] : 0}
              disabled={watch('slider')?.length === 2 && !Boolean(watch('slider')[0]) && !Boolean(watch('slider')[1])}
              onChange={event => setValue('slider', [parseInt(event.target.value), watch('slider')[1]])}
            />
          )}
        />
        <Controller
          name={`slider`}
          control={control}
          defaultValue={[0, 0]}
          render={({ field }) => (
            <Slider
              {...field}
              valueLabelDisplay="auto"
              getAriaLabel={() => 'range'}
              min={options.length === 2 ? options[0].value : 0}
              max={options.length === 2 ? options[1].value : 0}
              disabled={
                !options.length ||
                (watch('slider')?.length === 2 && !Boolean(watch('slider')[0]) && !Boolean(watch('slider')[1]))
              }
              onChange={(_, value: [number, number]) => {
                field.onChange(value);
                handleChangeSlider(value);
              }}
            />
          )}
        />

        <Controller
          name={`maxInput`}
          control={control}
          defaultValue={0}
          render={({ field }) => (
            <TextField
              type="tel"
              placeholder="max"
              sx={styles.input}
              value={watch('slider') ? watch('slider')[1] : 0}
              disabled={watch('slider')?.length === 2 && !Boolean(watch('slider')[0]) && !Boolean(watch('slider')[1])}
              onChange={event => setValue('slider', [watch('slider')[0], parseInt(event.target.value)])}
            />
          )}
        />
      </Div>

      {presetValues?.map((preset, index) => (
        <Controller
          name={`checkbox:${index}`}
          key={index}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.value}
                  {...field}
                  onChange={() => {
                    field.onChange(Array.isArray(getValues()[`checkbox:${index}`]) ? false : preset.values);
                    handleCheckboxChange();
                  }}
                />
              }
              label={preset.label}
            />
          )}
        />
      ))}
    </FormGroup>
  );
};

export default DoubleOptionedSlider;
