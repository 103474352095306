import React, { useState } from 'react';

import ContentLayout from '../../shared/ui/ContentLayout';
import { useDesktop } from '../../shared/hooks/useDesktop';
import SideBar from '../../shared/ui/SideBar';
import PaginationLine from '../../shared/ui/PaginationLine';

import styles from './styles';

import { MainLayout } from '../../widgets';
import { selectCategoryValueFromObject } from '../../shared/utils/selectors';

import {
  LingoLiftLessonCard,
  useGetLingoLiftTeacherLessonsFilterQuery,
  useGetLingoLiftTeacherLessonsQuery,
} from '../../entities/lingoLiftLesson';
import Div from '../../shared/ui/Div';
import { Grid, Typography } from '@mui/material';
import { UserRole } from '../../entities/user';
import { parseSingleQueryFromUrl } from '../../shared/utils/parseQueriesFromUrl';
import CategoryBadgeList from '../../shared/ui/CategoryBadgeList';

const LingoLiftLessonTeacherBrowsePage = () => {
  const isDesktop = useDesktop();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectCategory] = useState<Record<string, string>>({});

  const activeQuery = parseSingleQueryFromUrl()

  const { data: lessons, isLoading } = useGetLingoLiftTeacherLessonsQuery({
    page: currentPage,
    ...selectedCategory,
    ...activeQuery
  });

  const { data: filterList, isLoading: isFiltersLoading } = useGetLingoLiftTeacherLessonsFilterQuery();

  return (
    <MainLayout userRole={UserRole.Teacher} isLoading={isLoading}>
      <ContentLayout>
        <Grid container columnSpacing={{ lg: 2, md: 2 }} rowGap={{ lg: 0, xs: 2 }}>
          <Grid item lg={2} xs={12}>
            {!isDesktop && (
              <Div sx={styles.announce}>
                { lessons?.pageContent && <>
                  <Typography variant="h1">{ lessons?.pageContent.header }</Typography>
                  <Typography sx={styles.announceDescription}>
                    { lessons.pageContent.subheader }
                  </Typography>
                  <Typography>
                    { lessons?.pageContent.introText }
                  </Typography>
                  { lessons?.pageContent?.elpBadges.length >= 1 && <CategoryBadgeList list={lessons?.pageContent?.elpBadges} /> }
                </> }
              </Div>
            )}
            <SideBar
              allFiltersName={filterList?.resetFilterLabel ?? ''}
              filterOptions={filterList?.options ?? null}
              onSelectCategoryValue={setSelectCategory}
              selectedCategoryValue={selectCategoryValueFromObject(selectedCategory)}
              isLoading={isFiltersLoading}
            />
          </Grid>

          <Grid item lg={10} xs={12}>
            <Grid container rowGap={{ xs: 2 }}>
              {isDesktop && (
                <Grid item xs={12}>
                  <Div sx={styles.announce}>
                    <Div sx={styles.announceHeader}>
                      { lessons?.pageContent && <>
                        <Typography variant="h1">{ lessons?.pageContent.header }</Typography>
                        { lessons?.pageContent?.elpBadges.length >= 1 && <CategoryBadgeList list={lessons?.pageContent?.elpBadges} /> }
                      </> }
                    </Div>
                    { lessons?.pageContent && <>
                      <Typography sx={styles.announceDescription}>
                        { lessons.pageContent.subheader }
                      </Typography>
                      <Typography>
                        { lessons?.pageContent.introText }
                      </Typography>
                    </> }
                  </Div>
                </Grid>
              )}

              <Grid container spacing={2}>
                {lessons?.items?.map(lesson => (
                  <Grid key={lesson.id} item lg={3} sm={6} xs={12}>
                    <LingoLiftLessonCard {...lesson} category={lesson.category} />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {lessons && lessons?.pagination.totalPages > 0 && (
              <PaginationLine
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pagination={lessons?.pagination}
              />
            )}
          </Grid>
        </Grid>
      </ContentLayout>
    </MainLayout>
  );
};

export default LingoLiftLessonTeacherBrowsePage;
