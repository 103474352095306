import React, { type FC } from 'react';
import { Link, Typography } from '@mui/material';

import { budgeProperties, styles } from './styles';
import sx from 'mui-sx';
import Div from '../../../../shared/ui/Div';
import { LingoLiftBadgeType } from '../../model/enums';
import { snakeToCamelCase } from '@bigbinary/neeto-cist';
import { isTeacher, UserRole } from '../../../user';

interface ILessonBadgeProps {
  title: string;
  link?: string;
  secondary?: string;
  actionButton?: React.ReactNode;
  type?: LingoLiftBadgeType;
  userRole?: UserRole;
}

const LessonBadge: FC<ILessonBadgeProps> = props => {
  const { title, link, secondary, actionButton, type, userRole } = props;

  return (
    <Div sx={styles.wrapper}>
      <Link
        sx={sx(
          {
            ...styles.chip,
            ...styles.defaultBadge,
          },
          {
            sx: styles.secondaryBadge,
            condition: Boolean(secondary),
          },
          {
            sx: type && budgeProperties[snakeToCamelCase(type)],
            condition: Boolean(type),
          },
        )}
        href={link}
      >
        {title}
      </Link>
      {secondary && (
        <Typography variant="h5" sx={styles.secondary}>
          {secondary}
        </Typography>
      )}
      { (userRole && isTeacher(userRole)) && actionButton}
    </Div>
  );
};

export default LessonBadge;
