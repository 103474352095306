const styles = {
  card: {
    borderTop: '1px solid',
    borderColor: 'semiGray',
    borderRadius: 0,
  },
  cardElementRounded: {
    borderTop: 'none',
  },
  wrapper: {
    padding: '10px',
    borderTop: '1px solid',
    borderColor: 'semiGray',
  },
  poster: {
    objectFit: 'cover',
    borderRadius: '5px',
    width: '100%',
    height: '100%',
    backgroundColor: 'geyser',
  },
  contentWrapper: {
    display: 'flex',
    gap: '10px',
    paddingTop: '10px',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    width: 'fit-content',
    boxSizing: 'border-box',
    minHeight: {
      sm: '150px',
      xs: '100px',
    },
    '&.MuiCardContent-root': {
      padding: 0,
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    width: '150px',
    marginLeft: {
      md: 'auto',
      xs: 0,
    },
  },
  description: {
    fontSize: '12px',
  },
  link: {
    cursor: 'pointer',
    fontSize: {
      xs: '14px',
      sm: '16px',
    },
    fontWeight: '600',
    color: 'secondary.dark',
  },
  timeWrapper: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center'
  },
  timeIcon: {
    color: 'primary.dark',
  },
  timeMinutes: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    color: 'primary.dark',
    fontWeight: 600,
  }
};

export default styles;
