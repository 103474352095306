import React, { FC, useEffect, useRef, useState } from 'react';
import Div from '../Div';
import { Button, Fade, Menu, MenuItem, CircularProgress } from '@mui/material';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { styles, calculationDropdownList } from "./styles";
import { IOption } from "../../types/common";

interface IDropdownMenuProps {
  options?: Array<string>;
  onChange?: (label: string | IOption) => void;
  initialValue?: string | IOption;
  isLoading?: boolean;
  optionsWithId?: Array<IOption>
}

const DropdownMenu: FC<IDropdownMenuProps> = (props) => {
  const {
    options,
    onChange,
    initialValue,
    isLoading = false,
    optionsWithId
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);
  const open = Boolean(anchorEl);
  let buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (initialValue) {
      setSelectedLabel(typeof initialValue === 'string' ? initialValue : initialValue.label)
    }
  }, [options]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value: IOption | string) => {
    setSelectedLabel( typeof value === 'object' ? value.label : value);
    if (onChange) {
      onChange(value);
    }
    handleClose();
  };

  return (
    <Div sx={styles.container}>
      <Button
        ref={buttonRef}
        onClick={handleClick}
        sx={styles.dropDownMenuButton}
        endIcon={<KeyboardArrowDownRounded sx={styles.dropDownButtonIcon} />}
      >
        {selectedLabel}
      </Button>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        sx={{
          ...styles.dropDownList,
          width: calculationDropdownList(buttonRef?.current?.offsetWidth),
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {isLoading && (
          <Div sx={styles.loader}>
            <CircularProgress size={20} />
          </Div>
        )}
        {options && options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleSelect(option)}>
            {option}
          </MenuItem>
        ))}

        {optionsWithId && optionsWithId.map((option, index) => (
          <MenuItem key={index} onClick={() => handleSelect(option)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Div>
  );
}

export default DropdownMenu;
