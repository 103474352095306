import * as React from 'react';
import { Box, Link } from '@mui/material';

import defaultLogo from '../../../assets/defaultLogo.svg';

import styles from './styles';
import FooterList from './FooterList/FooterList';
import Div from '../../../shared/ui/Div';
import { useGetLingoLiftFooterListQuery } from '../api';
import Image from '../../../shared/ui/Image';
import Loader from '../../../shared/ui/Loader';

const Footer = () => {
  const { data: footerList, isLoading } = useGetLingoLiftFooterListQuery();

  return (
    <Box sx={styles.footer}>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={styles.footerCategories}>
          {footerList?.items?.map((item, index) =>
            index === 0 ? (
              <Div key={index} sx={styles.logoSectionWrapper}>
                <Link href={footerList.homeUrl}>
                  <Image sx={styles.footerLogo} src={defaultLogo} alt="logo" />
                </Link>
                <FooterList key={index} category={item.name} links={item.links} />
              </Div>
            ) : (
              <Box key={index} sx={styles.sectionWrapper}>
                <FooterList key={index} category={item.name} links={item.links} />
              </Box>
            )
          )}
        </Box>
      )}
    </Box>
  );
};

export default Footer;
