import { selectResponse } from '../../../shared/utils/selectFromResponseObjects';
import { apiContainer, apiRoutes } from '../../../shared/store';
import { CacheTags } from '../../../shared/store/commonEnums';
import {
  ILingoLiftTeacherMyLibraryAssignmentsResponse,
  ILingoLiftTeacherMyLibraryTagCreate,
  ILingoLiftTeacherMyLibraryTeacherAssignmentTag,
  ILingoLiftTeacherMyLibraryUnTagParams,
} from '../model/types';

export const lingoLiftMyLibraryApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLingoLiftTeacherMyLibraryLWAssignments: builder.query<ILingoLiftTeacherMyLibraryAssignmentsResponse, { tag: string | null }>({
      query: (params) => ({
        url: apiRoutes.lingoLiftTeacherMyLibraryLWAssignmentsPath(),
        method: 'GET',
        params
      }),
      transformResponse: selectResponse,
      providesTags: () => [{type: CacheTags.TeacherMyLibraryAssignments, id: 'LIST'}],
    }),
    getLingoLiftTeacherMyLibraryLWAssignmentTags: builder.query<Array<ILingoLiftTeacherMyLibraryTeacherAssignmentTag>, void>({
      query: () => ({
        url: apiRoutes.lingoLiftTeacherMyLibraryLWAssignmentTagsPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{type: CacheTags.TeacherMyLibraryAssignmentTags, id: 'LIST'}],
    }),
    postLingoLiftTeacherMyLibraryLWAssignmentTag: builder.mutation<
      ILingoLiftTeacherMyLibraryTagCreate<ILingoLiftTeacherMyLibraryTeacherAssignmentTag>,
      ILingoLiftTeacherMyLibraryTagCreate<string>>({
      query: data => ({
        url: apiRoutes.lingoLiftTeacherMyLibraryLWAssignmentTagPath(),
        method: 'POST',
        data,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [
        {type: CacheTags.TeacherMyLibraryAssignmentTags, id: 'LIST'},
        {type: CacheTags.TeacherMyLibraryAssignments, id: 'LIST'},
      ]
    }),
    deleteLingoLiftTeacherMyLibraryLWAssignmentTag: builder.mutation<void, { tagId: number }>({
      query: params => ({
        url: apiRoutes.lingoLiftTeacherMyLibraryLWAssignmentDeleteTagPath(),
        method: 'DELETE',
        params,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [
        {type: CacheTags.TeacherMyLibraryAssignmentTags, id: 'LIST'},
        {type: CacheTags.TeacherMyLibraryAssignments, id: 'LIST'},
      ]
    }),
    deleteLingoLiftTeacherMyLibraryLWAssignmentUnTag: builder.mutation<void, ILingoLiftTeacherMyLibraryUnTagParams>({
      query: params => ({
        url: apiRoutes.lingoLiftTeacherMyLibraryLWAssignmentUnTagPath(),
        method: 'DELETE',
        params,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [
        {type: CacheTags.TeacherMyLibraryAssignmentTags, id: 'LIST'},
        {type: CacheTags.TeacherMyLibraryAssignments, id: 'LIST'},
      ]
    }),
    getLingoLiftTeacherMyLibraryLLAssignments: builder.query<ILingoLiftTeacherMyLibraryAssignmentsResponse, { tag: string | null }>({
      query: (params) => ({
        url: apiRoutes.lingoLiftTeacherMyLibraryLLAssignmentsPath(),
        method: 'GET',
        params
      }),
      transformResponse: selectResponse,
      providesTags: () => [{type: CacheTags.TeacherMyLibraryAssignments, id: 'LIST'}],
    }),
    getLingoLiftTeacherMyLibraryLLAssignmentTags: builder.query<Array<ILingoLiftTeacherMyLibraryTeacherAssignmentTag>, void>({
      query: () => ({
        url: apiRoutes.lingoLiftTeacherMyLibraryLLAssignmentTagsPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{type: CacheTags.TeacherMyLibraryAssignmentTags, id: 'LIST'}],
    }),
    postLingoLiftTeacherMyLibraryLLAssignmentTag: builder.mutation<
      ILingoLiftTeacherMyLibraryTagCreate<ILingoLiftTeacherMyLibraryTeacherAssignmentTag>,
      ILingoLiftTeacherMyLibraryTagCreate<string>>({
      query: data => ({
        url: apiRoutes.lingoLiftTeacherMyLibraryLLAssignmentTagPath(),
        method: 'POST',
        data,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [
        {type: CacheTags.TeacherMyLibraryAssignmentTags, id: 'LIST'},
        {type: CacheTags.TeacherMyLibraryAssignments, id: 'LIST'},
      ]
    }),
    deleteLingoLiftTeacherMyLibraryLLAssignmentTag: builder.mutation<void, { tagId: number }>({
      query: params => ({
        url: apiRoutes.lingoLiftTeacherMyLibraryLLAssignmentDeleteTagPath(),
        method: 'DELETE',
        params,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [
        {type: CacheTags.TeacherMyLibraryAssignmentTags, id: 'LIST'},
        {type: CacheTags.TeacherMyLibraryAssignments, id: 'LIST'},
      ]
    }),
    deleteLingoLiftTeacherMyLibraryLLAssignmentUnTag: builder.mutation<void, ILingoLiftTeacherMyLibraryUnTagParams>({
      query: params => ({
        url: apiRoutes.lingoLiftTeacherMyLibraryLLAssignmentUnTagPath(),
        method: 'DELETE',
        params,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [
        {type: CacheTags.TeacherMyLibraryAssignmentTags, id: 'LIST'},
        {type: CacheTags.TeacherMyLibraryAssignments, id: 'LIST'},
      ]
    }),
  }),
});

export const {
  useGetLingoLiftTeacherMyLibraryLWAssignmentsQuery,
  useGetLingoLiftTeacherMyLibraryLWAssignmentTagsQuery,
  usePostLingoLiftTeacherMyLibraryLWAssignmentTagMutation,
  useDeleteLingoLiftTeacherMyLibraryLWAssignmentTagMutation,
  useDeleteLingoLiftTeacherMyLibraryLWAssignmentUnTagMutation,
  useGetLingoLiftTeacherMyLibraryLLAssignmentsQuery,
  useGetLingoLiftTeacherMyLibraryLLAssignmentTagsQuery,
  usePostLingoLiftTeacherMyLibraryLLAssignmentTagMutation,
  useDeleteLingoLiftTeacherMyLibraryLLAssignmentTagMutation,
  useDeleteLingoLiftTeacherMyLibraryLLAssignmentUnTagMutation,
} = lingoLiftMyLibraryApi;
