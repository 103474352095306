import * as React from 'react';
import { type FC } from 'react';

import { MainLayout } from '../../widgets';
import ContentLayout from '../../shared/ui/ContentLayout';
import {
  LingoLiftCollectionCard,
  useGetLingoLiftStudentCollectionDetailsQuery,
} from '../../entities/lingoLiftCollection';

interface ILingoLiftCollectionStudentShowPageProps {
  id: number;
  ecAscId?: number;
  currentUrl: string;
}

const LingoLiftCollectionStudentShowPage: FC<ILingoLiftCollectionStudentShowPageProps> = props => {
  const { id, ecAscId, currentUrl } = props;

  const { data: collection, isLoading } = useGetLingoLiftStudentCollectionDetailsQuery({
    id: id,
    ecAscId
  });

  return (
    <MainLayout isLoading={isLoading}>
      <ContentLayout>
        {collection && <LingoLiftCollectionCard
        {...collection}
        shareUrl={currentUrl}
        fullScreen />}
      </ContentLayout>
    </MainLayout>
  );
};

export default LingoLiftCollectionStudentShowPage;
