export const selectLingoLiftCollectionLessonParamsFromDomObject = (object: HTMLElement) => {
  const title = object.dataset.title ?? '';
  const beforeSubtitle = object.dataset.beforeSubtitle ?? '';
  const afterSubtitle = object.dataset.afterSubtitle ?? '';
  const linkName = object.dataset.linkName ?? '';
  const linkUrl = object.dataset.linkUrl ?? '';

  return {
    title,
    beforeSubtitle,
    afterSubtitle,
    linkName,
    linkUrl
  };
};
