import * as React from 'react';

const LingoLiftLogo = (props) => (
  <svg {...props} width="90" height="24" viewBox="0 0 90 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8677_3228)">
      <path
        d="M35.6181 18.2019C35.1324 18.2767 33.7322 18.3704 31.9774 18.3704C29.9793 18.3704 28 17.9763 28 14.8817V4.67784C28 4.4713 28.1678 4.30273 28.3734 4.30273H29.3258C29.5314 4.30273 29.6992 4.4713 29.6992 4.67784V14.8805C29.6992 16.3062 30.0171 16.8308 31.9952 16.8308H35.5992C35.7304 16.8308 35.8048 16.9246 35.8048 17.0564V17.9383C35.8048 18.0511 35.7304 18.1817 35.6181 18.2007V18.2019Z"
        fill="#09212A"/>
      <path
        d="M38.9043 6.27213H37.9519C37.7463 6.27213 37.5785 6.10357 37.5785 5.89702V4.39659C37.5785 4.19005 37.7463 4.02148 37.9519 4.02148H38.9043C39.1099 4.02148 39.2777 4.19005 39.2777 4.39659V5.89702C39.2777 6.10357 39.1099 6.27213 38.9043 6.27213ZM38.8665 18.2388H37.9708C37.7652 18.2388 37.5974 18.0702 37.5974 17.8636V8.29842C37.5974 8.09187 37.7652 7.92331 37.9708 7.92331H38.8665C39.0721 7.92331 39.2399 8.09187 39.2399 8.29842V17.8636C39.2399 18.0702 39.0721 18.2388 38.8665 18.2388Z"
        fill="#09212A"/>
      <path
        d="M48.6812 18.2363H47.7099C47.5043 18.2363 47.3743 18.0677 47.3743 17.8612V11.165C47.3743 9.75837 47.0198 9.21471 45.7496 9.21471H44.4237C43.1546 9.21471 42.799 9.75837 42.799 11.165V17.8612C42.799 18.0677 42.6678 18.2363 42.4634 18.2363H41.4921C41.2865 18.2363 41.1565 18.0677 41.1565 17.8612V11.165C41.1565 8.27694 43.1546 7.78906 44.4237 7.78906H45.7496C47.0186 7.78906 49.0168 8.27694 49.0168 11.165V17.8612C49.0168 18.0677 48.8857 18.2363 48.6812 18.2363Z"
        fill="#09212A"/>
      <path
        d="M55.0728 21.9315C53.6536 21.9315 52.4401 21.744 51.8989 21.5944C51.6377 21.5196 51.5255 21.4449 51.5255 21.2003V20.6567C51.5255 20.4691 51.6744 20.3563 51.8611 20.3563H51.9166C52.5701 20.4311 54.0826 20.5819 55.0716 20.5819C56.8074 20.5819 57.2187 20.151 57.2187 18.2932V18.1057C56.7708 18.2185 55.6872 18.368 55.1271 18.368C52.7934 18.368 50.9642 17.2617 50.9642 13.754V12.3841C50.9642 9.2705 52.3266 7.78906 55.1271 7.78906C56.2284 7.78906 57.5921 8.0146 58.4688 8.21996C58.8044 8.29474 58.8612 8.38852 58.8612 8.65086V18.2161C58.8612 21.0484 57.6098 21.9304 55.0704 21.9304L55.0728 21.9315ZM57.2198 9.38327C56.7153 9.28949 55.9129 9.17672 55.1283 9.17672C53.2802 9.17672 52.6079 10.0765 52.6079 12.3841V13.754C52.6079 16.1542 53.4669 16.9804 55.1283 16.9804C55.6506 16.9804 56.6408 16.9056 57.2198 16.7738V9.38445V9.38327Z"
        fill="#09212A"/>
      <path
        d="M64.9549 18.3688C62.2099 18.3688 60.8097 16.5491 60.8097 13.7927V12.3671C60.8097 9.60957 62.2099 7.79102 64.9549 7.79102C67.6999 7.79102 69.1179 9.61076 69.1179 12.3671V13.7927C69.1179 16.5502 67.6987 18.3688 64.9549 18.3688ZM67.4754 12.3671C67.4754 10.4536 66.7097 9.23446 64.9549 9.23446C63.2002 9.23446 62.4534 10.4346 62.4534 12.3671V13.7927C62.4534 15.7062 63.2002 16.9253 64.9549 16.9253C66.7097 16.9253 67.4754 15.7252 67.4754 13.7927V12.3671Z"
        fill="#09212A"/>
      <path
        d="M72.3237 18.2373H71.3524C71.1657 18.2373 71.0168 18.0688 71.0168 17.8622V4.48893C71.0168 4.30137 71.148 4.13281 71.3524 4.13281H72.3237C72.5104 4.13281 72.6593 4.28238 72.6593 4.48893V17.8622C72.6593 18.0498 72.5281 18.2373 72.3237 18.2373Z"
        fill="#09212A"/>
      <path
        d="M75.9336 6.27213H74.9812C74.7756 6.27213 74.6078 6.10357 74.6078 5.89702V4.39659C74.6078 4.19005 74.7756 4.02148 74.9812 4.02148H75.9336C76.1392 4.02148 76.307 4.19005 76.307 4.39659V5.89702C76.307 6.10357 76.1392 6.27213 75.9336 6.27213ZM75.8958 18.2388H75.0001C74.7945 18.2388 74.6267 18.0702 74.6267 17.8636V8.29842C74.6267 8.09187 74.7945 7.92331 75.0001 7.92331H75.8958C76.1014 7.92331 76.2692 8.09187 76.2692 8.29842V17.8636C76.2692 18.0702 76.1014 18.2388 75.8958 18.2388Z"
        fill="#09212A"/>
      <path
        d="M82.8427 5.33305H81.1813C80.1545 5.33305 79.9489 5.50162 79.9489 6.60794V7.92082H82.8616C83.0672 7.92082 83.2161 8.05258 83.2161 8.25794V8.89538C83.2161 9.08294 83.0672 9.23251 82.8616 9.23251H79.9489V17.86C79.9489 18.0665 79.7811 18.2351 79.5755 18.2351H78.6798C78.4742 18.2351 78.3064 18.0665 78.3064 17.86V6.60676C78.3064 4.59946 79.4266 4 81.1813 4C81.8537 4 82.5071 4.05579 82.8616 4.11277C83.1417 4.15076 83.235 4.26234 83.235 4.43209V5.06953C83.235 5.23809 83.1039 5.33187 82.8427 5.33187V5.33305Z"
        fill="#09212A"/>
      <path
        d="M89.6254 18.2577C89.2709 18.3135 88.5052 18.3705 87.8329 18.3705C86.0781 18.3705 85.0702 17.7889 85.0702 15.7638V6.08576C85.0702 5.89821 85.238 5.76644 85.4436 5.72965L86.3582 5.58008H86.396C86.5827 5.58008 86.7138 5.74864 86.7138 5.93619V7.9245H89.6266C89.8322 7.9245 89.9811 8.05626 89.9811 8.26162V8.89906C89.9811 9.08662 89.8322 9.23618 89.6266 9.23618H86.7138V15.7638C86.7138 16.8511 87.0683 17.0386 88.0952 17.0386H89.6077C89.8688 17.0386 90 17.1134 90 17.301V17.9384C90 18.107 89.9067 18.2198 89.6266 18.2577H89.6254Z"
        fill="#09212A"/>
      <path
        d="M15.2025 22.875C15.0787 22.875 14.7692 22.8403 14.5806 22.5164L13.6651 20.9416L13.4319 20.541L12.9713 20.5873C12.5625 20.6292 12.1464 20.6495 11.7319 20.6495C10.2362 20.6495 8.78667 20.3834 7.42347 19.857C6.76994 19.6054 6.138 19.2931 5.54924 18.9301C4.96625 18.5715 4.4178 18.1594 3.91974 17.7067C3.42311 17.2541 2.97255 16.7567 2.58101 16.2289C2.18659 15.6967 1.84831 15.1284 1.5748 14.5398C1.00764 13.3194 0.719742 12.0222 0.719742 10.6861C0.719742 9.3499 1.00764 8.05278 1.5748 6.8323C1.84831 6.24375 2.18659 5.67544 2.58101 5.14329C2.97255 4.61547 3.42311 4.11803 3.91974 3.66541C4.4178 3.21134 4.96481 2.80066 5.54924 2.44203C6.13944 2.07907 6.76994 1.76672 7.42347 1.5151C8.78667 0.990179 10.2362 0.722656 11.7319 0.722656C13.2275 0.722656 14.6771 0.988733 16.0403 1.5151C16.6938 1.76672 17.3257 2.07907 17.9145 2.44203C18.4975 2.80066 19.0459 3.21279 19.5426 3.66541C20.0392 4.11803 20.4898 4.61547 20.8813 5.14329C21.2757 5.67544 21.614 6.24375 21.8875 6.8323C22.4547 8.05278 22.7426 9.3499 22.7426 10.6861C22.7426 11.5103 22.6317 12.3303 22.4115 13.1227C22.197 13.892 21.8803 14.6396 21.4686 15.3424C21.0627 16.0351 20.5646 16.6858 19.9902 17.2758C19.4145 17.8658 18.7624 18.3936 18.0527 18.8448L17.9044 18.9388L17.8166 19.0906L15.8244 22.5164C15.6358 22.8388 15.3277 22.875 15.2025 22.875Z"
        fill="white"/>
      <path
        d="M15.2025 22.1509L17.3718 18.4215L17.6684 18.2335C20.3962 16.4996 22.0243 13.6784 22.0243 10.6865C22.0243 5.59195 17.4078 1.44607 11.7319 1.44607C6.05595 1.44607 1.43949 5.5905 1.43949 10.6865C1.43949 15.7824 6.05595 19.9268 11.7319 19.9268C12.1263 19.9268 12.515 19.9066 12.8979 19.8675L13.8177 19.775L15.1953 22.1509C15.1953 22.1509 15.1982 22.1509 15.2011 22.1509M15.2011 23.597C14.7188 23.597 14.2366 23.3584 13.9588 22.8812L13.0433 21.3064C12.6128 21.3498 12.1752 21.3729 11.7319 21.3729C5.25271 21.3729 0 16.5893 0 10.6865C0 4.7836 5.25271 0 11.7319 0C18.211 0 23.4638 4.7836 23.4638 10.6865C23.4638 14.3175 21.4758 17.5249 18.437 19.4554L16.4448 22.8812C16.167 23.3584 15.6847 23.597 15.2025 23.597H15.2011Z"
        fill="#09212A"/>
      <path
        d="M8.27709 6.62305C8.79386 6.62305 9.21276 7.04385 9.21276 7.56299V13.6365C9.21276 14.1556 8.79386 14.5764 8.27709 14.5764C7.76031 14.5764 7.34142 14.1556 7.34142 13.6365V7.56299C7.34142 7.04385 7.76031 6.62305 8.27709 6.62305Z"
        fill="#F04779"/>
      <path
        d="M10.9027 8.64662C11.4195 8.65529 11.8312 9.08333 11.8226 9.60247L11.788 11.627C11.7794 12.1461 11.3533 12.5597 10.8365 12.551C10.3197 12.5423 9.90805 12.1143 9.91668 11.5952L9.95123 9.57066C9.95987 9.05152 10.386 8.63794 10.9027 8.64662Z"
        fill="#C3325D"/>
      <path
        d="M13.4593 5.61133C13.9761 5.61133 14.3949 6.03213 14.3949 6.55127V14.6493C14.3949 15.1684 13.9761 15.5892 13.4593 15.5892C12.9425 15.5892 12.5236 15.1684 12.5236 14.6493V6.55127C12.5236 6.03213 12.9425 5.61133 13.4593 5.61133Z"
        fill="#C3325D"/>
      <path
        d="M16.1943 5.03125C16.7111 5.03125 17.13 5.45206 17.13 5.97119V15.3706C17.13 15.8898 16.7111 16.3106 16.1943 16.3106C15.6775 16.3106 15.2586 15.8898 15.2586 15.3706V5.97119C15.2586 5.45206 15.6775 5.03125 16.1943 5.03125Z"
        fill="#C3325D"/>
      <path
        d="M5.68601 8.06836C6.20279 8.06836 6.62168 8.48917 6.62168 9.0083V12.1897C6.62168 12.7088 6.20279 13.1296 5.68601 13.1296C5.16923 13.1296 4.75034 12.7088 4.75034 12.1897V9.0083C4.75034 8.48917 5.16923 8.06836 5.68601 8.06836Z"
        fill="#C3325D"/>
    </g>
    <defs>
      <clipPath id="clip0_8677_3228">
        <rect width="90" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)

export default LingoLiftLogo;
