const styles = {
  container: {
    paddingLeft: '81px',
    paddingTop: '5px',
    paddingBottom: '10px',
    paddingRight: '16px',
    boxSizing: 'border-box',
  },
  column: {
    display: 'grid',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
  columnTitle: {
    fontSize: '12px',
    fontWeight: 600,
  },
  dotedIcon: {
    fontSize: '20px',
  },
  lineTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  link: {
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '600',
    color: 'secondary.dark',
  }
};

export default styles;
