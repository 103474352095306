enum LingoLiftSearchProduct {
  LingoLift = 'lingolift',
  ListenWise = 'listenwise',
  General = 'general',
}

enum LingoLiftFilterKey {
  Topic = 'lw_topic',
  LwLexileAudioMessure = 'lw_lexile',
}

enum FilterOptionType {
  Checkbox = 'checkbox',
  Range = 'range',
  Chip = 'chip',
  RangeWithPresets = 'range_with_presets',
}

enum LingoLiftRequestQueryKeys {
  Products = 'products',
  GenGradeLevels = 'gen_grade_levels',
  GenElpBands = 'gen_elp_bands',
  LwSubjects = 'lw_subjects',
  LwContentTypes = 'lw_content_types',
  LwLexile = 'lw_lexile',
  LwStoryLength = 'lw_story_length',
  LwTopic = 'lw_topic',
  LlElpLevels = 'll_elp_levels',
  LlCategoryIds = 'll_category_ids',
  llVideoLength = 'll_video_length',
}

enum LingoLiftLexileLevel {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High'
}

export { LingoLiftSearchProduct, LingoLiftFilterKey, FilterOptionType, LingoLiftRequestQueryKeys, LingoLiftLexileLevel };
