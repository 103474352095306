import { Button, Pagination } from '@mui/material';
import React, { type FC } from 'react';

import styles from './styles';
import { IPagination } from '../../store';
import { NavigateNextRounded } from '@mui/icons-material';
import Div from '../Div';

interface IPaginationLineProps {
  pagination: IPagination;
  onCurrentPageChange: (page: number) => void;
  currentPage: number;
  size?: string;
}

const PaginationLine: FC<IPaginationLineProps> = props => {
  const { pagination, onCurrentPageChange, currentPage, size = '' } = props;

  return (
    <Div sx={styles.wrapper}>
      <Button
        disabled={currentPage === 1}
        onClick={() => currentPage <= pagination?.totalPages && onCurrentPageChange(currentPage - 1)}
        sx={styles.button}
        startIcon={<NavigateNextRounded sx={{ ...styles.buttonIcon, ...styles.prevIcon }} />}
      >
        Prev
      </Button>
      <Pagination
        onChange={(_, page) => onCurrentPageChange(page)}
        page={currentPage}
        hideNextButton
        hidePrevButton
        variant="text"
        count={pagination.totalPages}
        shape="rounded"
        size={size}
      />
      <Button
        disabled={currentPage === pagination.totalPages}
        onClick={() => currentPage < pagination.totalPages && onCurrentPageChange(currentPage + 1)}
        sx={styles.button}
        endIcon={<NavigateNextRounded sx={styles.buttonIcon} />}
      >
        Next
      </Button>
    </Div>
  );
};

export default PaginationLine;
