import * as React from 'react';
import { BoxProps, styled } from '@mui/material';

interface IDivProps extends BoxProps {
  children?: React.ReactNode;
}

const Div = styled('div')<IDivProps>({});

export default Div;
