import { Autocomplete, Typography, TextField, Checkbox } from '@mui/material';
import React, { FC, useState, useEffect } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import sx from 'mui-sx';
import { CheckBoxRounded, ExpandMoreRounded } from '@mui/icons-material';
import { IOption } from '../../types/common';
import styles from './styles';
import isEntireClasses from './lib/isEntireClasses';
import getUpdatedOptions from './lib/getUpdatedOption';
import Div from '../Div';
import { ILingoLiftManageClassStudentItem } from '../../../entities/lingoliftClasses';
import { isEmpty } from 'ramda';

interface ITreeSelectProps {
  label: string;
  students: Array<ILingoLiftManageClassStudentItem>;
  setValue: (name: string, value: string) => void;
  defaultValue: Array<IOption>;
  isError: boolean;
}

const entireClassesOption = {
  label: 'Entire class',
  value: 'all',
};

const TreeSelect: FC<ITreeSelectProps> = props => {
  const { label, students: studentsList, setValue, defaultValue, isError = false } = props;

  const studentsOptions = studentsList.map(({ id, firstName, lastName }) => ({
    value: id,
    label: `${firstName} ${lastName}`,
  }));

  const [selectedOptions, setSelectedOptions] = useState<Array<IOption>>(defaultValue);
  const [isEntireClassSelected, setIsEntireClassSelected] = useState<boolean>(false);

  useEffect(() => {
    const isAllClassesSelected = isEntireClasses(selectedOptions);
    const hasSelectedOptions = !isEmpty(selectedOptions);
    setIsEntireClassSelected(isAllClassesSelected);
    if (hasSelectedOptions) {
      if (isAllClassesSelected) {
        setValue('selectedStudents', true);
        setValue('selectedStudentIds', [0]);
        return;
      }
      setValue('selectedStudents', true);
      setValue('selectedStudentIds', selectedOptions.map(option => option.value));
    } else setValue('selectedStudents', false);
  }, [selectedOptions, setValue]);

  const handleChange = (_, option) => {
    const updatedOptions = getUpdatedOptions(option, selectedOptions);
    setSelectedOptions(updatedOptions);
    setIsEntireClassSelected(isEntireClasses(updatedOptions));
  };

  return (
    <Div>
      <Typography>{label}</Typography>
      <Autocomplete
        multiple
        options={[entireClassesOption, ...studentsOptions]}
        value={isEntireClassSelected ? [entireClassesOption] : selectedOptions}
        disableCloseOnSelect
        sx={styles.container}
        popupIcon={<ExpandMoreRounded />}
        getOptionLabel={option => option.label}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              sx={sx(styles.defaultOption, {
                sx: styles.parentOption,
                condition: isEntireClasses([option]),
              })}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxRounded fontSize="small" />}
              checked={
                selectedOptions.some(selectedOption => option.value === selectedOption.value) ||
                isEntireClassSelected ||
                selected
              }
            />
            {option.label}
          </li>
        )}
        onChange={handleChange}
        renderInput={params => <TextField {...params} error={isError} />}
        getOptionDisabled={option => isEntireClassSelected && option.value !== 'all'}
      />
    </Div>
  );
};

export default TreeSelect;