import React, { useCallback, useEffect, useState } from 'react';
import ContentLayout from '../../shared/ui/ContentLayout';
import lingoLiftIcon from '../../assets/tabsLogos/lingoLiftIcon.svg';
import lingoLiftActiveIcon from '../../assets/tabsLogos/lingoLiftActiveIcon.svg';
import listenWiseIcon from '../../assets/tabsLogos/listenWiseIcon.svg';
import listenWiseActiveIcon from '../../assets/tabsLogos/listenWiseActiveIcon.svg';
import appIcon from '../../assets/tabsLogos/appIcon.svg';
import appActiveIcon from '../../assets/tabsLogos/appActiveIcon.svg';

import { MainLayout } from '../../widgets';

import Div from '../../shared/ui/Div';
import TabsContainer from '../../shared/ui/Tabs/ui';
import { debounce, Grid, IconButton, Typography } from '@mui/material';
import {
  LingoLiftSearchCard,
  useGetLingoLiftStudentSearchFilterQuery,
  composePrimitiveValues,
  composeFilledParams,
  LingoLiftCollapsedFilterSection,
  LingoLiftSearchCollapsedFiltersSectionWrapper,
  useGetLingoLiftStudentSearchQuery,
} from '../../entities/lingoLiftSearch';
import SearchInput from '../../shared/ui/SearchInput';
import { useDesktop } from '../../shared/hooks/useDesktop';
import PaginationLine from '../../shared/ui/PaginationLine';
import { RestartAlt } from '@mui/icons-material';
import styles from './styles';
import { isFilledParams } from '../../entities/lingoLiftSearch/lib/compareConditions';
import { getUrlSearchParams } from "../../shared/utils/urlParsers";
import { paginationPluralizeResult } from "../../shared/utils/paginationPluralizeResult";

// render tabs list
const searchTabs = [
  {
    name: 'All Libraries',
    icon: appIcon,
    activeIcon: appActiveIcon,
  },
  {
    name: 'Listenwise',
    icon: listenWiseIcon,
    activeIcon: listenWiseActiveIcon,
  },
  {
    name: 'Lingolift',
    icon: lingoLiftIcon,
    activeIcon: lingoLiftActiveIcon,
  },
];

// select type for query params by tab index
const productSearchIndexedType = {
  0: '',
  1: 'listenwise',
  2: 'lingolift',
};

const LingoLiftSearchStudentBrowsePage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [params, setParams] = useState<Record<string, string | number | Array<string | number>>>({
    query: '',
  });
  const isDesktop = useDesktop();

  const { data: searchResult, isLoading } = useGetLingoLiftStudentSearchQuery({
    page: currentPage,
    ...params,
  });
  const { data: filterOptions,  refetch: resetFilters } = useGetLingoLiftStudentSearchFilterQuery({
    products: productSearchIndexedType[tabIndex]
  });

  useEffect(() => {
    if (getUrlSearchParams(window.location.search, 'query')?.length) {
      setParams({
        ...params,
        query: getUrlSearchParams(window.location.search, 'query'),
      });
    }
  }, []);

  const handleChangeFilter = (key: string, value: string | number) => {
    if (typeof value === 'string' || typeof value === 'number') {
      if (Array.isArray(params[key]) || !params[key]) {
        const filteredParams = {
          ...params,
          [key]: composePrimitiveValues(params[key] as Array<string | number>, value),
        };
        setParams(composeFilledParams(filteredParams));
      }
    }
  };

  const handleChangeSliderFilter = debounce((key: string, value: string | number) => {
    if (Array.isArray(value)) {
      setParams({
        ...params,
        [key]: value.join(','),
      });
    }
  }, 500);

  const handleChangeSearchInput = useCallback(debounce((value: string) => {
    setParams(prev => ({
      ...prev,
      query: value
    }));
  }, 500), []);

  const onChangeProductType = (index: keyof typeof productSearchIndexedType) => {
    setSearchInputValue('');
    setTabIndex(index);
    setParams({
      query: '',
      products: productSearchIndexedType[index],
    });
  };

  const onRefresh = () => {
    setParams({});
    setTabIndex(0);
    setSearchInputValue('');
    resetFilters();
  };

  return (
    <MainLayout backgroundColor="athensGray" isLoading={isLoading}>
      <ContentLayout>
        {!isDesktop && <SearchInput
          value={searchInputValue}
          handleChange={(value) => {
          handleChangeSearchInput(value);
          setSearchInputValue(value);
        }} />}
        <TabsContainer
          tabs={searchTabs}
          tabIndex={tabIndex}
          setTabIndex={onChangeProductType}
          isBordered
          sectionTitle={isDesktop && 'Search'}
        >
          <Div sx={styles.containedWrapper}>
            <Grid container columnSpacing={{ lg: 2, md: 2 }} rowGap={{ lg: 0, xs: 2 }}>
              <Grid item lg={2} md={2} xs={12}>
                <LingoLiftSearchCollapsedFiltersSectionWrapper>
                  {filterOptions &&
                    Object.keys(filterOptions)
                      .map(sectionKey => ({
                        ...filterOptions[sectionKey],
                        productType: sectionKey,
                      }))
                      .map((filterSection, index) => (
                        <LingoLiftCollapsedFilterSection
                          key={index}
                          {...filterSection}
                          onChangeFilter={handleChangeFilter}
                          onChangeSliderFilter={handleChangeSliderFilter}
                          filteredParams={params}
                        />
                      ))}
                </LingoLiftSearchCollapsedFiltersSectionWrapper>
              </Grid>

              <Grid item lg={10} md={10} xs={12}>
                {isDesktop && <SearchInput
                  bottomSpace
                  value={searchInputValue}
                  handleChange={(value) => {
                    handleChangeSearchInput(value);
                    setSearchInputValue(value);
                  }} />}
                <Grid container spacing={2}>
                  <Grid item xs={12} display="flex" gap="10px" alignItems="center">
                    <Typography variant="body2">
                      { paginationPluralizeResult(searchResult?.pagination, searchResult?.items.length) }
                    </Typography>
                    {isFilledParams(params) && (
                      <IconButton onClick={onRefresh}>
                        <RestartAlt />
                      </IconButton>
                    )}
                  </Grid>
                  {searchResult?.items.map(searchResult => (
                    <Grid key={searchResult.id} item xs={12}>
                      <LingoLiftSearchCard {...searchResult} />
                    </Grid>
                  ))}
                </Grid>
                {searchResult && searchResult?.pagination.totalPages > 0 && (
                  <PaginationLine
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pagination={searchResult?.pagination!}
                  />
                )}
              </Grid>
            </Grid>
          </Div>
        </TabsContainer>
      </ContentLayout>
    </MainLayout>
  );
};

export default LingoLiftSearchStudentBrowsePage;
