const styles = {
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    height: '100%',
  },
  loaderAlignStart: {
    alignItems: 'start',
  },
};

export default styles;
