import React, { FC, useEffect } from 'react';
import styles from './styles';
import { Button, Checkbox, Link, TextField, Typography } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import Div from "../../../shared/ui/Div";
import sx from "mui-sx";
import { formFields } from "../model/formFields";
import { SignInFormFieldType, SignInFormType } from "../model/enums";
import { getFormDefaultValues, getNestedFormErrorMessage } from '../../../shared/utils/helpers';
import { IDefaultSignInInput } from '../model/types';
import { ProductType } from '../../../shared/store/commonEnums';

interface ISignInFormProps {
  formType: SignInFormType;
  csrfToken?: string;
  url?: string;
  isLingoLiftStatus: boolean;
  defaultInputs: Array<IDefaultSignInInput>;
  defaultErrors: Array<string>;
  onChangeStatus: (value: boolean) => void;
}

const SignInForm: FC<ISignInFormProps> = (props) => {
  const { formType, url, isLingoLiftStatus, defaultInputs, defaultErrors, onChangeStatus } = props;

  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: getFormDefaultValues(defaultInputs),
  });

  const product = isLingoLiftStatus ? ProductType.Lingolift : ProductType.Listenwise;
  const productFieldName =
    formType === SignInFormType.Student ? 'student[product]' : 'teacher[product]';

  useEffect(() => {
    setValue(productFieldName, product);
  }, [isLingoLiftStatus, setValue]);

  useEffect(() => {
    onChangeStatus(getFormDefaultValues(defaultInputs)[productFieldName] === ProductType.Lingolift);
  }, [defaultInputs]);

  useWatch({ control });

  const isHidden = (type: string) => type === SignInFormFieldType.Hidden;

  const checkboxValue = (value) => value ? '1' : '0';

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setValue(name, checkboxValue(checked));
  };

  return (
    <form action={url} method="post">
      <Div sx={styles.formContainer}>
        <Typography
          sx={{
            ...styles.formItemLabelRequiredSymbol,
            ...styles.formItemLabel,
          }}
        >
          indicates a required field.
        </Typography>
        {formFields[formType]?.map((field, index) => (
          <Div key={index}>
            {(!Boolean(field.type) || field.type !== SignInFormFieldType.CheckBox) && (
              <Div
                sx={sx(styles.formItem, {
                  sx: styles.hiddenItem,
                  condition: isHidden(field.type),
                })}
              >
                <Div
                  sx={sx(styles.formItemLabel, {
                    sx: styles.formItemLabelRequiredSymbol,
                    condition: field.required,
                  })}
                >
                  <Typography variant="h5">{field.label}</Typography>
                  {field.labelLink && (
                    <Link sx={styles.formItemLabelLink} href={field.labelLink.href}>
                      {field.labelLink.name}
                    </Link>
                  )}
                </Div>
                <TextField
                  type={Boolean(field.type) && !isHidden(field.type) ? field.type : 'text'}
                  {...register(field.name)}
                  error={!!getNestedFormErrorMessage(errors, field.name)}
                  helperText={getNestedFormErrorMessage(errors, field.name) || ''}
                  placeholder={field.placeholder}
                  required={field.required}
                  fullWidth
                />
                {field.underInputLink && <Typography>{field.underInputLink}</Typography>}

                {field.underInputText && <Typography>{field.underInputText}</Typography>}
              </Div>
            )}

            {field.type === SignInFormFieldType.CheckBox && (
              <Div sx={styles.formItemCheckbox}>
                <input type="hidden" name={field.name} value="0" />
                <Checkbox
                  {...register(field.name)}
                  value={getValues(field.name)}
                  checked={['1'].includes(getValues(field.name))}
                  onChange={handleCheckboxChange}
                />
                <Div sx={styles.formItemCheckboxLabel}>
                  <Typography>{field.label}</Typography>

                  {field.labelLink && (
                    <Link sx={styles.formItemLabelLink} href={field.labelLink.link}>
                      {field.labelLink.name}
                    </Link>
                  )}
                </Div>
              </Div>
            )}
          </Div>
        ))}

        <Button sx={styles.submitButton} type="submit" variant="contained">
          Log In
        </Button>
        {defaultErrors && (
          <Typography sx={styles.error}>
            {Array.isArray(defaultErrors) ? defaultErrors.join('; ') : defaultErrors}
          </Typography>
        )}
      </Div>
    </form>
  );
}

export default SignInForm;
