const styles = {
  root: {
    background: 'white',
    color: 'primary.dark',
    padding: '16px',
    borderBottom: '1px solid',
    borderColor: 'pantone'
  },
  description: {
    fontWeight: 600,
    marginBottom: '5px',
  },
  title: {
    color: 'black',
    fontWeight: 450,
  },
  wrapperResponse: {
    display: 'flex',
    alignItems: 'center',
  },
  statusSuccess: {
    color: 'bottleGreen',
  },
  statusFailure: {
    color: 'crownOfThorns',
  },
  iconFailure: {
    color: 'flushMahogany',
    marginRight: '5px',
  },
  iconSuccess: {
    color: 'Eucalyptus',
    marginRight: '5px',
  },
  response: {
    fontWeight: 450,
  },
  attemptsWrapper: {
    textAlign: 'right',
  },
};

export default styles;
