const styles = {
  container: {
   padding: '16px'
  },
  panel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dropDown: {
    width: '320px'
  },
  averageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    paddingTop: '10px',
  },
  averageTitle: {
    color: 'primary.dark',
  },
  averagePercentage: {
    color: 'black'
  },
  averageCountLabel: {
    color: 'primary.dark'
  },
  transparentButton: {
    display: 'block',
    textAlign: 'center',
    backgroundColor: 'white',
    color: 'primary.main',
    padding: '10px 10px',
    border: '1px solid',
    borderColor: 'semiGray',
    borderRadius: '8px',
    width: '100%',
    '&:hover': {
      backgroundColor: 'secondary.main',
      color: 'white',
    },
  },
}

export default styles;
