import React, { FC } from 'react';
import Div from '../../../../shared/ui/Div';
import styles from './styles';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography
} from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import appRoutes from '../../../../shared/store/appRoutes';
import { ILingoLiftImportGoogleroomClass } from '../../model/types';
import { parseGoogleroomClassWithValue } from '../../lib/parseGoogleroomClass';
import { HelpOutlineRounded } from "@mui/icons-material";

interface ILihgoLiftCreateClassFormProps {
  onChange: (values: Array<ILingoLiftImportGoogleroomClass>) => void;
  courses: Array<ILingoLiftImportGoogleroomClass>;
  isListenwise?: boolean;
}

const LingoLiftClassroomImportForm: FC<ILihgoLiftCreateClassFormProps> = props => {
  const { onChange, courses, isListenwise } = props;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      courses: parseGoogleroomClassWithValue([...courses]),
      policyAccepted: false,
    }
  });

  const {
    fields,
  } = useFieldArray({
    control,
    name: "courses",
  });

  const onSubmit = (data: { policyAccepted: boolean, courses: Array<ILingoLiftImportGoogleroomClass & { value: boolean }> }) => {
    onChange(data.courses.filter((item) => item.value))
  };

  return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Div sx={styles.formContainer}>
          <Grid container sx={styles.formHeader}>
            <Grid item xs={4}>
                <Typography>
                  Check for Import
                </Typography>
            </Grid>
            { isListenwise && <Grid item xs={4}>
              <Div sx={styles.titleWrapper}>
                <Typography textAlign="center">
                  Elementary classroom
                </Typography>
                <Tooltip
                  placement="top"
                  arrow
                  title="Selecting this will restrict what content students can find on their own to items appropriate for elementary students">
                  <IconButton>
                    <HelpOutlineRounded />
                  </IconButton>
                </Tooltip>
              </Div>
            </Grid> }
            { isListenwise && <Grid item xs={4}>
              <Div sx={styles.titleWrapper}>
                <Typography textAlign="center">
                  Student-selected quizzes
                </Typography>
                <Tooltip
                  placement="top"
                  arrow
                  title="Allow students to select stories and take quizzes without a teacher-created assignment">
                  <IconButton>
                    <HelpOutlineRounded />
                  </IconButton>
                </Tooltip>
              </Div>
            </Grid> }
          </Grid>
          <Grid container>
            <Grid item xs={4} sx={styles.formItem}>
              {fields.map((item, index) => <Controller
                key={item.id}
                render={({ field }) =><FormControlLabel control={<Checkbox
                  disabled={item.paired}
                  {...field} />}
                                                        label={item.name} />}
                name={`courses.${index}.value`}
                control={control}
              />)}
            </Grid>
            { isListenwise && <Grid item xs={4} sx={styles.formItem}>
              {fields.map((item, index) => <Controller
                key={item.id}
                render={({ field }) =><FormControlLabel control={<Checkbox
                  sx={styles.checkboxItem}
                  disabled={item.paired}
                  {...field} />}
                label={''}/>}
                name={`courses.${index}.elementary`}
                control={control}
              />)}
            </Grid> }
            { isListenwise && <Grid item xs={4} sx={styles.formItem}>
              {fields.map((item, index) => <Controller
                key={item.id}
                render={({ field }) =><FormControlLabel control={<Checkbox
                  sx={styles.checkboxItem}
                  disabled={item.paired}
                  {...field} />}
                  label={''}/>}
                name={`courses.${index}.studentStartQuiz`}
                control={control}
              />)}
            </Grid> }
          </Grid>
          <FormGroup>
            <FormControlLabel
              {...register('policyAccepted', { required: true })}
              control={<Checkbox />}
              label={
                <Typography>
                  Accept Privacy{' '}
                  <Link sx={styles.actionLink} href={appRoutes.privatePolicy}>
                    Policy For
                  </Link>{' '}
                  Students
                </Typography>
              }
            />
            {Boolean(errors.policyAccepted) && <Typography color="red">must be accepted</Typography>}
          </FormGroup>
          <Button type="submit" variant="contained">
            Import
          </Button>
        </Div>
      </form>
  );
};

export default LingoLiftClassroomImportForm;
