import * as yup from "yup";
import { passwordFieldPattern } from "../../../shared/utils/regExpRules";

export const manageClassResetStudentPasswordFormSchema  = yup.object().shape({
  password: yup.string()
    .required('Field is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(passwordFieldPattern, 'Password must be contain at least 1 symbol, 1 uppercase letter'),
  passwordConfirmation: yup.string()
    .required('Field is required')
    .min(8, 'Password must be at least 8 characters')
    .oneOf([yup.ref('password')], 'Passwords must match')
    .matches(passwordFieldPattern, 'Password must be contain at least 1 symbol, 1 uppercase letter'),
});
