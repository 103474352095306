const styles = {
  tagsBarContainer: {
    boxShadow: `0px 3px 7px primary.main`,
    padding: '15px',
    backgroundColor: 'white',
    borderRadius: '12px',
    border: '1px solid',
    borderColor: 'semiGray'
  },
  tagsContainerTitle: {
    fontSize: '12px',
    fontWeight: 600,
    color: 'primary.dark'
  },
  tagsContainerSubTitle: {
    fontSize: '12px',
    color: 'primary.dark'
  },
  tagWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
  },
  tagsSection: {
    padding: '10px 0px'
  },
  link: {
    cursor: 'pointer',
    fontSize: '14px',
    color: 'secondary.dark',
  },
}

export default styles;
