const styles = {
  optionSection: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    paddingBottom: '20px',
  },
  actionLink: {
    color: 'secondary.dark',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  formItemCheckbox: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  formItemLabel: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
  },
  formItemCheckboxLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    width: '100%',
  },
  formItemLabelRequiredSymbol: {
    '&::before': {
      position: 'absolute',
      left: '-10px',
      top: 0,
      content: '"*"',
      color: 'red',
      fontSize: '14px',
    },
  },
  italic: {
    fontStyle: 'italic',
  },
  itemWithTooltip: {
    display: 'flex',
    alignItems: 'center',
  }
};

export default styles;
