import dayjs from "dayjs";

export const parseTimeToMmSs = (value: number) => {
  const minutes = Math.floor(value / 60);
  const seconds = value % 60;

  const formattedTime = dayjs()
    .minute(minutes)
    .second(seconds)

  return formattedTime
}

export const parseInputTimeToMm = (value: number) => {
  return Math.floor(dayjs(value).minute() * 60) + dayjs(value).second()
}
