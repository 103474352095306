const styles = {
  container: {
    minHeight: '166px',
    width: '100%',
    backgroundColor: 'geyser',
    borderRadius: '12px',
    overflow: 'hidden',
    backgroundSize: 'cover'
  },
  content: {
    display: 'block',
    backgroundColor: 'riverBed',
    padding: '16px',
  },
  contentDateTitle: {
    fontWeight: 600,
    fontSize: '12px',
    color: 'silverSand',
    textTransform: 'uppercase'
  },
  contentDescription: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '12px'
  }
}

export default styles;
