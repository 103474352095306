import { palette } from "../palette";

export default {
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.Mui-checked': {
          color: palette.secondary.dark
        }
      },
    },
  },
};
