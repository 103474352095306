import React, { FC } from 'react';
import Div from '../../../../shared/ui/Div';
import {
  Button,
  Grid,
  Modal,
  Typography
} from '@mui/material';
import styles from './styles';
import { ElpLevel } from '../../model/enums';
import { generateElpContentByElpLevel } from "../../lib/generateElpContentByElpLevel";

interface ILingoLiftLessonElpDetailsModalProps {
  isOpen: boolean;
  elpContent: Record<string, Record<string, Array<string> >> | null;
  onClose: () => void;
}

// Now this component is not using in project, but can be use in the future.

const LingoLiftLessonElpDetailsModal: FC<ILingoLiftLessonElpDetailsModalProps> = props => {
  const { isOpen, onClose, elpContent } = props;


  return (
    <Modal open={isOpen} onClose={onClose} closeAfterTransition>
      <Div sx={styles.container}>

        <Typography variant="h1">
          English Language Profiency
        </Typography>

        <Div>
          <Grid container>
            <Grid sx={styles.smallSection} item xs={4}>
              <Typography sx={styles.category}>
                Standards
              </Typography>
            </Grid>
            <Grid sx={styles.smallSection} item xs={4}>
              <Typography sx={styles.category}>
                ELP 1-3
              </Typography>
            </Grid>
            <Grid sx={styles.smallSection} item xs={4}>
              <Typography sx={styles.category}>
                ELP 4-6
              </Typography>
            </Grid>
          </Grid>

          { elpContent && Object.keys(elpContent).map((subCategory, subIndex) => <Grid
            container
            key={subIndex}>

            <Grid item sx={styles.standardSection} xs={4}>
              <Typography>
                { subCategory }
              </Typography>
            </Grid>

            <Grid item key={`${ElpLevel.ELP_1_3}-${subCategory}`} sx={styles.standardSection} xs={4}>

              { generateElpContentByElpLevel(elpContent, subCategory, ElpLevel.ELP_1_3) }

            </Grid>

            <Grid item key={`${ElpLevel.ELP_4_6}-${subCategory}`} sx={styles.standardSection} xs={4}>
              { generateElpContentByElpLevel(elpContent, subCategory, ElpLevel.ELP_4_6) }
            </Grid>
          </Grid>) }
        </Div>

        <Div sx={styles.actionsPanel}>
          <Button onClick={onClose}>
            Close
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};

export default LingoLiftLessonElpDetailsModal;
