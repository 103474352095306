const styles = {
  sliderContainer: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
  },
  input: {
    maxWidth: '120px',
    height: '40px'
  }
};

export default styles;
