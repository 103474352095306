enum SignUpFormType {
  Student = 'student',
  TeacherTrial = 'teacherTrial',
  TeacherPremium = 'teacherPremium',
  CompleteAccountSetup = 'completeAccountSetup',
  Teacher = 'teacher',
  TeacherOmniAuth = 'teacherOmniAuth',
  StudentOmniAuth = 'studentOmniAuth',
}

enum SignUpFormFieldType {
  Password = 'password',
  Email = 'email',
  CheckBox = 'checkbox',
  Select = 'select',
  Hidden = 'hidden',
}

enum TeacherSignUpPageStep {
  signUp = 'signUp',
  completeAccountSetup = 'completeAccountSetup',
}

export { SignUpFormType, SignUpFormFieldType, TeacherSignUpPageStep };
