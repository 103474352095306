import React, { FC } from 'react';
import styles from './styles';
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import {
  ILingoLiftTeacherClassesSummaryLLAscReport,
  ILingoLiftTeacherClassesSummaryLLEcAscReport,
} from "../../module/types";
import sx from "mui-sx";
import { LingoLiftSummaryStatus } from "../../module/enums";

interface ILingoLiftClassesSummaryTableProps  {
  llAscReport?: ILingoLiftTeacherClassesSummaryLLAscReport;
  llEcAscReport?: ILingoLiftTeacherClassesSummaryLLEcAscReport;
}

const LingoLiftClassesAscSummaryTable:FC<ILingoLiftClassesSummaryTableProps> = (props) => {
  const { llEcAscReport, llAscReport } = props;

  return (
    <TableContainer component={Paper} sx={styles.container}>
      <Table>
        <TableHead sx={styles.tableHead}>
          <TableRow sx={styles.headRow}>
            <TableCell sx={styles.titleCell}>
              <Typography variant="h5">Student</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="h5">Status</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="h5">Watch Completion</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="h5">Learn Completion</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="h5">Speak Score</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(llAscReport?.items || llEcAscReport?.items)?.map((row, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                <Link href={row.url} sx={styles.name}>
                  {row.name}
                </Link>
              </TableCell>
              <TableCell align="left">
                <Typography sx={sx(
                  styles.status,
                    {
                      sx: styles.statusType.notStarted,
                      condition: row.status === LingoLiftSummaryStatus.NotStarted
                    },
                  {
                    sx: styles.statusType.started,
                    condition: row.status === LingoLiftSummaryStatus.Started
                  },
                  {
                    sx: styles.statusType.completed,
                    condition: row.status === LingoLiftSummaryStatus.Completed
                  }
                )} variant="body1">{row.status}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="body1">{row.watchedVideosOfTotalLabel}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="body1">{row.learnQuestionsCorrectOfTotalLabel}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="body1">{row.spokenAverageGradePercent}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LingoLiftClassesAscSummaryTable;
