import { palette } from '../palette';

export default {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        color: palette.codGray,
        backgroundColor: palette.white,
        margin: 0,
        fontFamily: 'Inter, sans-serif !important',
        fontOpticalSizing: 'auto',
        fontWeight: 400,
        fontStyle: 'normal',
      },
    },
  },
};
