import * as React from 'react';

const AttentionIcon = (props) => (
  <svg { ...props } width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0ZM9 4C9.41421 4 9.75 4.33579 9.75 4.75V6.25C9.75 6.66421 9.41421 7 9 7C8.58579 7 8.25 6.66421 8.25 6.25V4.75C8.25 4.33579 8.58579 4 9 4ZM9 9C9.41421 9 9.75 9.33579 9.75 9.75L9.75 13.25C9.75 13.6642 9.41421 14 9 14C8.58579 14 8.25 13.6642 8.25 13.25L8.25 9.75C8.25 9.33579 8.58579 9 9 9Z"
          fill="currentFill"/>
  </svg>)

export default AttentionIcon
