import { LingoLiftLexileLevel } from '../model/enums';

export const displayLexileLevel = (lexileLevel: number) => {
  if (lexileLevel === 1) {
    return LingoLiftLexileLevel.Low
  }

  if (lexileLevel === 2) {
    return LingoLiftLexileLevel.Medium
  }

  if (lexileLevel === 3) {
    return LingoLiftLexileLevel.High
  }

  return ''
}
