const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'unset',
  },
  iframe: {
    width: '100%',
    height: {
      xs: '55vh',
      sm: 'max-content',
    },
    padding: {
      xs: '0px',
      md: '30px 0px 10px 0px',
    },
  },
  sideBar: {
    backgroundColor: 'athensGray',
    borderRight: '1px solid',
    borderColor: 'pantone',
  },
  container: {
    width: '100%',
    padding: '20px 30px',
  },
};

export default styles;
