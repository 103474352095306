import { selectResponse } from '../../../shared/utils/selectFromResponseObjects';
import { apiContainer, apiRoutes } from '../../../shared/store';
import { IHeader } from '../model/types';
import { CacheTags } from '../../../shared/store/commonEnums';

export const lingoLiftLessonsApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLingoLiftStudentHeaderList: builder.query<IHeader, null>({
      query: () => ({
        url: apiRoutes.lingoLiftStudentHeaderPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.HeaderMenu, id: 'LIST' }],
    }),
    getLingoLiftTeacherHeaderList: builder.query<IHeader, null>({
      query: () => ({
        url: apiRoutes.lingoLiftTeacherHeaderPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.HeaderMenu, id: 'LIST' }],
    }),
    getLingoLiftPublicHeaderList: builder.query<IHeader, null>({
      query: () => ({
        url: apiRoutes.lingoLiftPublicHeaderPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
  }),
});

export const {
  useGetLingoLiftStudentHeaderListQuery,
  useGetLingoLiftTeacherHeaderListQuery,
  useGetLingoLiftPublicHeaderListQuery
} = lingoLiftLessonsApi;
