import * as React from 'react';
import ReduxProvider from '../reduxProvider';
import { createRoot } from 'react-dom/client';
import SignUpPage from '../../pages/AuthPages/SignUp';
import { SignInFormType, SignUpFormType } from '../../widgets';
import SignInPage from '../../pages/AuthPages/SignIn';
import { selectLingoLiftAuthParamsFromDomObject } from '../../shared/utils/selectLingoLiftAuthParamsFromDomObject';
import ForgotPasswordPage from '../../pages/AuthPages/ForgotPassword';
import { ForgotPasswordFormType } from '../../widgets/ForgotPasswordForm/model/enums';
import {
  selectLingoLiftResetPasswordParamsFromDomObject
} from '../../shared/utils/selectLingoLiftResetPasswordParamsFromDomObject';
import MainAuthPage from "../../pages/AuthPages/MainPage";

const authRoutesRender = () => {
  const signUpTeacherElement = document.querySelector('#lingolift_teachers_sign_up');

  const signUpStudentElement = document.querySelector('#lingolift_students_sign_up');

  const signInStudentElement = document.querySelector('#lingolift_students_sign_in');

  const signInTeacherElement = document.querySelector('#lingolift_teachers_sign_in');

  const signUpOmniAuthTeacherElement = document.querySelector('#lingolift_teachers_omniauth_sign_up');

  const signUpOmniAuthStudentElement = document.querySelector('#lingolift_students_omniauth_sign_up');

  const forgotPasswordStudentElement = document.querySelector('#lingolift_students_password_new');

  const changePasswordStudentElement = document.querySelector('#lingolift_students_password_edit');

  const forgotPasswordTeacherElement = document.querySelector('#lingolift_teachers_password_new');

  const changePasswordTeacherElement = document.querySelector('#lingolift_teachers_password_edit');

  const mainSignInElement = document.querySelector('#lingolift_sign_in');

  const mainSignUpElement = document.querySelector('#lingolift_sign_up');

  const renderElement = (signUpTeacherElement ||
    signUpStudentElement ||
    signInStudentElement ||
    signInTeacherElement ||
    signUpOmniAuthTeacherElement ||
    signUpOmniAuthStudentElement ||
    forgotPasswordStudentElement ||
    changePasswordStudentElement ||
    forgotPasswordTeacherElement ||
    changePasswordTeacherElement ||
    mainSignInElement ||
    mainSignUpElement)

  if (renderElement) {
    const root = createRoot(renderElement);

    if (
      signInTeacherElement ||
      signInStudentElement ||
      signUpTeacherElement ||
      signUpStudentElement ||
      signUpOmniAuthTeacherElement ||
      signUpOmniAuthStudentElement
    ) {

      const {
        url,
        error,
        inputs,
        recaptchaSiteKey,
        googleUrl,
        googleQueryWho,
        googleQueryCommon,
        googleAlt,
        cleverUrl,
        cleverQueryType,
        cleverQueryCommon,
        cleverAlt,
        forgotPasswordUrl,
        notice,
      } = selectLingoLiftAuthParamsFromDomObject(
        (
          signInTeacherElement ||
          signInStudentElement ||
          signUpTeacherElement ||
          signUpStudentElement ||
          signUpOmniAuthTeacherElement ||
          signUpOmniAuthStudentElement ||
          forgotPasswordStudentElement ||
          changePasswordStudentElement ||
          forgotPasswordTeacherElement ||
          changePasswordTeacherElement
        ) as HTMLElement,
      );

      return root.render(
        <ReduxProvider>
          {signInTeacherElement && (
            <SignInPage
              formType={SignInFormType.Teacher}
              url={url}
              defaultInputs={inputs}
              defaultErrors={error}
              googleUrl={googleUrl}
              googleQueryWho={googleQueryWho}
              googleQueryCommon={googleQueryCommon}
              googleAlt={googleAlt}
              cleverUrl={cleverUrl}
              cleverQueryType={cleverQueryType}
              cleverQueryCommon={cleverQueryCommon}
              cleverAlt={cleverAlt}
              forgotPasswordUrl={forgotPasswordUrl}
              notice={notice}
            />
          )}
          {signInStudentElement && (
            <SignInPage
              formType={SignInFormType.Student}
              url={url}
              defaultInputs={inputs}
              defaultErrors={error}
              googleUrl={googleUrl}
              googleQueryWho={googleQueryWho}
              googleQueryCommon={googleQueryCommon}
              googleAlt={googleAlt}
              cleverUrl={cleverUrl}
              cleverQueryType={cleverQueryType}
              cleverQueryCommon={cleverQueryCommon}
              cleverAlt={cleverAlt}
              forgotPasswordUrl={forgotPasswordUrl}
              notice={notice}
            />
          )}
          {signUpOmniAuthTeacherElement && (
            <SignUpPage
              formType={SignUpFormType.TeacherOmniAuth}
              url={url}
              defaultInputs={inputs}
              recaptchaSitekey={recaptchaSiteKey}
              defaultErrors={error}
            />
          )}
          {signUpOmniAuthStudentElement && (
            <SignUpPage
              formType={SignUpFormType.StudentOmniAuth}
              url={url}
              defaultInputs={inputs}
              recaptchaSitekey={recaptchaSiteKey}
              defaultErrors={error}
            />
          )}
          {signUpTeacherElement && (
            <SignUpPage
              formType={SignUpFormType.TeacherPremium}
              url={url}
              defaultInputs={inputs}
              recaptchaSitekey={recaptchaSiteKey}
              defaultErrors={error}
              googleUrl={googleUrl}
              googleQueryWho={googleQueryWho}
              googleQueryCommon={googleQueryCommon}
              googleAlt={googleAlt}
              cleverUrl={cleverUrl}
              cleverQueryType={cleverQueryType}
              cleverQueryCommon={cleverQueryCommon}
              cleverAlt={cleverAlt}
            />
          )}
          {signUpStudentElement && (
            <SignUpPage
              formType={SignUpFormType.Student}
              url={url}
              defaultInputs={inputs}
              recaptchaSitekey={recaptchaSiteKey}
              defaultErrors={error}
              googleUrl={googleUrl}
              googleQueryWho={googleQueryWho}
              googleQueryCommon={googleQueryCommon}
              googleAlt={googleAlt}
              cleverUrl={cleverUrl}
              cleverQueryType={cleverQueryType}
              cleverQueryCommon={cleverQueryCommon}
              cleverAlt={cleverAlt}
            />
          )}
        </ReduxProvider>,
      );
    }

    if (
      forgotPasswordStudentElement ||
      changePasswordStudentElement ||
      forgotPasswordTeacherElement ||
      changePasswordTeacherElement
    ) {
      const {
        url,
        error,
        inputs,
        notice
      } = selectLingoLiftResetPasswordParamsFromDomObject(
        (
          forgotPasswordStudentElement ||
          changePasswordStudentElement ||
          forgotPasswordTeacherElement ||
          changePasswordTeacherElement
        ) as unknown as HTMLElement,
      );

      return root.render(<ReduxProvider>
        {forgotPasswordTeacherElement && (
          <ForgotPasswordPage notice={notice} url={url} defaultErrors={error} defaultInputs={inputs} formType={ForgotPasswordFormType.teacherForgotPassword}/>
        )}

        {forgotPasswordStudentElement && (
          <ForgotPasswordPage notice={notice} url={url} defaultErrors={error} defaultInputs={inputs} formType={ForgotPasswordFormType.studentForgotPassword} />
        )}

        {changePasswordStudentElement && (
          <ForgotPasswordPage notice={notice} url={url} defaultErrors={error} defaultInputs={inputs} formType={ForgotPasswordFormType.studentEditPassword} />
        )}

        {changePasswordTeacherElement && (
          <ForgotPasswordPage notice={notice} url={url} defaultErrors={error} defaultInputs={inputs} formType={ForgotPasswordFormType.teacherEditPassword} />
        )}
      </ReduxProvider>)
    }

    return root.render(<ReduxProvider>
      { mainSignInElement && <MainAuthPage isSignIn signInParams={(mainSignInElement as HTMLElement)?.dataset} /> }
      { mainSignUpElement && <MainAuthPage signUpParams={(mainSignUpElement as HTMLElement)?.dataset} /> }
    </ReduxProvider>)


  }
};

export default authRoutesRender;
