import React, { useState } from 'react';
import ContentLayout from '../../shared/ui/ContentLayout';

import { MainLayout } from '../../widgets';

import Div from '../../shared/ui/Div';
import { Grid, Typography, Button } from '@mui/material';

import { UserRole } from "../../entities/user";
import { useDeleteLingoLiftTeachersFavoriteTagMutation, useGetLingoLiftTeacherFavoritesQuery } from '../../entities/lingoLiftFavorite';
import PaginationLine from '../../shared/ui/PaginationLine';
import styles from './styles';
import LingoLiftMyFavoritesTag from '../../entities/lingoLiftFavorite/ui/MyFavoritesTag';
import { uniqBy, prop } from 'ramda';
import LingoLiftFavoritesTeacherCard from '../../entities/lingoLiftFavorite/ui/FavoriteCard';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { isEmpty } from 'ramda';
import { ILingoLiftFavoritesTagParams } from '../../entities/lingoLiftFavorite';

const LingoLiftFavoritesTeacherBrowsePage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [params, setParams] = useState<ILingoLiftFavoritesTagParams>({});

  const {
    data: favoritesResult,
    isLoading,
  } = useGetLingoLiftTeacherFavoritesQuery({
    page: currentPage,
    ...params,
  });

  const [deleteTag] = useDeleteLingoLiftTeachersFavoriteTagMutation();

  const favoritesTags = favoritesResult?.items?.flatMap(item => item.tags || []);

  const uniqueFavoritesTags = favoritesTags && uniqBy(prop('id'), favoritesTags);

  const handleResetParams = () => {
    setParams({});
    setCurrentPage(1);
  };

  return (
    <MainLayout userRole={UserRole.Teacher} backgroundColor="athensGray" isLoading={isLoading}>
      <ContentLayout>
        <Grid container spacing={5}>
          <Grid item xs={8}>
            {isEmpty(params) ? (
              <Typography variant="h1">My Favorites</Typography>
            ) : (
              <Div sx={styles.titleWrapper}>
                <Typography variant="h1">
                  Favorites Tagged:{' '}
                  <Typography variant="h1" component="span" color="secondary.dark">
                    {params.tag}
                  </Typography>
                </Typography>
                <Button variant="text" onClick={handleResetParams}>
                  See All Favorites
                </Button>
              </Div>
            )}
            {isEmpty(favoritesResult?.items) ? (
              <Typography>You haven't favorited any lessons or events yet</Typography>
            ) : (
              favoritesResult?.items.map((card) => <LingoLiftFavoritesTeacherCard key={card.id} card={card} />)
            )}
          </Grid>
          <Grid item xs={4}>
            <Div sx={styles.tagsBarContainer}>
              <Typography sx={styles.tagsContainerSubTitle}>
                <LocalOfferOutlinedIcon sx={styles.icon} />
                MY favorites tags
              </Typography>
              <Typography fontSize="10px" color="primary.dark">
                Click any tag to see tagged favorites
              </Typography>
              <Div sx={styles.tagWrapper}>
                {uniqueFavoritesTags &&
                  uniqueFavoritesTags.map((tag) => (
                    <LingoLiftMyFavoritesTag
                      key={tag.id}
                      label={tag.name}
                      onDelete={() => deleteTag({ tagId: tag.id })}
                      onParamsChange={() => setParams({ tag: tag.name })}
                    />
                  ))}
              </Div>
            </Div>
          </Grid>
          <Grid item xs={12}>
            <Div sx={styles.paginationWrapper}>
              {favoritesResult?.items && favoritesResult?.pagination.totalPages > 0 && (
                <PaginationLine
                  currentPage={currentPage}
                  onCurrentPageChange={setCurrentPage}
                  pagination={favoritesResult?.pagination}
                />
              )}
            </Div>
          </Grid>
        </Grid>
      </ContentLayout>
    </MainLayout>
  );
};

export default LingoLiftFavoritesTeacherBrowsePage;
