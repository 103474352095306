import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const resolver = yupResolver(
  Yup.object({
    isActive: Yup.boolean(),
    dueDateTime: Yup.string().required('Due Date Time is required'),
    googleClassroomData: Yup.string().nullable(),
    id: Yup.number().nullable(),
    llLessonId: Yup.string().nullable(),
    llLessonName: Yup.string().nullable(),
    llSchoolClassId: Yup.number().nullable(),
    releaseDateTime: Yup.string().nullable(),
    selectedStudents: Yup.boolean()
      .oneOf([true], 'Students or class must be selected')
      .required('Student or class selection is required'),
    selectedStudentIds: Yup.array(),
    syncToGoogleClassroom: Yup.bool(),
    syncWithGradesToGoogleClassroom: Yup.bool(),
    teacherId: Yup.number().nullable(),
  }),
);
