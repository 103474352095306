const studentSignInFormFields = [
  {
    name: 'student[username]',
    label: 'Username',
    placeholder: 'Username',
    required: true,
    requiredText: 'Required',
  },
  {
    name: 'student[password]',
    label: 'Password',
    placeholder: 'Password',
    required: true,
    type: 'password',
    underInputText: 'If you don\'t remember your username or password, please ask your teacher for help.',
  },
  {
    name: 'student[product]',
    label: 'product',
    type: 'hidden',
  },
  {
    name: 'authenticity_token',
    label: 'token',
    type: 'hidden',
  },
];

const teacherSignInFormFields = [
  {
    name: 'teacher[email]',
    label: 'Email',
    placeholder: 'Email',
    required: true,
    requiredText: 'Required',
    type: 'email',
  },
  {
    name: 'teacher[password]',
    label: 'Password',
    placeholder: 'Password',
    required: true,
    type: 'password',
  },
  {
    name: 'teacher[remember_me]',
    label: 'Remember me',
    type: 'checkbox',
  },
  {
    name: 'teacher[invite_access_token]',
    type: 'hidden',
  },
  {
    name: 'teacher[enroll_school_trial]',
    type: 'hidden',
  },
  {
    name: 'teacher[product]',
    type: 'hidden',
  },
  {
    name: 'authenticity_token',
    label: 'token',
    type: 'hidden',
  },
];

const formFields = {
  student: studentSignInFormFields,
  teacher: teacherSignInFormFields,
};

export { formFields };
