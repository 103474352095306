import React, { useState } from 'react';
import { MainLayout } from '../../widgets';
import ContentLayout from '../../shared/ui/ContentLayout';
import Div from '../../shared/ui/Div';
import { UserRole } from '../../entities/user';
import styles from './styles';
import { Button, TextField, Typography } from '@mui/material';
import { usePostLingoLiftStudentDashboardJoinClassMutation } from '../../entities/lingoLiftStudentDashboard';
import Loader from "../../shared/ui/Loader";
import { useSnackbar } from '../../shared/hooks/useSnackbar';
import { appRoutes } from '../../shared/store';

const LingoLiftDashboardStudentJoinClassPage = () => {
  const [inputText, setInputText] = useState('');
  const [
    onJoinClass,
    {
      isError: isJoinClassError,
      isLoading: isJoinClassLoading,
      error: joinClassError,
    },
  ] = usePostLingoLiftStudentDashboardJoinClassMutation();

  const { showSuccessNotification, showErrorNotification } = useSnackbar();

  const handleJoinClass = async () => {
    try {
      await onJoinClass({ accessToken: inputText }).unwrap();
      showSuccessNotification();
      window.location.href = appRoutes.studentDashboard;
    } catch (error) {
      showErrorNotification(error.response.data.error);
    }
  };

  return (
    <MainLayout userRole={UserRole.Student}>
      <ContentLayout>
        {isJoinClassError && (
          <Div sx={styles.errorWrapper}>
            <Typography color="hibiscus">{joinClassError.response.data.error}</Typography>
          </Div>
        )}

        <Div sx={styles.container}>
          <Typography variant="h1">Join a Class</Typography>
          <Typography>If your teacher gave you an invite code, please copy or enter it below.</Typography>
          <Typography variant="h4">Class invite code</Typography>

          <TextField
            sx={styles.input}
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Enter your class code"
          />
          {isJoinClassLoading ? (
            <Loader />
          ) : (
            <Button disabled={isJoinClassLoading} onClick={handleJoinClass}>
              Join
            </Button>
          )}
        </Div>
      </ContentLayout>
    </MainLayout>
  );
};

export default LingoLiftDashboardStudentJoinClassPage;
