const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    backgroundColor: 'white',
    margin: '0 auto',
    width: {
      lg: '70%',
    },
    padding: '30px 20px 20px 20px',
    textAlign: 'center'
  },
  input: {
    width: '100%'
  },
  errorWrapper: {
    backgroundColor: 'bridesmaid',
    padding: '10px',
    width: '100%',
    textAlign: 'center',
    marginBottom: '10px',
  }
}

export default styles;
