import React, { FC, useState } from 'react';
import Div from '../../../../shared/ui/Div';
import styles from './styles';
import {
  Button,
  Grid,
  Link,
  Paper,
  Table,
  TableBody, TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import lingoLiftLogo from '../../../../assets/lingoLiftLessonLogo.svg';
import listenWiseLogo from '../../../../assets/listenWiseLessonLogo.svg';
import Image from '../../../../shared/ui/Image';
import { isLingoLiftProduct, isListenWiseProduct } from '../../../../shared/utils/lingoLiftCompareConditions';
import sx from 'mui-sx';
import { ArrowRightRounded } from '@mui/icons-material';
import { LingoLiftProduct } from "../../../../shared/types/common-enums";
import { ILingoLiftTeacherClassesStudentProgress } from "../../module/types";

interface ILingoLiftClassesProgressCardProps extends ILingoLiftTeacherClassesStudentProgress {
  product: LingoLiftProduct
}

const LingoLiftClassesProgressCard:FC<ILingoLiftClassesProgressCardProps> = (props) => {
  const {
    product = LingoLiftProduct.LingoLift,
    schoolClassUrl,
    schoolClassName,
    students,
    quizAveragePercent,
    seeClassSummaryReportUrl,
    seeAllAssignedUrl
  } = props;

  const [isShowRoosterClasses, setIsShowRoosterClasses] = useState(false)
  return (
    <Div sx={styles.container}>
      <Grid
        sx={{
          ...styles.row,
          ...styles.rowSpace,
          ...styles.borderedRow,
        }}
        container
      >
        <Div>
          { isLingoLiftProduct(product) && <Image src={ lingoLiftLogo } alt="lingolift icon"/> }

          { isListenWiseProduct(product) && <Image src={ listenWiseLogo } alt="listenwise icon"/> }

          <Div sx={styles.titleWrapper}>
            <Link href={schoolClassUrl} sx={styles.title}>
              { schoolClassName }
            </Link>
            { students.length > 0 && <Typography>
              { students.length }
            </Typography> }
          </Div>
        </Div>
      </Grid>

      <Grid sx={{
        ...styles.row,
        ...styles.contentRow
      }}>
        <Div sx={styles.coverageRow}>
          <Typography fontWeight={700}>
            Class Completion Average: { quizAveragePercent }
          </Typography>
          <Div
            sx={styles.actions}
          >
            <Link href={seeClassSummaryReportUrl} sx={{
              ...styles.actionButton,
              ...styles.viewSummary
            }}>
              View Class Summary Report
            </Link>
            <Link href={seeAllAssignedUrl} sx={{
              ...styles.actionButton,
              ...styles.viewAssigned
            }}>
              View All Assigned
            </Link>
          </Div>
        </Div>
        <Div sx={styles.progressContainer}>
            <Div sx={styles.progressWrapper}>
              { quizAveragePercent > 0 && <Div
                sx={sx(
                  {
                    sx: {
                      ...styles.progressFilledZone,
                      width: `${quizAveragePercent}%`,
                    },
                    condition: quizAveragePercent > 0
                  },
                  {
                    sx: styles.lineType.cinderella,
                    condition: true
                  }
                )}
              >
                <Typography sx={styles.progressPercent}>
                  {quizAveragePercent}
                </Typography>
              </Div> }
            </Div>
          </Div>

        <Button
          onClick={() => setIsShowRoosterClasses(!isShowRoosterClasses)}
          sx={styles.roosterButton}
          endIcon={<ArrowRightRounded />}>
          Class Roster
        </Button>

        { isShowRoosterClasses && <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead sx={styles.tableHead}>
              <TableRow>
                <TableCell>Student Name</TableCell>
                <TableCell align="right">Average score</TableCell>
                <TableCell align="right">Last quiz score</TableCell>
                <TableCell align="right">Last quiz name</TableCell>
                <TableCell align="right">Last quiz submitted at</TableCell>
                <TableCell align="right">Last quiz time spent</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <Link href={row.studentUrl}>
                      {row.studentName}
                    </Link>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      {row.averageScore.level} {row.averageScore.result}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      {row.lastQuizQuizableName}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      {row.lastQuizSubmittedAt}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      {row.lastQuizTimeSpent}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> }
      </Grid>
    </Div>
  );
};

export default LingoLiftClassesProgressCard;
