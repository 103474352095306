const styles = {
  container: {
    borderRadius: '12px',
    border: '1px solid',
    borderColor: 'semiGray',
    boxShadow: '0px 2px 8px -1px #1B242C14, 0px 2px 2px -1px #1B242C0A',
  },
  tableHead: {
    backgroundColor: 'athensGray',
    '& th': {
      padding: '10px 16px',
    },
  },
  headRow: {
    height: '40px',
  },
  titleCell: {
    minWidth: '470px',
  },
  name: {
    fontSize: '14px',
    color: 'secondary.dark',
    fontWeight: 700,
  },
  status: {
    padding: '0px 8px',
    borderRadius: '4px',
    width: 'max-content',
    textAlign: 'center'
  },
  statusType: {
    notStarted: {
      backgroundColor: 'pantone'
    },
    started: {
      backgroundColor: 'secondary.light'
    },
    completed: {
      backgroundColor: 'grannyApple'
    }
  }
};

export default styles;
