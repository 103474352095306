export const selectLingoLiftLessonParamsFromDomObject = (object: HTMLElement) => {
  const accessToken = JSON.parse(object.dataset.access ?? '');
  const key = JSON.parse(object.dataset.key ?? '');
  const dialogId = JSON.parse(object.dataset.dialog ?? '');
  const lessonId = JSON.parse(object.dataset.id ?? '');
  const edGateUrl = object.dataset.edGateUrl ?? '';
  const languageCode = object.dataset.languageCode ?? '';
  const currentUrl = object.dataset.currentUrl ?? '';

  return {
    id: lessonId,
    partnerKey: key,
    accessToken,
    dialogId,
    edGateUrl,
    languageCode,
    currentUrl,
  };
};
