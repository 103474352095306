import * as React from "react";
import { createRoot } from "react-dom/client";
import { selectLingoLiftLessonParamsFromDomObject } from "../../shared/utils/selectLingoLiftLessonParamsFromDomObject";
import ReduxProvider from "../reduxProvider";
import LingoLiftLessonTeacherShowPage from "../../pages/LingoLiftLessonTeacherShow";
import LingoLiftLessonStudentShowPage from "../../pages/LingoLiftLessonStudentShow";
import LingoLiftLessonStudentBrowsePage from "../../pages/LingoLiftLessonStudentBrowse";
import LingoLiftLessonTeacherBrowsePage from "../../pages/LingoLiftLessonTeacherBrowse";
import LingoLiftLessonAssignPage from "../../pages/LingoLiftLessonAssignPage";
import { selectRailsPropIdFromHtmlElement } from "../../shared/utils/selectors";

const lingoLiftLessonRoutesRender = () => {
  // LingoLift Show details pages render
  const lingoLiftLessonStudentShowElement = document.querySelector('#lingolift_lesson_students_show');
  const lingoLiftLessonTeacherShowElement = document.querySelector('#lingolift_lesson_teachers_show');
  // LingoLift Browse lessons pages render
  const lingoLiftLessonStudentBrowseElement = document.querySelector('#lingolift_lesson_students_browse');
  const lingoLiftLessonTeacherBrowseElement = document.querySelector('#lingolift_lesson_teachers_browse');

  const lingoLiftLessonAssignElement: HTMLElement | null = document.querySelector(
    '#lingolift_lesson_teachers_assign')

  const renderElement = (
    lingoLiftLessonStudentShowElement ||
    lingoLiftLessonTeacherShowElement ||
    lingoLiftLessonStudentBrowseElement ||
    lingoLiftLessonTeacherBrowseElement ||
    lingoLiftLessonAssignElement
  );

  if (renderElement) {
    const root = createRoot(renderElement);

    if (lingoLiftLessonStudentShowElement || lingoLiftLessonTeacherShowElement) {
      const { accessToken, partnerKey, dialogId, id, edGateUrl, languageCode, currentUrl } = selectLingoLiftLessonParamsFromDomObject(
        (lingoLiftLessonStudentShowElement || lingoLiftLessonTeacherShowElement) as HTMLElement
      );

      return root.render(
        <ReduxProvider>
          {lingoLiftLessonTeacherShowElement && (
            <LingoLiftLessonTeacherShowPage
              id={id}
              accessToken={accessToken}
              partnerKey={partnerKey}
              dialogId={dialogId}
              edGateUrl={edGateUrl}
              languageCode={languageCode}
              currentUrl={currentUrl}
            />
          )}

          {lingoLiftLessonStudentShowElement && (
            <LingoLiftLessonStudentShowPage
              id={id}
              accessToken={accessToken}
              partnerKey={partnerKey}
              dialogId={dialogId}
              languageCode={languageCode}
            />
          )}
        </ReduxProvider>
      );
    }

    return root.render(<ReduxProvider>
      { lingoLiftLessonStudentBrowseElement && <LingoLiftLessonStudentBrowsePage />}
      { lingoLiftLessonTeacherBrowseElement &&  <LingoLiftLessonTeacherBrowsePage />}
      { lingoLiftLessonAssignElement && <LingoLiftLessonAssignPage id={selectRailsPropIdFromHtmlElement(lingoLiftLessonAssignElement)} /> }
    </ReduxProvider>)
  }
}

export default lingoLiftLessonRoutesRender;
