import React from 'react';
import { type FC } from 'react';
import { CardMedia, Grid, Link, Typography, IconButton } from '@mui/material';
import { StarOutlineRounded, StarRounded } from '@mui/icons-material';
import Div from '../../../../shared/ui/Div';
import styles from './styles';
import Image from '../../../../shared/ui/Image';
import videoIcon from '../../../../assets/secondaryIcons/videoIcon.svg';
import quizIcon from '../../../../assets/secondaryIcons/quizIcon.svg';
import lingoLiftLessonLogo from '../../../../assets/untitledLogos/lingoLiftUntitledLogo.svg';
import listenWiseLessonLogo from '../../../../assets/untitledLogos/lintenWiseUntitledLogo.svg';
import { ILingoLiftTeacherDashboardFeed } from '../../model/types';
import { isLingoLiftProduct, isListenWiseProduct } from '../../../../shared/utils/lingoLiftCompareConditions';
import { sliceTextContent } from "../../../../shared/utils/sliceTextContent";

interface ILingoLiftTeacherDashboardFeedCardProps extends ILingoLiftTeacherDashboardFeed {
  onToggleFavorite?: (object: Record<string, string | number>, isFavorite: boolean) => void;
  isFavorite: boolean;
}

const LingoLiftTeacherDashboardFeedCard: FC<ILingoLiftTeacherDashboardFeedCardProps> = props => {
  const {
    id,
    type,
    description,
    name,
    category,
    photoSrc,
    product,
    quiz,
    url,
    video,
    durationMmSs,
    onToggleFavorite,
    isFavorite,
  } = props;

	return (
    <Grid container spacing={1}>
      <Grid item lg={4} display={{ xs: 'none', lg: 'grid' }}>
        <CardMedia sx={styles.poster} component="img" image={photoSrc} alt="green iguana" />
      </Grid>
      <Grid sx={styles.content} item lg={7} xs={11}>
        <Typography variant="body2" color="primary.dark">
          {category ? `${category} | ` : ''}Length: {durationMmSs}
        </Typography>
        <Link sx={styles.title} href={url}>
          {name}
        </Link>
        <Typography variant="body2" color="primary.dark">
          {sliceTextContent(description, 100)}
        </Typography>
        <Div sx={styles.tagWrapper}>
          {isLingoLiftProduct(product) && <Image src={lingoLiftLessonLogo} alt="lingo lift lesson logo" />}
          {isListenWiseProduct(product) && <Image src={listenWiseLessonLogo} alt="listen wise lesson logo" />}
          {video && <Image sx={styles.tagIcon} src={videoIcon} alt="Listenwise watch card icon" />}
          {isListenWiseProduct(product) && quiz && (
            <Image sx={styles.tagIcon} src={quizIcon} alt="Listenwise card quiz icon" />
          )}
        </Div>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          arial-label="favorite"
          onClick={() => onToggleFavorite && onToggleFavorite({ favoritableId: id, favoritableType: type }, isFavorite)}
        >
          {isFavorite ? (
            <StarRounded sx={{ ...styles.favoriteIcon, color: 'secondary.dark' }} />
          ) : (
            <StarOutlineRounded sx={styles.favoriteIcon} />
          )}
        </IconButton>
      </Grid>
    </Grid>
  );
}
export default LingoLiftTeacherDashboardFeedCard;
