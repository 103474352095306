import * as React from 'react';
import ReduxProvider from '../reduxProvider';
import { createRoot } from 'react-dom/client';
import LingoLiftClassesTeacherBrowsePage from '../../pages/LingoLiftClassesTeacherBrowse';
import LingoLiftClassesTeacherAddLingoLiftClassPage from '../../pages/LingoLiftClassesTeacherAddLingoLiftClass';
import LingoLiftClassesTeacherAddListenwiseClassPage from '../../pages/LingoLiftClassesTeacherAddListenwiseClass';
import { selectSchoolParamsFromDomObject } from '../../shared/utils/selectors';
import LingoLiftGoogleClassroomImportAddPage from '../../pages/LingoLiftGoogleClassroomImportAdd';
import {
  selectLingoLiftGoogleClassroomImportsParamsFromDomObject
} from '../../shared/utils/selectLingoLiftGoogleClassroomImportsParamsFromDomObject';
import {
  selectLingoLiftSchoolClassesParamsFromDomObject
} from '../../shared/utils/selectLingoLiftSchoolClassesParamsFromDomObject';
import LingoLiftClassesTeacherSummaryLLReportPage from '../../pages/LingoLiftClassesTeacherSummaryLLReport';
import LingoLiftClassesTeacherSummaryLLAscReportPage from '../../pages/LingoLiftClassesTeacherSummaryLLAscReport';
import LingoLiftClassesTeacherSummaryLLEcAscReportPage from "../../pages/LingoLiftClassesTeacherSummaryLLEcAscReport";
import LingoLiftClassesTeacherLLAssignmentsReportPage from '../../pages/LingoLiftClassesTeacherLLAssignmentsReport';
import LingoLiftClassesTeacherLWAssignmentsReportPage from "../../pages/LingoLiftClassesTeacherLWAssignmentsReport";

const lingoLiftClassesRoutesRender = () => {
  const lingoLiftClassesTeacherBrowseElement = document.querySelector('#lingolift_teachers_classes');

  const lingoLiftClassesTeacherAddLingoLiftClassElement = document.querySelector('#lingolift_teachers_classes_ll_add');

  const lingoLiftClassesTeacherAddListenwiseClassElement = document.querySelector('#lingolift_teachers_classes_add');

  const lingoLiftLLGoogleClassesRoomImportElement = document.querySelector('#ll_google_classroom_courses_import');

  const lingoLiftLWGoogleClassesRoomImportElement = document.querySelector('#lw_google_classroom_courses_import');

  const lingoLiftClassesTeacherSummaryLLReportElement = document.querySelector('#lingolift_teachers_ll_school_classes_report');

  const lingoLiftClassesTeacherSummaryLLAscReportElement = document.querySelector('#lingolift_teachers_ll_school_classes_ll_lesson_asc_report');

  const lingoLiftClassesTeacherSummaryLLEcAscReportElement = document.querySelector('#lingolift_teachers_ll_school_classes_ll_ec_asc_report');

  const lingoLiftClassesTeacherLLAssignmentsReportElement = document.querySelector('#lingolift_teachers_ll_school_classes_assignments_report');

  const lingoLiftClassesTeacherLWAssignmentsReportElement = document.querySelector('#lingolift_teachers_school_classes_assignments_report');

  const renderElement =
    lingoLiftClassesTeacherBrowseElement ||
    lingoLiftClassesTeacherAddLingoLiftClassElement ||
    lingoLiftClassesTeacherAddListenwiseClassElement ||
    lingoLiftLLGoogleClassesRoomImportElement ||
    lingoLiftLWGoogleClassesRoomImportElement ||
    lingoLiftClassesTeacherSummaryLLReportElement ||
    lingoLiftClassesTeacherSummaryLLAscReportElement ||
    lingoLiftClassesTeacherSummaryLLEcAscReportElement ||
    lingoLiftClassesTeacherLLAssignmentsReportElement ||
    lingoLiftClassesTeacherLWAssignmentsReportElement;

  if (renderElement) {
    const root = createRoot(renderElement);

    if (lingoLiftClassesTeacherAddLingoLiftClassElement || lingoLiftClassesTeacherAddListenwiseClassElement) {
      const { schoolName, googleClassroomLink } = selectSchoolParamsFromDomObject(
        (lingoLiftClassesTeacherAddLingoLiftClassElement || lingoLiftClassesTeacherAddListenwiseClassElement) as HTMLElement,
      );

      return root.render(
        <ReduxProvider>
          {lingoLiftClassesTeacherAddLingoLiftClassElement && (
            <LingoLiftClassesTeacherAddLingoLiftClassPage schoolName={schoolName} googleClassroomLink={googleClassroomLink} />
          )}
          {lingoLiftClassesTeacherAddListenwiseClassElement && (
            <LingoLiftClassesTeacherAddListenwiseClassPage schoolName={schoolName} googleClassroomLink={googleClassroomLink} />
          )}
        </ReduxProvider>,
      );
    }

    return root.render(
      <ReduxProvider>
        { lingoLiftClassesTeacherBrowseElement && <LingoLiftClassesTeacherBrowsePage isListenwise={selectLingoLiftSchoolClassesParamsFromDomObject(lingoLiftClassesTeacherBrowseElement as HTMLElement)} /> }

        { lingoLiftLLGoogleClassesRoomImportElement && <LingoLiftGoogleClassroomImportAddPage
          isLingoLift
          courses={selectLingoLiftGoogleClassroomImportsParamsFromDomObject(lingoLiftLLGoogleClassesRoomImportElement as HTMLElement)} /> }

        { lingoLiftLWGoogleClassesRoomImportElement && <LingoLiftGoogleClassroomImportAddPage
          isListenWise
          courses={selectLingoLiftGoogleClassroomImportsParamsFromDomObject(lingoLiftLWGoogleClassesRoomImportElement as HTMLElement)} /> }

        { lingoLiftClassesTeacherSummaryLLReportElement && <LingoLiftClassesTeacherSummaryLLReportPage id={(lingoLiftClassesTeacherSummaryLLReportElement as HTMLElement).dataset.llSchoolClassId} /> }

        { lingoLiftClassesTeacherSummaryLLAscReportElement && <LingoLiftClassesTeacherSummaryLLAscReportPage
          llSchoolClassId={(lingoLiftClassesTeacherSummaryLLAscReportElement as HTMLElement).dataset.llSchoolClassId}
          llLessonAscId={(lingoLiftClassesTeacherSummaryLLAscReportElement as HTMLElement).dataset.llLessonAscId}
          /> }

        { lingoLiftClassesTeacherSummaryLLEcAscReportElement && <LingoLiftClassesTeacherSummaryLLEcAscReportPage
          llSchoolClassId={(lingoLiftClassesTeacherSummaryLLEcAscReportElement as HTMLElement).dataset.llSchoolClassId}
          llEcAscId={(lingoLiftClassesTeacherSummaryLLEcAscReportElement as HTMLElement).dataset.llEcAscId}
        /> }

        { lingoLiftClassesTeacherLLAssignmentsReportElement && <LingoLiftClassesTeacherLLAssignmentsReportPage llSchoolClassId={(lingoLiftClassesTeacherLLAssignmentsReportElement as HTMLElement).dataset.llSchoolClassId} /> }

        { lingoLiftClassesTeacherLWAssignmentsReportElement && <LingoLiftClassesTeacherLWAssignmentsReportPage lwSchoolClassId={(lingoLiftClassesTeacherLWAssignmentsReportElement as HTMLElement).dataset.schoolClassId} /> }
      </ReduxProvider>,
    );
  }
};

export default lingoLiftClassesRoutesRender;
