import { selectResponse } from '../../../shared/utils/selectFromResponseObjects';
import { apiContainer, apiRoutes } from '../../../shared/store';
import { ILingoLiftStandard, ILingoLiftStandardParams } from '../model/types';

export const lingoLiftStandardsApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLingoLiftStandards: builder.query<Array<ILingoLiftStandard>, ILingoLiftStandardParams>({
      query: params => ({
        url: apiRoutes.lingoLiftEdGateStandardsPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectResponse,
    }),
  }),
});

export const {
  useLazyGetLingoLiftStandardsQuery,
} = lingoLiftStandardsApi;
