import * as React from 'react';
import ReduxProvider from '../reduxProvider';
import { createRoot } from 'react-dom/client';
import LingoLiftMyLibraryBrowsePage from '../../pages/LingoLiftMyLibraryBrowse';
import {
  selectLingoLiftCollectionLessonParamsFromDomObject
} from '../../shared/utils/selectLingoLiftMyLibraryParamsFromDomObject';

const lingoLiftMyLibraryRoutesRender = () => {
  const lingoLiftMyLibraryBrowseElement = document.querySelector('#lingolift_my_library_browse');

  const renderElement = lingoLiftMyLibraryBrowseElement;
  if (renderElement) {
    const root = createRoot(renderElement);

    return root.render(
      <ReduxProvider>
        {lingoLiftMyLibraryBrowseElement && <LingoLiftMyLibraryBrowsePage
          isListenwise={(lingoLiftMyLibraryBrowseElement as HTMLElement).dataset.isListenwise as unknown as boolean}
          isLingolift={(lingoLiftMyLibraryBrowseElement as HTMLElement).dataset.isLingolift as unknown as boolean}
          {...selectLingoLiftCollectionLessonParamsFromDomObject(lingoLiftMyLibraryBrowseElement as HTMLElement)} />}
    </ReduxProvider>
  );
  }
};

export default lingoLiftMyLibraryRoutesRender;
