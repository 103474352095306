import { selectResponse } from '../../../shared/utils/selectFromResponseObjects';
import { apiContainer, apiRoutes, IPaginatedResponse } from '../../../shared/store';

import { ILingoLiftCollection, ILingoLiftCollectionFilter, ILingoLiftCollectionAssignmentResponse, } from '../model/types';
import { CacheTags } from '../../../shared/store/commonEnums';

export const lingoLiftCollectionApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLingoLiftTeacherCollections: builder.query<
      IPaginatedResponse<ILingoLiftCollection>,
      Record<string, string | number>
    >({
      query: params => ({
        url: apiRoutes.lingoLiftTeacherCollectionsPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftStudentCollections: builder.query<
      IPaginatedResponse<ILingoLiftCollection>,
      Record<string, string | number>
    >({
      query: params => ({
        url: apiRoutes.lingoLiftStudentCollectionsPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftStudentCollectionsFilter: builder.query<ILingoLiftCollectionFilter, void>({
      query: () => ({
        url: apiRoutes.lingoLiftStudentCollectionsFilterPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherCollectionsFilter: builder.query<ILingoLiftCollectionFilter, void>({
      query: () => ({
        url: apiRoutes.lingoLiftTeacherCollectionsFilterPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherCollectionDetails: builder.query<ILingoLiftCollection, number>({
      query: id => ({
        url: apiRoutes.lingoLiftTeacherCollectionDetailsPath(id),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.EnhancedCollection, id: 'LIST' }],
    }),
    getLingoLiftStudentCollectionDetails: builder.query<ILingoLiftCollection, { id: number, ecAscId?: number }>({
      query: ({ id, ecAscId }) => ({
        url: apiRoutes.lingoLiftStudentCollectionDetailsPath(id, ecAscId),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftStudentCollectionLessonDetails: builder.query<
      ILingoLiftCollection,
      { collectionId: number; lessonId: number }
    >({
      query: ({ collectionId, lessonId }) => ({
        url: apiRoutes.lingoLiftStudentEnhancedCollectionLessonPath(collectionId, lessonId),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    postLingoLiftStudentCollectionAssignmentCompletions: builder.mutation<
      { id: number },
      { llCollectionAssignmentCompletion: { enhancedCollectionId: number } }
    >({
      query: data => ({
        url: apiRoutes.lingoLiftStudentEnhancedCollectionAssignmentCompletionsPath(),
        method: 'POST',
        data,
      }),
      transformResponse: selectResponse,
    }),
    putLingoLiftStudentCollectionAssignmentCompletion: builder.mutation<
      void,
      { id: number; stateAction: string }
    >({
      query: ({ id, stateAction }) => ({
        url: apiRoutes.lingoLiftStudentEnhancedCollectionAssignmentCompletionPath(id),
        method: 'PUT',
        params: { stateAction },
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherCollectionAssignmentSchoolClasses: builder.query<ILingoLiftCollectionAssignmentResponse, { enhancedCollectionId: number }>({
      query: params => ({
        url: apiRoutes.lingoLiftTeacherCollectionAssignmentSchoolClassesPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.ClassesAssignment, id: 'LIST' }],
    }),
    patchLingoLiftTeacherCollectionAddAssignmentSchoolClasses: builder.mutation<Record<string, string>, any>({
      query: ({ params, body }) => ({
        url: apiRoutes.lingoLiftTeacherCollectionAssignmentUpdateSchoolClassesPath(),
        method: 'PATCH',
        params,
        data: body,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.ClassesAssignment, id: 'LIST' }],
    }),
    getLingoLiftStudentCollectionAscDetails: builder.query<ILingoLiftCollection, { id: number, ascId: number }>({
      query: ({ id, ascId }) => ({
        url: apiRoutes.lingoLiftStudentCollectionAscDetailsPath(ascId, id),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftStudentCollectionAscLessonDetails: builder.query<
      ILingoLiftCollection,
      { lessonId: number, ecAscId: number }
    >({
      query: ({ lessonId, ecAscId }) => ({
        url: apiRoutes.lingoLiftStudentCollectionAscDetailsPath(ecAscId, lessonId),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
  }),
});

export const {
  useGetLingoLiftTeacherCollectionsQuery,
  useGetLingoLiftStudentCollectionsQuery,
  useGetLingoLiftStudentCollectionsFilterQuery,
  useGetLingoLiftTeacherCollectionsFilterQuery,
  useGetLingoLiftTeacherCollectionDetailsQuery,
  useGetLingoLiftStudentCollectionDetailsQuery,
  useGetLingoLiftStudentCollectionLessonDetailsQuery,
  usePostLingoLiftStudentCollectionAssignmentCompletionsMutation,
  usePutLingoLiftStudentCollectionAssignmentCompletionMutation,
  useGetLingoLiftTeacherCollectionAssignmentSchoolClassesQuery,
  usePatchLingoLiftTeacherCollectionAddAssignmentSchoolClassesMutation,
  useGetLingoLiftStudentCollectionAscDetailsQuery,
  useGetLingoLiftStudentCollectionAscLessonDetailsQuery,
} = lingoLiftCollectionApi;
