import { selectResponse } from '../../../shared/utils/selectFromResponseObjects';
import { apiContainer, apiRoutes } from '../../../shared/store';
import { ILingoLiftStudentSummary, ILingoLiftStudentSummaryAllReportsResponse } from '../model/types';

export const lingoLiftSummaryApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLingoLiftStudentSummaryReports: builder.query<ILingoLiftStudentSummary, void>({
      query: () => ({
        url: apiRoutes.lingoLiftStudentSummaryReportsPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftStudentSummaryAllReports: builder.query<ILingoLiftStudentSummaryAllReportsResponse, void>({
      query: () => ({
        url: apiRoutes.lingoLiftStudentSummaryAllReportsPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftClassesSummaryStudentReport: builder.query<ILingoLiftStudentSummaryAllReportsResponse, { llSchoolClassId: string, studentId: string }>({
      query: ({ llSchoolClassId, studentId }) => ({
        url: apiRoutes.lingoLiftClassesSummaryStudentReportPath(llSchoolClassId, studentId),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftClassesSummaryStudentAllReports: builder.query<ILingoLiftStudentSummaryAllReportsResponse, { llSchoolClassId: string, studentId: string }>({
      query: ({ llSchoolClassId, studentId }) => ({
        url: apiRoutes.lingoLiftClassesSummaryStudentAllReportsPath(llSchoolClassId, studentId),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
  }),
});

export const {
  useGetLingoLiftStudentSummaryReportsQuery,
  useGetLingoLiftStudentSummaryAllReportsQuery,
  useGetLingoLiftClassesSummaryStudentReportQuery,
  useGetLingoLiftClassesSummaryStudentAllReportsQuery,
} = lingoLiftSummaryApi;
