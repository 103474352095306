import { InputAdornment, TextField } from '@mui/material';
import React, { FC } from 'react';
import styles from './styles';
import searchIcon from './../../../assets/searchIcon.svg';
import Image from '../Image';
import sx from 'mui-sx';

interface ISearchInputProps {
  handleChange: (value: string) => void;
  bottomSpace?: boolean;
  value: string;
}

const SearchInput: FC<ISearchInputProps> = props => {
  const { handleChange, bottomSpace = false, value } = props;

  return (
    <TextField
      placeholder="Search..."
      variant="outlined"
      type="search"
      value={value}
      onChange={({ target: { value } }) => {
        handleChange(value);
      }}
      sx={sx(styles.container, {
        sx: styles.bottomSpace,
        condition: bottomSpace,
      })}
      InputProps={{
        startAdornment: (
          <InputAdornment sx={styles.inputIcon} position="start">
            <Image src={searchIcon} alt="search icon" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;
