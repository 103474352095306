import { palette } from '../palette';

export default {
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          height: '40px',
          borderRadius: '8px',
          padding: 0,
          '& fieldset': {
            borderColor: palette.semiGray,
          },
          '&:hover fieldset': {
            borderColor: palette.semiBlue,
          },
          '&.Mui-focused fieldset': {
            borderColor: palette.semiBlue,
          },
        },
      },
    },
  },
};
