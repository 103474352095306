import { palette } from '../palette';

export default {
  MuiSelect: {
    styleOverrides: {
      root: {
        '&.MuiOutlinedInput-root': {
          height: '40px',
          borderRadius: '8px',
          padding: 0,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.semiGray,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.semiBlue,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.semiBlue,
          },
        },
      },
    },
  },
};
