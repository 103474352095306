import { selectResponse } from '../../../shared/utils/selectFromResponseObjects';
import { apiContainer, apiRoutes } from '../../../shared/store';
import {
  ILingoLiftStudentProfileLanguage,
  ILingoLiftStudentProfileLanguageResponse,
  ILingoLiftStudentUpdate
} from '../model/types';
import { CacheTags } from '../../../shared/store/commonEnums';
import { Language } from "../model/enums";

export const lingoLiftStudentProfileApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLingoLiftStudentProfileLanguage: builder.query<ILingoLiftStudentProfileLanguageResponse, void>({
      query: () => ({
        url: apiRoutes.lingoLiftStudentProfileLanguage(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.StudentProfileLanguage, id: 'LIST' }],
    }),
    patchLingoLiftStudentProfileLanguageUpdate: builder.mutation<Language, ILingoLiftStudentUpdate>({
      query: (data) => ({
        url: apiRoutes.lingoLiftStudentProfileLanguageUpdate(),
        method: 'PATCH',
        data,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [
        { type: CacheTags.StudentProfileLanguage, id: 'LIST' },
        { type: CacheTags.StudentLWClassList, id: 'LIST' },
        { type: CacheTags.StudentLLClassList, id: 'LIST' },
        { type: CacheTags.StudentLWEventList, id: 'LIST' },
        { type: CacheTags.StudentDashboardAssignmentQuizList, id: 'LIST' },
        { type: CacheTags.HeaderMenu, id: 'LIST' },
        { type: CacheTags.FooterMenu, id: 'LIST' },
        { type: CacheTags.StudentDashboardLWEvents, id: 'LIST' },
        { type: CacheTags.StudentProgressReports, id: 'LIST' }
      ]
    }),
  }),
});

export const {
  useGetLingoLiftStudentProfileLanguageQuery,
  usePatchLingoLiftStudentProfileLanguageUpdateMutation,
} = lingoLiftStudentProfileApi;
