export const selectLingoLiftResetPasswordParamsFromDomObject = (object: HTMLElement) => {
  const url = JSON.parse(object.dataset.url ?? '');
  const error = JSON.parse(object.dataset.errorMessages ?? '');
  const inputs = JSON.parse(object.dataset.inputs ?? '');
  const notice = JSON.parse(object.dataset.notice ?? '');

  return {
    url,
    error,
    inputs,
    notice,
  };
};
