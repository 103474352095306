import { selectResponse } from '../../../shared/utils/selectFromResponseObjects';
import { apiContainer, apiRoutes } from '../../../shared/store';
import {
  ILingoLiftDeleteManageClassStudent,
  ILingoLiftManageClassResetStudentPassword,
  ILingoLiftManageClassUpdate,
  ILingoLiftManageClassCreate,
  ILingoLiftManageClassEntity,
  ILingoLiftImportGoogleroomClassResponse,
  ILingoLiftImportGoogleroomClassUpdate
} from '../model/types';
import { CacheTags } from '../../../shared/store/commonEnums';
import { ILingoLiftManageClass } from '../../../entities/lingoliftClasses';

export const lingoLiftClassesApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    postLingoLiftManageClass: builder.mutation<ILingoLiftManageClassEntity, ILingoLiftManageClassCreate>({
      query: data => ({
        url: apiRoutes.lingoLiftTeacherLLClassesPath(),
        method: 'POST',
        data,
      }),
      transformResponse: selectResponse,
    }),
    patchLingoLiftManageLLClass: builder.mutation<
      ILingoLiftManageClass,
      { id: number; data: ILingoLiftManageClassUpdate }
    >({
      query: ({ id, data }) => ({
        url: apiRoutes.lingoLiftTeacherLLClassPath(id),
        method: 'PATCH',
        data,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.ManageClassList, id: 'LIST' }]
    }),
    patchLingoLiftManageLWClass: builder.mutation<
      ILingoLiftManageClass,
      { id: number; data: ILingoLiftManageClassUpdate }
    >({
      query: ({ id, data }) => ({
        url: apiRoutes.lingoLiftTeacherLWClassPath(id),
        method: 'PATCH',
        data,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.ManageClassList, id: 'LIST' }]
    }),
    postLingoLiftManageLWClass: builder.mutation<ILingoLiftManageClassEntity, ILingoLiftManageClassCreate>({
      query: data => ({
        url: apiRoutes.lingoLiftTeacherLWClassesPath(),
        method: 'POST',
        data,
      }),
      transformResponse: selectResponse,
    }),
    patchLingoLiftResetLLStudentPassword: builder.mutation<
      { student: ILingoLiftManageClassResetStudentPassword },
      { id: number; student: ILingoLiftManageClassResetStudentPassword }
    >({
      query: ({ id, student }) => ({
        url: apiRoutes.lingoLiftTeacherLLStudentPath(id),
        method: 'PATCH',
        data: { student },
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.ManageClassList, id: 'LIST' }]
    }),
    patchLingoLiftResetLWStudentPassword: builder.mutation<
      { student: ILingoLiftManageClassResetStudentPassword },
      { id: number; student: ILingoLiftManageClassResetStudentPassword }
    >({
      query: ({ id, student }) => ({
        url: apiRoutes.lingoLiftTeacherLWStudentPath(id),
        method: 'PATCH',
        data: { student },
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.ManageClassList, id: 'LIST' }]
    }),
    deleteLingoLiftManageLLClass: builder.mutation<string, number>({
      query: id => ({
        url: apiRoutes.lingoLiftTeacherLLClassPath(id),
        method: 'DELETE',
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.ManageClassList, id: 'LIST' }]
    }),
    deleteLingoLiftManageLWClass: builder.mutation<string, number>({
      query: id => ({
        url: apiRoutes.lingoLiftTeacherLWClassPath(id),
        method: 'DELETE',
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.ManageClassList, id: 'LIST' }]
    }),
    deleteLingoLiftStudentFromLLClass: builder.mutation<string, ILingoLiftDeleteManageClassStudent>({
      query: data => ({
        url: apiRoutes.lingoLiftLLClassStudentPath(),
        method: 'DELETE',
        data,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.ManageClassList, id: 'LIST' }]
    }),
    deleteLingoLiftStudentFromLWClass: builder.mutation<string, ILingoLiftDeleteManageClassStudent>({
      query: data => ({
        url: apiRoutes.lingoLiftLWClassStudentPath(),
        method: 'DELETE',
        data,
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.ManageClassList, id: 'LIST' }]
    }),
    patchLingoLiftLWClassRoomImport: builder.mutation<
      ILingoLiftImportGoogleroomClassResponse,
      ILingoLiftImportGoogleroomClassUpdate
    >({
      query: (data) => ({
        url: apiRoutes.lingoLiftLWClassRoomImportPath(),
        method: 'PATCH',
        data,
      }),
      transformResponse: selectResponse,
    }),
    patchLingoLiftLLClassRoomImport: builder.mutation<
      ILingoLiftImportGoogleroomClassResponse,
      ILingoLiftImportGoogleroomClassUpdate
    >({
      query: (data) => ({
        url: apiRoutes.lingoLiftLLClassRoomImportPath(),
        method: 'PATCH',
        data,
      }),
      transformResponse: selectResponse,
    }),
  }),
});

export const {
  usePostLingoLiftManageClassMutation,
  usePostLingoLiftManageLWClassMutation,
  useDeleteLingoLiftManageLLClassMutation,
  useDeleteLingoLiftManageLWClassMutation,
  usePatchLingoLiftManageLLClassMutation,
  usePatchLingoLiftManageLWClassMutation,
  usePatchLingoLiftResetLLStudentPasswordMutation,
  usePatchLingoLiftResetLWStudentPasswordMutation,
  useDeleteLingoLiftStudentFromLLClassMutation,
  useDeleteLingoLiftStudentFromLWClassMutation,
  usePatchLingoLiftLWClassRoomImportMutation,
  usePatchLingoLiftLLClassRoomImportMutation,
} = lingoLiftClassesApi;
