import {
  FilterOptionType,
  LingoLiftFilterKey,
  LingoLiftRequestQueryKeys,
  LingoLiftSearchProduct,
} from '../model/enums';

export const isLingoLiftSearchProduct = (product: LingoLiftSearchProduct) => {
  return product === LingoLiftSearchProduct.LingoLift;
};

export const isListenWiseSearchProduct = (product: LingoLiftSearchProduct) => {
  return product === LingoLiftSearchProduct.ListenWise;
};

export const isGeneralSearchProduct = (product: LingoLiftSearchProduct) => {
  return product === LingoLiftSearchProduct.General;
};

export const isTopicSearchFilter = (type: string) => {
  return type === LingoLiftFilterKey.Topic;
};

export const isSliderWithPresets = (type: string) => {
  return type === FilterOptionType.RangeWithPresets;
};

export const isCheckbox = (type: string) => {
  return type === FilterOptionType.Checkbox;
};

export const isRangeSlider = (type: string) => {
  return type === FilterOptionType.Range;
};

export const isFilledParams = (params: Record<string, string | number | Array<string | number>>) => {
  return (params?.query as string)?.length || Object.keys(params).filter(param => (param !== LingoLiftRequestQueryKeys.Products && param !== 'query')).length > 0;
};
