import React, { FC } from 'react';
import { SettingsRounded, ShareOutlined, StarOutlineRounded, StarRounded, } from '@mui/icons-material';

import styles from './styles';
import Div from "../../../shared/ui/Div";
import { IconButton } from "@mui/material";

interface IPageToolsActionsProps {
  onSettings?: () => void;
  onFavorite?: () => void;
  onShare?: () => void;
  isFavorite: boolean;
}

const PageToolActions: FC<IPageToolsActionsProps> = (props) => {
  const {
    onSettings,
    onShare,
    onFavorite,
    isFavorite
  } = props;

  return (
    <Div sx={ styles.wrapper }>
      { onSettings && <IconButton arial-label="settings">
        <SettingsRounded sx={ styles.icon }/>
      </IconButton> }

      { onFavorite && <IconButton
        arial-label="favorite"
        onClick={ () => onFavorite()}>
        { isFavorite ? (
          <StarRounded sx={ { ...styles.icon, color: 'secondary.dark' } }/>
        ) : (
          <StarOutlineRounded sx={ styles.icon }/>
        ) }
      </IconButton> }

      { onShare && <IconButton arial-label="share" onClick={() => onShare()}>
        <ShareOutlined sx={ styles.icon }/>
      </IconButton> }
    </Div>
  );
};

export default PageToolActions;
