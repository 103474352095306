const styles = {
  title: {
    marginBottom: '16px',
    color: '#404040',
  },
  wrapper: {
    marginBottom: '10px',
  },
  button: {
    textAlign: 'right',
  },
  transparentButton: {
    backgroundColor: 'white',
    color: 'primary.main',
    padding: '10px',
    border: '1px solid',
    borderColor: 'semiGray',
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'secondary.main',
      color: 'white',
    },
  },
};

export default styles;
