const styles = {
  containedWrapper: {
    padding: '20px 0px',
  },
  tagsBarContainer: {
    boxShadow: `0px 3px 7px primary.main`,
    padding: '15px',
    backgroundColor: 'white',
    borderRadius: '12px',
    border: '1px solid',
    borderColor: 'semiGray',
    marginTop: '50px',
  },

  tagsContainerSubTitle: {
    fontSize: '12px',
    color: 'primary.dark',
    textTransform: 'uppercase',
  },
  tagWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    paddingTop: '10px',
  },
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '14px',
    marginRight: '5px',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default styles;
