import React, { FC, useState } from 'react';

import { IDefaultSignUpInput, MainLayout, SignUpFormType } from '../../../widgets';
import ContentLayout from '../../../shared/ui/ContentLayout';
import SignUpForm from '../../../widgets/SignUpForm/ui';
import Div from '../../../shared/ui/Div';
import styles from './styles';
import { Link, Typography } from '@mui/material';
import Image from '../../../shared/ui/Image';
import googleIcon from '../../../assets/socialIcons/googleIcon.svg';
import cleverIcon from '../../../assets/socialIcons/cleverIcon.svg';
import { UserRole } from '../../../entities/user';
import { TeacherSignUpPageStep } from '../../../widgets/SignUpForm/model/enums';
import { appRoutes } from '../../../shared/store';
import { isOmniAuthPage } from '../../../widgets/SignUpForm/lib/compareConditions';

interface ISignUpProps {
  formType: SignUpFormType;
  url?: string;
  defaultInputs: Array<IDefaultSignUpInput>;
  recaptchaSitekey: string;
  defaultErrors: Array<string>;
  googleUrl?: string;
  googleQueryWho?: string;
  googleQueryCommon?: boolean;
  googleAlt?: string;
  cleverUrl?: string;
  cleverQueryType?: string;
  cleverQueryCommon?: boolean;
  cleverAlt?: string;
}
const SignUpPage: FC<ISignUpProps> = props => {
  const {
    formType,
    url,
    defaultInputs,
    recaptchaSitekey,
    defaultErrors,
    googleUrl,
    googleQueryCommon,
    googleQueryWho,
    googleAlt,
    cleverUrl,
    cleverQueryType,
    cleverQueryCommon,
    cleverAlt
  } = props;

  const [isLingoLiftStatus, setIsLingoLiftStatus] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState <TeacherSignUpPageStep>(TeacherSignUpPageStep.signUp);

  //@ts-ignore
  const googleQueryParams = new URLSearchParams({
    who: googleQueryWho ?? '',
    common: googleQueryCommon ?? '',
    product: isLingoLiftStatus ? 'lingolift' : 'listenwise'
  }).toString()

  const cleverQueryParams = new URLSearchParams({
    type: cleverQueryType ?? '',
    common: cleverQueryCommon ?? '',
    product: isLingoLiftStatus ? 'lingolift' : 'listenwise'
  }).toString()

  return (
    <MainLayout
      userRole={UserRole.Public}
      onChangeStatus={setIsLingoLiftStatus}
      isLingoLiftStatus={isLingoLiftStatus}
    >
      <ContentLayout>
        <Div sx={styles.container}>
          <Div sx={styles.content}>
            {formType === SignUpFormType.TeacherTrial && <Typography variant="h1">Free Trial Teacher Sign Up</Typography>}

            {
              ((formType === SignUpFormType.TeacherPremium && currentStep === TeacherSignUpPageStep.signUp) ||
                formType === SignUpFormType.TeacherOmniAuth) && <Typography variant="h1">Teacher Sign Up</Typography>
            }

            {(formType === SignUpFormType.Student || formType === SignUpFormType.StudentOmniAuth) && <Typography variant="h1">Student Sign Up</Typography>}

            {currentStep === TeacherSignUpPageStep.completeAccountSetup && (
              <Typography variant="h1">Complete Account Setup</Typography>
            )}

            {formType === SignUpFormType.Student && (
              <Typography>
                Already have an Account?{' '}
                <Link sx={styles.link} href={appRoutes.studentSignIn}>
                  Log In
                </Link>
              </Typography>
            )}

            {formType === SignUpFormType.TeacherPremium && currentStep === TeacherSignUpPageStep.signUp && (
              <Typography>Have your code ready? You’ll enter it on the next step.</Typography>
            )}

            {formType === SignUpFormType.TeacherTrial && (
              <Typography>
                If you already have a code, switch to regular{' '}
                <Link sx={styles.link} href="/">
                  Sign Up
                </Link>
              </Typography>
            )}

            {(formType === SignUpFormType.TeacherPremium || formType === SignUpFormType.TeacherTrial) && currentStep === TeacherSignUpPageStep.signUp && (
              <Typography>
                Interested in Lingolift?{' '}
                <Link sx={styles.link} href="/">
                  Contact us.
                </Link>
              </Typography>
            )}
          </Div>
          {
            currentStep !== TeacherSignUpPageStep.completeAccountSetup && !isOmniAuthPage(formType) && (
              <Link href={`${googleUrl}?${googleQueryParams}`} sx={styles.socialLink}>
                <Image marginRight="auto" src={googleIcon} alt="{googleAlt}" />
                <Typography fontWeight={600} marginRight="auto">
                  {googleAlt}
                </Typography>
              </Link>
            )
          }
          {
            currentStep !== TeacherSignUpPageStep.completeAccountSetup && !isOmniAuthPage(formType) && (
              <Link href={`${cleverUrl}?${cleverQueryParams}`} sx={styles.socialLink}>
                <Image marginRight="auto" src={cleverIcon} alt="{cleverAlt}" />
                <Typography fontWeight={600} marginRight="auto">
                  {cleverAlt}
                </Typography>
              </Link>
            )
          }

          <SignUpForm
            formType={formType}
            url={url}
            isLingoLiftStatus={isLingoLiftStatus}
            defaultInputs={defaultInputs}
            defaultErrors={defaultErrors}
            recaptchaSitekey={recaptchaSitekey}
            onChangeStatus={setIsLingoLiftStatus}
            currentStep={currentStep}
            onChangeStep={setCurrentStep}
          />

          <Link sx={styles.link} href={formType === SignUpFormType.Student ? appRoutes.teacherSignUp : appRoutes.studentSignUp}>
            {formType === SignUpFormType.Student && (
              <Typography sx={styles.socialLinkText}>Switch to Teacher Sign Up</Typography>
            )}

            {(formType === SignUpFormType.TeacherPremium || formType === SignUpFormType.TeacherTrial) && (
              <Typography sx={styles.socialLinkText}>Switch to Student Sign Up</Typography>
            )}
          </Link>
        </Div>
      </ContentLayout>
    </MainLayout>
  );
};

export default SignUpPage;
