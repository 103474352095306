const styles = {
  root: {
    borderRadius: '12px',
    background: 'white',
    padding: '24px',
    color: 'primary.dark',
    border: '1px solid',
    borderColor: 'semiGray',
    boxShadow: '0px 2px 8px -1px #1B242C14, 0px 2px 2px -1px #1B242C0A',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: '10px',
  },
  percentage: {
    color: 'black',
    marginBottom: '10px',
  },
};

export default styles;
