import React, { FC } from 'react';
import { MainLayout } from '../../widgets';
import ContentLayout from '../../shared/ui/ContentLayout';
import {
  Grid,
  ListItemText,
  MenuItem,
  MenuList,
  Typography
} from '@mui/material';
import { LingoLiftStandardAccordionCard } from '../../entities/lingoLiftStandard';
import Div from '../../shared/ui/Div';
import styles from './styles';
import { useDesktop } from '../../shared/hooks/useDesktop';
import { UserRole } from '../../entities/user';

interface ILingoLiftStandardsPageProps {
  subjects: Array<string>;
  // rails pass this prop as lowercase, it doesn't fix on front-end
  schoolstate: string;
  grades: Array<number>;
}

const LingoLiftStandardsPage: FC<ILingoLiftStandardsPageProps> = (props) => {
  const {
    subjects,
    schoolstate,
    grades,
  } = props;

  const isDesktop = useDesktop();

  return (
    <MainLayout userRole={UserRole.Teacher}>
      <ContentLayout>
        <Grid container spacing={5}>
          { isDesktop && <Grid item lg={3} xs={12}>
            <Div sx={styles.sideBarMenu}>
              <MenuList
                aria-label="subjects"
              >
                { subjects.map((subjectItem) => <MenuItem
                  onClick={() => document.getElementById(subjectItem)?.scrollIntoView({
                    behavior: 'smooth'
                  })}
                  sx={styles.menuItem}>
                  <ListItemText primary={`${subjectItem} Standards`} key={subjectItem} />
                </MenuItem>) }
              </MenuList>
            </Div>
          </Grid> }
          <Grid item lg={9} xs={12}>
            <Div>
              <Grid container spacing={3}>
                { subjects.map((subjectItem) => <Grid
                  display="grid"
                  gap={1}
                  key={subjectItem}
                  xs={12}
                  item>
                  <Typography id={subjectItem} variant="h2">
                    {subjectItem} Standards
                  </Typography>

                  <Div sx={styles.contentContainer}>
                    { grades.map((gradeItem, index) => <LingoLiftStandardAccordionCard
                      isFirst={index === 0}
                      isLast={index === (grades.length -1)}
                      key={gradeItem}
                      grade={gradeItem}
                      schoolState={schoolstate}
                      subject={subjectItem}
                    />) }
                  </Div>
                </Grid> ) }
              </Grid>
            </Div>
          </Grid>
        </Grid>
      </ContentLayout>
    </MainLayout>
  );
}

export default LingoLiftStandardsPage;
