import * as R from 'ramda';

export const getFormDefaultValues = defaultInputs =>
  defaultInputs?.reduce((accumulator, input) => {
    accumulator[input.name] = input.value;
    return accumulator;
  }, {});

export const getNestedFormErrorMessage = (errors, fieldName) => {
  const pathArray = fieldName.split(/[.[\]]+/).filter(Boolean);
  return R.path([...pathArray, 'message'], errors);
};
