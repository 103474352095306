export const selectCategoryValueFromObject = (category: Record<string, string>): string | null => {
  return Object.values(category).length ? Object.values(category).join('') : null;
};

export const selectRailsPropIdFromHtmlElement = (object: HTMLElement): number => {
  return JSON.parse(object.dataset.id!);
};

export const selectRailsPropAscIdFromHtmlElement = (object: HTMLElement): number => {
  return JSON.parse(object.dataset.ecAscId!);
};

export const selectSchoolParamsFromDomObject = (object: HTMLElement) => {
  const {
    schoolName = '',
    googleClassroomLink = '',
  } = object.dataset;

  return { schoolName, googleClassroomLink };
};

export const selectCurrentUrlFromDomObject = (object: HTMLElement) => {
  const { currentUrl = '' } = object.dataset;

  return { currentUrl };
};
