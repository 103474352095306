export default {
  MuiTypography: {
    styleOverrides: {
      root: {
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: [
          'Inter',
          'sans-serif',
        ].join(','),
        color: 'primary.dark',
      },
      h1: {
        fontSize: '32px',
        fontWeight: 600,
      },
      h2: {
        fontSize: '24px',
        fontWeight: 600,
      },
      h3: {
        fontSize: '18px',
        fontWeight: 600,
      },
      h4: {
        fontSize: '16px',
        fontWeight: 600,
      },
      h5: {
        fontSize: '14px',
        fontWeight: 600,
      },
      body1: {
        fontSize: '14px',
      },
      body2: {
        fontSize: '12px',
      },
    },
  },
};
