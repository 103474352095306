import * as React from 'react';
import Div from '../Div';
import styles from './styles';
import { FC } from 'react';
import { Typography } from "@mui/material";

interface ICategoryBadgeListProps {
  list: Array<string>
}
const CategoryBadgeList: FC<ICategoryBadgeListProps> = (props) => {
  const { list } = props;
  return <Div sx={styles.container}>
    { list.map((badge, index) => <Typography key={index} sx={styles.badge}>
      ELP  {badge}
    </Typography>) }
  </Div>
}

export default CategoryBadgeList;
