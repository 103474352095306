import React, { FC } from 'react';
import ContentLayout from '../../shared/ui/ContentLayout';
import { MainLayout } from '../../widgets';
import Div from '../../shared/ui/Div';
import { Grid, Link, Divider, Typography } from '@mui/material';
import styles from './styles';
import { UserRole } from '../../entities/user';
import DashboardSummaryCard from '../../widgets/DashboardSummaryCard/ui';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LingoLiftClassesSummaryTable from '../../entities/lingoliftClasses/ui/SummaryTable';
import { useGetLingoLiftTeacherClassesSummaryLLReportQuery } from "../../entities/lingoliftClasses";
import { ILingoLiftTeacherClassesSummaryLLReport } from "../../entities/lingoliftClasses/module/types";

interface ILingoLiftClassesTeacherSummaryPageProps {
  id: number
}

const LingoLiftClassesTeacherSummaryLLReportPage:FC<ILingoLiftClassesTeacherSummaryPageProps> = (props) => {
  const { id } = props;
  const { data: llReport, isLoading: isLLReportLoading } = useGetLingoLiftTeacherClassesSummaryLLReportQuery(id);

  return (
    <MainLayout userRole={UserRole.Teacher} isLoading={isLLReportLoading}>
      <Div sx={styles.header}>
        <Link sx={styles.link} href={llReport?.seeAllClassesUrl}>
          See All Classes
        </Link>
        <Div sx={styles.container}>
          <Typography variant="h1">{
            llReport?.title
          }</Typography>
          <Link sx={styles.linkAssignments}>
            { llReport?.subtitle1 }
          </Link>
        </Div>
      </Div>
      <Divider variant="fullWidth" sx={styles.divider} />
      <ContentLayout>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={8}>
            <Typography variant="h2" sx={styles.title}>
              { llReport?.subtitle2 }
            </Typography>
          </Grid>
          <Grid display="flex" justifyContent="flex-end" item xs={4}>
            <Link href={llReport?.viewAllAssignedUrl} sx={{
              ...styles.transparentButton,
              ...styles.borderRadiusLeft,
            }}>
              View All Assigned
            </Link>
            <Link download href={llReport?.downloadReportUrl} sx={{
              ...styles.transparentButton,
              ...styles.borderRadiusRight,
            }}>
              <FileDownloadOutlinedIcon fontSize="small" /> Download Report
            </Link>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={4}>
            <DashboardSummaryCard
              title={llReport?.averageData?.averageWatch?.label}
              percentage={llReport?.averageData?.averageWatch.percentage}
              statistics={llReport?.averageData?.averageWatch.countLabel}
              iconType="watch"
            />
          </Grid>
          <Grid item xs={4}>
            <DashboardSummaryCard
              title={llReport?.averageData?.averageLearn?.label}
              percentage={llReport?.averageData?.averageLearn?.percentage}
              statistics={llReport?.averageData?.averageLearn?.countLabel}
              iconType="learn"
            />
          </Grid>
          <Grid item xs={4}>
            <DashboardSummaryCard
              title={llReport?.averageData?.averageSpeak?.label}
              percentage={llReport?.averageData?.averageSpeak?.percentage}
              statistics={llReport?.averageData?.averageSpeak?.countLabel}
              iconType="speak"
            />
          </Grid>
        </Grid>
        <Div sx={styles.wrapper}>
          <LingoLiftClassesSummaryTable {...llReport as ILingoLiftTeacherClassesSummaryLLReport} />
        </Div>
      </ContentLayout>
    </MainLayout>
  );
};

export default LingoLiftClassesTeacherSummaryLLReportPage;
