import React, { FC } from 'react';
import Div from '../../../../shared/ui/Div';
import styles from './styles';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import appRoutes from '../../../../shared/store/appRoutes';
import { ILingoLiftManageClassEntity } from '../../model/types';
import { HelpOutlineRounded } from "@mui/icons-material";

interface ILihgoLiftCreateClassFormProps {
  onChange: (values: ILingoLiftManageClassEntity) => void;
  isListenwise?: boolean;
}

const LihgoLiftCreateClassForm: FC<ILihgoLiftCreateClassFormProps> = props => {
  const { onChange, isListenwise } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <Div sx={styles.optionSection}>
      <Typography variant="h2">Option 2: Create a Class</Typography>
      <Typography>Enroll your students using a class code.</Typography>
      <form onSubmit={handleSubmit(onChange)}>
        <Div sx={styles.formContainer}>
          <Div sx={styles.formItem}>
            <Div
              sx={{
                ...styles.formItemLabel,
                ...styles.formItemLabelRequiredSymbol,
              }}
            >
              <Typography variant="h5">Class name</Typography>
            </Div>
            <TextField
              type="text"
              {...register('name', { required: true })}
              error={!!errors.name}
              helperText={errors.name ? 'Required' : ''}
              placeholder="Enter class name"
              fullWidth
            />
            { isListenwise &&
            <FormGroup>
              <Div sx={styles.itemWithTooltip}>
                <FormControlLabel
                  {...register('elementary')}
                  control={<Checkbox />}
                  label="Elementary classroom"
                />
                <Tooltip
                  placement="top"
                  arrow
                  title="Selecting this will restrict what content students can find on their own to items appropriate for elementary students">
                  <IconButton>
                    <HelpOutlineRounded />
                  </IconButton>
                </Tooltip>
              </Div>
              <Div sx={styles.itemWithTooltip}>
                <FormControlLabel
                  {...register('studentStartQuiz')}
                  control={<Checkbox />}
                  label="Student-selected quizzes?"
                />
                <Tooltip
                  placement="top"
                  arrow
                  title="Allow students to select stories and take quizzes without a teacher-created assignment">
                  <IconButton>
                    <HelpOutlineRounded />
                  </IconButton>
                </Tooltip>
              </Div>
            </FormGroup> }
          </Div>
          <FormGroup>
            <FormControlLabel
              {...register('policyAccepted', { required: true })}
              control={<Checkbox />}
              label={
                <Typography>
                  Accept{' '}
                  <Link sx={styles.actionLink} href={appRoutes.privatePolicy} target="_blank">
                    Privacy Policy
                  </Link>{' '}
                  For Students
                </Typography>
              }
            />
            {Boolean(errors.policyAccepted) && <Typography color="red">must be accepted</Typography>}
          </FormGroup>
          <Typography sx={styles.italic}>
            By accepting Privacy Policy, you consent to the collection of necessary student
            information for the operation of the Listenwise application, as specified in the{' '}
            <Link sx={styles.actionLink} href={appRoutes.privatePolicy} target="_blank">
              Privacy Policy
            </Link>{' '}
            .
          </Typography>
          <Button type="submit" variant="contained">
            Create class
          </Button>
        </Div>
      </form>
    </Div>
  );
};

export default LihgoLiftCreateClassForm;
