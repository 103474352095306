export const isStartButton = (status: string) => {
  return status === 'not_started' || status === 'returned'
}

export const isReviewButton = (status: string) => {
  return status === 'completed'
}

export const isContinueButton = (status: string) => {
  return status === 'started'
}
