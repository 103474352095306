import { Grid, Link, Typography } from '@mui/material';
import styles from './styles';
import Div from '../../../../../shared/ui/Div';
import React, { FC } from 'react';
import { ILingoLiftManageClassStudentItem } from '../../../module/types';
import { LingoLiftManageClassChildItemDropDownMenu } from "../../../../../features/lingoLiftClasses";
import { isEmpty } from 'ramda';

interface IManageChildItemProps extends ILingoLiftManageClassStudentItem {
  setCurrentStudentId: (id: number | null) => void;
  onDeleteStudent: () => void;
}

const ManageChildItem: FC<IManageChildItemProps> = props => {
  const { firstName, lastName, username, linkedLabel } = props;

  return (
    <Div sx={styles.container}>
      <Grid container spacing={4}>
        <Grid xs={12} sm={3} item sx={styles.column}>
          <Link sx={styles.linkItem}>
            {lastName}, {firstName}
          </Link>
        </Grid>
        <Grid xs={12} sm={4} item sx={styles.column}>
          <Typography>{username}</Typography>
        </Grid>
        <Grid gap={1} xs={12} sm={4} sx={styles.column} item>
          <Typography sx={styles.googleAccountLink}>
            {isEmpty(linkedLabel) ? '-' : linkedLabel}
          </Typography>
        </Grid>
      </Grid>
      <LingoLiftManageClassChildItemDropDownMenu {...props} />
    </Div>
  );
};

export default ManageChildItem;
