const styles = {
  actionLink: {
    color: 'secondary.dark',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    paddingBottom: '20px',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  checkboxItem: {
    margin: '0 auto'
  },
  formItemLabelRequiredSymbol: {
    '&::before': {
      position: 'absolute',
      left: '-10px',
      top: 0,
      content: '"*"',
      color: 'red',
      fontSize: '14px',
    },
  },
  formHeader: {
    backgroundColor: 'semiGray',
    padding: '10px',
    display: 'flex',
    alignItems: 'center'
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px'
  },
};

export default styles;
