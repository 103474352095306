import type { FC } from 'react';
import * as React from 'react';
import { Card, CardContent, CardMedia, Grid, Link, Typography } from '@mui/material';

import styles from './styles';
import { sliceTextContent } from '../../../../shared/utils/sliceTextContent';
import Div from '../../../../shared/ui/Div';
import { ILingoLiftCollectionLesson } from '../../model/types';
import sx from 'mui-sx';

interface ILingoLiftCollectionLessonCardProps {
  card: ILingoLiftCollectionLesson;
  index: number;
  isRounded?: boolean;
}

const LingoLiftCollectionLessonCard: FC<ILingoLiftCollectionLessonCardProps> = props => {
  const {
    card: { name, photoSrc, description, current, url, category, durationMmSs },
    index,
    isRounded,
  } = props;

  return (
    <Card
      sx={sx(
        styles.card,
        {
          sx: styles.cardElementRounded,
          condition: isRounded,
        },
        {
          sx: styles.currentCard,
          condition: current,
        },
      )}
    >
      <Link href={url}>
        <Grid sx={styles.wrapper} container>
          <Grid item xs={1} sx={styles.item}>
            <Typography sx={styles.index}>{index}</Typography>
          </Grid>
          <Grid item xs={4} sx={styles.item}>
            <CardMedia image={photoSrc} component="div" sx={styles.poster} />
          </Grid>
          <Grid item xs={7} sx={styles.item}>
            <Div sx={styles.contentWrapper}>
              <CardContent sx={styles.content}>
                <Typography sx={styles.category}>
                  {category ? `${category} | ` : ''}Length: {durationMmSs}
                </Typography>
                <Typography
                  sx={sx(styles.link, {
                    sx: styles.currentLink,
                    condition: current,
                  })}
                >
                  {name}
                </Typography>
                <Typography sx={styles.description}>{sliceTextContent(description)}</Typography>
              </CardContent>
            </Div>
          </Grid>
        </Grid>
      </Link>
    </Card>
  );
};

export default LingoLiftCollectionLessonCard;
