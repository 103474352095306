import React, { FC } from 'react';

import { IDefaultSignInInput, MainLayout } from '../../../widgets';
import ContentLayout from '../../../shared/ui/ContentLayout';
import Div from '../../../shared/ui/Div';
import styles from './styles';
import { Typography } from '@mui/material';
import { UserRole } from '../../../entities/user';
import ForgotPasswordForm from '../../../widgets/ForgotPasswordForm/ui';
import { ForgotPasswordFormType } from "../../../widgets/ForgotPasswordForm/model/enums";

interface IForgotPasswordProps {
  formType: ForgotPasswordFormType;
  url?: string;
  defaultErrors: Array<string>;
  defaultInputs: Array<IDefaultSignInInput>;
  notice: string;
}

const ForgotPasswordPage: FC<IForgotPasswordProps> = (props) => {
  const {
    formType,
    url,
    defaultErrors,
    defaultInputs,
    notice
  } = props;

  return (
    <MainLayout userRole={UserRole.Public}>
      <ContentLayout>
        <Div sx={styles.container}>
          <Div sx={styles.content}>
            { (formType === ForgotPasswordFormType.studentForgotPassword || formType === ForgotPasswordFormType.teacherForgotPassword) && <>
              <Typography variant="h1">Forgot your password?</Typography>
              <Typography>
                Please enter your email and we'll send you reset instructions
              </Typography>
            </> }

            { (formType === ForgotPasswordFormType.teacherEditPassword || formType === ForgotPasswordFormType.studentEditPassword) && <>
              <Typography variant="h1">Change your password</Typography>
              <Typography>
                Just pick a new password below and we'll log you in.
              </Typography>
            </> }

            { notice && <Typography>{ notice }</Typography> }
            <ForgotPasswordForm
              formType={formType}
              url={url}
              defaultErrors={defaultErrors}
              defaultInputs={defaultInputs}
            />
          </Div>
        </Div>
      </ContentLayout>
    </MainLayout>
  );
};

export default ForgotPasswordPage;
