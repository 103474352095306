const styles = {
  card: {
    borderRadius: '12px',
    margin: '10px 0',
    padding: '10px',
  },
  poster: {
    borderRadius: '8px',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  title: {
    color: 'secondary.dark',
    fontSize: '14px',
    fontWeight: 600,
  },
  description: {
    fontSize: '10px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  tagWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  tagIcon: {
    width: '15px',
    height: 'auto',
    objectFit: 'contain',
    margin: '0 10px',
  },
  favoriteIcon: {
    fontSize: '20px',
    color: 'primary.main',
  },
  category: {
    color: 'primary.dark',
    marginRight: '15px',
  },
  chipWrapper: {
    marginTop: 'auto',
    flexWrap: 'wrap',
  },
};

export default styles;
