import React, { type FC } from 'react';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Header, Footer } from './../../../widgets';
import theme from '../../../shared/theme';

import styles from './styles';
import { CssBaseline } from '@mui/material';
import Div from '../../../shared/ui/Div';
import Loader from '../../../shared/ui/Loader';
import { colors } from '../../../shared/theme/palette';
import { UserRole } from "../../../entities/user";
import { SnackbarProvider } from 'notistack';

interface IMainLayoutProps {
  isLoading?: boolean;
  children: React.ReactNode;
  backgroundColor?: keyof typeof colors;
  userRole?: UserRole;
  isLingoLiftStatus?: boolean;
  onChangeStatus?: (value: boolean) => void;
}
const MainLayout: FC<IMainLayoutProps> = props => {
  const {
    isLoading = false,
    children,
    backgroundColor = colors.athensGray,
    userRole,
    onChangeStatus,
    isLingoLiftStatus,
  } = props;

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={5} autoHideDuration={7000}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <Div
            sx={{
              ...styles.layout,
              backgroundColor,
            }}
          >
            <Header
              userRole={userRole}
              onChangeStatus={onChangeStatus}
              isLingoLiftStatus={isLingoLiftStatus}
            />
            {isLoading ? <Loader /> : <Div sx={styles.content}>{children}</Div>}
            <Footer />
          </Div>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default MainLayout;
