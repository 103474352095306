import * as React from 'react';

const ListenWiseLogo = (props) => (
  <svg {...props} width="111" height="24" viewBox="0 0 111 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.9391 18.7245C32.9391 18.8382 32.8633 18.9709 32.7496 18.9898C32.257 19.0656 30.8359 19.1603 29.0548 19.1603C27.0273 19.1603 25.0188 18.7624 25.0188 15.636V5.32818C25.0188 5.11975 25.1893 4.94922 25.3978 4.94922H26.3641C26.5725 4.94922 26.7431 5.11975 26.7431 5.32818V15.636C26.7431 17.076 27.0652 17.6066 29.0737 17.6066H32.7307C32.8633 17.6066 32.9391 17.7013 32.9391 17.834V18.7245Z"
      fill="#09212A"/>
    <path
      d="M36.0973 6.55888C36.0973 6.76731 35.9267 6.93784 35.7183 6.93784H34.7519C34.5435 6.93784 34.373 6.76731 34.373 6.55888V5.04303C34.373 4.8346 34.5435 4.66406 34.7519 4.66406H35.7183C35.9267 4.66406 36.0973 4.8346 36.0973 5.04303V6.55888ZM36.0594 18.6478C36.0594 18.8562 35.8888 19.0268 35.6804 19.0268H34.7709C34.5625 19.0268 34.3919 18.8562 34.3919 18.6478V8.98424C34.3919 8.77581 34.5625 8.60528 34.7709 8.60528H35.6804C35.8888 8.60528 36.0594 8.77581 36.0594 8.98424V18.6478Z"
      fill="#09212A"/>
    <path
      d="M45.08 16.4119C45.08 18.3447 43.6589 19.1594 41.5367 19.1594C40.8356 19.1594 39.4714 19.0836 38.5429 18.8752C38.2966 18.8184 38.145 18.7047 38.145 18.4962V17.833C38.145 17.6625 38.2966 17.5488 38.4861 17.5488C38.524 17.5488 38.5429 17.5488 38.5618 17.5488C39.4714 17.6625 40.8546 17.7573 41.5367 17.7573C42.7683 17.7573 43.4126 17.4351 43.4126 16.4119C43.4126 15.8435 43.0715 15.4835 41.9915 14.8392L40.0777 13.7023C38.9219 13.0013 38.145 12.1865 38.145 10.9928C38.145 9.02215 39.6229 8.47266 41.5746 8.47266C42.6357 8.47266 43.64 8.5674 44.5305 8.71898C44.7768 8.75688 44.8905 8.88952 44.8905 9.09795V9.72324C44.8905 9.95061 44.7579 10.0454 44.5684 10.0454C44.5495 10.0454 44.5305 10.0454 44.5116 10.0454C43.8484 9.98851 42.6357 9.89377 41.5746 9.89377C40.5135 9.89377 39.8124 10.0264 39.8124 10.9928C39.8124 11.5612 40.2482 11.9212 41.1199 12.4328L42.9957 13.5508C44.5684 14.4792 45.08 15.294 45.08 16.4119Z"
      fill="#09212A"/>
    <path
      d="M61.399 13.9297C61.399 14.1761 61.2663 14.3466 61.039 14.3466H54.6913V14.574C54.6913 16.753 55.7145 17.7573 57.4578 17.7573C58.5568 17.7573 59.8263 17.6246 60.6979 17.492C60.7358 17.492 60.7547 17.492 60.7926 17.492C60.9442 17.492 61.0958 17.5678 61.0958 17.7573V18.4015C61.0958 18.6478 61.02 18.7805 60.7168 18.8373C59.6368 19.0457 58.7273 19.1594 57.4578 19.1594C55.2787 19.1594 53.0239 18.1173 53.0239 14.4792V13.1529C53.0239 10.4622 54.5208 8.47266 57.2304 8.47266C59.9968 8.47266 61.399 10.4433 61.399 13.1339V13.9297ZM59.7126 13.0771V12.9823C59.7126 10.9549 58.8978 9.93167 57.2304 9.93167C55.6008 9.93167 54.6913 11.0307 54.6913 12.9823V13.0771H59.7126Z"
      fill="#09212A"/>
    <path
      d="M51.9358 18.7232C51.9358 18.8937 51.8411 19.0074 51.5569 19.0453C51.1969 19.1021 50.42 19.159 49.7379 19.159C47.9567 19.159 46.9335 18.5716 46.9335 16.5252V6.74793C46.9335 6.55845 47.1041 6.42581 47.3125 6.38791L48.241 6.23633C48.2599 6.23633 48.2789 6.23633 48.2789 6.23633C48.4683 6.23633 48.601 6.40686 48.601 6.59634V8.60485H51.5569C51.7653 8.60485 51.9169 8.73748 51.9169 8.94591V9.59015C51.9169 9.77963 51.7653 9.93122 51.5569 9.93122H48.601V16.5252C48.601 17.6242 48.961 17.8137 50.0031 17.8137H51.5379C51.8032 17.8137 51.9358 17.8894 51.9358 18.0789V18.7232Z"
      fill="#09212A"/>
    <path
      d="M71.232 18.6478C71.232 18.8563 71.0994 19.0268 70.891 19.0268H69.9057C69.6972 19.0268 69.5646 18.8563 69.5646 18.6478V11.8833C69.5646 10.4622 69.2046 9.91272 67.9161 9.91272H66.5708C65.2823 9.91272 64.9223 10.4622 64.9223 11.8833V18.6478C64.9223 18.8563 64.7897 19.0268 64.5812 19.0268H63.5959C63.3875 19.0268 63.2549 18.8563 63.2549 18.6478V11.8833C63.2549 8.96531 65.2823 8.47266 66.5708 8.47266H67.9161C69.2046 8.47266 71.232 8.96531 71.232 11.8833V18.6478Z"
      fill="#09212A"/>
    <path
      d="M86.0995 14.9152C86.0995 18.269 84.2237 19.1596 82.272 19.1596C81.3246 19.1596 80.5667 18.9891 79.7709 18.7048C78.975 18.9891 78.1982 19.1596 77.2507 19.1596C75.2991 19.1596 73.4232 18.269 73.4232 14.9152V8.98443C73.4232 8.776 73.5938 8.60547 73.8022 8.60547H74.6927C74.9012 8.60547 75.0717 8.776 75.0717 8.98443V14.9152C75.0717 16.9806 75.8107 17.7385 77.4023 17.7385C77.876 17.7385 78.3687 17.6248 78.9182 17.4922V8.98443C78.9182 8.776 79.0887 8.60547 79.2971 8.60547H80.2256C80.434 8.60547 80.6046 8.776 80.6046 8.98443V17.4922C81.1541 17.6248 81.6467 17.7385 82.1204 17.7385C83.6742 17.7385 84.451 16.9806 84.451 14.9152V8.98443C84.451 8.776 84.6216 8.60547 84.83 8.60547H85.7206C85.929 8.60547 86.0995 8.776 86.0995 8.98443V14.9152Z"
      fill="#09212A"/>
    <path
      d="M90.2054 6.55888C90.2054 6.76731 90.0348 6.93784 89.8264 6.93784H88.86C88.6516 6.93784 88.4811 6.76731 88.4811 6.55888V5.04303C88.4811 4.8346 88.6516 4.66406 88.86 4.66406H89.8264C90.0348 4.66406 90.2054 4.8346 90.2054 5.04303V6.55888ZM90.1675 18.6478C90.1675 18.8562 89.9969 19.0268 89.7885 19.0268H88.879C88.6706 19.0268 88.5 18.8562 88.5 18.6478V8.98424C88.5 8.77581 88.6706 8.60528 88.879 8.60528H89.7885C89.9969 8.60528 90.1675 8.77581 90.1675 8.98424V18.6478Z"
      fill="#09212A"/>
    <path
      d="M99.1881 16.4119C99.1881 18.3447 97.767 19.1594 95.6448 19.1594C94.9438 19.1594 93.5795 19.0836 92.651 18.8752C92.4047 18.8184 92.2531 18.7047 92.2531 18.4962V17.833C92.2531 17.6625 92.4047 17.5488 92.5942 17.5488C92.6321 17.5488 92.651 17.5488 92.67 17.5488C93.5795 17.6625 94.9627 17.7573 95.6448 17.7573C96.8765 17.7573 97.5207 17.4351 97.5207 16.4119C97.5207 15.8435 97.1796 15.4835 96.0996 14.8392L94.1858 13.7023C93.03 13.0013 92.2531 12.1865 92.2531 10.9928C92.2531 9.02215 93.7311 8.47266 95.6827 8.47266C96.7438 8.47266 97.7481 8.5674 98.6386 8.71898C98.885 8.75688 98.9987 8.88952 98.9987 9.09795V9.72324C98.9987 9.95061 98.866 10.0454 98.6765 10.0454C98.6576 10.0454 98.6386 10.0454 98.6197 10.0454C97.9565 9.98851 96.7438 9.89377 95.6827 9.89377C94.6216 9.89377 93.9206 10.0264 93.9206 10.9928C93.9206 11.5612 94.3564 11.9212 95.228 12.4328L97.1038 13.5508C98.6765 14.4792 99.1881 15.294 99.1881 16.4119Z"
      fill="#09212A"/>
    <path
      d="M109.114 13.9297C109.114 14.1761 108.981 14.3466 108.754 14.3466H102.406V14.574C102.406 16.753 103.429 17.7573 105.172 17.7573C106.271 17.7573 107.541 17.6246 108.412 17.492C108.45 17.492 108.469 17.492 108.507 17.492C108.659 17.492 108.81 17.5678 108.81 17.7573V18.4015C108.81 18.6478 108.735 18.7805 108.431 18.8373C107.351 19.0457 106.442 19.1594 105.172 19.1594C102.993 19.1594 100.738 18.1173 100.738 14.4792V13.1529C100.738 10.4622 102.235 8.47266 104.945 8.47266C107.711 8.47266 109.114 10.4433 109.114 13.1339V13.9297ZM107.427 13.0771V12.9823C107.427 10.9549 106.612 9.93167 104.945 9.93167C103.315 9.93167 102.406 11.0307 102.406 12.9823V13.0771H107.427Z"
      fill="#09212A"/>
    <ellipse cx="14.5663" cy="11.6845" rx="3.07908" ry="3.07908" fill="#09212A"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M14.5663 6.47385C11.6885 6.47385 9.35556 8.80678 9.35556 11.6846C9.35556 13.2936 10.084 14.7319 11.2317 15.6888C11.5331 15.9401 11.5737 16.3882 11.3224 16.6896C11.0711 16.991 10.623 17.0316 10.3216 16.7803C8.86398 15.5649 7.93445 13.733 7.93445 11.6846C7.93445 8.02192 10.9036 5.05273 14.5663 5.05273C16.4414 5.05273 18.1358 5.83188 19.3412 7.08219C19.6136 7.3647 19.6053 7.81453 19.3228 8.0869C19.0403 8.35927 18.5905 8.35104 18.3181 8.06852C17.3695 7.08453 16.0398 6.47385 14.5663 6.47385Z"
          fill="#35A8D5"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M14.5663 3.6301C11.2499 3.6301 8.40012 5.63493 7.16593 8.50163C7.01075 8.86207 6.59275 9.02847 6.23231 8.87329C5.87187 8.71811 5.70547 8.30011 5.86065 7.93967C7.31128 4.57025 10.6622 2.20898 14.5663 2.20898C17.0713 2.20898 19.3506 3.18213 21.0442 4.76965C21.3305 5.03802 21.345 5.48769 21.0767 5.774C20.8083 6.06031 20.3586 6.07485 20.0723 5.80648C18.6316 4.45604 16.6962 3.6301 14.5663 3.6301Z"
          fill="#35A8D5"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M11.4483 0.478831C11.5764 0.849769 11.3795 1.25431 11.0086 1.38239C6.73736 2.85725 3.67111 6.91331 3.67111 11.6837C3.67111 17.7009 8.54906 22.5789 14.5663 22.5789C16.1516 22.5789 17.6561 22.2407 19.013 21.6333C19.3712 21.473 19.7915 21.6333 19.9519 21.9915C20.1122 22.3497 19.9518 22.77 19.5937 22.9304C18.0576 23.618 16.3556 24 14.5663 24C7.7642 24 2.25 18.4858 2.25 11.6837C2.25 6.28873 5.71831 1.70568 10.5448 0.0391071C10.9157 -0.0889781 11.3202 0.107893 11.4483 0.478831Z"
          fill="#35A8D5"/>
  </svg>

)

export default ListenWiseLogo;
