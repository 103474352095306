import { selectResponse } from '../../../shared/utils/selectFromResponseObjects';
import { apiContainer, apiRoutes, IPaginatedResponse } from "../../../shared/store";

import {
  ILingoLiftFilter,
  ILingoLiftLesson,
  ILingoLiftLessonDetails,
  ILingoLiftLessonStandard,
  ILingoLiftLessonStandardItem,
  ILingoLiftLessonParams,
} from '../model/types';
import { CacheTags } from "../../../shared/store/commonEnums";

export const lingoLiftLessonsApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLingoLiftStudentLessonDetails: builder.query<ILingoLiftLessonDetails, number>({
      query: id => ({
        url: apiRoutes.lingoLiftStudentLessonsDetailsPath(id),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.StudentLessonDetails, id: 'LIST' }],
    }),
    getLingoLiftTeacherLessonDetails: builder.query<ILingoLiftLessonDetails, number>({
      query: id => ({
        url: apiRoutes.lingoLiftTeacherLessonsDetailsPath(id),
        method: 'GET',
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.TeacherLessonDetails, id: 'LIST' }],
    }),
    getLingoLiftStudentLessons: builder.query<IPaginatedResponse<ILingoLiftLesson>, ILingoLiftLessonParams>({
      query: (params) => ({
        url: apiRoutes.lingoLiftStudentLessonsPath(),
        method: 'GET',
        params
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherLessons: builder.query<IPaginatedResponse<ILingoLiftLesson>, ILingoLiftLessonParams>({
      query: (params) => ({
        url: apiRoutes.lingoLiftTeacherLessonsPath(),
        method: 'GET',
        params
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftStudentLessonsFilter: builder.query<ILingoLiftFilter, void>({
      query: () => ({
        url: apiRoutes.lingoLiftStudentLessonsFilterPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherLessonsFilter: builder.query<ILingoLiftFilter, void>({
      query: () => ({
        url: apiRoutes.lingoLiftTeacherLessonsFilterPath(),
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
    getLingoLiftTeacherLessonDetailsStandards: builder.query<Array<ILingoLiftLessonStandard<ILingoLiftLessonStandardItem, ILingoLiftLessonStandardItem>>, string>({
      query: (url) => ({
        url: url,
        method: 'GET',
      }),
      transformResponse: selectResponse,
    }),
  }),
});

export const {
  useGetLingoLiftStudentLessonDetailsQuery,
  useGetLingoLiftTeacherLessonDetailsQuery,
  useGetLingoLiftStudentLessonsQuery,
  useGetLingoLiftTeacherLessonsQuery,
  useGetLingoLiftStudentLessonsFilterQuery,
  useGetLingoLiftTeacherLessonsFilterQuery,
  useGetLingoLiftTeacherLessonDetailsStandardsQuery,
} = lingoLiftLessonsApi;
