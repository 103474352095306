import React, { FC } from 'react';
import { Link } from '@mui/material';
import { KeyboardBackspaceRounded } from '@mui/icons-material';

import styles from './styles';
import Div from '../../shared/ui/Div';

interface ILessonGoBackLineProps {
  title: string;
}

const LessonGoBackLine: FC<ILessonGoBackLineProps> = props => {
  const { title } = props;
  return (
    <Div sx={{ ...styles.wrapper, ...styles.wrapperLingoLiftBorder }}>
      <Link onClick={() => history.back()} sx={styles.link}>
        <KeyboardBackspaceRounded /> {title}
      </Link>
    </Div>
  );
};

export default LessonGoBackLine;
