import React, { FC } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import styles from './styles';
import { MoreHorizRounded } from '@mui/icons-material';
import ConfirmModalButton from "../../../../shared/ui/ConfirmButton";

interface ILingoLiftClassesManageCollapseItemProps {
  id: number;
  name: string;
  onDeleteClass: (id: number) => void;
  setCurrentClassId: (value: number | null) => void;
}

const ILingoLiftManageClassCollapseItemDropDownMenu: FC<ILingoLiftClassesManageCollapseItemProps> = props => {
  const { id, name,  onDeleteClass, setCurrentClassId } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHorizRounded sx={styles.dotedIcon} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={styles.dotedMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem>
          <Typography
            onClick={() => {
              setCurrentClassId(id);
              handleClose();
            }}
          >
            Class Settings
          </Typography>
        </MenuItem>
        <ConfirmModalButton
          onSubmit={() => {
            onDeleteClass(id);
            handleClose();
          }}
          handleCancel={handleClose}
          buttonTitle="Delete Class"
          description="Are you sure to delete this class?"
          title={`Delete the class: ${name}`}
          type="item" />
      </Menu>
    </>
  );
};

export default ILingoLiftManageClassCollapseItemDropDownMenu;
