import React, { FC } from 'react';
import ContentLayout from '../../shared/ui/ContentLayout';
import { MainLayout } from '../../widgets';
import Div from '../../shared/ui/Div';
import { Typography, Link, Divider } from '@mui/material';
import styles from './styles';
import { UserRole } from '../../entities/user';
import LingoLiftClassesAssignmentsTable from '../../entities/lingoliftClasses/ui/AssignedTable';
import {
  useGetLingoLiftTeacherClassesLWPastAssignmentsReportQuery,
  useGetLingoLiftTeacherClassesLWRecentAssignmentsReportQuery
} from '../../entities/lingoliftClasses';

interface LingoLiftClassesTeacherLWAssignmentsReportPageProps {
  lwSchoolClassId?: string;
}

const LingoLiftClassesTeacherLWAssignmentsReportPage:FC<LingoLiftClassesTeacherLWAssignmentsReportPageProps> = (props) => {
  const { lwSchoolClassId } = props;

  const { data: recentAssignmensReport, isLoading: isLoadingRecentAssignmensReport } = useGetLingoLiftTeacherClassesLWRecentAssignmentsReportQuery(lwSchoolClassId as string, {
    skip: !lwSchoolClassId
  });
  const { data: pastAssignmensReport, isLoading: isLoadingPastAssignmensReport } = useGetLingoLiftTeacherClassesLWPastAssignmentsReportQuery(lwSchoolClassId as string, {
    skip: !lwSchoolClassId
  });

  const isLoading = isLoadingPastAssignmensReport || isLoadingRecentAssignmensReport

  return (
    <MainLayout userRole={UserRole.Teacher} isLoading={isLoading}>
      <Div sx={styles.header}>
        <Link sx={styles.link} href={recentAssignmensReport?.seeAllClassesUrl}>
          { recentAssignmensReport?.seeAllClassesLabel ?? 'See All Classes' }
        </Link>
        <Div sx={styles.container}>
          <Typography variant="h1">{ recentAssignmensReport?.schoolClassName }</Typography>
        </Div>
      </Div>
      <Divider variant="fullWidth" sx={styles.divider} />
      <ContentLayout>
        <Div sx={styles.wrapperButton}>
          <Link href={recentAssignmensReport?.classSummaryReportUrl} sx={{
            ...styles.borderRadiusLeft,
            ...styles.transparentButton
          }}>
            View Class Summary Report
          </Link>
          <Link href={recentAssignmensReport?.classStudentsUrl} sx={{
            ...styles.borderRadiusRight,
            ...styles.transparentButton
          }}>
            View Student Roster
          </Link>
        </Div>
        <Div sx={styles.wrapper}>
          <Typography variant="h2" sx={styles.title}>
            Recently Assigned
          </Typography>
          <LingoLiftClassesAssignmentsTable ascs={recentAssignmensReport?.ascs ?? []} />
        </Div>
        <Div sx={styles.wrapper}>
          <Typography variant="h2" sx={styles.title}>
            Previously Assigned
          </Typography>
          <LingoLiftClassesAssignmentsTable ascs={pastAssignmensReport?.ascs ?? []} />
        </Div>
      </ContentLayout>
    </MainLayout>
  );
};

export default LingoLiftClassesTeacherLWAssignmentsReportPage;
