const styles = {
  wrapper: {
    backgroundColor: '#FEF5F8',
    borderBottom: '8px solid',
  },
  wrapperLingoLiftBorder: {
    borderColor: 'hibiscus',
  },
  wrapperListenWiseBorder: {
    borderColor: 'secondary.dark',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    fontSize: '14px',
    color: 'primary.main',
    padding: '20px',
    cursor: 'pointer',
  },
};

export default styles;
