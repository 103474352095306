import React, { FC } from 'react';
import Div from '../Div';
import { Chip } from '@mui/material';
import styles from './styles';
import sx from 'mui-sx';

interface IMultipleSelectChipsProps {
  sectionKey: string;
  handleChange: (key: string, value: string) => void;
  chips: Array<string>;
  filteredParams: Record<string, string>;
}

const MultipleSelectChips: FC<IMultipleSelectChipsProps> = props => {
  const { chips, sectionKey, handleChange, filteredParams } = props;

  const handleTagClick = (chip: string) => {
    handleChange(sectionKey, chip);
  };

  return (
    <Div sx={styles.container}>
      {chips.map((chip, index) => (
        <Chip
          key={index}
          label={chip}
          clickable
          sx={sx(styles.defaultChipState, {
            sx: styles.activeChipState,
            condition: Boolean(filteredParams[sectionKey]?.includes(chip)),
          })}
          onClick={() => handleTagClick(chip)}
        />
      ))}
    </Div>
  );
};

export default MultipleSelectChips;
