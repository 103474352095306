import { Grid, Link, Typography } from '@mui/material';
import styles from './styles';
import Div from '../../../../../shared/ui/Div';
import React, { FC } from 'react';

interface IManageChildColumnsProps {
  onUpdateStudents: () => void;
  isLoadingUpdateStudents: boolean;
}

const ManageChildColumns:FC<IManageChildColumnsProps> = (props) => {
  const { onUpdateStudents, isLoadingUpdateStudents } = props;
  return (
    <Div sx={styles.container}>
      <Grid container>
        <Grid xs={12} sm={3} item sx={styles.column}>
          <Typography sx={styles.columnTitle}>Student</Typography>
        </Grid>
        <Grid xs={12} sm={4} item sx={styles.column}>
          <Typography sx={styles.columnTitle}>Username</Typography>
        </Grid>
        <Grid gap={1} xs={12} sm={5} sx={styles.column} item>
          <Div sx={styles.lineTitleWrapper}>
            <Typography sx={styles.columnTitle}>You can</Typography>
            <Link onClick={onUpdateStudents} sx={{
              ...styles.columnTitle,
              ...styles.link
            }}>
              { isLoadingUpdateStudents ?  'Importing...' : 'update roster from Google Classroom' }
            </Link>
          </Div>
        </Grid>
      </Grid>
    </Div>
  );
};

export default ManageChildColumns;
