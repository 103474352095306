import React, { FC } from 'react';

import { MainLayout } from '../../../widgets';
import ContentLayout from '../../../shared/ui/ContentLayout';
import { UserRole } from '../../../entities/user';
import Div from "../../../shared/ui/Div";
import styles from "./styles";
import { Grid, Link, Typography } from "@mui/material";
import Image from "../../../shared/ui/Image";
import { ArrowBack } from "@mui/icons-material";

interface IMainSignInProps {
  isSignIn?: boolean;
  signInParams: {
    signInBtnPath: string;
    signUpBtnPath: string;
    signUpPath: string;
    studentImgSrc: string
    studentSignInPath: string;
    teacherImgSrc: string;
    teacherSignInPath: string;
    backLinkPath: string;
  };
  signUpParams: {
    signInBtnPath: string;
    signInPath: string;
    signUpBtnPath: string;
    studentImgSrc: string;
    studentSignUpPath: string;
    teacherImgSrc: string;
    teacherSignUpPath: string;
    backLinkPath: string;
  };
}

const MainAuthPage: FC<IMainSignInProps> = (props) => {
  const { signInParams, isSignIn = false, signUpParams } = props;

  return (
    <MainLayout
      userRole={UserRole.Public}
    >
     <ContentLayout>
       <Link href={ isSignIn ? signInParams?.backLinkPath : signUpParams?.backLinkPath } sx={styles.link}>
          <ArrowBack /> Back
       </Link>
       <Div sx={styles.container}>
         <Div sx={styles.preview}>
           <Typography variant="h1">
             { isSignIn ? 'Welcome Back' : 'Welcome' }
           </Typography>
           <Typography variant="h3">
             Let’s get you to the right type of account.
           </Typography>
         </Div>
         <Grid sx={styles.navigationContainer} container spacing={5}>
           <Grid xs={12} sm={6} item>
             <Link sx={styles.navigationCard} href={isSignIn ? signInParams?.teacherSignInPath : signUpParams?.teacherSignUpPath}>
               <Image src={isSignIn ? signInParams?.teacherImgSrc : signUpParams?.teacherImgSrc} alt="teacher image" />
             </Link>
           </Grid>
           <Grid xs={12} sm={6} item>
              <Link sx={styles.navigationCard} href={isSignIn ? signInParams?.studentSignInPath : signUpParams?.studentSignUpPath}>
                <Image src={isSignIn ? signInParams?.studentImgSrc : signUpParams?.studentImgSrc} alt="student image" />
              </Link>
           </Grid>
         </Grid>
         <Typography sx={styles.joinWrapper}>
           { isSignIn ? 'Not a member?' : 'Already a member?' } <Link sx={styles.link} href={isSignIn ? signInParams?.signUpPath : signUpParams?.signInPath}>
           { isSignIn ? 'Join Now' : 'Sign In' }
         </Link>
         </Typography>
       </Div>
     </ContentLayout>
    </MainLayout>
  );
};

export default MainAuthPage;
