const styles = {
  formContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    margin: '0 auto',
    padding: '20px 0px',
    textAlign: 'left',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  hiddenItem: {
    display: 'none',
  },
  formItemCheckbox: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  formItemLabel: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
  },
  formItemCheckboxLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    width: '100%',
  },
  formItemLabelLink: {
    color: 'secondary.dark',
  },
  formItemFieldExample: {
    fontSize: '10px',
    color: 'red',
  },
  formItemLabelRequiredSymbol: {
    '&::before': {
      position: 'absolute',
      left: '-10px',
      top: 0,
      content: '"*"',
      color: 'red',
      fontSize: '14px',
    },
  },
  submitButton: {
    width: '100%',
  },
  error: {
    color: 'red',
  },
};

export default styles;
