import * as React from 'react';
import { type FC } from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Link, Typography } from '@mui/material';

import lingoLiftLessonLogo from '../../../../assets/lingoLiftLessonLogo.svg';
import listenWiseLessonLogo from '../../../../assets/listenWiseLessonLogo.svg';
import quizIcon from '../../../../assets/secondaryIcons/quizIcon.svg';
import videoIcon from '../../../../assets/secondaryIcons/videoIcon.svg';

import styles from './styles';
import Div from '../../../../shared/ui/Div';
import sx from 'mui-sx';
import { sliceTextContent } from '../../../../shared/utils/sliceTextContent';
import Image from '../../../../shared/ui/Image';
import { LingoLiftBadgeType } from '../../model/enums';
import { ILingoLiftLesson, LingoLiftLessonBadge } from './../../../lingoLiftLesson';
import { parseToPascaleCase } from "../../../../shared/utils/parseToPascaleCase";
import { isLingoLiftProduct, isListenWiseProduct } from "../../../../shared/utils/lingoLiftCompareConditions";

interface ILessonCardProps extends Omit<ILingoLiftLesson, 'category'> {
  category: string;
}

const LingoLiftLessonCard: FC<ILessonCardProps> = props => {
  const { name, description, photoSrc, url, category, product, quiz, video, subjects  } = props;

  return (
    <Div sx={styles.wrapper}>
      <Card sx={styles.card}>
        <CardActionArea disableRipple sx={styles.actionArea}>
          <CardMedia
            image={photoSrc}
            component="div"
            sx={sx(
              {
                ...styles.poster,
              },
              {
                sx: styles.lingoLiftPoster,
                condition: isLingoLiftProduct(product),
              },
              {
                sx: styles.listenWisePoster,
                condition: isListenWiseProduct(product),
              }
            )}
          >
            {(category && isLingoLiftProduct(product)) && (
              <Div sx={styles.badge}>
                <LingoLiftLessonBadge type={LingoLiftBadgeType.ShuttleGray} title={category} />
              </Div>
            )}

            { (subjects?.length > 0 && isLingoLiftProduct(product)) && <Div sx={styles.subjectsWrapper}>
              { subjects.map((subject) => <Div key={subject}>
                <LingoLiftLessonBadge type={LingoLiftBadgeType[parseToPascaleCase(subject)]} title={subject} />
              </Div>) }
            </Div>}
          </CardMedia>

          <CardContent sx={styles.content}>
            <Link href={url} sx={styles.link}>
              {name}
            </Link>
            <Typography sx={styles.description}>{sliceTextContent(description, 100)}</Typography>
            <Div sx={styles.horizontalSpaceBetween}>
              <Link sx={styles.lessonLogo}>
                {isLingoLiftProduct(product) && <Image src={lingoLiftLessonLogo} alt="lingo lift lesson logo" />}
                {isListenWiseProduct(product) && (
                  <Image src={listenWiseLessonLogo} alt="listen wise lesson logo" />
                )}
              </Link>
              <Div sx={styles.tagWrapper}>
                {video && <Image sx={styles.tagIcon} src={videoIcon} alt="Listenwise watch card icon" />}
                {quiz && <Image sx={styles.tagIcon} src={quizIcon} alt="Listenwise card quiz icon" />}
              </Div>
            </Div>
          </CardContent>
        </CardActionArea>
      </Card>
    </Div>
  );
};

export default LingoLiftLessonCard;
