import React, { FC, useState, useEffect } from 'react';
import styles from './styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Grid } from "@mui/material";
import { CalendarTodayOutlined } from '@mui/icons-material';
import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'ramda';

const DEFAULT_FORMAT = 'YYYY-MM-DD HH:mm:ss';

interface IDateTimeFieldProps {
  onChange: (value: string | null) => void;
  name: string;
  datePlaceholder: string;
  timePlaceholder: string;
  initialValue?: string;
  isError?: boolean;
}

const DateTimeField: FC<IDateTimeFieldProps> = props => {
  const { onChange, name, datePlaceholder, timePlaceholder, initialValue, isError = false } = props;
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null);

  const mergedDateTime = () => {
    if (!isNil(selectedDate) && !isNil(selectedTime)) {
      return selectedDate
        .hour(selectedTime.hour())
        .minute(selectedTime.minute())
        .second(selectedTime.second())
        .format(DEFAULT_FORMAT);
    }
    if (!isNil(selectedDate)) {
      return selectedDate.format(DEFAULT_FORMAT);
    }
    return null;
  };

  useEffect(() => {
    if (!isNil(initialValue)) {
      const dateTime = dayjs(initialValue);
      setSelectedDate(dateTime);
      setSelectedTime(dateTime);
    }
  }, [initialValue]);

  useEffect(() => {
    const mergedDateTimeValue = mergedDateTime();
    if (!isNil(mergedDateTimeValue) && dayjs(mergedDateTimeValue).isValid()) {
      onChange(dayjs(mergedDateTimeValue, DEFAULT_FORMAT).format(DEFAULT_FORMAT));
    } else onChange(null);
  }, [selectedDate, selectedTime]);

  return (
    <Grid container spacing={2}>
      <Grid xs={12} sm={6} item>
        <DatePicker
          slotProps={{
            openPickerIcon: CalendarTodayOutlined,
            textField: {
              placeholder: datePlaceholder,
              error: isError,
            },
          }}
          name={name}
          value={selectedDate}
          onChange={date => setSelectedDate(date)}
          sx={styles.pickerItem}
        />
      </Grid>
      <Grid xs={12} sm={6} item>
        <TimePicker
          slotProps={{
            textField: {
              placeholder: timePlaceholder,
              error: isError,
            },
          }}
          name={name}
          value={selectedTime}
          onChange={time => setSelectedTime(time)}
          sx={styles.pickerItem}
        />
      </Grid>
    </Grid>
  );
};

export default DateTimeField;
