const styles = {
  list: {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    boxSizing: 'border-box',
    border: 'none',
    flex: 1,
    gap: '15px',
  },
  category: {
    color: 'white',
    textTransform: 'uppercase',
    margin: 0,
  },
  categoryItemsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: {
      xs: 0,
      sm: '15px',
    },
    padding: 0,
    margin: 0,
  },
  categoryItem: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      '& .MuiLink-root': {
        color: 'secondary.dark',
      },
    },
  },
  categoryItemBold: {
    fontWeight: 700,
  },
  link: {
    '&.MuiLink-root': {
      fontSize: '14px',
      textTransform: 'capitalize',
      color: '#A9B7BD',
      textDecoration: 'none',
    },
  },
};

export default styles;
