import React, { FC } from 'react';
import Div from '../../../../shared/ui/Div';
import styles from './styles';
import { Grid, Link, Typography } from '@mui/material';
import Image from '../../../../shared/ui/Image';
import lingoLiftLessonLogo from '../../../../assets/lingoLiftLessonLogo.svg';
import listenWiseLessonLogo from '../../../../assets/listenWiseLessonLogo.svg';
import {
  type ILingoLiftTeacherLWClassesRecentAssignments,
  type ILingoLiftTeacherLLClassesRecentAssignments,
} from '../../../lingoliftClasses';
import dayjs from 'dayjs';
import { isEmpty } from 'ramda';
import { getListenWiseClassStatuses } from '../../lib/getClassStatuses';

const DEFAULT_FORMAT = 'MM/DD/YYYY';

interface ILingoLiftClassesAssignmentCard {
  isListenWiseClass?: boolean;
  schoolClass: ILingoLiftTeacherLWClassesRecentAssignments &
    ILingoLiftTeacherLLClassesRecentAssignments;
}

const LingoLiftClassesAssignmentCard: FC<ILingoLiftClassesAssignmentCard> = (props) => {
  const {
    isListenWiseClass = false,
    schoolClass: {
      schoolClassName,
      schoolClassStudentsCountLabel,
      url,
      assignments,
      quizAssignments,
      classAssignmentsUrl,
      classSummaryReportUrl,
      llSchoolClassName,
      llSchoolClassStudentsCountLabel,
      llLessonAscs,
      llEnhancedCollectionAscs,
      classLlAssignmentsUrl,
    },
  } = props;

  const getAssignmentRow = (assignment, isQuizAssignment = false) => {
    const {
      lessonName,
      name,
      url: lessonUrl,
      results: {
        new: newCount = 0,
        completed: completedCount = 0,
        started: startedCount = 0,
        notStarted: notStartedCount = 0,
        returned: returnedCount = 0,
      },
      seeResponsesUrl,
      dueDate,
      dueDateLabel,
      id,
    } = assignment;
    const listenWiseClassStatuses = getListenWiseClassStatuses(newCount, completedCount, startedCount, notStartedCount);
    return (
      <Grid
        key={id}
        sx={{
          ...styles.row,
          ...styles.rowSpace,
          ...styles.borderedRow,
        }}
        container
      >
        <Grid xs={12} sm={5} item sx={styles.column}>
          <Typography variant="h5">{lessonName}</Typography>
          <Link sx={styles.entitySemiName} href={lessonUrl}>
            {name}
          </Link>
        </Grid>
        <Grid xs={12} sm={5} item sx={styles.column}>
          <Typography sx={styles.entityName}>
            {isListenWiseClass
              ? listenWiseClassStatuses
              : `${listenWiseClassStatuses}, ${returnedCount} Returned`}
          </Typography>
          {isQuizAssignment ? (
            <Link sx={styles.entitySemiName} href={assignment.seeQuizResultsUrl}>
              See Quiz Results
            </Link>
          ) : (
            <Link sx={styles.entitySemiName} href={seeResponsesUrl}>
              See Responses
            </Link>
          )}
        </Grid>
        <Grid xs={12} sm={2} item sx={styles.column}>
          <Typography sx={styles.entityName}>{dayjs(dueDate).format(DEFAULT_FORMAT)}</Typography>
          <Typography color="primary.dark">{dueDateLabel}</Typography>
        </Grid>
      </Grid>
    );
  };

  const isEmptyAssignments =
    (isEmpty(assignments) && isEmpty(quizAssignments)) ||
    (isEmpty(llLessonAscs) && isEmpty(llEnhancedCollectionAscs));

  return (
    <Div sx={styles.container}>
      <Grid
        sx={{
          ...styles.rowTitle,
          ...styles.rowSpace,
          ...styles.borderedRow,
        }}
        container
      >
        <Grid xs={10} sm={10} item>
          {isListenWiseClass ? (
            <Image src={listenWiseLessonLogo} alt="listenwise lesson logo" />
          ) : (
            <Image src={lingoLiftLessonLogo} alt="lingolift lesson logo" />
          )}
          <Div sx={styles.wrapper}>
            <Link sx={styles.title} href={url}>
              {isListenWiseClass ? schoolClassName : llSchoolClassName}
            </Link>
            <Typography color="primary.dark" variant="body2" sx={styles.subtitle}>
              {isListenWiseClass ? schoolClassStudentsCountLabel : llSchoolClassStudentsCountLabel}
            </Typography>
          </Div>
        </Grid>
      </Grid>
      <Div
        sx={{
          ...styles.row,
          ...styles.rowSpace,
          ...styles.borderedRow,
        }}
      >
        {isEmptyAssignments ? (
          <Typography variant="h5" sx={styles.italic}>
            No Recent Assignments
          </Typography>
        ) : (
          <Typography variant="h5">Recently Assigned</Typography>
        )}
      </Div>
      {isListenWiseClass && assignments.map(assignment => getAssignmentRow(assignment))}

      {isListenWiseClass &&
        quizAssignments.map(quizAssignment => getAssignmentRow(quizAssignment, true))}

      {!isListenWiseClass && llLessonAscs.map(llAssignment => getAssignmentRow(llAssignment))}

      {!isListenWiseClass &&
        llEnhancedCollectionAscs.map(llEnhancedCollectionAssignment =>
          getAssignmentRow(llEnhancedCollectionAssignment),
        )}
      <Div
        sx={{
          ...styles.rowSpace,
          ...styles.actions,
        }}
      >
        <Link
          href={classSummaryReportUrl}
          sx={{ ...styles.actionButton, ...styles.borderRadiusLeft }}
        >
          See Class Summary Report
        </Link>
        <Link
          href={isListenWiseClass ? classAssignmentsUrl : classLlAssignmentsUrl}
          sx={{ ...styles.actionButton, ...styles.borderRadiusRight }}
        >
          View All Assigned
        </Link>
      </Div>
    </Div>
  );
};

export default LingoLiftClassesAssignmentCard;
