const appRoutes = {
  studentSearch: '/lingolift/students/search',
  teacherSearch: '/lingolift/teachers/search',
  privatePolicy: 'https://listenwise.com/terms/?_ga=2.183389615.862074281.1715603072-529674293.1708940770',
  addClassRedirect: '/lingolift/teachers/school_classes/?type=manage',
  addLWClass: '/lingolift/teachers/school_classes/add',
  addLLClass: '/lingolift/teachers/school_classes/ll_add',
  studentDashboard: '/lingolift/students/dashboard',
  public: '/public',
  studentDashboardJoinClass: '/lingolift/students/dashboard/join',
  studentSignIn: '/students/sign_in',
  teacherSignIn: '/teachers/sign_in',
  studentSignUp: '/students/sign_up',
  teacherSignUp: '/teachers/sign_up',
  googleClassroomVideo: 'https://watch.screencastify.com/v/qNGxLrsJIQK6F2JNImK4',
  mailListenWise: 'mailto:support@listenwise.com',
};

export default appRoutes;
