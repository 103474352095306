const styles = {
  contentContainer: {
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'semiGray',
    overflow: 'hidden',
    zIndex: 1,
    borderCollapse: 'collapse'
  },
  sideBarMenu: {
    borderRadius: '12px',
    backgroundColor: 'white',
    width: '100%',
    padding: '7px'
  },
  menuItem: {
    color: 'primary.dark',
    '&:hover': {
      backgroundColor: 'primary.light',
      borderRadius: '8px',
      '& .MuiTypography-root': {
        color: 'secondary.main',
      },
    },
  },


}

export default styles;
