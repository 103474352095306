import React, { type FC } from 'react';
import styles from './styles';
import { Grid, Typography } from '@mui/material';
import { ILingoLiftRelatedLesson, LingoLiftLessonCard } from '../../../entities/lingoLiftLesson';
import Div from '../../../shared/ui/Div';

interface IRelatedLessonsProps {
  related: Array<ILingoLiftRelatedLesson>;
}

const RelatedLessons: FC<IRelatedLessonsProps> = props => {
  const { related } = props;

  return (
    <Div sx={styles.wrapper}>
      <Typography variant="h2" color="darkGray">
        Related Lessons
      </Typography>
      <Grid sx={styles.lessons} container spacing={2}>
        {related.map(
          (lesson, index) =>
            index !== 4 && (
              <Grid key={index} item lg={3} sm={6} xs={12}>
                <LingoLiftLessonCard {...lesson} key={lesson.id} />
              </Grid>
            )
        )}
      </Grid>
    </Div>
  );
};

export default RelatedLessons;
