const styles = {
  container: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    padding: '24px',
    borderRadius: '8px',
  },
  doubleLogo: {
    width: {
      sm: '238px',
      xs: '160px',
    },
    height: 'auto',
  },
  link: {
    color: 'secondary.dark',
    fontWeight: 600,
  },
  modalWarningWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: 'max-content'
  },
  modalWarningList: {
    decimalList: {
      listStyle: 'decimal',
      pl: 4
    },
    lowerAlphalList: {
      listStyle: 'lower-alpha',
      pl: 5
    },
    item: {
      display: 'list-item',
      py: 0
    }
  }
};

export default styles;
