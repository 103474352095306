import * as React from 'react';
import { FC } from 'react';
import Div from '../../../Div';
import styles from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabContent: FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props;

  return (
    <Div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={styles.content}
    >
      {value === index && <>{children}</>}
    </Div>
  );
};

export const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});
