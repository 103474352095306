import { selectResponse } from '../../../shared/utils/selectFromResponseObjects';
import { apiContainer, apiRoutes, IPaginatedResponse } from '../../../shared/store';
import { ILingoLiftSearch, ILingoLiftSearchFilterOption } from '../model/types';
import { CacheTags } from "../../../shared/store/commonEnums";

export const lingoLiftSearchApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLingoLiftTeacherSearch: builder.query<IPaginatedResponse<ILingoLiftSearch>, Record<string, string | number>>({
      query: params => ({
        url: apiRoutes.lingoLiftTeacherSearchPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.SearchList, id: 'LIST' }],
    }),
    getLingoLiftTeacherSearchFilter: builder.query<Array<ILingoLiftSearchFilterOption>, { products: string }>({
      query: (params) => ({
        url: apiRoutes.lingoLiftTeacherSearchFilterPath(),
        method: 'GET',
        params
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.SearchList, id: 'LIST' }],
    }),
    getLingoLiftStudentSearch: builder.query<IPaginatedResponse<ILingoLiftSearch>, Record<string, string | number>>({
      query: params => ({
        url: apiRoutes.lingoLiftStudentSearchPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectResponse,
      providesTags: () => [{ type: CacheTags.SearchList, id: 'LIST' }],
    }),
    getLingoLiftStudentSearchFilter: builder.query<Array<ILingoLiftSearchFilterOption>, { products: string }>({
      query: (params) => ({
        url: apiRoutes.lingoLiftStudentSearchFilterPath(),
        method: 'GET',
        params
      }),
      transformResponse: selectResponse,
      invalidatesTags: () => [{ type: CacheTags.SearchList, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetLingoLiftTeacherSearchFilterQuery,
  useGetLingoLiftTeacherSearchQuery,
  useGetLingoLiftStudentSearchFilterQuery,
  useGetLingoLiftStudentSearchQuery,
} = lingoLiftSearchApi;
