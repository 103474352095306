import React, { type FC, useEffect } from 'react';

import ContentLayout from '../../shared/ui/ContentLayout';
import { LessonGoBackLine, LingoLiftLessonHeader, MainLayout, RelatedLessons } from '../../widgets';

import styles from './styles';
import { useGetLingoLiftStudentLessonDetailsQuery } from '../../entities/lingoLiftLesson';
import Div from '../../shared/ui/Div';
import { UserRole } from "../../entities/user";

interface ILingoLiftLessonStudentShowProps {
  id: number;
  accessToken: string;
  partnerKey: string;
  dialogId: number;
  languageCode: string;
}

const LingoLiftLessonStudentShowPage: FC<ILingoLiftLessonStudentShowProps> = props => {
  const { id, accessToken, partnerKey, dialogId, languageCode } = props;

  const { data: lessonDetails, isLoading } = useGetLingoLiftStudentLessonDetailsQuery(id);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (window.ECSDK && lessonDetails) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.ECSDK.loadWidget('player', {
        partnerKey,
        partnerSdkToken: accessToken,
        dialogId,
        container: 'player-container',
        lang: languageCode,
        settings: {
          autoStart: false,
          isComprehensionQuizEnabled: false,
        },
      });
    }
  }, [lessonDetails]);

  return (
    <MainLayout backgroundColor="white" userRole={UserRole.Student} isLoading={isLoading}>
      <LessonGoBackLine title="See All Lessons" />
      {lessonDetails && (
        <>
            <LingoLiftLessonHeader
              {...lessonDetails.item}
            />
            <Div sx={styles.iframe} id="player-container" />

            { lessonDetails?.related?.length > 0 &&  <RelatedLessons related={lessonDetails.related} /> }
        </>
      )}
    </MainLayout>
  );
};

export default LingoLiftLessonStudentShowPage;
