const styles = {
  accordion: {
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'semiGray',
    zIndex: 0,
    borderCollapse: 'collapse'
  },
  firstAccordion: {
    borderTop: 'none',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px'
  },
  lastAccordion: {
    borderBottom: 'none',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  summary: {
    '&.Mui-expanded': {
      '& .MuiSvgIcon-root': {
        transform: 'rotate(180deg)',
      }
    }
  },
  gradeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  standardChildItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  gradeTitle: {
    fontSize: '18px',
    color: 'secondary.dark',
    fontWeight: 600
  },
  standardParentTitle: {
    marginLeft: '10px'
  },
}

export default styles;
