const styles = {
  container: {
    borderRadius: '12px',
    border: '1px solid',
    borderColor: 'semiGray',
    boxShadow: '0px 2px 8px -1px #1B242C14, 0px 2px 2px -1px #1B242C0A',
  },
  tableHead: {
    backgroundColor: 'athensGray',
    '& th': {
      padding: '10px 16px',
    },
  },
  headRow: {
    height: '40px',
  },
  titleCell: {
    minWidth: '300px',
  },
  name: {
    fontSize: '14px',
    color: 'secondary.dark',
  },
  link: {
    fontWeight: 700,
  },
  entitySemiName: {
    fontWeight: 600,
  },
  button: {
    borderRadius: '4px',
    width: '32px',
    height: '32px',
    '&:hover, &:focus': {
      filter: 'brightness(90%)',
    },
  },
  icon: {
    width: '32px',
    height: '32px',
  },
  transparentButton: {
    backgroundColor: 'white',
    color: 'primary.main',
    padding: '5px 10px',
    border: '1px solid',
    borderColor: 'semiGray',
    cursor: 'pointer',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'secondary.main',
      color: 'white',
    },
  },
  tableLastChildRow: {
    '&:last-child td, &:last-child th': {
      border: 0
    }
  },
};

export default styles;
