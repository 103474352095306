import React, { type FC } from 'react';
import { Button, Card, CardContent, CardMedia, Grid, Link, Typography } from '@mui/material';

import styles from './styles';
import Div from '../../../../shared/ui/Div';
import sx from 'mui-sx';
import { sliceTextContent } from '../../../../shared/utils/sliceTextContent';
import Image from '../../../../shared/ui/Image';
import listenWiseLessonLogo from '../../../../assets/listenWiseLessonLogo.svg';
import lingoLiftLessonLogo from '../../../../assets/lingoLiftLessonLogo.svg';

import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
import LingoLiftCollectionChildCard from './CollectionChildCard';
import { PageToolActions } from '../../../../widgets';
import { ILingoLiftCollection } from '../../model/types';
import editIcon from '../../../../assets/editIcon.svg';
import { isTeacher, UserRole } from '../../../user';
import { isNil } from 'ramda';
import { isLingoLiftProduct, isListenWiseProduct } from '../../../../shared/utils/lingoLiftCompareConditions';
import { ILingoLiftFavoriteParams, useDeleteLingoLiftTeacherFavoriteMutation, usePostLingoLiftTeacherAddFavoriteMutation } from '../../../lingoLiftFavorite';
import { LingoLiftFavoriteableType } from '../../../lingoLiftLesson';
import { useSnackbar } from '../../../../shared/hooks/useSnackbar';

const SUCCESS_MESSAGE = 'Link copied to clipboard';

interface ILingoLiftCollectionCardProps extends ILingoLiftCollection {
  onToggleExpandCollection?: (value: number | null) => void;
  expandedCollection?: number | null;
  fullScreen?: boolean;
  userRole?: UserRole;
  assignUrl?: string;
  shareUrl?: string;
}

const LingoLiftCollectionCard: FC<ILingoLiftCollectionCardProps> = props => {
  const {
    id,
    name,
    description,
    photoSrc,
    product,
    url,
    collectionItems,
    expandedCollection,
    onToggleExpandCollection,
    fullScreen,
    userRole = UserRole.Student,
    assignUrl = null,
    shareUrl = '',
    favorited,
  } = props;

  const { showSuccessNotification, showErrorNotification } = useSnackbar();

  const [postLingoLiftTeacherToggleFavorite] = usePostLingoLiftTeacherAddFavoriteMutation();

  const [deleteLingoLiftTeacherFavorite] = useDeleteLingoLiftTeacherFavoriteMutation();

  const onToggleFavorite = async (params: ILingoLiftFavoriteParams, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await deleteLingoLiftTeacherFavorite(params);
        showSuccessNotification('Removed from favorites');
      } else {
        await postLingoLiftTeacherToggleFavorite(params);
        showSuccessNotification('Added to favorites');
      }
    } catch (error) {
      showErrorNotification(error.response.data.error);
    }
  };

  const handleShare = () => {
    navigator.clipboard.writeText(shareUrl.currentUrl);
    showSuccessNotification(SUCCESS_MESSAGE);
  };

  return (
    <Card
      sx={sx(styles.card, {
        sx: styles.fullScreenCard,
        condition: Boolean(fullScreen),
      })}
    >
      <Grid container sx={styles.wrapper} spacing={2}>
        <Grid item xs={4} sm={2}>
          <CardMedia image={photoSrc} component="div" sx={styles.poster} />
        </Grid>
        <Grid item xs={8} sm={fullScreen ? 7 : 10}>
          <CardContent sx={styles.content}>
            {!fullScreen && (
              <Link sx={styles.lessonLogo}>
                {isLingoLiftProduct(product) && (
                  <Image src={lingoLiftLessonLogo} alt="lingo lift lesson logo" />
                )}
                {isListenWiseProduct(product) && (
                  <Image src={listenWiseLessonLogo} alt="listen wise lesson logo" />
                )}
              </Link>
            )}
            <Link
              href={url}
              sx={sx(styles.link, {
                sx: styles.fullScreenLink,
                condition: Boolean(fullScreen),
              })}
            >
              {name}
            </Link>
            <Typography
              sx={sx(styles.description, {
                sx: styles.fullScreenDescription,
                condition: Boolean(fullScreen),
              })}
            >
              {sliceTextContent(description, 200)}
            </Typography>
          </CardContent>

          {!fullScreen && (
            <Typography
              sx={styles.collapseAccordionAction}
              onClick={() => onToggleExpandCollection && onToggleExpandCollection(expandedCollection === id ? null : id)}
            >
              View {collectionItems.length} Lessons{' '}
              {expandedCollection === id ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
            </Typography>
          )}
        </Grid>
        {fullScreen && (
          <Grid item sx={styles.tools} xs={12} md={3}>
            <PageToolActions
              onFavorite={isTeacher(userRole) ? () => onToggleFavorite(
                {
                  favoritableId: id,
                  favoritableType: LingoLiftFavoriteableType.EnhancedCollection,
                },
                favorited,
              ) : false}
              onShare={handleShare}
              isFavorite={favorited}
            />
            { isTeacher(userRole) && !isNil(assignUrl) && (
              <Button
                startIcon={<Image sx={styles.assignButtonIcon} src={editIcon} alt="icon" />}
                sx={styles.assignButton}
                component={Link}
                href={assignUrl}
              >
                Assign Collection
              </Button>
            )}
          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sx={sx({
          sx: styles.accordionDetailsFullScreenBorder,
          condition: Boolean(fullScreen),
        })}
      >
        <Div
          sx={sx(
            {
              sx: styles.accordionDetailsActive,
              condition: fullScreen || expandedCollection === id,
            },
            {
              sx: styles.accordionDetailsListenWise,
              condition: isListenWiseProduct(product),
            },
            {
              sx: styles.accordionDetailsLingoLift,
              condition: isLingoLiftProduct(product),
            },
            {
              sx: styles.accordionDetailsInActive,
              condition: !fullScreen && expandedCollection !== id,
            },
          )}
        >
          {collectionItems?.map((item, index) => (
            <LingoLiftCollectionChildCard elementRounded={fullScreen && index === 0} key={item.id} {...item} />
          ))}
        </Div>
      </Grid>
    </Card>
  );
};

export default LingoLiftCollectionCard;
