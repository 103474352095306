import React, { useState, FC } from 'react';
import { Grid, Typography, CircularProgress, Link, Button } from '@mui/material';
import { isNil } from 'ramda';

import ContentLayout from '../../shared/ui/ContentLayout';

import { DashboardSection, LingoLiftDashboardSectionHeader, MainLayout } from '../../widgets';

import styles from './styles';
import DropdownMenu from '../../shared/ui/DropdownMenu';
import {
  LingoLiftTeacherDashboardClassesList,
  LingoLiftTeacherDashboardFeedCard,
  LingoLiftTeacherDashboardTaskList,
  LingoLiftTeacherDashboardReport,
  useGetLingoLiftTeacherDashboardTasksQuery,
  useGetLingoLiftTeacherDashboardFeedQuery,
  useGetLingoLiftTeacherDashboardFilterQuery,
  useGetLingoLiftTeacherDashboardClassesQuery,
} from '../../entities/lingoLiftTeacherDashboard';
import Div from '../../shared/ui/Div';
import lingoLiftIcon from '../../assets/tabsLogos/lingoLiftIcon.svg';
import lingoLiftActiveIcon from '../../assets/tabsLogos/lingoLiftActiveIcon.svg';
import listenWiseIcon from '../../assets/tabsLogos/listenWiseIcon.svg';
import listenWiseActiveIcon from '../../assets/tabsLogos/listenWiseActiveIcon.svg';
import TabsContainer from '../../shared/ui/Tabs/ui';
import { TabContent } from '../../shared/ui/Tabs/ui/TabContent';
import PaginationLine from '../../shared/ui/PaginationLine';
import Loader from '../../shared/ui/Loader';
import { UserRole } from '../../entities/user';
import {
  ILingoLiftTeacherDashboardClass,
  ILingoLiftTeacherDashboardClassesLink
} from '../../entities/lingoLiftTeacherDashboard/model/types';
import {
  ILingoLiftFavoriteParams,
  useDeleteLingoLiftTeacherFavoriteMutation,
  usePostLingoLiftTeacherAddFavoriteMutation,
} from '../../entities/lingoLiftFavorite';
import { useSnackbar } from "../../shared/hooks/useSnackbar";

const LIMIT = 5;
const LIMIT_TASKS = 3;

interface ILingoLiftTeacherDashboardBrowsePageProps {
  isListenwise: boolean;
  isLingolift: boolean;
  teacherName: string;
}
interface ReportTab {
  name: string;
  icon: any;
  activeIcon: any;
  isListenwise?: boolean;
  isLingolift?: boolean;
}

const LingoLiftTeacherDashboardBrowsePage: FC<ILingoLiftTeacherDashboardBrowsePageProps> = (props) => {
  const { isListenwise, isLingolift, teacherName } = props;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedFilter, setSelectFilter] = useState<string>('All');
  const [schoolClassesDisplayedCount, setSchoolClassesDisplayedCount] = useState<number>(LIMIT);
  const [tasksDisplayedCount, setTasksDisplayedCount] = useState<number>(LIMIT_TASKS);
  const [sectionOrder, setSectionOrder] = useState<Array<DashboardSection>>(['tasks', 'classes', 'reports']);
  const [viewReportsLink, setViewReportsLink] = useState<string | null>(null);
  const { showSuccessNotification, showErrorNotification } = useSnackbar();

  const reportTabs: ReportTab[] = [];

  if (isListenwise) {
    reportTabs.push({
      name: 'Listenwise',
      icon: listenWiseIcon,
      activeIcon: listenWiseActiveIcon,
      isListenwise: true,
      isLingolift: false,
    });
  }

  if (isLingolift) {
    reportTabs.push({
      name: 'Lingolift',
      icon: lingoLiftIcon,
      activeIcon: lingoLiftActiveIcon,
      isListenwise: false,
      isLingolift: true,
    });
  }

  const { data: dashboardFilters = [], isLoading: isDashboardFiltersLoading } =
    useGetLingoLiftTeacherDashboardFilterQuery();

  const selectedFilterUrl = dashboardFilters.find((filter) => filter.label === selectedFilter);

  const {
    data: dashboardFeed,
    isFetching,
  } = useGetLingoLiftTeacherDashboardFeedQuery({
      url: isNil(selectedFilterUrl) ? null : selectedFilterUrl?.url,
      params: {
        page: currentPage,
      }
    },
    {
      // This skip need to escape request twice
      skip: !dashboardFilters?.length
    });

  const [postLingoLiftTeacherDashboardToggleFavorite] = usePostLingoLiftTeacherAddFavoriteMutation();

  const [deleteLingoLiftTeacherDashboardFavorite] = useDeleteLingoLiftTeacherFavoriteMutation();

  const { data: tasksList, isLoading: isLoadingTasks} = useGetLingoLiftTeacherDashboardTasksQuery();

  const { data: allClasses, isLoading: isAllClassesLoading} = useGetLingoLiftTeacherDashboardClassesQuery();

  const onToggleFavorite = async (params: ILingoLiftFavoriteParams, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await deleteLingoLiftTeacherDashboardFavorite(params);
        showSuccessNotification('Removed from favorites');
      } else {
        await postLingoLiftTeacherDashboardToggleFavorite(params);
        showSuccessNotification('Added to favorites');
      }
    } catch(error) {
      showErrorNotification(error.response.data.error);
    }
  };

  const handleShowFewerClasses = () => {
    setSchoolClassesDisplayedCount(LIMIT);
  };

  const handleShowMoreClasses = () => {
    setSchoolClassesDisplayedCount(allClasses?.items?.length);
  };

  const showMoreOrLess = () => {
    if (allClasses?.items?.length <= LIMIT) {
      return null;
    }
    if (schoolClassesDisplayedCount > LIMIT) {
      return (
        <Button sx={styles.listItemLink} variant="text" onClick={handleShowFewerClasses}>
          Show Fewer Classes
        </Button>
      );
    }
    return (
      <Button sx={styles.listItemLink} variant="text" onClick={handleShowMoreClasses}>
        Show More Classes
      </Button>
    );
  };

  const handleShowFewerTasks = () => {
    setTasksDisplayedCount(LIMIT_TASKS);
  };

  const handleShowMoreTasks = () => {
    setTasksDisplayedCount(tasksList?.items?.length);
  };

  const showMoreOrLessTasks = () => {
    if (tasksList?.items?.length <= LIMIT_TASKS) {
      return null;
    }
    if (tasksDisplayedCount > LIMIT_TASKS) {
      return (
        <Button sx={styles.listItemLink} variant="text" onClick={handleShowFewerTasks}>
          Show Fewer Tasks
        </Button>
      );
    }
    return (
      <Button sx={styles.listItemLink} variant="text" onClick={handleShowMoreTasks}>
        Show All Tasks
      </Button>
    );
  };

  const renderSection = (sectionType, index) => {
    const moveSectionUp = () => {
      if (index > 0) {
        const newOrder = [...sectionOrder];
        [newOrder[index - 1], newOrder[index]] = [newOrder[index], newOrder[index - 1]];
        setSectionOrder(newOrder);
      }
    };

    const moveSectionDown = () => {
      if (index < sectionOrder.length - 1) {
        const newOrder = [...sectionOrder];
        [newOrder[index], newOrder[index + 1]] = [newOrder[index + 1], newOrder[index]];
        setSectionOrder(newOrder);
      }
    };

    switch (sectionType) {
      case DashboardSection.tasks:
        return (
          <Div sx={styles.contentSection}>
            <Div sx={styles.contentSectionPanel}>
              <Typography variant="h2">Tasks ({tasksList?.items.length ?? 0})</Typography>
              <Div sx={styles.contentSectionPanelActions}>
                {showMoreOrLessTasks()}
                <LingoLiftDashboardSectionHeader
                  index={index}
                  onMoveUp={moveSectionUp}
                  onMoveDown={moveSectionDown}
                  sectionOrder={sectionOrder}
                />
              </Div>
            </Div>
            {isLoadingTasks ? (
              <Loader />
            ) : (
              <LingoLiftTeacherDashboardTaskList
                tasksDisplayedCount={tasksDisplayedCount}
                tasks={tasksList?.items}
              />
            )}
          </Div>
        );
      case DashboardSection.classes:
        return (
          <Div sx={styles.contentSection}>
            <Div sx={styles.contentSectionPanel}>
              <Typography variant="h2">My Classes ({allClasses?.items?.length})</Typography>
              <Div sx={styles.contentSectionPanelActions}>
                {showMoreOrLess()}
                {allClasses?.links?.map((link: ILingoLiftTeacherDashboardClassesLink) => (
                  <Link fontWeight="600" href={link.link} sx={styles.listItemLink} key={link.name}>
                    {link.name}
                  </Link>
                ))}
                <LingoLiftDashboardSectionHeader
                  index={index}
                  onMoveUp={moveSectionUp}
                  onMoveDown={moveSectionDown}
                  sectionOrder={sectionOrder}
                />
              </Div>
            </Div>
            {isAllClassesLoading ? (
              <Loader />
            ) : (
              allClasses.items
                .slice(0, schoolClassesDisplayedCount)
                .map((schoolClass: ILingoLiftTeacherDashboardClass) => <LingoLiftTeacherDashboardClassesList key={schoolClass.id} {...schoolClass} />)
            )}
          </Div>
        );
      case DashboardSection.reports:
        return (
          <Div sx={styles.contentSection}>
            <Div sx={styles.contentSectionPanel}>
              <Typography variant="h2">Reports</Typography>
              <Div sx={styles.contentSectionPanelActions}>
                { (viewReportsLink && tabIndex === 1) && <Link href={viewReportsLink} sx={styles.listItemLink}>
                  View Reports
                </Link> }
                <LingoLiftDashboardSectionHeader
                  index={index}
                  onMoveUp={moveSectionUp}
                  onMoveDown={moveSectionDown}
                  sectionOrder={sectionOrder}
                />
              </Div>
            </Div>
            <Div sx={styles.contentReportsSection}>
              <TabsContainer tabs={reportTabs} tabIndex={tabIndex} setTabIndex={setTabIndex} isCentered>
                {reportTabs.map((tab, index) => (
                  <TabContent
                    key={tab.name}
                    index={index}
                    value={tabIndex}
                  >
                    <LingoLiftTeacherDashboardReport
                      setViewReportsLink={setViewReportsLink}
                      isListenwise={tab.isListenwise}
                      isLingolift={tab.isLingolift}
                    />
                  </TabContent>
                ))}
              </TabsContainer>
            </Div>
          </Div>
        );
      default:
        return null;
    }
  };

  return (
    <MainLayout userRole={UserRole.Teacher} isLoading={false}>
      <Grid container>
        <Grid item sx={styles.feedContainer} display={{ xs: 'none', lg: 'flex' }} xs={0} md={4} lg={3}>
          <Typography variant="h2">My Lesson Feed</Typography>
          <Typography>Lesson Types</Typography>
          <DropdownMenu
            options={dashboardFilters.map((item) => item.label)}
            onChange={setSelectFilter}
            initialValue={selectedFilter}
            isLoading={isDashboardFiltersLoading}
          />
          {isFetching ? (
            <Div sx={styles.loader}>
              <CircularProgress />
            </Div>
          ) : (
            <>
              {dashboardFeed?.items.map((feed) => (
                <LingoLiftTeacherDashboardFeedCard
                  key={feed.id}
                  {...feed}
                  onToggleFavorite={onToggleFavorite}
                  isFavorite={feed.favorited}
                />
              ))}
              {dashboardFeed?.items && dashboardFeed?.pagination.totalPages > 0 && (
                <PaginationLine
                  currentPage={currentPage}
                  onCurrentPageChange={setCurrentPage}
                  pagination={dashboardFeed?.pagination}
                  size="small"
                />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <ContentLayout>
            <Div sx={styles.contentContainer}>
              <Typography variant="h1">Welcome back, {teacherName}</Typography>
              {sectionOrder.map((sectionType, index) => (
                <Div key={sectionType} sx={styles.contentSection}>
                  {renderSection(sectionType, index)}
                </Div>
              ))}
            </Div>
          </ContentLayout>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default LingoLiftTeacherDashboardBrowsePage;
