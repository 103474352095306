import React, { FC, useState } from 'react';
import { Button, Divider, Fade, ListItemText, Menu, MenuItem, MenuList, Paper, Typography } from '@mui/material';
import { KeyboardArrowDownRounded } from '@mui/icons-material';

import { useDesktop } from '../../hooks/useDesktop';

import styles from './styles';
import Div from '../Div';
import sx from 'mui-sx';
import { ILingoLiftFilterOptions } from '../../../entities/lingoLiftLesson';
import Loader from '../Loader';
import { clearQueryParams, parseSingleQueryFromUrl } from "../../utils/parseQueriesFromUrl";

interface ISideBarProps {
  allFiltersName: string;
  filterOptions: ILingoLiftFilterOptions | null;
  onSelectCategoryValue: (object: Record<string, string>) => void;
  selectedCategoryValue: string | null;
  isLoading: boolean;
}

interface IPositionedMenuProps {
  allFiltersName: string;
  filterOptions: ILingoLiftFilterOptions | null;
  handleClickMenuItem: (key: string, value: string) => void;
  selectedCategoryValue: string | null;
}

const PositionedMenu: FC<IPositionedMenuProps> = (props) => {
  const { filterOptions, handleClickMenuItem, allFiltersName } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedLabel, setSelectedLabel] = useState(allFiltersName);

  const open = Boolean(anchorEl);

  const activeQuery = parseSingleQueryFromUrl();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (key: string, value: string, label: string) => {
    handleClickMenuItem(key, value);
    setSelectedLabel(label);
    handleClose();
  };

  // Title of select input
  const selectedInputLabel = (Object.values(activeQuery).length && filterOptions) ?
    filterOptions[Object.keys(activeQuery)[0]]?.values?.find(
      (item) => item.value === Object.values(activeQuery)[0])?.label : selectedLabel;

  return (
    <Div>
      <Button
        onClick={handleClick}
        sx={styles.dropDownMenuButton}
        endIcon={<KeyboardArrowDownRounded sx={styles.dropDownButtonIcon} />}
      >
        { selectedInputLabel }
      </Button>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        sx={styles.dropDownList}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={() => {
          handleSelect('', '', 'All Lessons');
          clearQueryParams();
          }
        }>{ selectedLabel }</MenuItem>

        <Divider />

        {filterOptions &&
          Object.keys(filterOptions)
            .sort()
            .map(key => (
              <Div key={key}>
                <Typography sx={styles.menuItemLabel}>{filterOptions[key].label}</Typography>
                {(!Array.isArray(filterOptions[key].values)
                  ? Object.values(filterOptions[key].values)
                  : filterOptions[key].values
                ).map(value => (
                  <MenuItem
                    key={value?.value || value}
                    onClick={() => handleSelect(value?.key || key, (value?.value || value), filterOptions[key].label)}
                    sx={styles.menuItem}
                  >
                    {value?.label || value}
                  </MenuItem>
                ))}
                <Divider />
              </Div>
            ))}
      </Menu>
    </Div>
  );
}

const SideBar: FC<ISideBarProps> = (props) => {
  const { filterOptions, onSelectCategoryValue, selectedCategoryValue, allFiltersName, isLoading } = props;
  const isDesktop = useDesktop();

  const activeQuery = parseSingleQueryFromUrl();

  const handleClickMenuItem = (key: string, value: string) => {
    onSelectCategoryValue({ [key]: value });

    // Change query params without rerender page
    const currentUrl = new URL(window.location.href);

    // Clear queries in url before set
    currentUrl.search = ''

    currentUrl.searchParams.set(key, value)
    window.history.pushState({}, '', currentUrl)

  }

  return (
    <>
      {isLoading ? (
        <Loader position="start" />
      ) : (
        <>
          {isDesktop ? (
            <Paper key="desktop-menu" sx={styles.container}>
              <MenuList sx={styles.list}>
                <MenuItem
                  sx={sx(
                    {
                      ...styles.menuItem,
                    },
                    {
                      sx: styles.menuActiveItem,
                      condition: !selectedCategoryValue && !Object.values(activeQuery).length,
                    }
                  )}
                  onClick={() => {
                    onSelectCategoryValue({});
                    clearQueryParams();
                  }
                }>
                  <ListItemText>{allFiltersName}</ListItemText>
                </MenuItem>

                <Divider />

                {filterOptions &&
                  Object.keys(filterOptions)
                    .map((key, index) => (
                      <Div key={key}>
                        <Typography sx={styles.menuItemLabel}>{filterOptions[key].label}</Typography>
                        {(!Array.isArray(filterOptions[key].values)
                          ? Object.values(filterOptions[key].values)
                          : filterOptions[key].values
                        ).map(value => (
                          <MenuItem
                            key={value?.value || value}
                            onClick={() => handleClickMenuItem( value?.key || key, (value?.value || value))}
                            sx={sx(
                              {
                                ...styles.menuItem,
                              },
                              {
                                sx: styles.menuActiveItem,
                                condition: (
                                  selectedCategoryValue === value.value ||
                                  selectedCategoryValue === value ||
                                  activeQuery[key] === value ||
                                  activeQuery[key] === value.value),
                              }
                            )}
                          >
                            <ListItemText>{value?.label || value}</ListItemText>
                          </MenuItem>
                        ))}

                        {index + 1 !== Object.keys(filterOptions).length && <Divider />}
                      </Div>
                    ))}
              </MenuList>
            </Paper>
          ) : (
            <PositionedMenu
              key="positioned-menu"
              allFiltersName={allFiltersName}
              filterOptions={filterOptions}
              handleClickMenuItem={handleClickMenuItem}
              selectedCategoryValue={selectedCategoryValue}
            />
          )}
        </>
      )}
    </>
  );
};

export default SideBar;
