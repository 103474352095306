import React, { type FC } from 'react';
import { Typography, Divider } from '@mui/material';

import styles from './styles';
import Div from '../../../../shared/ui/Div';

interface IDashboardSummaryHeader {
  name: string;
}

const DashboardSummaryHeader: FC<IDashboardSummaryHeader> = props => {
  const { name } = props;

  return (
    <Div sx={styles.root}>
      <Typography variant="h5" sx={styles.student}>
        {name}
      </Typography>
      <Divider variant="fullWidth" sx={styles.divider} />
    </Div>
  );
};

export default DashboardSummaryHeader;
