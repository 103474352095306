interface PaginationParams {
  currentPage: number,
  totalPages: number,
  itemsPerPage: number,
  availableItems: number
}
export const paginationPluralizeResult = (params: PaginationParams | undefined, items: number | undefined) => {
  if (params) {
    return `Displaying ${ params?.availableItems > 0 ? 1 : 0 }–${ items ?? 0 } of ${ params?.availableItems ?? 0 } results`
  }

  return `Displaying 0–0 of 0 results`
}
